/* eslint-disable object-shorthand */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-new */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import Radio from "@mui/material/Radio";
import { v4 as uuidv4 } from "uuid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Storage, db } from "FirebaseConfi";
import Compressor from "compressorjs";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { userTypeController } from "context/userInfo";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deleteIcon from "../../assets/images/delete-icon.svg";
import tickIcon from "../../assets/images/tick-icon.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import uploadIcon from "../../assets/images/upload-icon.svg";
import pdfIcon from "../../assets/images/pdf-icon.svg";
import panaromaIcon from "../../assets/images/360-icon.svg";
import exclamationMark from "../../assets/images/exclamation-mark.svg";
import { handleCancelToBackRouteButton } from "../../CommonFunctions/index";

function InspectionDetails() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [fetchedData, setFetchedData] = useState();
  const [selectedInspectionFormat, setSelectedInspectionFormat] = useState("pdf");
  const [selectedImages, setSelectedImages] = useState("");
  const [renovationDetailsText, setRenovationDetailsText] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dlt, setDlt] = useState(false);
  const [doneOpen, setDoneOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [deleteRenovationText, setDeleteRenovationText] = useState("");
  const [editRenovationID, setEditRenovationID] = useState("");
  const [inspectionDocuments, setInspectionDocuments] = useState(false);
  const [nextBtnClicked, setNextBtnClicked] = useState(false);
  const [onPurchaseYearChange, setonPurchaseYearChange] = useState(new Date());
  const [onConstructionYearChange, setonConstructionYearChange] = useState();
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  const [surrenderInspectionDetails, setSurrenderInspectionDetails] = useState({
    buildingType: "",
    present: "",
    yearOfPurchase: "",
  });
  const [propertyInformation, setPropertyInformation] = useState({
    documentDetails: "",
    propertyFauls: "",
    miscellaneousInfo: "",
    inspectionDocuments,
  });
  const [ocularInspectionDetails, setOcularInspectionDetails] = useState({
    specialCondition:
      "Byggnaden var vid besiktningstillfället fullt möblerad. Besiktning har skett av de delar som varit normalt åtkomliga utan omflyttning av möbler på belamrade ytor. Bakomliggande ytor ingår i köparens undersökningsplikt.\n För ytor, utrymmen och byggnadsdelar som noterats helt eller delvis ej besiktningsbara eller belamrade har besiktningsmannen inget ansvar.\n Notering ”-----” innebär att utrymmet/ytan bedöms vara i normalt skick med hänsyn taget till byggnadens ålder och byggnadssätt",
    oralTasks:
      "Avsikten har varit att i detta utlåtande redovisa samtliga förhållanden, som framkommit vid besiktningen och då även samtliga muntliga uppgifter av någon betydelse som lämnats av besiktningsmannen vid besiktningen.\nSkulle någon muntlig uppgift av betydelse ha utelämnats eller blivit felaktigt återgiven, enligt beställarens uppfattning, har beställaren att snarast och helst inom sju dagar meddela besiktningsmannen vad som enligt beställarens uppfattning skall ändras i utlåtandet.\nOm sådant meddelande inte lämnats inom ovan angiven tid kan inte besiktningsmannen göras ansvarig för eventuella brister i utlåtandet, som på så sätt kunnat rättas",
    constructionYear: "",
    weatherInfo: "",
    foundation: "",
    frameFloor: "",
    facade: "",
    window: "",
    roofDetails: "",
    heating: "",
    ventilation: "",
    water: "",
    Sewer: "",
    miscellaneousInfo: "",
  });
  const [renovationDetails, setRenovationDetails] = useState([]);
  useEffect(async () => {
    if (!companyUID) return;
    const docRef = doc(db, `companies/${companyUID}/projects`, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setFetchedData(docSnap.data());

      if (docSnap.data().surrenderInspectionDetails) {
        setSurrenderInspectionDetails(docSnap.data().surrenderInspectionDetails);
        setonPurchaseYearChange(
          new Date(`01/01/${docSnap.data().surrenderInspectionDetails?.yearOfPurchase}`)
        );
      }
      if (docSnap.data().propertyInformation) {
        setPropertyInformation(docSnap.data().propertyInformation);
        setInspectionDocuments(docSnap.data().propertyInformation.inspectionDocuments);
      }
      if (docSnap.data().ocularInspectionDetails) {
        setOcularInspectionDetails(docSnap.data().ocularInspectionDetails);
        setonConstructionYearChange(
          new Date(`01/01/${docSnap.data().ocularInspectionDetails?.constructionYear}`)
        );
      }
      if (docSnap.data().renovationDetails) {
        setRenovationDetails(docSnap.data().renovationDetails);
      }
      if (docSnap.data().selectedInspectionFormat) {
        setSelectedInspectionFormat(docSnap.data().selectedInspectionFormat);
      }
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }, [companyUID]);

  const [deleteRenovationID, setDeleteRenovationID] = useState("");

  useEffect(() => {
    if (!companyUID) return;
    const storageRef = ref(Storage, `/companies/${companyUID}/Projects/${id}/frontImage`);
    getDownloadURL(storageRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        setSelectedImages(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // eslint-disable-next-line default-case
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            // setImageLoading(false);
            // setImageLoadError(true);
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            alert("User doesn't have permission to access the object");
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            alert("Unknown error occurred, inspect the server response");
            break;
        }
      });
  }, [companyUID]);

  const handleRadioChange = (value) => {
    setSelectedInspectionFormat(value);
  };
  const onYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
  };
  const handleSurrenderFieldChange = (event) => {
    const { name, value } = event.target;
    setSurrenderInspectionDetails({
      ...surrenderInspectionDetails,
      [name]: value,
    });
  };
  const handlePropertyInfoFieldChange = (event) => {
    const { name, value } = event.target;
    setPropertyInformation({
      ...propertyInformation,
      [name]: value,
    });
  };
  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth,
        maxHeight,
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  }
  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };
  const hasEmptyFields = (fields, excludedFields = []) => {
    const keys = Object.keys(fields);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (!excludedFields.includes(key) && fields[key].trim() === "") {
        return true;
      }
    }
    return false;
  };
  const submitForm = () => {
    if (
      hasEmptyFields(surrenderInspectionDetails, ["yearOfPurchase"]) ||
      hasEmptyFields(ocularInspectionDetails, ["miscellaneousInfo", "constructionYear"]) ||
      hasEmptyFields(propertyInformation, [
        "propertyFauls",
        !inspectionDocuments && "documentDetails",
        "miscellaneousInfo",
        "inspectionDocuments",
      ]) ||
      !selectedImages ||
      !onPurchaseYearChange ||
      !onConstructionYearChange
    ) {
      toast.error("Please fill all the mandatory fields!");
    } else {
      try {
        const values = {
          surrenderInspectionDetails,
          propertyInformation,
          renovationDetails,
          ocularInspectionDetails,
          selectedInspectionFormat,
          frontImage: selectedImages,
        };
        const companyRef = doc(db, `companies/${companyUID}/projects/`, id);
        setDoc(companyRef, values, { merge: true }).then(() => {
          toast.success("Data Successfully Uploaded!");
        });
      } catch (e) {
        toast.error(JSON.stringify(e));
      }
    }
  };
  const handleImageChange = (event) => {
    try {
      // eslint-disable-next-line prefer-destructuring
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        const storageRef = ref(Storage, `/companies/${companyUID}/Projects/${id}/frontImage`);
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            const compressedFile = await compressImage(file, 800, 600);
            await uploadBytes(storageRef, compressedFile).then(() => {
              getDownloadURL(storageRef).then((downloadURL) => {
                setSelectedImages(downloadURL);
              });
            });
          } catch (error) {
            toast.error("Unable To upload image, Try Again!");
          }
        };
      }

      // setSelectedImages(URL.createObjectURL(file));
    } catch (error) {
      toast.error("Unable To upload image, Try Again!");
    }
  };

  const handleOcularFieldChange = (event) => {
    const { name, value } = event.target;
    setOcularInspectionDetails({
      ...ocularInspectionDetails,
      [name]: value,
    });
  };

  const handleCancelButton = () => {
    setOpen(false);
    setSelectedYear("");
    setRenovationDetailsText("");
  };
  const handleDoneButton = () => {
    setDoneOpen(false);
    setSelectedYear("");
    setRenovationDetailsText("");
    setDlt(false);
    setEdit(false);
  };
  const handleSaveButton = () => {
    if (edit) {
      setRenovationDetails((prevDetails) =>
        prevDetails.map((item) =>
          item.id === editRenovationID
            ? { ...item, year: selectedYear, renovationDetail: renovationDetailsText }
            : item
        )
      );
    } else {
      const uuid = generateUUID();
      const obj = {
        id: uuid,
        year: selectedYear,
        renovationDetail: renovationDetailsText,
      };
      setRenovationDetails((prevDetails) => [...prevDetails, obj]);
    }
    setDoneOpen(true);
    setOpen(false);
    setSelectedYear("");
    setRenovationDetailsText("");
  };
  const handleEditRenovation = (item) => {
    setEdit(true);
    setEditRenovationID(item.id);
    setSelectedYear(item.year);
    setRenovationDetailsText(item.renovationDetail);
    setOpen(true);
  };

  const handleModalDeleteButton = () => {
    if (renovationDetails?.length) {
      setRenovationDetails((prevDetails) =>
        prevDetails.filter((item) => item.id !== deleteRenovationID)
      );
    }
    setDeleteOpen(false);
    setDlt(true);
    setDeleteRenovationText("");
    setDoneOpen(true);
  };

  const handlePurchaseDateChange = (date) => {
    const year = new Date(date).getFullYear().toString();

    setSurrenderInspectionDetails({ ...surrenderInspectionDetails, yearOfPurchase: year });
    setonPurchaseYearChange(date);
  };
  const handleConstructionDateChange = (date) => {
    const year = new Date(date).getFullYear().toString();

    setOcularInspectionDetails({ ...ocularInspectionDetails, constructionYear: year });
    setonConstructionYearChange(date);
  };
  const handleNextClick = () => {
    setNextBtnClicked(true);
    if (
      hasEmptyFields(surrenderInspectionDetails, ["yearOfPurchase"]) ||
      hasEmptyFields(ocularInspectionDetails, ["miscellaneousInfo", "constructionYear"]) ||
      hasEmptyFields(propertyInformation, [
        "propertyFauls",
        !inspectionDocuments && "documentDetails",
        "miscellaneousInfo",
        "inspectionDocuments",
      ]) ||
      !selectedImages ||
      !onPurchaseYearChange ||
      !onConstructionYearChange
    ) {
      toast.error("Please fill all the mandatory fields!");
    } else {
      // Proceed to the next step
      // ...
      selectedInspectionFormat === "panaroma"
        ? navigate(`${currentPath}/InspectionImages`, { state: { data: fetchedData } })
        : navigate(`${currentPath}/InspectionNotes`, {
            state: { data: fetchedData },
          });
      console.log("surrenderInspectionDetails", surrenderInspectionDetails);
      console.log("propertyInformation", propertyInformation);
      console.log("renovationDetails", renovationDetails);
      console.log("ocularInspectionDetails", ocularInspectionDetails);
      console.log("selectedImage", selectedImages);
      console.log("selectedInspectionFormat", selectedInspectionFormat);
    }
  };
  useEffect(() => {
    setPropertyInformation({ ...propertyInformation, inspectionDocuments });
  }, [inspectionDocuments]);
  return (
    <DashboardLayout>
      <Header>
        <Headings>
          <Heading>Inspection details</Heading>
          <SubHeading>Enter inspection details so that PDF can be generated.</SubHeading>
        </Headings>
        <ActionButtons>
          <CancelButton
            onClick={() => {
              setNextBtnClicked(false);
              handleCancelToBackRouteButton();
            }}
          >
            Cancel
          </CancelButton>
          <NextButton onClick={submitForm}>Save</NextButton>
          <NextButton onClick={handleNextClick}>Next</NextButton>
        </ActionButtons>
      </Header>
      <Container>
        <InspectionSection style={{ paddingTop: "40px" }}>
          <ContentHeading>Statement of surrender inspection</ContentHeading>
          <FieldsContainer>
            <Div>
              <Field>
                <Label id="buildingType">Building Type</Label>
                <SelectField
                  labelId="buildingType"
                  id="demo-simple-select"
                  placeholder="Select Building Type"
                  value={surrenderInspectionDetails.buildingType}
                  name="buildingType"
                  onChange={handleSurrenderFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={surrenderInspectionDetails.buildingType.trim() === ""}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="apartment">Apartment</MenuItem>
                  <MenuItem value="villa">Villa</MenuItem>
                </SelectField>
                {surrenderInspectionDetails.buildingType.trim() === "" && nextBtnClicked && (
                  <Error>Select Building Type</Error>
                )}
              </Field>
              <Field>
                <Label id="yearOfPurchase">Year of purchase</Label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {" "}
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    views={["year"]}
                    value={onPurchaseYearChange}
                    onChange={(date) => {
                      handlePurchaseDateChange(date);
                    }}
                    minDate={new Date("1500-01-01")}
                    maxDate={new Date()}
                    format="yyyy"
                  />
                </LocalizationProvider>

                {/* <SelectField
                  labelId="yearOfPurchase"
                  id="demo-simple-select"
                  placeholder="Select Year"
                  value={surrenderInspectionDetails.yearOfPurchase}
                  name="yearOfPurchase"
                  onChange={handleSurrenderFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={surrenderInspectionDetails.yearOfPurchase.trim() === ""}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2022">2022</MenuItem>
                </SelectField> */}
                {!onPurchaseYearChange && nextBtnClicked && <Error>Select Year</Error>}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="present">Present</Label>
                <InputField
                  name="present"
                  placeholder="Enter Present Peoples"
                  value={surrenderInspectionDetails.present}
                  onChange={handleSurrenderFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={surrenderInspectionDetails.present.trim() === ""}
                />
                {surrenderInspectionDetails.present.trim() === "" && nextBtnClicked && (
                  <Error>Enter Present Peoples</Error>
                )}
              </Field>
            </Div>
          </FieldsContainer>
        </InspectionSection>
        <Hr />
        <InspectionSection>
          <ContentHeading>Property Information</ContentHeading>
          <PropertyFieldsSection>
            <DocumentField>
              <CheckBox>
                <Checkbox
                  label="document"
                  checked={inspectionDocuments}
                  onChange={(e) => setInspectionDocuments(e.target.checked)}
                />
                <ContentHeading>Documents provided at the time of inspection</ContentHeading>
              </CheckBox>
              {inspectionDocuments && (
                <>
                  {" "}
                  <Textarea
                    rows={6}
                    placeholder="Enter detail of all documents provided at the time of inspecton"
                    value={propertyInformation.documentDetails}
                    name="documentDetails"
                    onChange={handlePropertyInfoFieldChange}
                    nextBtnClicked={nextBtnClicked}
                    isEmpty={propertyInformation.documentDetails.trim() === ""}
                  />
                  {propertyInformation.documentDetails.trim() === "" &&
                    nextBtnClicked &&
                    inspectionDocuments && <Error>Enter Document Details</Error>}
                </>
              )}
            </DocumentField>
            <RenovationSection>
              <ContentHeading>Renovation details</ContentHeading>
              <AddmoreBtn
                renovationDetails={renovationDetails?.length}
                onClick={() => {
                  setOpen(true);
                  setEdit(false);
                }}
              >
                {renovationDetails?.length ? (
                  <span>
                    <AddIcon /> Add more
                  </span>
                ) : (
                  <span style={{ color: "#6941C6", fontSize: "14px", fontWeight: "600" }}>
                    Add renovation details
                  </span>
                )}
              </AddmoreBtn>
              {renovationDetails?.map((item) => (
                <RenovationDetail>
                  <Tag>{item.year}</Tag>
                  <Text>{item.renovationDetail}</Text>
                  <ActionBtns>
                    <img src={editIcon} alt="edit" onClick={() => handleEditRenovation(item)} />
                    <img
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        setDeleteOpen(true);
                        setDeleteRenovationID(item.id);
                        setDeleteRenovationText(item.renovationDetail);
                      }}
                    />
                  </ActionBtns>
                </RenovationDetail>
              ))}
            </RenovationSection>
            <Div>
              <Field>
                <Label id="propertyFaults">Property faults</Label>
                <Textarea
                  rows={6}
                  placeholder="Enter if there is any property fault information"
                  value={propertyInformation.propertyFauls}
                  name="propertyFauls"
                  onChange={handlePropertyInfoFieldChange}
                />
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="miscellaneousInformation">Miscellaneous information</Label>
                <Textarea
                  rows={6}
                  placeholder="Enter if there is any miscellaneous information"
                  value={propertyInformation.miscellaneousInfo}
                  name="miscellaneousInfo"
                  onChange={handlePropertyInfoFieldChange}
                />
              </Field>
            </Div>
          </PropertyFieldsSection>
        </InspectionSection>
        <Hr />
        <InspectionSection>
          <ContentHeading>Ocular Inspection</ContentHeading>
          <FieldsContainer>
            <Div>
              <Field>
                <Label id="specialCondition">Special conditions during inspection</Label>
                <Textarea
                  rows={6}
                  placeholder="Enter information about the special condition of property during inspection"
                  value={ocularInspectionDetails.specialCondition}
                  name="specialCondition"
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.specialCondition.trim() === ""}
                />
                {ocularInspectionDetails.specialCondition.trim() === "" && nextBtnClicked && (
                  <Error>EnterSpecial Condition</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="oralTasks">Oral tasks</Label>
                <Textarea
                  rows={6}
                  placeholder="Enter details of oral tasks"
                  value={ocularInspectionDetails.oralTasks}
                  name="oralTasks"
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.oralTasks.trim() === ""}
                />
                {ocularInspectionDetails.oralTasks.trim() === "" && nextBtnClicked && (
                  <Error>Enter Oral Tasks</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="constructionYear">Construction year</Label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {" "}
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    views={["year"]}
                    value={onConstructionYearChange}
                    onChange={(date) => {
                      handleConstructionDateChange(date);
                    }}
                    minDate={new Date("1500-01-01")}
                    maxDate={new Date()}
                    format="yyyy"
                  />
                </LocalizationProvider>
                {/* <SelectField
                  // sx={{ width: 200, height: 50 }}
                  labelId="constructionYear"
                  id="demo-simple-select"
                  placeholder="Select Year"
                  value={ocularInspectionDetails.constructionYear}
                  name="constructionYear"
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.constructionYear.trim() === ""}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                </SelectField> */}
                {!onConstructionYearChange && nextBtnClicked && (
                  <Error>Select construction year</Error>
                )}
              </Field>
              <Field>
                <Label id="weatherInfo">Weather information</Label>
                <InputField
                  name="weatherInfo"
                  placeholder="Enter Property Destination"
                  value={ocularInspectionDetails.weatherInfo}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.weatherInfo.trim() === ""}
                />
                {ocularInspectionDetails.weatherInfo.trim() === "" && nextBtnClicked && (
                  <Error>Enter Weather Information</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="roofDetails">Enter roof details</Label>
                <InputField
                  name="roofDetails"
                  placeholder="Enter roof details"
                  value={ocularInspectionDetails.roofDetails}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.roofDetails.trim() === ""}
                />
                {ocularInspectionDetails.roofDetails.trim() === "" && nextBtnClicked && (
                  <Error>Enter Roof Details</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="facade">Facade</Label>
                <InputField
                  name="facade"
                  placeholder="Enter facade details"
                  value={ocularInspectionDetails.facade}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.facade.trim() === ""}
                />
                {ocularInspectionDetails.facade.trim() === "" && nextBtnClicked && (
                  <Error>Enter Facade Details</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="frameFloor">Frame/Floor</Label>
                <InputField
                  name="frameFloor"
                  placeholder="Enter building frame or floor type"
                  value={ocularInspectionDetails.frameFloor}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.frameFloor.trim() === ""}
                />
                {ocularInspectionDetails.frameFloor.trim() === "" && nextBtnClicked && (
                  <Error>Enter Frame/Floor Information</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="window">Window</Label>
                <InputField
                  name="window"
                  placeholder="Enter windows details"
                  value={ocularInspectionDetails.window}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.window.trim() === ""}
                />
                {ocularInspectionDetails.window.trim() === "" && nextBtnClicked && (
                  <Error>Enter Window Details</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="foundation">Foundation</Label>
                <InputField
                  name="foundation"
                  placeholder="Enter building foundation detail"
                  value={ocularInspectionDetails.foundation}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.foundation.trim() === ""}
                />
                {ocularInspectionDetails.foundation.trim() === "" && nextBtnClicked && (
                  <Error>Enter Foundation</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="ventilation">Ventilation</Label>
                <InputField
                  name="ventilation"
                  placeholder="Enter ventilation details"
                  value={ocularInspectionDetails.ventilation}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.ventilation.trim() === ""}
                />
                {ocularInspectionDetails.ventilation.trim() === "" && nextBtnClicked && (
                  <Error>Enter Ventilation Details</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="heating">Heating</Label>
                <InputField
                  name="heating"
                  placeholder="Enter heating details"
                  value={ocularInspectionDetails.heating}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails.heating.trim() === ""}
                />
                {ocularInspectionDetails.heating.trim() === "" && nextBtnClicked && (
                  <Error>Enter Heating Details</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="water">Water</Label>
                <SelectField
                  labelId="water"
                  id="demo-water-select"
                  placeholder="water"
                  value={ocularInspectionDetails?.water}
                  name="water"
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails?.water?.trim() === ""}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem value="Saknas">Saknas</MenuItem>
                  <MenuItem value="Enskild">Enskild</MenuItem>
                  <MenuItem value="Kommunalt">Kommunalt</MenuItem>
                </SelectField>
                {ocularInspectionDetails?.water?.trim() === "" && nextBtnClicked && (
                  <Error>Select water Type</Error>
                )}
              </Field>
            </Div>
            <Div>
              <Field>
                <Label id="Sewer">Sewer</Label>
                <SelectField
                  labelId="Sewer"
                  id="demo-Sewer-select"
                  placeholder="Sewer"
                  value={ocularInspectionDetails?.Sewer}
                  name="Sewer"
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails?.Sewer?.trim() === ""}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem value="Saknas">Saknas</MenuItem>
                  <MenuItem value="Enskild">Enskild</MenuItem>
                  <MenuItem value="Kommunalt">Kommunalt</MenuItem>
                </SelectField>
                {ocularInspectionDetails?.Sewer?.trim() === "" && nextBtnClicked && (
                  <Error>Select Sewer Type</Error>
                )}
              </Field>
            </Div>
            {/* <Div>
              <Field>
                <Label id="Sewer">Sewer</Label>
                <InputField
                  name="waterAndSewer"
                  placeholder="Enter water and sewer details"
                  value={ocularInspectionDetails?.Sewer}
                  onChange={handleOcularFieldChange}
                  nextBtnClicked={nextBtnClicked}
                  isEmpty={ocularInspectionDetails?.Sewer?.trim() === ""}
                />
                {ocularInspectionDetails?.Sewer?.trim() === "" && nextBtnClicked && (
                  <Error>Enter Sewer Details</Error>
                )}
              </Field>
            </Div> */}
            <Div>
              <Field>
                <Label id="miscellaneousInfo">Miscellaneous information</Label>
                <Textarea
                  rows={6}
                  placeholder="Enter if there is any miscellaneous information"
                  value={ocularInspectionDetails.miscellaneousInfo}
                  onChange={handleOcularFieldChange}
                  name="miscellaneousInfo"
                />
              </Field>
            </Div>
          </FieldsContainer>
        </InspectionSection>
        <Hr />
        <InspectionSection>
          <ContentHeading>Property Images</ContentHeading>
          <FieldsContainer>
            <Div>
              <Field>
                <Label id="propertyImages">Add images of property</Label>
                <Uploader nextBtnClicked={nextBtnClicked} isEmpty={!selectedImages?.length}>
                  <img src={uploadIcon} alt="upload" />
                  <UploadBtn
                    accept="image/svg+xml,image/png,image/jpeg,image/gif"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <UploadInstructionText>
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </UploadInstructionText>
                  {selectedImages && (
                    <div style={{ width: "50%", height: "150px" }}>
                      <img
                        style={{ width: "-webkit-fill-available", height: "inherit" }}
                        src={selectedImages}
                        alt="dummyImg"
                      />
                    </div>
                  )}
                </Uploader>
                {!selectedImages && nextBtnClicked && <Error>Upload Image</Error>}
              </Field>
            </Div>
          </FieldsContainer>
        </InspectionSection>
        <Hr />
        <InspectionSection style={{ paddingBottom: "8%" }}>
          <ContentHeading>Inspection format</ContentHeading>
          <FieldsContainer style={{ gap: "12px !important" }}>
            <Format nextBtnClicked={nextBtnClicked} isEmpty={!selectedInspectionFormat}>
              <RadioOption>
                <img src={pdfIcon} alt="pdf" />
                <TextSpan> PDF Format </TextSpan>
              </RadioOption>
              <Radio
                onChange={() => handleRadioChange("pdf")}
                checked={selectedInspectionFormat === "pdf"}
              />
            </Format>
            <Format nextBtnClicked={nextBtnClicked} isEmpty={!selectedInspectionFormat}>
              <RadioOption>
                <img src={panaromaIcon} alt="pdf" />
                <TextSpan> 360 Format </TextSpan>
              </RadioOption>
              <Radio
                onChange={() => handleRadioChange("panaroma")}
                checked={selectedInspectionFormat === "panaroma"}
              />
            </Format>
            {!selectedInspectionFormat && nextBtnClicked && <Error>Select One Format</Error>}
          </FieldsContainer>
        </InspectionSection>
      </Container>

      {/* Add and Edit Renovation Modal */}
      <CustomDialog open={open} onClose={handleCancelButton}>
        <DialogTitle
          sx={{
            color: "#101828",
            fontSize: "18px",
            padding: "24px",
            paddingBottom: "0px !important",
          }}
        >
          {edit ? "Edit" : "Add"} Renovation
        </DialogTitle>
        <DialogContent sx={{ padding: "24px !important" }}>
          <DialogFieldDiv>
            <ModalField>
              <Label id="yearOfRenovation">Year of rennovation</Label>
              <TextField
                value={selectedYear}
                onChange={onYearChange}
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 100,
                        width: 150,
                      },
                    },
                  },
                }}
              />
            </ModalField>
            <ModalField>
              <Label id="renovationDetails">Renovation details</Label>
              <Textarea
                rows={6}
                value={renovationDetailsText}
                onChange={(e) => setRenovationDetailsText(e.target.value)}
                placeholder="Enter detail of all documents provided at the time of inspecton"
              />
            </ModalField>
            <DialogBtnsDiv>
              <RandomButton
                dialogBtns="yes"
                sx={{
                  width: "100%",
                  textAlgn: "center",
                  color: "#344054 !important",
                  border: "1px solid #D0D5DD",
                }}
                purple="false"
                variant="contained"
                color="primary"
                onClick={handleCancelButton}
              >
                Cancel
              </RandomButton>
              <RandomButton
                dialogBtns="yes"
                sx={{ width: "100%", textAlgn: "center" }}
                purple="true"
                variant="contained"
                color="primary"
                disabled={!renovationDetailsText || !selectedYear}
                onClick={handleSaveButton}
              >
                {edit ? "Update" : "Add"}
              </RandomButton>
            </DialogBtnsDiv>
          </DialogFieldDiv>
        </DialogContent>
      </CustomDialog>
      {/* Delete Modal  */}
      <CustomDialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DoneContent>
          <img src={exclamationMark} width="48px" alt="done" />
          <DoneTypography sx={{ marginBottom: "8px !important" }}>
            Are you sure to delete {deleteRenovationText?.slice(0, 7)}
            {deleteRenovationText?.length > 7 ? "..." : ""}?
          </DoneTypography>
          <DoneSubTypography>You can not recover once the renovation is deleted.</DoneSubTypography>
          <DialogBtnsDiv sx={{ width: "100%" }}>
            <RandomButton
              dialogBtns="yes"
              sx={{
                width: "100%",
                textAlgn: "center",
                color: "#344054 !important",
                border: "1px solid #D0D5DD",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={() => setDeleteOpen(false)}
            >
              Cancel
            </RandomButton>
            <RandomButton
              dialogBtns="yes"
              sx={{
                width: "100%",
                textAlgn: "center",
                marginRight: "0",
                background: "#D92D20 !important",
                color: "white !important",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={handleModalDeleteButton}
            >
              Delete
            </RandomButton>
          </DialogBtnsDiv>
        </DoneContent>
      </CustomDialog>
      {/* Done Modal */}
      <CustomDialog open={doneOpen} onClose={handleDoneButton}>
        <DoneContent>
          <img src={tickIcon} width="48px" alt="done" />
          <DoneTypography>
            Text {dlt ? "Deleted" : edit ? "Updated" : "Added"} Successfully
          </DoneTypography>
          <RandomButton
            dialogBtns="yes"
            sx={{ width: "100%", textAlgn: "center", marginRight: "0" }}
            purple="true"
            variant="contained"
            color="primary"
            onClick={handleDoneButton}
          >
            Done
          </RandomButton>
        </DoneContent>
      </CustomDialog>
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </DashboardLayout>
  );
}
export default InspectionDetails;

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "30%",
  },
});
const DialogFieldDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#fff",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  // padding: dialogBtns === "yes" ? "10px 18px" : "14px 28px",
  padding: "10px 18px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: dialogBtns === "yes" ? "600" : "",
  fontSize: "16px",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#fff",
  },
}));
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const ModalField = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
});
const DoneTypography = styled(Typography)({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
  marginTop: "20px",
  marginBottom: "32px",
});
const DoneContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  alignItems: "center",
});
const DoneSubTypography = styled(Typography)({
  color: "#667085",
  fontSize: "14px",
  marginBottom: "32px",
});
const Container = styled("div")({
  background: "#FCFCFD",
  padding: "0px 40px",
});
const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "32px 40px 16px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  flexWrap: "wrap",

  position: "sticky",
  top: 0,
  zIndex: 1,
});
const Headings = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Heading = styled("p")({
  color: "#101828",
  fontSize: "20px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const ActionButtons = styled("div")({
  display: "flex",
  gap: "12px",
});
const CancelButton = styled("button")({
  cursor: "pointer",
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  fontFamily: "Inter",
  height: "max-content",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const NextButton = styled("button")({
  cursor: "pointer",
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Inter",
  height: "max-content",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const InspectionSection = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
  flexWrap: "wrap",
  justifyContent: "space-between",
});
const Hr = styled("hr")({
  border: "1px solid #EAECF0",
  marginTop: "32px",
  marginBottom: "32px",
});
const ContentHeading = styled("p")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "capitalize",
});
const FieldsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "70%",
  "@media (min-width: 0px) and (max-width: 1175px)": {
    width: "100% !important",
  },
});
const Label = styled(InputLabel)({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
});
const Div = styled("div")({
  display: "flex",
  gap: "24px",
  "@media (min-width: 0px) and (max-width: 486px)": {
    flexWrap: "wrap",
  },
});
const Field = styled("div")({
  display: "flex",
  gap: "6px",
  flexDirection: "column",
  width: "-webkit-fill-available",
});
const InputField = styled(TextField)(({ nextBtnClicked, isEmpty }) => ({
  background: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired hover border color
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired focused border color
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired clicked border color
  },
}));
const SelectField = styled(Select)(({ nextBtnClicked, isEmpty }) => ({
  background: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired hover border color
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired focused border color
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired clicked border color
  },
}));
const PropertyFieldsSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "70%",
  "@media (min-width: 0px) and (max-width: 1175px)": {
    width: "100% !important",
  },
});
const DocumentField = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const RenovationSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const CheckBox = styled("div")({
  display: "flex",
  gap: "8px",
  alignItems: "center",
});
const Textarea = styled("textarea")(({ nextBtnClicked, isEmpty }) => ({
  borderRadius: "8px",
  // border: "1px solid #D0D5DD",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "10px",
  fontFamily: "inherit",
  border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
}));
const AddmoreBtn = styled("button")(({ renovationDetails }) => ({
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: renovationDetails ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  width: "-webkit-fill-available",
  color: "white",
  padding: "8px 14px",
}));
const RenovationDetail = styled("div")({
  display: "flex",
  gap: "16px",
  justifyContent: "space-between",
  padding: "16px",
  borderRadius: "8px",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  alignItems: "center",
});
const Tag = styled("p")({
  padding: "2px 12px",
  borderRadius: "28px",
  background: "#FFF",
  fontSize: "14px",
  flex: "0 0 auto",
});
const ActionBtns = styled("div")({
  display: "flex",
  gap: "16px",
  flex: "0 0 auto",
});
const Text = styled("p")({
  color: "#475467",
  fontSize: "14px",
  fontWeight: "500",
  display: "flex",
  flexWrap: "wrap",
  flex: "1 0 auto",
  maxWidth: "80%",
});
const Uploader = styled("div")(({ nextBtnClicked, isEmpty }) => ({
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: nextBtnClicked && isEmpty ? "1px solid red" : "1px solid #EAECF0",
  background: "#FFF",
}));
const UploadBtn = styled("input")({
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
});
const UploadInstructionText = styled("p")({
  color: "#667085",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: "400",
});
const RadioOption = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "14px",
});
const TextSpan = styled("span")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
});
const Format = styled("div")(({ nextBtnClicked, isEmpty }) => ({
  borderRadius: "8px",
  border: nextBtnClicked && isEmpty ? "1px solid red" : "1px solid #EAECF0",
  background: "#FFF",
  display: "flex",
  padding: "16px",
  justifyContent: "space-between",
}));
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
const Error = styled("div")({
  fontSize: "12px",
  color: "red",
  paddingLeft: "5px",
});
