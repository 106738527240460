/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable no-new */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import * as React from "react";

import Drawer from "@mui/material/Drawer";

import { useState, useEffect, useRef } from "react";
import { userTypeController } from "context/userInfo";
import { styled } from "@mui/material/styles";
import Compressor from "compressorjs";
import {
  getDownloadURL,
  uploadBytes,
  ref as StorageRef,
  getStorage,
  deleteObject,
} from "firebase/storage";
import { onValue, ref } from "firebase/database";
import { rdb, Storage } from "FirebaseConfi";
import InspectionNoteModal from "layouts/AdminLayouts/InspectionNotes/InspectionNoteModal";
import { Box, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { v1, v4 } from "uuid";
import replaceIcon from "../../../assets/images/replaceIcon.svg";
import deleteIcon from "../../../assets/images/trash-2.svg";
import closeModalIcon from "../../../assets/images/closeModalIcon.svg";

export default function HotspotModal({
  isUpdateHotspot,
  updatingHotspotData,
  open,
  handleClose,
  addHotSpot,
  pitchPoint,
  yawPoint,
  RemoveHotspot,
  UpdateHotspot,
  ProjectId,
}) {
  const [textLibraryData, setTextLibraryData] = useState({});
  const [selectedImages, setSelectedImages] = useState("");

  const [updateData, setUpdateData] = useState();
  const [HotsportImageUid, setUIdHotsportImageUid] = useState();

  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const fileInputRef = useRef(null);

  const storage = getStorage();
  useEffect(() => {
    if (companyUID) {
      const dataRef = ref(rdb, `/companies/${companyUID}/textLibrary`);
      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData !== null) setTextLibraryData(fetchedData);
      });
    }
  }, [companyUID]);
  useEffect(() => {
    console.log(updateData);
  }, [updateData]);

  const generateUUID = () => {
    const uuid = v4();
    return uuid;
  };
  useEffect(() => {
    // console.log(updatingHotspotData);
    if (isUpdateHotspot) setUpdateData(updatingHotspotData);
    if (isUpdateHotspot && updatingHotspotData?.textImage)
      setSelectedImages(updatingHotspotData?.textImage);
  }, [isUpdateHotspot]);
  useEffect(() => {
    const id = generateUUID().slice(0, 8);
    if (!isUpdateHotspot) setUIdHotsportImageUid(id);
    if (!isUpdateHotspot) setSelectedImages("");
  }, [open]);

  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        strict: true,
        checkOrientation: true,
        maxWidth,
        maxHeight,
        minWidth: 0,
        minHeight: 0,
        width: 3698,
        height: undefined,
        resize: "none",
        quality: 0.8,
        mimeType: "",
        convertTypes: "image/png",
        convertSize: 5000000,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
      // new Compressor(file, {
      //  maxWidth,
      //  maxHeight,
      //  success(result) {
      //    resolve(result);
      //  },
      //  error(error) {
      //    reject(error);
      //  },
      // });
    });
  }

  const handleImageChange = (event) => {
    try {
      // eslint-disable-next-line prefer-destructuring
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        const storageRef = StorageRef(
          Storage,
          `/companies/${companyUID}/Projects/${ProjectId}/hotspotImage/${HotsportImageUid}`
        );
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            const compressedFile = await compressImage(file, 400, 200);
            await uploadBytes(storageRef, compressedFile).then(() => {
              getDownloadURL(storageRef).then((downloadURL) => {
                setSelectedImages(downloadURL);
              });
            });
          } catch (error) {
            toast.error("Unable To upload image, Try Again!");
          }
        };
      }

      // setSelectedImages(URL.createObjectURL(file));
    } catch (error) {
      toast.error("Unable To upload image, Try Again!");
    }
  };

  const handleDelete = (callFrom) => {
    const desertRef = StorageRef(
      storage,
      `/companies/${companyUID}/Projects/${ProjectId}/hotspotImage/${HotsportImageUid}`
    );
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        setSelectedImages("");

        if (callFrom !== "Cancel" || callFrom === "onClose") {
          toast.success("File Deleted Successfully!");
        }
      })
      .catch((error) => {
        toast.error("Uh-oh, an error occurred!!");
      });
  };
  return (
    <div>
      <Drawer
        PaperProps={{
          style: {
            width: "463px",
          },
        }}
        anchor="right"
        open={open}
        onClose={() => {
          handleClose();
          setUpdateData(null);
          if (selectedImages && !isUpdateHotspot) handleDelete("onClose");
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <Header>
            <Heading>Add Hotspot</Heading>

            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
                setUpdateData(null);
              }}
              src={closeModalIcon}
              alt="_close"
            />
          </Header>
          <Box p={2}>
            {selectedImages === "" && (
              <AddUploadButton
                className="custom-input-button"
                onClick={() => fileInputRef.current.click()}
              >
                + Add Image
              </AddUploadButton>
            )}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/svg+xml,image/png,image/jpeg,image/gif"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />

            {selectedImages !== "" && (
              <ImageDiv>
                <ImgaeNameHeader>
                  <ImageName>Add Image</ImageName>
                  <ActionBtns>
                    <ReplaceBtn onClick={() => fileInputRef.current.click()}>
                      Replace <img src={replaceIcon} alt="_replace" />
                    </ReplaceBtn>
                    <DeleteBtn onClick={handleDelete}>
                      Delete <img src={deleteIcon} alt="_delete" />
                    </DeleteBtn>
                  </ActionBtns>
                </ImgaeNameHeader>
                <Image>
                  <Img src={selectedImages} alt="_dummy" />
                </Image>
              </ImageDiv>
            )}
            <ImageName style={{ marginBottom: "16px", marginTop: "24px" }}>Add Notes</ImageName>

            <InspectionNoteModal
              textLibraryData={textLibraryData}
              setUpdateData={setUpdateData}
              update={isUpdateHotspot}
              updateData={updateData}
              setAddBtnDisable
              addTextLibraryBtnDisabled
            />
          </Box>
          <Footer>
            {isUpdateHotspot && (
              <DeleteBtn
                onClick={() => {
                  RemoveHotspot(updateData?.ID);
                  handleClose();
                  setUpdateData(null);
                }}
              >
                Delete <img src={deleteIcon} alt="_delete" />
              </DeleteBtn>
            )}
            <CancelButton
              onClick={() => {
                if (selectedImages && !isUpdateHotspot) handleDelete("Cancel");
                handleClose();
                setUpdateData(null);
              }}
            >
              Cancel
            </CancelButton>
            <ActionBtn
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                isUpdateHotspot
                  ? UpdateHotspot(updateData)
                  : addHotSpot(pitchPoint, yawPoint, updateData, selectedImages);
                handleClose();
                setUpdateData(null);
              }}
              // disabled={!selectedPosition || !selectedTag || !selectedValue}
            >
              {isUpdateHotspot ? "Update Hotspot" : "Save Hotspot"}
            </ActionBtn>
          </Footer>
        </div>
      </Drawer>
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
const Header = styled("div")({
  display: "flex",
  paddingTop: "16px",
  paddingBottom: "16px",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  borderBottom: "1px solid #EAECF0",
  paddingRight: "16px",
  paddingLeft: "16px",
  position: "sticky",
  top: "0",
  width: "100%",
  background: "white",
});
const Heading = styled("p")({
  color: "#000",
  fontSize: "20px",
  fontWeight: "500",
});
const AddUploadButton = styled("button")(({ uploading }) => ({
  padding: "6px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  textAlign: "center",
  cursor: !uploading ? "pointer" : "not-allowed",
}));
const ImageDiv = styled("div")({
  display: "flex",
  maginTop: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
});
const Image = styled("div")({
  width: "415px",
  height: "280px",
  flexShrink: "0",
  alignSelf: "stretch",
  borderRadius: "12px",
});
const Img = styled("img")({
  width: "inherit",
  height: "280px",
  borderRadius: "12px",
});
const ImgaeNameHeader = styled("div")({
  display: "flex",
  paddingRight: "0px",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignSelf: "stretch",
});
const ImageName = styled("div")({
  color: "#101828",
  fontSize: "14px",
  fontWeight: "600",
});
const ActionBtns = styled("div")({
  display: "flex",
  gap: "16px",
});
const ReplaceBtn = styled("p")({
  display: "flex",
  gap: "4px",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
});
const DeleteBtn = styled("p")({
  display: "flex",
  gap: "4px",
  color: "#B42318",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
});
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
const Footer = styled("div")({
  display: "flex",
  padding: "24px",
  justifyContent: "right",
  alignItems: "center",
  gap: "12px",
  borderTop: "1px solid #EAECF0",
  background: "white",
  position: "sticky",
  bottom: "0",
});
const CancelButton = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
});
const ActionBtn = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#FFF",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
});
