/* eslint-disable import/no-named-as-default */
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material";

// import PanoramaCanvas from "./components/PanoramaCanvas";
import "./components/pannellum.css";
import PanoramaCanvas from "./components/PanoramaCanvas";

function index() {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const location = useLocation();
  const Data = location.state?.pdfData;

  return (
    <DashboardLayout>
      <Header>
        <Headings>
          <Heading>3D Virtual Tour</Heading>
          <SubHeading>Add floor and building panorama images to create a virtual tour.</SubHeading>
        </Headings>
        <ActionButtons>
          <CancelButton
          // onClick={() => setNextBtnClicked(false)}
          >
            Cancel
          </CancelButton>
          <NextButton
          // onClick={handleNextClick}
          >
            Next
          </NextButton>
          <NextButton onClick={() => setOpenPreviewModal(true)}>Export</NextButton>
        </ActionButtons>
      </Header>
      <PanoramaCanvas
        setOpenPreviewModal={setOpenPreviewModal}
        openPreviewModal={openPreviewModal}
        Data={Data}
      />
    </DashboardLayout>
  );
}

export default index;
const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "19px 34px 0px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  flexWrap: "wrap",
});

const Heading = styled("p")({
  color: "#000",
  fontsize: "24px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});

const Headings = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const ActionButtons = styled("div")({
  display: "flex",
  gap: "12px",
});
const CancelButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  fontFamily: "Inter",
  height: "max-content",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const NextButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Inter",
  height: "max-content",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
