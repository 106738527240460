// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React example components

import PageLayout from "WebItems/LayoutContainers/PageLayout";

// Authentication pages components

function BasicLayout({ children }) {
  return (
    <PageLayout>
      <Grid
        sx={{
          background:
            "linear-gradient(234deg, rgba(87, 155, 247, 0.10) 0%, rgba(197, 198, 200, 0.10) 28.76%, rgba(87, 155, 247, 0.10) 76.58%, rgba(87, 155, 247, 0.10) 100%), linear-gradient(111deg, rgba(21, 26, 45, 0.06) 0%, rgba(179, 57, 252, 0.29) 5.94%, rgba(161, 76, 251, 0.25) 24.11%, rgba(87, 155, 247, 0.28) 37.11%, rgba(87, 155, 247, 0.33) 54.15%, rgba(124, 113, 246, 0.31) 63.80%, rgba(165, 67, 244, 0.29) 74.69%, rgba(165, 67, 244, 0.29) 98.44%, rgba(165, 67, 244, 0.29) 98.45%)",
        }}
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item xs={12} sm={9} md={5} lg={4} xl={3}>
          {children}
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
