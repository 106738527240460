import React from "react";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import DashboardNavbar from "WebItems/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import CollectPDFDataForSeller from "./components/collectPdfData/CollectMoreProjectData.js";
// import PanoramaProject from "./components/Panorama";
import NotingerManager from "../NotingerManager";

function Index() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox m={5}>
        <CollectPDFDataForSeller />
        <NotingerManager />
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
