/* eslint-disable no-console */
/* eslint-disable func-names */
import * as Yup from "yup";
import CreateCompanyFormModel from "./CreateCompanyFormModel";

const {
  formField: {
    CompanyName,
    OrganizationNumber,
    AdminName,
    AdminEmail,
    AdminConfimEmail,
    AdminPassword,
  },
} = CreateCompanyFormModel;
// const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [CompanyName.name]: Yup.string().required(`${CompanyName.requiredErrorMsg}`),
    [OrganizationNumber.name]: Yup.string().required(`${OrganizationNumber.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [AdminName.name]: Yup.string().required(`${AdminName.requiredErrorMsg}`),
    [AdminEmail.name]: Yup.string().required(`${AdminEmail.requiredErrorMsg}`).email(),
    [AdminConfimEmail.name]: Yup.string()
      .required(`${AdminConfimEmail.requiredErrorMsg}`)
      .oneOf([Yup.ref(AdminEmail.name), null], "Email must match"),
    [AdminPassword.name]: Yup.string()
      .required(`${AdminPassword.requiredErrorMsg}`)
      .min(6, "Your password is too short."),
  }),
  Yup.object().shape({
    Position: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, {
        message: "No special characters allowed",
        excludeEmptyString: true,
      }),
  }),
];
