/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line react/prop-types
/* eslint-disable-next-line react/prop-types */

import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, uploadBytes, ref as StorageRef } from "firebase/storage";
import { useParams } from "react-router-dom";
import { Storage } from "FirebaseConfi";
import CreatableSelect from "react-select/creatable";
import { userTypeController } from "context/userInfo";
import Compressor from "compressorjs";
import disabledRemoveIcon from "../../../../assets/images/disabled-remove.svg";
import removeIcon from "../../../../assets/images/remove.svg";

function InspectionNoteModal({
  textLibraryData,
  setUpdateData,
  update,
  updateData,
  addTextLibraryBtnDisabled,
}) {
  const [updatingData, setUpdatingData] = useState(updateData || { step1: null });
  const [activeStep1, setActiveStep1] = useState();
  const [activeStep3, setActiveStep3] = useState();
  const [step2Array, setStep2Array] = useState({});
  const [notesArray, setNotesArray] = useState([]);
  const [riskOptionsArray, setriskOptionsArray] = useState([]);
  const [ftuOptionsArray, setFtuOptionsArray] = useState([]);
  const [extraFilter] = useState(["KÄLLARVÅNING", "ENTRÉPLAN", "ÖVERVÅNING", "VIND"]);
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  // DummyData For the functionality purpose

  useEffect(() => {
    if (update) {
      setActiveStep1(updatingData.step1);
      setActiveStep3(updatingData.step3.key);
    }
  }, []);
  useEffect(() => {
    setUpdateData(updatingData);
  }, [updatingData]);
  // on changing text library fields
  const handleFieldChange = (e, ID, type) => {
    const indexToUpdate = updatingData.textpoints.findIndex((item) => item.id === ID);
    if (indexToUpdate !== -1) {
      if (type === "text") {
        const newTextpoints = [...updatingData.textpoints];
        newTextpoints[indexToUpdate] = {
          ...newTextpoints[indexToUpdate],
          text: e.label,
          value: e.value,
          lable: e.label,
        };

        // Update the state with the new textpoints array
        setUpdatingData({
          ...updatingData,
          textpoints: newTextpoints,
        });
      } else if (type === "risk") {
        const newTextpoints = [...updatingData.textpoints];
        newTextpoints[indexToUpdate] = {
          ...newTextpoints[indexToUpdate],
          riskText: { text: e.label, value: e.value },
        };

        // Update the state with the new textpoints array
        setUpdatingData({
          ...updatingData,
          textpoints: newTextpoints,
        });
      } else if (type === "ftu") {
        const newTextpoints = [...updatingData.textpoints];
        newTextpoints[indexToUpdate] = {
          ...newTextpoints[indexToUpdate],
          ftuText: { text: e.label, value: e.value },
        };

        // Update the state with the new textpoints array
        setUpdatingData({
          ...updatingData,
          textpoints: newTextpoints,
        });
      }
    }
  };

  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        strict: true,
        checkOrientation: true,
        maxWidth,
        maxHeight,
        minWidth: 0,
        minHeight: 0,
        width: 3698,
        height: undefined,
        resize: "none",
        quality: 0.8,
        mimeType: "",
        convertTypes: "image/png",
        convertSize: 5000000,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
      // new Compressor(file, {
      //  maxWidth,
      //  maxHeight,
      //  success(result) {
      //    resolve(result);
      //  },
      //  error(error) {
      //    reject(error);
      //  },
      // });
    });
  }

  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };

  const handleImageChange = (event) => {
    try {
      // eslint-disable-next-line prefer-destructuring
      const ImageId = generateUUID();
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        const storageRef = StorageRef(
          Storage,
          `companies/${companyUID}/Projects/${id}/hotspotImage/${ImageId}`
        );
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            const compressedFile = await compressImage(file, 400, 200);
            await uploadBytes(storageRef, compressedFile).then(() => {
              getDownloadURL(storageRef).then((downloadURL) => {});
            });
          } catch (error) {
            console.log("Error while iploadingImage", error);
          }
        };
      }
    } catch (error) {
      console.log("Unable To upload image, Try Again!");
    }
  };
  // Adding new text library
  const handleAddField = () => {
    // Add a new empty field
    const ID = uuidv4();
    const newitem = {
      ID,
      text: "",
      value: "",
      lable: "",
      hasRisk: false,
      riskText: {},
      hasFtu: false,
      ftuText: {},
    };

    const updatedTextpoints = [...updatingData.textpoints, newitem];

    setUpdatingData({ ...updatingData, textpoints: updatedTextpoints });
  };

  // Removing Text Library
  const handleRemoveField = (ID) => {
    const indexToRemove = updatingData.textpoints.findIndex((item) => item.id === ID);

    // If the item with the specified ID is found, create a copy of the textpoints array without that item
    if (indexToRemove !== -1 && updatingData?.textpoints?.length > 1) {
      const updatedTextpoints = updatingData.textpoints.filter(
        (_, index) => index !== indexToRemove
      );

      // Update the state with the new textpoints array
      setUpdatingData({
        ...updatingData,
        textpoints: updatedTextpoints,
      });
    }
  };

  // Function to handle step 1 , step 2 and step 3
  const handeStepOneData = (index, value) => {
    setActiveStep1(value);
    if (!update) {
      const ID = uuidv4();

      const newStep1Value = { ID, step1: value };
      setUpdatingData(newStep1Value);
    }
  };

  const handleStepTwoData = (value) => {
    const newStep1Value = { ...updatingData, step2: value };
    setUpdatingData(newStep1Value);
  };
  const handleStepThreeData = (value, key) => {
    // setActiveStep3(index);
    setActiveStep3(key);
    if (!update) {
      const ID = uuidv4();
      const newitem = [
        {
          ID,
          text: "",
          value: "",
          lable: "",
          hasRisk: false,
          riskText: {},
          hasFtu: false,
          ftuText: {},
        },
      ];
      const newStep3Value = { ...updatingData, step3: { value, key }, textpoints: newitem };

      setUpdatingData(newStep3Value);
    }
  };

  // Handling Text Library Fields
  const handleAddRisk = (ID) => {
    const indexToUpdate = updatingData.textpoints.findIndex((item) => item.id === ID);
    if (indexToUpdate !== -1) {
      const newTextpoints = [...updatingData.textpoints];
      newTextpoints[indexToUpdate] = {
        ...newTextpoints[indexToUpdate],
        hasRisk: true,
        riskText: {},
        hasFtu: false,
        ftuText: {},
      };

      // Update the state with the new textpoints array
      setUpdatingData({
        ...updatingData,
        textpoints: newTextpoints,
      });
    }
  };

  const handleAddFtu = (ID) => {
    const indexToUpdate = updatingData.textpoints.findIndex((item) => item.id === ID);
    if (indexToUpdate !== -1) {
      const newTextpoints = [...updatingData.textpoints];
      newTextpoints[indexToUpdate] = {
        ...newTextpoints[indexToUpdate],
        hasRisk: false,
        riskText: {},
        hasFtu: true,
        ftuText: {},
      };

      // Update the state with the new textpoints array
      setUpdatingData({
        ...updatingData,
        textpoints: newTextpoints,
      });
    }
  };
  const handleRemoveRiskOrFtuField = (ID) => {
    console.log(ID);
    const indexToUpdate = updatingData.textpoints.findIndex((item) => item.id === ID);
    if (indexToUpdate !== -1) {
      const newTextpoints = [...updatingData.textpoints];
      newTextpoints[indexToUpdate] = {
        ...newTextpoints[indexToUpdate],
        hasRisk: false,
        riskText: {},
        hasFtu: false,
        ftuText: {},
      };

      // Update the state with the new textpoints array
      setUpdatingData({
        ...updatingData,
        textpoints: newTextpoints,
      });
    }
  };
  useEffect(() => {
    const Step2ArrayData = textLibraryData[activeStep1]?.step2;
    if (Step2ArrayData) setStep2Array(Step2ArrayData);
  }, [activeStep1]);
  useEffect(() => {
    // tranforming object of textlibrary to arrays
    let EmptyotesArray = [];
    let emptyRiskOptionsArray = [];
    let emptyftuOptionsArray = [];
    const notesObj = textLibraryData[activeStep1]?.notes?.[activeStep3];
    const RisksObj = textLibraryData[activeStep1]?.risks?.[activeStep3];
    const ftuObj = textLibraryData[activeStep1]?.ftu?.[activeStep3];

    if (notesObj) {
      Object.entries(notesObj).map(([keys, values]) => {
        console.log(keys, values);
        EmptyotesArray.push({ label: values.name, value: values.name });
      });
    }
    if (RisksObj) {
      Object.entries(RisksObj).map(([keys, values]) => {
        emptyRiskOptionsArray.push({ label: values.name, value: values.name });
      });
    }
    if (ftuObj) {
      Object.entries(ftuObj).map(([keys, values]) => {
        emptyftuOptionsArray.push({ label: values.name, value: values.name });
      });
    }

    setNotesArray(EmptyotesArray);
    setriskOptionsArray(emptyRiskOptionsArray);
    setFtuOptionsArray(emptyftuOptionsArray);
  }, [updatingData]);

  return (
    <>
      {" "}
      {update && (
        <NoteRoute>
          {updatingData.step1 ? `${updatingData.step1} > ` : null}
          {updatingData.step2 ? `${updatingData.step2} > ` : null}
          {updatingData.step3 ? ` ${updatingData.step3.value}` : null}
        </NoteRoute>
      )}
      {!update && (
        <Steps>
          {/* Step 1 */}
          {Object.keys(textLibraryData)[0] && (
            <Step>
              <StepHeading>Step 1:</StepHeading>
              <StepsBtns>
                <ChoiceBtn
                  activeBtn={updatingData.step1 === "inside"}
                  onClick={() => {
                    handeStepOneData(1, "inside");
                  }}
                  disabled={update ? updatingData.step1 !== "inside" : false}
                >
                  Interior
                  {/* {Object.keys(textLibraryData)[0]} */}
                </ChoiceBtn>
                <ChoiceBtn
                  disabled={update ? updatingData.step1 !== "outside" : false}
                  activeBtn={updatingData.step1 === "outside"}
                  onClick={() => {
                    handeStepOneData(0, "outside");
                  }}
                >
                  Exterior
                  {/* {Object.keys(textLibraryData)[1]} */}
                </ChoiceBtn>
              </StepsBtns>
            </Step>
          )}
          {/* step2 */}
          {updatingData.step1 !== null &&
            updatingData.step1 !== undefined &&
            updatingData.step1 === "inside" && (
              <Step>
                <StepHeading>Step 2:</StepHeading>{" "}
                <StepsBtns>
                  {updatingData.step1 !== null &&
                    updatingData.step1 !== undefined &&
                    updatingData.step1 === "inside" &&
                    extraFilter.map((value, index) => (
                      <ChoiceBtn
                        key={index}
                        activeBtn={updatingData.step2 === value}
                        disabled={update ? updatingData.step2 !== value : false}
                        onClick={() => handleStepTwoData(value)}
                      >
                        {value}
                      </ChoiceBtn>
                    ))}
                </StepsBtns>
              </Step>
            )}

          {
            <Step>
              {updatingData?.step2 !== undefined && (
                <>
                  <StepHeading>Step 3:</StepHeading>
                  <StepsBtns>
                    {Object.entries(step2Array).map(([key, value]) => (
                      <ChoiceBtn
                        key={key}
                        activeBtn={updatingData?.step3?.key === key}
                        disabled={update ? updatingData?.step3?.key !== key : false}
                        onClick={() => handleStepThreeData(value.name, key)}
                      >
                        {value?.name}
                      </ChoiceBtn>
                    ))}
                  </StepsBtns>
                </>
              )}
              {updatingData?.step1 !== undefined && updatingData.step1 === "outside" && (
                <>
                  <StepHeading>Step 2:</StepHeading>
                  <StepsBtns>
                    {Object.entries(step2Array).map(([key, value]) => (
                      <ChoiceBtn
                        key={key}
                        activeBtn={updatingData?.step3?.key === key}
                        disabled={update ? updatingData?.step3?.key !== key : false}
                        onClick={() => handleStepThreeData(value.name, key)}
                      >
                        {value?.name}
                      </ChoiceBtn>
                    ))}
                  </StepsBtns>
                </>
              )}
            </Step>

            // <Step>
            //  {updatingData.step1 !== null &&
            //  updatingData.step1 !== undefined &&
            //  updatingData.step1 === 0 ? (
            //    <StepHeading>Step 2:</StepHeading>
            //  ) : (
            //    <StepHeading>Step 3:</StepHeading>
            //  )}

            //  <StepsBtns>
            //    {Object.entries(step2Array).map(([key, value], index) => (
            //      <ChoiceBtn
            //        key={index}
            //        activeBtn={activeStep3 === key}
            //        disabled={update ? activeStep3 !== key : false}
            //        onClick={() => handleStepThreeData(index)}
            //      >
            //        {value?.name}
            //      </ChoiceBtn>
            //    ))}
            //  </StepsBtns>
            // </Step>
          }

          {/* Step 3 */}
          {/* {updatingData?.step2 !== undefined &&
          updatingData?.step2 !== null &&
          updatingData?.step1 === 1 && (
            <Step>
              <StepHeading>Step 3:</StepHeading>
              <StepsBtns>
                {data[updatingData?.step1][updatingData?.step1 === 0 ? "outside" : "inside"][
                  updatingData?.step2
                ]?.step3?.map((item, index) => (
                  <ChoiceBtn
                    key={index}
                    activeBtn={updatingData.step3 === item.id}
                    disabled={update ? updatingData.step3 !== item.id : false}
                    onClick={() => {
                      handleStepThreeData(index);
                    }}
                  >
                    {item?.text}
                  </ChoiceBtn>
                ))}
              </StepsBtns>
            </Step>
          )} */}
        </Steps>
      )}
      {/* Text Libraries */}
      {updatingData.step1 !== null && updatingData.step3 !== null && (
        <>
          {updatingData?.textpoints?.map((item, index) => (
            <TextLibrary key={item.id}>
              <LibraryHeader>
                <img
                  onClick={() => handleRemoveField(item.id)}
                  src={updatingData?.textpoints?.length > 1 ? removeIcon : disabledRemoveIcon}
                  alt="_remove"
                />
                <LibHeading>Text library {index + 1}</LibHeading>
              </LibraryHeader>
              <LibFields>
                <SelectField
                  onChange={(e) => handleFieldChange(e, item.id, "text")}
                  defaultValue={{
                    label: item.text,
                    value: item.value,
                  }}
                  options={notesArray || []}
                />
                {/* risk */}
                {item?.hasRisk && (
                  <>
                    <LibraryHeader>
                      <img
                        src={removeIcon}
                        alt="_remove"
                        onClick={() => handleRemoveRiskOrFtuField(item.id)}
                      />
                      <Label>Add {item.hasRisk ? "Risk" : "FTU"}</Label>
                    </LibraryHeader>
                    {/* Render Risk Field */}
                    <SelectField
                      className="basic-single"
                      classNamePrefix="select"
                      name="color"
                      defaultValue={{
                        label: item.riskText.text,
                        value: item.riskText.value,
                      }}
                      onChange={(e) => handleFieldChange(e, item.id, "risk")}
                      options={riskOptionsArray || []}
                    />
                  </>
                )}
                {item?.hasFtu && (
                  <>
                    <LibraryHeader>
                      <img
                        src={removeIcon}
                        alt="_remove"
                        onClick={() => handleRemoveRiskOrFtuField(item.id)}
                      />
                      <Label>Add {item.hasRisk ? "Risk" : "FTU"}</Label>
                    </LibraryHeader>
                    {/* Render FTU Field */}
                    <SelectField
                      defaultValue={{
                        label: item.ftuText.text,
                        value: item.ftuText.value,
                      }}
                      onChange={(e) => handleFieldChange(e, item.id, "ftu")}
                      options={ftuOptionsArray}
                    />
                  </>
                )}
                {/* Add Risk & FTU Btns */}
                {!item.hasRisk && (
                  <AddBtn onClick={() => handleAddRisk(item.id)}>+ Add Risk</AddBtn>
                )}
                {!item.hasFtu && <AddBtn onClick={() => handleAddFtu(item.id)}>+ Add FTU</AddBtn>}
                {!addTextLibraryBtnDisabled && (
                  <AddBtn onClick={() => fileInputRef.current.click()}>+ Add Image</AddBtn>
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/svg+xml,image/png,image/jpeg,image/gif"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </LibFields>
            </TextLibrary>
          ))}
          {updatingData?.textpoints?.length > 0 && !addTextLibraryBtnDisabled ? (
            <AddTextLibBtn disabled={addTextLibraryBtnDisabled} onClick={handleAddField}>
              + Add text library
            </AddTextLibBtn>
          ) : null}
        </>
      )}
    </>
  );
}
export default InspectionNoteModal;
// Style
const Steps = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
});
const Step = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "12px",
  flexDirection: "Column",
});
const StepHeading = styled("div")({
  color: "#101828",
  fontSize: "16px",
  fontWeight: "600",
});
const StepsBtns = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: "12px",
});
const ChoiceBtn = styled("button")(({ activeBtn, disabled }) => ({
  padding: "12px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: disabled ? "#e0e0e0" : activeBtn ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: disabled ? "gray" : activeBtn ? "#FFF" : "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  cursor: disabled ? "not-allowed" : "pointer",
}));
const TextLibrary = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  marginTop: "24px",
});
const LibraryHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
});
const LibHeading = styled("p")({
  flex: "1 0 0",
  color: "#101828",
  fontSize: "16px",
  fontWeight: "600",
});
const LibFields = styled("div")({
  display: "flex",
  width: "100%",
  padding: "16px",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  gap: "16px",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FCFCFD",
});

const SelectField = styled(CreatableSelect)({
  width: "100%",
});

const AddBtn = styled("p")({
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
});
const Label = styled("label")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
});
const AddTextLibBtn = styled("button")({
  padding: "8px 14px",
  textAlign: "center",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  border: "none",
  outline: "none",
  width: "-webkit-fill-available",
  borderRadius: "8px",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  marginTop: "20px",
});
const NoteRoute = styled("p")({
  color: "#667085",
  fontSize: "15px",
  fontWeight: "500",
});
