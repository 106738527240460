/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import ProjectViewPanoramaCanvas from "layouts/PanoramaProject/components/ProjectViewPanoramaCanvas";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from "firebase/storage";
import { userTypeController } from "context/userInfo";
import { Storage, db } from "FirebaseConfi";
import { v4 as uuidv4 } from "uuid";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLocation, useParams } from "react-router-dom";
import backArrowIcon from "../../../assets/images/back-arrow-icon.svg";
import delGrayIcon from "../../../assets/images/gray-del-icon.svg";
import editGrayIcon from "../../../assets/images/gray-edit-icon.svg";
import shareGrayIcon from "../../../assets/images/gray-share-icon.svg";
import dummyImg from "../../../assets/images/home-decor-1.jpg";
import addressIcon from "../../../assets/images/address-icon.svg";
import inspectorIcon from "../../../assets/images/inspecto-icon.svg";
import pdfIcon from "../../../assets/images/pdf-purple-icon.svg";
import downloadIcon from "../../../assets/images/download-purple-icon.svg";
import fileIcon from "../../../assets/images/files-icon.svg";
import uploadIcon from "../../../assets/images/upload-purple-icon.svg";
import downloadGrayIcon from "../../../assets/images/download-gray-icon.svg";
import documentationImageIcon from "../../../assets/images/documentation-image-icon.svg";
import addImageIcon from "../../../assets/images/add-image-purple-icon.svg";
import dummyImg1 from "../../../assets/images/dummyImg1.svg";
import dummyImg2 from "../../../assets/images/dummyImg2.svg";
import dummyImg3 from "../../../assets/images/dummyImg3.svg";
import pdfFile from "../../../assets/pdfFile/SBRInspection.pdf";
import { handleCancelToBackRouteButton } from "../../../CommonFunctions/index";

function ViewProject() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const location = useLocation();
  const [projectData, setProjectData] = useState(location?.state?.data);
  const [projectInspectionDocs, setProjectInspectionDocs] = useState([]);
  const { id } = useParams();
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const storage = getStorage();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [imageUrls, setImageUrls] = useState([]);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const onDocumentLoadSuccess = ({ nPages }) => {
    setNumPages(nPages);
  };

  const scenes = {
    T2enx2eSVsN7vq54OEis: {
      panorama: dummyImg,
      title: "Image 1",
      assignedFloor: 0,
    },
    T2enx2eSVsN7vq54yEis: {
      panorama: dummyImg,
      title: "Image 2",
      assignedFloor: 1,
    },
    T2enx2eSVsN7rq54OEis: {
      panorama: dummyImg,
      title: "Image 3",
      assignedFloor: 2,
    },
  };

  const floorMapData = {
    T2enx2eSVsN7vq54OEis: {
      floorMap: dummyImg,
      floorNumber: 0,
    },
    T2enx2eSVsr7vq54OEis: {
      floorMap: dummyImg,
      floorNumber: 1,
    },
    T2enx2esVsN7vq54OEis: {
      floorMap: dummyImg,
      floorNumber: 2,
    },
  };

  const getProjectInspectionDocs = () => {
    const storageRef = ref(storage, `companies/${companyUID}/Projects/${id}/InspectionDocs`);
    listAll(storageRef)
      .then((res) => {
        setProjectInspectionDocs(res?.items);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getProjectInspectionImages = () => {
    setImageUrls([]);
    const storageRefImage = ref(storage, `companies/${companyUID}/Projects/${id}/hotspotImage`);
    listAll(storageRefImage)
      .then((res) => {
        res?.items?.map((e) => {
          const imageStorageRef = ref(Storage, e?.fullPath);
          getDownloadURL(imageStorageRef)
            .then((url) => {
              console.log("url", url);
              setImageUrls((prevData) => [...prevData, url]);
            })
            .catch((error) => {
              // eslint-disable-next-line default-case
              console.log("error", error);
            });
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(async () => {
    console.log("id", id);
    const docRef = doc(db, `companies/${companyUID}/projects/${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProjectData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
    getProjectInspectionDocs();
    getProjectInspectionImages();
  }, [companyUID, id]);

  const handleUploadProjectDocs = (file) => {
    const storageRef = ref(
      storage,
      `companies/${companyUID}/Projects/${id}/InspectionDocs/${file.name}`
    );

    // Upload the file to its respective reference
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        getProjectInspectionDocs();
        toast.success("File uploaded successfully!");
      })
      .catch((error) => {
        toast.error("Error while uploading the file!");
        console.error(`Error uploading ${file.name}:`, error);
      });
  };
  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };

  const handleUploadProjectHotspots = (file) => {
    const ImageId = generateUUID();
    const storageRef = ref(
      storage,
      `companies/${companyUID}/Projects/${id}/hotspotImage/${ImageId}`
    );

    // Upload the file to its respective reference
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        getProjectInspectionImages();
        toast.success("Image uploaded successfully!");
      })
      .catch((error) => {
        toast.error("Error while uploading the file!");
        console.error(`Error uploading Image:`, error);
      });
  };

  const handleDownload = async (file) => {
    try {
      const fileRef = ref(
        storage,
        `companies/${companyUID}/Projects/${id}/InspectionDocs/${file.name}`
      );

      // Get the download URL for the file
      const downloadURL = await getDownloadURL(fileRef);

      // Create an invisible anchor element and trigger the download
      const anchor = document.createElement("a");
      anchor.href = downloadURL;
      anchor.download = file.name;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      toast.error("Error While downloading the file!");
      console.error("Error downloading file:", error);
    }
  };
  return (
    <DashboardLayout>
      <div style={{ background: "white", padding: "24px" }}>
        <ProjectViewHeader>
          <PrjectNameDiv>
            <img
              onClick={() => handleCancelToBackRouteButton()}
              style={{ cursor: "pointer" }}
              src={backArrowIcon}
              alt="_back"
            />
            <ProjectName>Home Safety Evaluation</ProjectName>
          </PrjectNameDiv>
          <ActionBtnsDiv>
            <ActionBtn>
              <img src={delGrayIcon} alt="_delet" />
              <BtnText>Delete</BtnText>
            </ActionBtn>
            <ActionBtn>
              <img src={editGrayIcon} alt="_delet" />
              <BtnText>Edit</BtnText>
            </ActionBtn>
            <ActionBtn>
              <img src={shareGrayIcon} alt="_delet" />
              <BtnText>Share</BtnText>
            </ActionBtn>
          </ActionBtnsDiv>
        </ProjectViewHeader>
        {projectData?.selectedInspectionFormat === "pdf" && <Hr />}
        {projectData?.selectedInspectionFormat === "panaroma" && <ProjectViewPanoramaCanvas />}
        <ProjectInfo>
          <ProjectInfoCol>
            <AddressAndInspctrDiv>
              <AddressDiv>
                <InfoHeader>
                  <img src={addressIcon} alt="_address" />
                  <Heading>Address</Heading>
                </InfoHeader>
                <Address>
                  {projectData?.PAddress}, {projectData?.city}, {projectData?.state}
                </Address>
              </AddressDiv>
              <Section>
                <InfoHeader>
                  <img src={inspectorIcon} alt="_address" />
                  <Heading>Inspector</Heading>
                </InfoHeader>
                <InspectorInfo>
                  <Avatar alt={projectData?.InspectorName} src="/static/images/avatar/1.jpg" />
                  <InspectorNames>
                    <InsName>{projectData?.InspectorName}</InsName>
                    {/* <InsDesig>Junior Inspector</InsDesig> */}
                  </InspectorNames>
                </InspectorInfo>
              </Section>
            </AddressAndInspctrDiv>
            <InspectionPdfDiv>
              <InspectionPdfHeader>
                <InfoHeader>
                  <img src={pdfIcon} alt="_pdf" />
                  <Heading>Inspectors PDF</Heading>
                </InfoHeader>
                <PdfBtn>
                  <img src={downloadIcon} alt="_download" />
                  Download PDF
                </PdfBtn>
              </InspectionPdfHeader>
              <Pdf>
                {/* <iframe
                  src="https://www.africau.edu/images/default/sample.pdf"
                  width="100%"
                  height="500px"
                  title="Pdf"
                /> */}
                {/* <Document
                  file="https://www.africau.edu/images/default/sample.pdf" // Use the imported PDF file as the source
                  onLoadError={(error) => console.error("Error loading PDF:", error)}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document> */}
              </Pdf>
              {/* <Pdf>No document Exist</Pdf> */}
            </InspectionPdfDiv>
          </ProjectInfoCol>
          <ProjectInfoCol>
            <Section style={{ width: "100%" }}>
              <FileHeader>
                <InfoHeader>
                  <img src={fileIcon} alt="_file" />
                  <Heading>Files</Heading>
                </InfoHeader>
                <PdfBtn onClick={() => fileInputRef.current.click()}>
                  <img src={uploadIcon} alt="_upload" />
                  Upload
                </PdfBtn>
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadProjectDocs(e.target.files[0])}
                />
              </FileHeader>
              <Ul>
                {projectInspectionDocs?.map((item) => (
                  <Li>
                    {item?.name}
                    <img
                      style={{ cursor: "pointer" }}
                      src={downloadGrayIcon}
                      onClick={() => handleDownload(item)}
                      alt="_download"
                    />
                  </Li>
                ))}
              </Ul>
            </Section>
            <Section>
              <InfoHeader>
                <img src={documentationImageIcon} alt="_documentation" />
                <Heading>Documentation Images</Heading>
              </InfoHeader>
              <DocumentsImages>
                <AddImage onClick={() => imageInputRef.current.click()}>
                  <img src={addImageIcon} alt="_add" />
                  <AddImageText>Add Image</AddImageText>
                </AddImage>
                <input
                  ref={imageInputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadProjectHotspots(e.target.files[0])}
                />
                {imageUrls?.map((item) => (
                  <ImageDiv>
                    <img
                      style={{ borderRadius: "12px" }}
                      width="80px"
                      height="80px"
                      src={item}
                      alt="_doc"
                    />
                  </ImageDiv>
                ))}
              </DocumentsImages>
            </Section>
          </ProjectInfoCol>
        </ProjectInfo>
      </div>
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </DashboardLayout>
  );
}

export default ViewProject;

const ProjectViewHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
});
const PrjectNameDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});
const ProjectName = styled("p")({
  color: "#101828",
  fontSize: "20px",
  fontWeight: "500",
});
const ActionBtnsDiv = styled("div")({
  display: "flex",
  padding: "8px",
  alignItems: "flex-start",
  gap: "20px",
});
const ActionBtn = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});
const BtnText = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "500",
});
const ProjectInfo = styled("div")({
  marginTop: "24px",
  display: "flex",
  padding: "0px 16px",
  alignItems: "flex-start",
  gap: "64px",
  alignSelf: "stretch",
  "& > *:first-child": {
    flex: "60%", // 60% width for the first column
  },
  "& > *:nth-child(2)": {
    flex: "40%", // 40% width for the second column
  },
});
const ProjectInfoCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "56px",
});
const AddressAndInspctrDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "48px",
});
const AddressDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "4px",
});
const InfoHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});
const Heading = styled("p")({
  color: "#101828",
  fontSize: "16px",
  fontWeight: "500",
});
const Address = styled("p")({
  color: "#101828",
  fontSize: "14px",
  fontWeight: "500",
});
const Section = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
});
const InspectorInfo = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});
const InspectorNames = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
});
const InsName = styled("p")({
  color: "#101828",
  fontSize: "14px",
  fontWeight: "500",
});
// const InsDesig = styled("p")({
//   color: "#667085",
//   fontSize: "12px",
//   fontWeight: "500",
// });
const InspectionPdfDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
});
const InspectionPdfHeader = styled("div")({
  display: "flex",
  paddingRight: "0px",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
});
const PdfBtn = styled("button")({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "8px 12px",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const FileHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
});
const Ul = styled("ul")({
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  width: "100%",
});
const Li = styled("li")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 0px",
  color: "#101828",
  fontSize: "14px",
  fontWeight: "500",
  borderBottom: "1px solid #EAECF0",
});
const DocumentsImages = styled("div")({
  display: "flex",
  // justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "12px",
});
const AddImage = styled("div")({
  cursor: "pointer",
  display: "flex",
  width: "80px",
  height: "80px",
  padding: "32.5px 4.5px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "9px",
  borderRadius: "10px",
  border: "1px solid #F4EBFF",
  background: "#F9F5FF",
});
const AddImageText = styled("p")({
  color: "#53389E",
  textAlign: "center",
  fontSize: "10px",
  fontWeight: "600",
});
const ImageDiv = styled("div")({
  width: "80px",
  height: "80px",
  borderRadius: "10px",
});
const Pdf = styled("div")({
  width: "577.686px",
  height: "816.487px",
  display: "flex",
  padding: "3.724px 3.724px 0px 3.724px",
  justifyContent: "center",
  alignItems: "center",
  gap: "3.724px",
  overflowY: "auto",
  borderRadius: "15.2px",
  border: "1px solid #EAECF0",
});
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
const Hr = styled("hr")({
  border: "1px solid #EAECF0",
  margin: "24px 0px 0px 24px",
});
