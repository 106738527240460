/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable default-case */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */

import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useLocation, useParams } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Storage, db } from "FirebaseConfi";
import { userTypeController } from "context/userInfo";
import { doc, getDoc, refEqual, setDoc, updateDoc } from "firebase/firestore";
import PreviewModal from "layouts/AdminLayouts/InspectionNotes/PreviewModal/index";
import { ToastContainer, toast } from "react-toastify";
import FloorMapPopoverContent from "./FloorMapPopoverContent";
import ManageImagesModalContent from "./ManageImagesModalContent";
import AddHotspotModal from "./HotspotModal";
import ImagesNav from "./ImagesNav";
// import image from "./washroom.jpg";
// import dummyImg from "../../../assets/images/home-decor-2.jpg";
import deleteIcon from "../../../assets/images/red-basket.svg";
// import notesicon from "../../../assets/images/notes-icon.svg";
import shareicon from "../../../assets/images/share-icon.svg";
import settingsicon from "../../../assets/images/settings-icon.svg";
import closeModalIcon from "../../../assets/images/closeModalIcon.svg";
import floorMapsIcon from "../../../assets/images/floor-maps-icon.svg";
import activeFloorMapsIcon from "../../../assets/images/active-floorMaps-icon.svg";
import ChatTitle from "./Title";

// import settingsicon from "../../../assets/images/settings-icon.svg";

let viewer;

function PanoramaCanvas({ openPreviewModal, setOpenPreviewModal, Data }) {
  const [open, setOpen] = useState(false);
  const [openManageSetting, setOpenManageSetting] = useState(false);
  const [activeFloorMapImgId, setActiveFloorMapImgId] = useState("");
  const [activeFloorMapImg, setActiveFloorMapImg] = useState("");
  const [activeFloorMapHotspots, setActiveFloorMapHotspots] = useState([]);
  const [isFloorMapActive, setIsFloorMapActive] = useState(false);
  const [updateHotspot, setUpdateHotspot] = useState(false);
  const [updatingHotspotData, setUpdatingHotspotData] = useState({});
  const location = useLocation();
  const [disableFloorNumbers, setDisableFloorNumbers] = useState(
    location.state?.disableFloorNumbers || []
  );
  const [FloorMapsData, setFloorMapsData] = useState(location.state?.floorMaps || {});
  const [BuildingImagesData, setBuildingImagesData] = useState({});
  const { id } = useParams();
  const [pitchPoint, setPitchPoint] = useState("");
  const [yawPoint, setYawPoint] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [floorMapHotspots, setFloorMapHotspots] = useState([]);
  const [activeSceneId, setActiveSceneId] = useState("");
  const [activeSceneTitle, setActiveSceneTitile] = useState("");
  const [selectedBuildingImgsFile, setSelectedBuildingImgsFile] = useState([]);
  const [selectedFloorMapImgsFile, setSelectedFloorMapImgsFile] = useState([]);
  const [newSelectedBuildingImgsFile, setNewSelectedBuildingImgsFile] = useState([]);
  const [newSelectedFloorMapImgsFile, setNewSelectedFloorMapImgsFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [buildingImages, setBuildingImages] = useState({});
  const [floorMaps, setFloorMaps] = useState({});
  const [activeTab, setActiveTab] = useState("buildingImg");
  const [updatedFloorMapsData, setUpdatedFloorMapsData] = useState({});
  const [updatedScenes, setUpdatedScenes] = useState({});
  const [userInfo] = userTypeController();
  const [re, setre] = useState(false);
  const [removeSceneID, setRemoveSceneID] = useState("");
  const [InspectionNoteData, setInspectionNoteData] = useState([]);
  const [pdfData, setPdfData] = useState();

  const { companyUID } = userInfo;

  const [scenes, setScenes] = useState({});

  useEffect(async () => {
    if (!companyUID) return;
    console.log(companyUID);
    const docRef = doc(db, `companies/${companyUID}/projects`, id);
    const docSnap = await getDoc(docRef);

    if (Data && Data?.propertyInformation) {
      setPdfData(Data);
    } else if (docSnap.exists()) {
      setPdfData(docSnap.data());
    }
  }, [companyUID]);
  // useEffect(async () => {
  //  if (!companyUID) return;
  //  const data = location.state?.buildingImages;

  //  const reconstructedObject = {};
  //  const reconstructedFloorMapsObject = {};

  //  if (data) {
  //    data.forEach((item) => {
  //      const { key, ...value } = item;
  //      reconstructedObject[key] = value;
  //    });

  //    setBuildingImagesData({ ...reconstructedObject });
  //  } else if (companyUID) {
  //    const docRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
  //    const docSnap = await getDoc(docRef);
  //    if (docSnap.exists()) {
  //      docSnap.data()?.scenes?.forEach((item) => {
  //        const { key, ...value } = item;
  //        reconstructedObject[key] = value;
  //      });

  //      setBuildingImagesData({ ...reconstructedObject });
  //    }
  //    const floorMapDocRef = doc(
  //      db,
  //      `companies/${companyUID}/projects/${id}/FloorMaps`,
  //      "floorMaps"
  //    );
  //    const floorMapsDocSnap = await getDoc(floorMapDocRef);
  //    if (floorMapsDocSnap.exists()) {
  //      floorMapsDocSnap.data()?.floorMaps?.forEach((item) => {
  //        const { key, ...value } = item;
  //        reconstructedFloorMapsObject[key] = value;
  //      });
  //      setFloorMapsData({ ...reconstructedFloorMapsObject });
  //    }
  //  }
  // }, [companyUID]);

  // useEffect(() => {
  //  console.log("newSelectedBuildingImgsFile", newSelectedBuildingImgsFile);
  //  console.log("newSelectedFloorMapImgsFile", newSelectedFloorMapImgsFile);
  // }, [newSelectedBuildingImgsFile, newSelectedFloorMapImgsFile]);
  useEffect(async () => {
    if (!companyUID) return;
    const data = location.state?.buildingImages;

    const reconstructedObject = {};
    const reconstructedFloorMapsObject = {};

    if (data) {
      data.forEach((item) => {
        const { key, ...value } = item;
        reconstructedObject[key] = value;
      });

      setBuildingImagesData({ ...reconstructedObject });
      if (companyUID) {
        console.log("fetched");
        const floorMapDocRef = doc(
          db,
          `companies/${companyUID}/projects/${id}/FloorMaps`,
          "FloorMaps"
        );
        const floorMapsDocSnap = await getDoc(floorMapDocRef);
        if (floorMapsDocSnap.exists()) {
          setFloorMapsData({ ...floorMapsDocSnap.data()?.floorMaps });
        }
      }
    } else if (companyUID) {
      const docRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
      const docSnap = await getDoc(docRef);
      // edited

      if (docSnap.exists()) {
        docSnap.data()?.scenes?.forEach((item) => {
          const { key, ...value } = item;
          reconstructedObject[key] = value;
        });

        setBuildingImagesData({ ...reconstructedObject });
      }
      const floorMapDocRef = doc(
        db,
        `companies/${companyUID}/projects/${id}/FloorMaps`,
        "FloorMaps"
      );
      const floorMapsDocSnap = await getDoc(floorMapDocRef);
      if (floorMapsDocSnap.exists()) {
        console.log("fetched from id", floorMapsDocSnap.data()?.floorMaps);
        setFloorMapsData({ ...floorMapsDocSnap.data()?.floorMaps });
      }
    }
  }, [companyUID]);
  // useEffect(() => {
  //  // This useEffect is for adding the assignedFloor  number to the scenes , means  which scene/image is added on which floor ,This is for the assigned floor field  showing in the manage images modal,  if want to keep that field in future so just leave the useEffect in the file otherwise remove this useEffect

  //  if (true) {
  //    if (Object?.keys(scenes)?.length > 0 && Object?.keys(FloorMapsData)?.length > 0) {
  //      // Iterate through scenes
  //      const updatedscenes = Object?.keys(scenes)?.reduce((acc, imageKey) => {
  //        const image = scenes[imageKey];
  //        const matchingHotspot = Object?.values(FloorMapsData)?.find((floorMap) =>
  //          floorMap?.hotspot?.some((hotspot) => hotspot.props.imageId === imageKey)
  //        );

  //        if (matchingHotspot) {
  //          const { floorNumber } = matchingHotspot;
  //          const updatedImage = { ...image, assignedFloor: floorNumber };
  //          acc[imageKey] = updatedImage;
  //        } else {
  //          acc[imageKey] = image;
  //        }

  //        return acc;
  //      }, {});

  //      // console.log("updatedscenes", updatedscenes);
  //      // setScenes(updatedscenes);
  //    }
  //  }
  // }, [FloorMapsData, floorMapHotspots]);
  // useEffect(() => {
  //  console.log("floorMaps", floorMaps);
  //  console.log("BuildingImagesData", BuildingImagesData);
  //  console.log("buildingImages", buildingImages);
  // }, [BuildingImagesData, floorMaps]);

  // useEffect(() => {
  //  console.log("FloorMapsData", FloorMapsData);
  //  console.log("floorMapHotspots", floorMapHotspots);
  // }, [FloorMapsData, floorMapHotspots]);
  useEffect(() => {
    // Find the index of the object to be updated in FloorMapsData
    if (activeFloorMapImgId) {
      const updatedHotspots = [...floorMapHotspots];

      // Update the hotspot data based on idToBeUpdated
      const updatedData = {
        ...FloorMapsData,
        [activeFloorMapImgId]: {
          ...FloorMapsData[activeFloorMapImgId],
          hotspot: updatedHotspots,
        },
      };

      // Update the state with the modified data
      setFloorMapsData(updatedData);
    }
  }, [floorMapHotspots]);
  useEffect(() => {
    // Initial screen size check
    setIsSmallScreen(window.matchMedia("(max-width: 840px)").matches);
    setIsMediumScreen(window.matchMedia("(min-width: 841px) and (max-width: 1040px)").matches);

    // Add event listener for screen resize
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 840px)").matches);
      setIsMediumScreen(window.matchMedia("(min-width: 841px) and (max-width: 1040px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleClose = () => {
    setOpen(false);
    setUpdateHotspot(false);
  };

  const handleFloorClick = (event, projectID, activeFloorMapImage, hotspots) => {
    setAnchorEl(event.currentTarget);
    setActiveFloorMapImgId(projectID);
    setActiveFloorMapImg(activeFloorMapImage);
    hotspots && setActiveFloorMapHotspots(hotspots);
  };

  const popoverOpen = Boolean(anchorEl);
  const hotspotFunc = (hotsportdiv, args) => {
    setUpdatingHotspotData(args);
    setUpdateHotspot(true);
    setOpen(true);
  };
  const addNewScene = (Uid, config) => {
    viewer.addScene(Uid, config);
    console.log(Uid);
    console.log(config);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setActiveFloorMapImgId("");
    setActiveFloorMapImg("");
    setActiveFloorMapHotspots([]);
  };

  useEffect(() => {
    if (Object.keys(BuildingImagesData).length > 0) {
      const scenesObject = {};
      const allHotspots = [];
      Object.entries(BuildingImagesData).forEach(([sceneId, scene]) => {
        scenesObject[sceneId] = {
          ...scene,
          hotSpots: [],
        };

        if (scene.hotSpots) {
          scene.hotSpots.forEach((hotspot) => {
            allHotspots.push(hotspot);
          });
        }
      });

      if (Object.keys(scenesObject).length > 0) {
        viewer = window.pannellum.viewer("panorama", {
          type: "equirectangular",
          scenes: scenesObject,
          autoLoad: true,
          showZoomCtrl: false,
          default: scenesObject[Object.keys(scenesObject)[0]],
          sceneFadeDuration: 3000,
          compass: false,
        });

        viewer.on("scenechange", (sceneId) => {
          // This function will be called whenever the scene changes
          setActiveSceneId(sceneId);
        });
        let updatedHotspotAray = [...InspectionNoteData];

        allHotspots?.map((item) => {
          if (item?.clickHandlerArgs) {
            updatedHotspotAray = [...updatedHotspotAray, item.clickHandlerArgs];
          }
        });
        setInspectionNoteData(updatedHotspotAray);
        console.log("All hotspots>>>>>", allHotspots);
        setScenes(viewer.getConfig().scenes);
        setActiveSceneId(Object.keys(BuildingImagesData)[0]);
      }

      allHotspots.forEach((item) => {
        console.log(item);
        viewer.addHotSpot(
          {
            id: item.id,
            pitch: item.pitch,
            yaw: item.yaw,
            type: "info",
            SceneID: item.SceneID,
            text: item.text,
            textImage: item?.textImage,
            clickHandlerArgs: { ...item.clickHandlerArgs },
            clickHandlerFunc: hotspotFunc,
          },
          item.SceneID
        );
      });
    }
  }, [BuildingImagesData]);
  const removeScene = (sceneID) => {
    viewer.removeScene(sceneID);
  };
  const UpdateProject = (scene) => {
    console.log(scene);
    console.log(viewer.getConfig().scenes);

    const jsonString = JSON.stringify(viewer.getConfig().scenes);
    const parsedObject = JSON.parse(jsonString);
    const itemsArray = Object.entries(parsedObject).map(([key, value]) => ({ key, ...value }));
    try {
      const companyRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
      updateDoc(companyRef, { scenes: itemsArray }).then(() => {
        console.log("Doc updated");
        toast.success("Data Successfully Uploaded!");

        // toast.success("Data Successfully Uploaded!");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateProjectFloorMap = (floorMap) => {
    const jsonString = JSON.stringify(floorMap);
    const parsedObject = JSON.parse(jsonString);
    console.log(parsedObject);

    const itemsArray = Object.entries(parsedObject).map(([key, value]) => ({ key, ...value }));
    try {
      const companyRef = doc(db, `companies/${companyUID}/projects/${id}/FloorMaps`, "FloorMaps");
      updateDoc(companyRef, { floorMaps: { ...parsedObject } }).then(() => {
        console.log("FLoor updated");
        // toast.success("Data Successfully Uploaded!");
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (viewer) {
      viewer.getConfig().scenes = { ...scenes };
      // UpdateProject(scenes);
    }
  }, [scenes]);

  useEffect(() => {
    if (viewer) {
      const activeSceneConfig = viewer.getConfig().scenes[activeSceneId];
      if (activeSceneConfig) setActiveSceneTitile(activeSceneConfig?.title);
    }
  }, [activeSceneId]);

  const addHotspots = (pitch, yaw, data, textImage) => {
    // const text = `Note:${data?.textpoints?.[0]?.text}${
    //  data?.textpoints?.[0]?.hasRisk ? `<br>Risk: ${data.textpoints[0]?.riskText.value}` : ""
    // }${data?.textpoints?.[0]?.hasFtu ? `<br> FTU: ${data.textpoints[0]?.ftuText.value}` : ""}`;
    console.log(data.ID);
    console.log(data);
    viewer.addHotSpot({
      id: data?.ID,
      pitch,
      yaw,
      SceneID: activeSceneId,
      type: "info",
      textImage: textImage,
      text: data?.textpoints?.[0].text,
      clickHandlerArgs: { ...data, pitch, yaw },
      clickHandlerFunc: hotspotFunc,
    });
  };
  const RemoveHotspot = (HotspotId) => {
    console.log(viewer.getConfig());
    console.log("removing hotsoort ID", HotspotId);
    viewer.removeHotSpot(HotspotId);
  };
  const UpdateHotspotData = (data) => {
    viewer.removeHotSpot(data.ID);

    // viewer.addHotSpot(data);
  };
  const handleDblClickOpen = (e) => {
    const arr = viewer.mouseEventToCoords(e);
    setPitchPoint(arr[0]);
    setYawPoint(arr[1]);
    setOpen(true);
  };
  const changeScene = (sceneID) => {
    viewer.loadScene(sceneID);

    // viewer.scenechange(sceneID);
  };
  const printViewer = () => {
    console.log(viewer.getConfig().scenes);
  };
  function setHfovAndPitch() {
    const scene = viewer.getConfig();
    if (scene) {
      const hfov = viewer.getHfov();
      const yaw = viewer.getYaw();
      const pitch = viewer.getPitch();
      scene.scenes[activeSceneId] = { ...scene.scenes[activeSceneId], hfov };
      scene.scenes[activeSceneId] = { ...scene.scenes[activeSceneId], yaw };
      scene.scenes[activeSceneId] = { ...scene.scenes[activeSceneId], pitch };
    }
  }
  function changeName() {
    const scene = viewer.getConfig();
    if (scene) {
      scene.scenes[activeSceneId] = { ...scene.scenes[activeSceneId], title: activeSceneTitle };

      setScenes({ ...viewer.getConfig().scenes });
    }
  }

  const updateUploadProgress = (Uid, progress) => {
    setUploadProgress((prevProgress) => ({ ...prevProgress, [Uid]: progress }));
  };

  const handleCancelPreviewButton = () => {
    setOpenPreviewModal(false);
  };
  useEffect(() => {
    if (viewer) {
      const allCurrentHotspots = [];
      const CurrentScenes = { ...viewer.getConfig().scenes };
      Object.entries(CurrentScenes).forEach(([sceneId, scene]) => {
        if (scene.hotSpots) {
          scene.hotSpots.forEach((hotspot) => {
            allCurrentHotspots.push(hotspot);
          });
        }
      });

      let updatedHotspotAray = [];
      allCurrentHotspots?.map((item) => {
        if (item?.clickHandlerArgs) {
          updatedHotspotAray = [...updatedHotspotAray, item.clickHandlerArgs];
        }
      });
      setInspectionNoteData(updatedHotspotAray);
    }

    // setInspectionNoteData([]);
  }, [openPreviewModal]);

  return (
    <div style={{ background: "white" }}>
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <AddHotspotModal
        open={open}
        handleClose={handleClose}
        pitchPoint={pitchPoint}
        yawPoint={yawPoint}
        addHotSpot={addHotspots}
        isUpdateHotspot={updateHotspot}
        updatingHotspotData={updatingHotspotData}
        RemoveHotspot={RemoveHotspot}
        UpdateHotspot={UpdateHotspotData}
        ProjectId={id}
      />
      <MenuBar>
        <Options>
          <ChatTitle
            InitialTitle={activeSceneTitle}
            setSceneTitle={setActiveSceneTitile}
            changeName={() => {
              changeName();
            }}
          />

          {/* <StyledBtn
            type="delete"
            onClick={() => {
              removeScene(activeSceneId);
            }}
          >
            <img src={deleteIcon} alt="_delete" /> Delete
          </StyledBtn> */}
          <StyledBtn
            onClick={() => {
              setHfovAndPitch();
            }}
          >
            Set Point
          </StyledBtn>
        </Options>
        <Options>
          {/* <StyledBtn>
            <img src={notesicon} alt="_notes" /> Notes
          </StyledBtn> */}
          <StyledBtn
            onClick={() => {
              UpdateProject(scenes);
              UpdateProjectFloorMap(FloorMapsData);
            }}
          >
            Save
          </StyledBtn>
          <StyledBtn
            onClick={() => {
              navigator.clipboard.writeText(`https://tour.virtualinspect.se/${id}`);
              toast.success("Link Copied");
            }}
          >
            <img src={shareicon} alt="_share" /> Share
          </StyledBtn>
          <StyledBtn onClick={() => setOpenManageSetting(true)}>
            <img src={settingsicon} alt="_settings" /> Manage
          </StyledBtn>
        </Options>
      </MenuBar>
      <CanvasContainer className="canvas-containerrr">
        <div>
          <ImagesNav
            className="images-navvv"
            scenesItem={scenes}
            activeSceneId={activeSceneId}
            changeScene={changeScene}
          />
        </div>
        {isFloorMapActive && (
          <FloorMapList>
            {FloorMapsData &&
              Object?.keys(FloorMapsData).map((projectID) => {
                const project = FloorMapsData[projectID];
                return (
                  <Li
                    key={projectID}
                    aria-describedby="floorMap"
                    onClick={(event) =>
                      handleFloorClick(event, projectID, project?.floorMap, project?.hotspot)
                    }
                    activeList={activeFloorMapImgId === projectID}
                  >
                    {project?.floorNumber}
                    {activeFloorMapImgId === projectID && (
                      <Box
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white !important",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: "-10px",
                            marginLeft: "18px",
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />
                    )}
                  </Li>
                );
              })}
          </FloorMapList>
        )}
        <Popover
          id="floorMap"
          open={popoverOpen}
          anchorEl={anchorEl}
          sx={{ marginLeft: "38px" }}
          onClose={handleClosePopover}
          arrow={true}
          PaperProps={{
            style: {
              backgroundColor: "white",
              padding: "16px",
              scrollbarWidth: "thin",
              scrollbarColor: "#6248BF #EAECF0",
              width: isSmallScreen ? "350px" : isMediumScreen ? "400px" : "auto",
              height: isSmallScreen ? "250px" : isMediumScreen ? "300px" : "auto",
              overflow: "auto",
            },
          }}
        >
          <FloorMapPopoverContent
            activeFloorMapHotspots={activeFloorMapHotspots}
            activeFloorMapImg={activeFloorMapImg}
            scenes={scenes}
            handleClosePopover={handleClosePopover}
            setFloorMapHotspots={setFloorMapHotspots}
            type="3dTour"
          />
        </Popover>
        <ImagesAndOptions>
          <div style={{ height: "60px", padding: "20px", paddingBottom: "55px" }}>
            <FloorMapsIcon
              src={isFloorMapActive ? activeFloorMapsIcon : floorMapsIcon}
              alt="_floorMaps"
              onClick={() => setIsFloorMapActive(!isFloorMapActive)}
            />
          </div>
        </ImagesAndOptions>
        <div
          id="panorama"
          onDoubleClick={(e) => {
            handleDblClickOpen(e);
          }}
        />
      </CanvasContainer>
      <CustomDialog disableBackdropClick open={openManageSetting}>
        <ModalContainer>
          <ModalHeader>
            <ModalHeading>Manage Images</ModalHeading>
            <img
              // style={{ cursor: !isUploading ? "pointer" : "not-allowed" }}
              // onClick={() => {
              //   !isUploading &&
              //     (setOpen(false),
              //     setSelectedBuildingImgsFile([]),
              //     setSelectedFloorMapImgsFile([]));
              // }}
              onClick={() => setOpenManageSetting(false)}
              src={closeModalIcon}
              alt="_close"
            />
          </ModalHeader>
          <ModalContent>
            <ManageImagesModalContent
              setSelectedBuildingImgsFile={setSelectedBuildingImgsFile}
              selectedBuildingImgsFile={selectedBuildingImgsFile}
              setSelectedFloorMapImgsFile={setSelectedFloorMapImgsFile}
              selectedFloorMapImgsFile={selectedFloorMapImgsFile}
              activeTab={activeTab}
              addNewScene={addNewScene}
              companyUID={companyUID}
              projectID={id}
              setActiveTab={setActiveTab}
              uploadProgress={uploadProgress}
              uploading={isUploading}
              scenes={scenes}
              activeSceneId={activeSceneId}
              setScenes={setScenes}
              FloorMapsData={FloorMapsData}
              setFloorMapsData={setFloorMapsData}
              disableFloorNumbers={disableFloorNumbers}
              setDisableFloorNumbers={setDisableFloorNumbers}
              setUpdatedFloorMapsData={setUpdatedFloorMapsData}
              setUpdatedScenes={setUpdatedScenes}
              removeScene={removeScene}
              setNewSelectedFloorMapImgsFile={setNewSelectedFloorMapImgsFile}
              setNewSelectedBuildingImgsFile={setNewSelectedBuildingImgsFile}
            />
          </ModalContent>
          <ModalFooter>
            <ModalCancelBtn
              // isUploading={isUploading}
              // onClick={() => {
              //   !isUploading &&
              //     (setOpen(false),
              //     setSelectedBuildingImgsFile([]),
              //     setSelectedFloorMapImgsFile([]));
              // }}
              onClick={() => setOpenManageSetting(false)}
            >
              Cancel
            </ModalCancelBtn>
            <ModalUploadBtn
              // disabled={isUpdateButtonDisabled || isUploading}
              onClick={() => {
                UpdateProject(scenes);
                UpdateProjectFloorMap(FloorMapsData);
                setOpenManageSetting(false);
              }}
            >
              {/* {btnText} */}
              Save
            </ModalUploadBtn>
          </ModalFooter>
        </ModalContainer>
      </CustomDialog>

      {/* Preview Modal */}
      <PreviewModal
        openPreviewModal={openPreviewModal}
        handleCancelPreviewButton={handleCancelPreviewButton}
        InspectionNoteData={InspectionNoteData}
        pdfData={pdfData}
        setOpenPreviewModal={setOpenPreviewModal}
      />
    </div>
  );
}
export default PanoramaCanvas;
export const CanvasContainer = styled("div")({
  position: "relative",
  padding: "4px 14px 0px 24px",
  borderRadius: "16px",
  display: "flex",
  // justifyContent: "center",
  alignItems: "end",
});
const MenuBar = styled("div")({
  padding: "12px 24px 0px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
});
const StyledBtn = styled(Button)(({ type }) => ({
  borderRadius: "8px",
  background: type === "delete" ? "#FEF3F2" : "#F9F5FF",
  color: type === "delete" ? "#D92D20" : "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  display: "flex",
  padding: "8px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
}));

const Options = styled("div")({
  display: "flex",
  padding: "4px 0px 4px 14px",
  alignItems: "center",
  gap: "12px",
  alignSelf: "stretch",
});
export const FloorMapsIcon = styled("img")({
  display: "flex",
  position: "absolute",
  zIndex: "1",
  cursor: "pointer",
});
export const ImagesAndOptions = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
});
export const FloorMapList = styled("ul")({
  position: "absolute",
  zIndex: "1",
  paddingBottom: "60px",
  paddingLeft: "20px",
  listStyle: "none",
  gap: "4px",
});
export const Li = styled("li")(({ activeList }) => ({
  display: "flex",
  width: "32px",
  height: "32px",
  padding: "4px 8px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "40px",
  background: activeList ? "#7F56D9" : "#FFF",
  marginBottom: "4px",
  color: activeList ? "#FFF" : "#101828",
  fontSize: "14px",
  fontWeight: "500",
  cursor: "pointer",
}));
const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "80%",
    height: "100%",
    maxWidth: "none", // Set this to prevent the modal from being restricted by the default maxWidth
  },
});
const ModalContainer = styled("div")({
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  height: "100%", // Set the modal container to occupy the full height
});

const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  flex: "0 0 auto", // Fixed height for the header
});

const ModalHeading = styled("p")({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
});

const ModalContent = styled("div")({
  flex: "1 1 auto", // Allow the content to grow and occupy the remaining space with a scrollable behavior
  overflowY: "auto", // Enable vertical scrolling if the content overflows
});

const ModalFooter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  flex: "0 0 auto", // Fixed height for the footer
});
const ModalCancelBtn = styled("button")(({ isUploading }) => ({
  cursor: !isUploading ? "pointer" : "not-allowed",
  padding: "10px 18px",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#344054",
  fontSize: "16px",
  fontWeight: "600",
}));

const ModalUploadBtn = styled("button")(({ disabled }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
  padding: "10px 18px",
  borderRadius: "8px",
  border: "1px solid #7F56D9",
  background: disabled ? "#cbb3ff" : "#7F56D9",
  color: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  fontSize: "16px",
  fontWeight: "600",
}));
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
