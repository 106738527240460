import React from "react";
// import MDBox from "components/MDBox";
// import DashboardNavbar from "WebItems/Navbars/DashboardNavbar";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material";
import CreateNewProject from "./components/CreateNewProjectForm";

function index() {
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MainDiv>
        <CreateNewProject />
      </MainDiv>
    </DashboardLayout>
  );
}

export default index;
const MainDiv = styled("div")({
  padding: "32px 40px",
  background: "white",
});
