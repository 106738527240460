import React from "react";
import { useFormikContext } from "formik";
import { Typography, Grid } from "@mui/material";

import CompanyDetails from "./CompanyDetails";
import PaymentDetails from "./AdminDetails";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Confirm Information
      </Typography>
      <Grid container spacing={2}>
        <CompanyDetails formValues={formValues} />
        <PaymentDetails formValues={formValues} />
      </Grid>
    </>
  );
}
