/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField } from "../../FormFields";

export default function AddressForm(props) {
  const {
    formField: { CompanyName, OrganizationNumber },
  } = props;
  return (
    <>
      <Typography variant="h6" gutterBottom p={2}>
        Company Details
      </Typography>
      <Grid container px={2} spacing={3}>
        <Grid item xs={12}>
          <InputField name={CompanyName.name} label={CompanyName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={OrganizationNumber.name} label={OrganizationNumber.label} fullWidth />
        </Grid>
      </Grid>
    </>
  );
}
