/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable no-console */

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Images
import { useEffect, useState } from "react";
import { auth } from "FirebaseConfi";
import { Box, Typography } from "@mui/material";
import logoImage from "../../../assets/images/logo-ct.png";

function singInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          navigate("/");
        } else {
          navigate("/authentication/verify-email");
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
      })
      .catch((error) => {
        let errorMessage = "";

        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "Invalid email address format.";
            break;
          case "auth/user-disabled":
            errorMessage = "This user account has been disabled.";
            break;
          case "auth/user-not-found":
            errorMessage = "Invalid email or password.";
            break;
          case "auth/wrong-password":
            errorMessage = "Invalid email or password.";
            break;
          case "auth/too-many-requests":
            errorMessage = "Too many unsuccessful login attempts. Please try again later.";
            break;
          default:
            errorMessage = "An error occurred during authentication.";
        }

        toast.error(errorMessage);
      });
  };

  return (
    <BasicLayout>
      <ToastContainer />
      <Card sx={{ borderRadius: "15px" }}>
        <Box pt={2} pb={3} px={3}>
          <Box display="flex" justifyContent="center" m={1}>
            <img src={logoImage} alt="Logo" height={60} />
          </Box>
          <Typography variant="h5" textAlign="center" my={3}>
            Welcome Back
          </Typography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Register
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </Box>
      </Card>
    </BasicLayout>
  );
}

export default singInForm;
