/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Stepper, Step, StepLabel, Typography, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
// import { collection, addDoc } from "firebase/firestore";
// import { db } from "FirebaseConfi";
import { httpsCallable } from "firebase/functions";
import { firebasefunctions } from "FirebaseConfi";
import AddressForm from "./Forms/AddressForm";
import ReviewOrder from "./ReviewOrder";
import CheckoutSuccess from "./CheckoutSuccess";
import validationSchema from "./FormModel/validationSchema";
import CreateCompanyFormModel from "./FormModel/CreateCompanyFormModel";
import formInitialValues from "./FormModel/formInitialValues";
import NewAdminForm from "./Forms/NewAdminForm";

// import useStyles from "./styles";

const steps = ["Company Details", "Admin Details", "Review Information"];
const { formId, formField } = CreateCompanyFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} />;
    case 1:
      return <NewAdminForm formField={formField} />;
    case 2:
      return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  // const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [CompanyId, setCompanyId] = useState("");
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  // function _sleep(ms) {
  //  return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  async function _submitForm(values, actions) {
    try {
      const createFunction = httpsCallable(firebasefunctions, "createCompanyAndAdmin");
      createFunction({
        name: values.AdminName,
        email: values.AdminEmail,
        password: values.AdminPassword,
        CompanyName: values.CompanyName,
        OrganizationNumber: values.OrganizationNumber,
      })
        .then((result) => {
          actions.setSubmitting(false);
          console.log(result.data.massage); // logs the result of the function
          setCompanyId(result.data.message);
          setActiveStep(activeStep + 1);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          console.error(error); // logs any errors that occur
          setCompanyId(error.message);
        });
    } catch (e) {
      console.error("Error adding document: ", e);
      alert(JSON.stringify(e));
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  function _handleAddMore() {
    setActiveStep(0);
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Add Company
      </Typography>
      <div style={{ padding: 15 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {activeStep === steps.length ? (
        <>
          <CheckoutSuccess CompanyId={CompanyId} />
          <Button onClick={_handleAddMore}>Add More</Button>
        </>
      ) : (
        <Formik
          initialValues={formInitialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form id={formId}>
              {_renderStepContent(activeStep)}

              <div>
                {activeStep !== 0 && <Button onClick={_handleBack}>Back</Button>}
                <div>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    style={{ color: "white" }}
                  >
                    {isLastStep ? "Create" : "Next"}
                  </Button>
                  {isSubmitting && <CircularProgress size={24} />}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
