/* eslint-disable no-useless-escape */
/* eslint-disable no-alert */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
// adding change

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { firebasefunctions, db, auth } from "FirebaseConfi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import validator from "validator";
import ReactSelect from "react-select";

import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
// import { AdapterDataFns } from "@mui/lab/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Dialog from "@mui/material/Dialog";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { format } from "date-fns";
import { width } from "@mui/system";
import { Navigate, useNavigate } from "react-router-dom";
import { userTypeController } from "context/userInfo";
import { CheckBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import jsonData from "./Municipalities.json";
import cities from "./Cities.json";
import BackDropLoading from "../../../../components/BackDropLoading";

function CreateNewProjectForm() {
  const DateObj = new Date();

  const [loading, setLoading] = useState(false);
  const [phoneValidityError, setPhoneValidityError] = useState(false);
  const [companyInfoModalOpen, setCompanyInfoModalOpen] = useState(false);
  const navigate = useNavigate();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the font size here
      // Change the background color her
    }),
    input: (provided) => ({
      ...provided,
      color: "blue", // Change the text color here
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the font size for the options
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#b3b6b9", // Change the placeholder color here
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the font size for the "No options" message
    }),
    // Add more styles here if needed
  };
  const [organisationDetails, setOrganisationDetails] = useState({
    CName: "",
    CAddress: "",
    telNumber: "",
    website: "",
    orgNumber: "",
    Tax: "",
    ePost: "",
    uppdragNumber: "",
    city: "",
    state: "",
    municipality: "",
  });
  const [customerInfo, setcustomerInfo] = useState({
    name: "",
    Phone: "",
    ePost: "",
  });

  const [projectDetails, setProjectDetails] = useState({
    template: "",
    inspectionType: "",
    inspectFor: "",
    propertyDestination: "",
    PAddress: "",
    pincode: "",
    agare: "",
    price: "8000",
    cancelationCharges: "1000",
    inspectorEpost: "",
    InspectorName: "",
    inspectionDate: "",
    inspectionTime: "",
    status: "pending",
    inspectorUid: "",
    city: "",
    state: "",
    municipality: "",
  });

  const [InspectorInfo, setInspectorInfo] = useState({
    name: "",
    ePost: "",
    uid: "",
  });
  const [selectedState, setselectedState] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [createBtnClicked, setCreateBtnClicked] = useState(false);

  const StateOptions = jsonData.States.map((State) => ({
    value: State.name,
    label: State.name,
  }));
  const CitiesOptions = cities.map((item) => ({
    value: item.city,
    label: item.city,
  }));

  const municipalityOptions = selectedState
    ? jsonData.States.find((State) => State.name === selectedState.value)?.Municipalites.map(
        (Municipality) => ({
          value: Municipality,
          label: Municipality,
        })
      )
    : [];
  const [receiversEmail, setReceviersEmail] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sendEmail, setSendEmail] = useState(true);
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const StackSpacing = 1;
  // current user details
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        setInspectorInfo({ name: user.displayName, ePost: user.email, uid: user.uid });
      } else {
        setInspectorInfo(null);
      }
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      inspectionDate: selectedDate, // update projectData with the selected date
    });
  }, [selectedDate]);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      inspectionTime: selectedTime, // update projectData with the selected date
    });
  }, [selectedTime]);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      inspectorEpost: InspectorInfo.ePost,
      InspectorName: InspectorInfo.name,
      inspectorUid: InspectorInfo.uid,
    });
  }, [InspectorInfo]);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      state: selectedState?.value, // update projectData with the selected date
    });
  }, [selectedState]);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      municipality: selectedMunicipality?.value, // update projectData with the selected date
    });
  }, [selectedMunicipality]);
  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      city: selectedCity?.value, // update projectData with the selected date
    });
  }, [selectedCity]);
  const handleCustomerFieldChange = (event) => {
    const { name, value } = event.target;
    setcustomerInfo({
      ...customerInfo,
      [name]: value,
    });
  };
  const handleProjectFieldChange = (event) => {
    const { name, value } = event.target;
    setProjectDetails({
      ...projectDetails,
      [name]: value,
    });
  };
  const hasEmptyFields = (fields, excludedFields = []) => {
    const keys = Object.keys(fields);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      console.log("fields", fields);
      if (!excludedFields.includes(key) && fields[key].trim() === "") {
        return true;
      }
    }
    return false;
  };
  const handleSubmit = async (
    organisationDetailsArgs,
    customerInfoArgs,
    projectDetailsArgs,
    InspectorInfoArgs,
    sendEmalArgs
  ) => {
    setCreateBtnClicked(true);
    if (customerInfo?.Phone !== "" && /^[0-9]+$/.test(customerInfo.Phone)) {
      if (
        hasEmptyFields(projectDetails, [
          "inspectorEpost",
          "InspectorName",
          "inspectionDate",
          "inspectionTime",
          "status",
          "inspectorUid",
          "city",
          "state",
          "municipality",
        ]) ||
        hasEmptyFields(customerInfo) ||
        !selectedDate ||
        !selectedTime ||
        !selectedState ||
        !selectedCity ||
        !selectedMunicipality
      ) {
        toast.error("Please fill all the mandatory fields!");
      } else {
        const projectsRef = collection(db, `companies/${companyUID}`, "projects");
        // Generate a new document ID
        const newDocRef = doc(projectsRef);

        // Get the new document ID
        const newDocId = newDocRef.id;
        setLoading(true);
        const finalProjectDetails = {
          ...projectDetailsArgs,
          customerInfo: customerInfoArgs,
          CreatedAt: serverTimestamp(),
          PUid: newDocId,
          sendEmail: sendEmalArgs,
        };
        await setDoc(
          doc(db, `companies/${companyUID}/projects`, newDocId),
          finalProjectDetails
        ).then(() => {
          const createAndSendPdfByEmail = httpsCallable(
            firebasefunctions,
            "createAndSendPdfByEmail"
          );
          createAndSendPdfByEmail({
            to: customerInfoArgs.ePost,
            organisationDetails: organisationDetailsArgs,
            customerInfo: customerInfoArgs,
            projectDetails: projectDetailsArgs,
            InspectorInfo: InspectorInfoArgs,
            sendEmail: sendEmalArgs,
            ProjectId: newDocId,
            companyUID,
          })
            .then((result) => {
              console.log(result.data);
              console.log("confirmation sent successfully");
              const path = `/dashboard`;
              navigate(path);
            })
            .catch((error) => {
              console.error(error);
              toast.error("Unable to Create and send Pdf Try again later or contact support");
              setLoading(false);
            });
        });
      }
    } else {
      setPhoneValidityError(true);
    }
  };
  useEffect(async () => {
    if (companyUID) {
      const docRef = doc(db, "companies", companyUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const requiredProperties = [
          "CName",
          "CAddress",
          "telNumber",
          "website",
          "orgNumber",
          "Tax",
          "ePost",
          "uppdragNumber",
        ];
        console.table(data);
        console.table(requiredProperties);
        if (requiredProperties.every((prop) => data.hasOwnProperty(prop))) {
          setOrganisationDetails(docSnap.data());
        } else {
          alert("if Admin please add the company details to prosscced else Ask admin to add");
          setCompanyInfoModalOpen(true);
        }
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        setCompanyInfoModalOpen(true);
      }
    } else {
      setCompanyInfoModalOpen(true);
    }
  }, [companyUID]);
  useEffect(() => {
    const currentYear = DateObj.getFullYear();
    setProjectDetails({
      ...projectDetails,
      uppdragNumber:
        organisationDetails?.uppdragNumber?.toString().length < 3
          ? `${currentYear}-${organisationDetails?.uppdragNumber?.toString().padStart(3, "0")}`
          : `${currentYear}-${organisationDetails?.uppdragNumber?.toString()}`,
    });
  }, [organisationDetails]);
  return (
    <>
      <BackDropLoading open={loading} />
      <Header>
        <HeadingsDiv>
          <Heading>Create new project</Heading>
          <SubHeading>Enter project details so that user can confirm to start project</SubHeading>
        </HeadingsDiv>
        <Button
          variant="contained"
          sx={{ height: "36px", background: "#7F56D9" }}
          onClick={() => {
            handleSubmit(
              organisationDetails,
              customerInfo,
              projectDetails,
              InspectorInfo,
              sendEmail
            );
          }}
        >
          {loading ? "Creating" : "Create"}
        </Button>
      </Header>

      <Grid container spacing={3} my={3}>
        <Grid item container xs={12} paddingBottom={3} borderBottom="#EAECF0 solid 1px">
          <Grid item xs={4}>
            <SubHeading>Inspection Details</SubHeading>
          </Grid>
          <Grid item container xs={8} spacing={3}>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel id="Templatelabel">Template</InputLabel>
                <Select
                  // sx={{ width: 200, height: 50 }}
                  labelId="templateTypelabel"
                  id="demo-simple-select"
                  value={projectDetails.template}
                  name="template"
                  onChange={handleProjectFieldChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="SBR">SBR</MenuItem>
                  <MenuItem value="OBM" disabled>
                    OBM
                  </MenuItem>
                  <MenuItem value="Anticemix" disabled>
                    Anticemix
                  </MenuItem>
                </Select>
                {projectDetails.template.trim() === "" && createBtnClicked && (
                  <Error>Select Template</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel id="InspectionTypelabel">Inspection Type</InputLabel>
                <Select
                  // sx={{ width: 200, height: 50 }}
                  labelId="InspectionTypelabel"
                  id="demo-simple-select"
                  name="inspectionType"
                  value={projectDetails.inspectionType}
                  onChange={handleProjectFieldChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Housing">Housing</MenuItem>
                  <MenuItem value="Enterpnaure" disabled>
                    Enterpnaure
                  </MenuItem>
                  <MenuItem value="Building controler" disabled>
                    Building controler
                  </MenuItem>
                </Select>
                {projectDetails.inspectionType.trim() === "" && createBtnClicked && (
                  <Error>Select inspection type</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel id="InspectForLabel">Inspect For</InputLabel>
                <Select
                  labelId="InspectForLabel"
                  id="demo-simple-select"
                  name="inspectFor"
                  value={projectDetails.inspectFor}
                  onChange={handleProjectFieldChange}
                >
                  <MenuItem value="buyer">Buyer</MenuItem>
                  <MenuItem value="seller">Seller</MenuItem>
                </Select>
                {projectDetails.inspectFor.trim() === "" && createBtnClicked && (
                  <Error>Select inspection for</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InputLabel id="InspectionTypelabel">Date</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DemoContainer
                    components={[
                      "DatePicker",
                      "MobileDatePicker",
                      "DesktopDatePicker",
                      "StaticDatePicker",
                    ]}
                  >
                    {" "}
                    <MobileDatePicker
                      sx={{ width: "100%" }}
                      value={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                      }}
                      format="dd/MM/yyyy"
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {!selectedDate && createBtnClicked && <Error>Select Date</Error>}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="InspectionTypelabel">Time</InputLabel>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DemoContainer components={["MobileTimePicker", "MobileTimePicker"]}>
                  <MobileTimePicker
                    sx={{ width: "100%" }}
                    onChange={(time) => {
                      setSelectedTime(time);
                    }}
                    value={selectedTime}
                    ampm
                  />
                </DemoContainer>
              </LocalizationProvider>
              {!selectedTime && createBtnClicked && <Error>Select Time</Error>}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} paddingBottom={3} borderBottom="#EAECF0 solid 1px">
          <Grid item xs={4}>
            <SubHeading>Property Details</SubHeading>
          </Grid>
          <Grid item container xs={8} spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Propety Destination</InputLabel>
                <TextField
                  name="propertyDestination"
                  placeholder="Enter Property Destination"
                  value={projectDetails.propertyDestination}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.propertyDestination.trim() === "" && createBtnClicked && (
                  <Error>Select property destination</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Property Address</InputLabel>
                <TextField
                  placeholder="Enter address"
                  name="PAddress"
                  value={projectDetails.PAddress}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.PAddress.trim() === "" && createBtnClicked && (
                  <Error>Select property address</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Select State</InputLabel>
                <ReactSelect
                  value={selectedState}
                  onChange={(selectedOption) => {
                    setselectedState(selectedOption);
                    organisationDetails.state = selectedOption;
                  }}
                  options={StateOptions}
                  placeholder="Select State"
                  styles={customStyles}
                />
                {!selectedState && createBtnClicked && <Error>Select state</Error>}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Select City</InputLabel>

                <ReactSelect
                  value={selectedCity}
                  onChange={(selectedOption) => {
                    setSelectedCity(selectedOption);
                    organisationDetails.city = selectedOption;
                  }}
                  options={CitiesOptions}
                  placeholder="Select City"
                  styles={customStyles}
                />
                {!selectedCity && createBtnClicked && <Error>Select city</Error>}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Select Municipality</InputLabel>
                <ReactSelect
                  value={selectedMunicipality}
                  onChange={(selectedOption) => {
                    setSelectedMunicipality(selectedOption);
                    organisationDetails.municipality = selectedOption;
                  }}
                  options={municipalityOptions}
                  placeholder="please select state first"
                  styles={customStyles}
                  isDisabled={!selectedState}
                />
                {!selectedMunicipality && createBtnClicked && <Error>Select municipality</Error>}
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Zip Code</InputLabel>
                <TextField
                  placeholder="Enter Zip Code"
                  name="pincode"
                  value={projectDetails.pincode}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.pincode.trim() === "" && createBtnClicked && (
                  <Error>Select pincode</Error>
                )}
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Agarer</InputLabel>
                <TextField
                  placeholder="Enter Agarer"
                  name="agare"
                  value={projectDetails.agare}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.agare.trim() === "" && createBtnClicked && (
                  <Error>Select agare</Error>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} paddingBottom={3} borderBottom="#EAECF0 solid 1px">
          <Grid item xs={4}>
            <SubHeading>Customer Details</SubHeading>
          </Grid>
          <Grid item container xs={8} spacing={3}>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Customer Name</InputLabel>
                <TextField
                  placeholder="Enter Customer Name"
                  name="name"
                  value={customerInfo.name}
                  onChange={handleCustomerFieldChange}
                />
                {customerInfo.name.trim() === "" && createBtnClicked && (
                  <Error>Select customer name</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Phone Number</InputLabel>
                <TextField
                  name="Phone"
                  placeholder="+41 4323523332"
                  type="number"
                  value={customerInfo.Phone}
                  onChange={(event) => {
                    setPhoneValidityError(false);
                    handleCustomerFieldChange(event);
                  }}
                />
                {customerInfo.Phone.trim() === "" && createBtnClicked && !phoneValidityError && (
                  <Error>Select customer phone</Error>
                )}
                {phoneValidityError && <Error>Enter a valid phone number</Error>}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Customer Email</InputLabel>
                <TextField
                  type="email"
                  name="ePost"
                  value={customerInfo.ePost}
                  onChange={handleCustomerFieldChange}
                />
                {customerInfo.ePost.trim() === "" && createBtnClicked && (
                  <Error>Select customer E-Post</Error>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} paddingBottom={3} borderBottom="#EAECF0 solid 1px">
          <Grid item xs={4}>
            <SubHeading>Payment Details</SubHeading>
          </Grid>
          <Grid item container xs={8} spacing={3}>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Inspection Price</InputLabel>
                <TextField
                  name="price"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                  }}
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  value={projectDetails.price}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.price.trim() === "" && createBtnClicked && (
                  <Error>Select price</Error>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={StackSpacing}>
                <InputLabel>Cancelation Charges</InputLabel>
                <TextField
                  name="cancelationCharges"
                  type="number"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                  }}
                  value={projectDetails.cancelationCharges}
                  onChange={handleProjectFieldChange}
                />
                {projectDetails.cancelationCharges.trim() === "" && createBtnClicked && (
                  <Error>Select cancelation charges</Error>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(event) => {
                    setSendEmail(event.target.checked);
                  }}
                />
              }
              label="Send confirmation email"
            />

            {sendEmail && <Typography> we will send Email to Customer </Typography>}
          </Grid>
        </Grid>
      </Grid>

      {/*  */}
      <Dialog
        open={companyInfoModalOpen}
        onClose={() => {
          navigate(`/dashboard`);
          setCompanyInfoModalOpen(false);
        }}
      >
        <DialogContent>
          <DialogTypography sx={{ marginBottom: "8px !important" }}>
            You have not added the company information, Click Add Company Info to proceed
          </DialogTypography>
        </DialogContent>
        <DialogBtnsDiv sx={{ width: "100%" }}>
          <RandomButton
            sx={{
              width: "100%",
              textAlgn: "center",
              color: "#344054 !important",
              border: "1px solid #D0D5DD",
            }}
            purple="false"
            variant="contained"
            color="primary"
            onClick={() => navigate(`/dashboard`)}
          >
            Cancel
          </RandomButton>
          <RandomButton
            sx={{
              width: "100%",
              textAlgn: "center",
              marginRight: "0",
              color: "white !important",
            }}
            purple="true"
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(`/settings`, {
                state: { activeTab: "workspace" },
              })
            }
          >
            Add Company Info
          </RandomButton>
        </DialogBtnsDiv>
      </Dialog>
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
export default CreateNewProjectForm;
const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  padding: "10px 0px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  position: "sticky",
  top: 0,
  zIndex: 1,
});

const HeadingsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10",
});
const Heading = styled("p")({
  color: "#000",
  fontsize: "24px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const CustomStack = styled(Stack)({
  "@media (min-width: 0px) and (max-width: 690px)": {
    display: "flex",
    flexDirection: "column",
  },
});
const CustomBox = styled(Box)({
  "@media (min-width: 0px) and (max-width: 690px)": {
    marginLeft: "0 !important",
    width: "-webkit-fill-available",
  },
});
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
const Error = styled("div")({
  fontSize: "12px",
  color: "red",
  paddingLeft: "5px",
});
const DialogContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  alignItems: "center",
});
const DialogTypography = styled(Typography)({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
  marginTop: "20px",
  marginBottom: "32px",
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
  padding: "0px 24px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  fontWeight: "600",
  letterSpacing: "0.5px",
  fontSize: "16px",
  padding: "10px",
  background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  },
}));
