// ErrorHandler.js
import { toast } from "react-toastify";
import { AuthErrorCodes } from "firebase/auth";

const handleError = (error) => {
  console.error(error.code);
  switch (error.code) {
    case AuthErrorCodes.EMAIL_EXISTS:
      toast.error("The email address is already in use.");
      break;
    case AuthErrorCodes.INVALID_EMAIL:
      toast.error("The email address is not valid.");
      break;
    case AuthErrorCodes.WEAK_PASSWORD:
      toast.error("Password is very weak");
      break;
    // Add more cases as needed
    default:
      toast.error("An error occurred.");
  }
};

export default handleError;
