/* eslint-disable no-console */
import CreateCompanyFormModel from "./CreateCompanyFormModel";

const {
  formField: {
    CompanyName,
    OrganizationNumber,
    AdminName,
    AdminEmail,
    AdminConfimEmail,
    AdminPassword,
  },
} = CreateCompanyFormModel;
export default {
  [CompanyName.name]: "",
  [OrganizationNumber.name]: "",
  [AdminName.name]: "",
  [AdminEmail.name]: "",
  [AdminConfimEmail.name]: "",
  [AdminPassword.name]: "",
};
