/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import hotsportDData from "../data";

function NotingerDialog() {
  // to clear the selected values
  const selectValue = useRef();
  const [open, setOpen] = useState(false);
  const data = hotsportDData[0].sections;
  const buldingType = Object.keys(data);
  const [ActivebuildingTypes, setActivebuildingTypes] = useState("");
  const [positions, setPostions] = useState([]);
  const [Hotspot, setHotspot] = useState([]);
  const [Activepositions, setActivePositions] = useState("");

  const [riskText, setriskText] = useState("");
  const [forstatText, setforstatText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setActivebuildingTypes("");
    setActivePositions("");
    if (selectValue.current !== undefined && Hotspot.length > 0) {
      selectValue.current.clearValue();
    }
    setHotspot([]);
  };
  const handlebuildingTypeClick = (itemName) => {
    setActivebuildingTypes(itemName);
    const positions = Object.keys(hotsportDData[0].sections[itemName]);
    setActivePositions("");
    setPostions(positions);
    setHotspot([]);
  };
  const handleActivepositionsClick = (itemName) => {
    setActivePositions(itemName);
    if (selectValue.current !== undefined && Hotspot.length > 0) {
      selectValue.current.clearValue();
    }
    const hotsportArray = hotsportDData[0].sections[ActivebuildingTypes][itemName];
    setHotspot(hotsportArray);
  };
  const getRemovedText = (array1, array2) =>
    array1.filter((obj1) => !array2.some((obj2) => obj1.value === obj2.value));
  const handleChange = (options) => {
    // find risk remove from risk array

    const z = getRemovedText(selectedOptions, options);
    // removeRisk(z[0]?.riskID);
    // removeForstatt(z[0]?.forstattID);
    // for loop remove the key from last selected text
    for (let i = 0; i < selectedOptions.length; i++) {
      if (selectedOptions[i]?.riskID !== undefined && selectedOptions[i]?.riskID === z[0]?.riskID) {
        delete selectedOptions[i]?.riskID;
      }
      if (
        selectedOptions[i]?.forstattID !== undefined &&
        selectedOptions[i]?.forstattID === z[0]?.forstattID
      ) {
        delete selectedOptions[i]?.forstattID;
      }
    }

    setSelectedOptions(options);
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Notes
      </Button>
      <Dialog open={open} onClose={handleClose} disableBackdropClick>
        <DialogTitle>Add Notinger</DialogTitle>
        <DialogContent>
          {buldingType.map((item, index) => (
            <Button
              key={index}
              variant={`${ActivebuildingTypes === item ? "contained" : "outlined"}`}
              onClick={() => {
                handlebuildingTypeClick(item);
              }}
            >
              {item}
            </Button>
          ))}
          {ActivebuildingTypes.length > 0 ? (
            <div className="py-3">
              <h4>Select Room</h4>
              <div className="d-flex gap-2 flex-wrap">
                {positions.map((item, index) => (
                  <Button
                    key={index}
                    variant={`${Activepositions === item ? "contained" : "outlined"}`}
                    onClick={() => {
                      handleActivepositionsClick(item);
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
          ) : null}
          {Hotspot.length > 0 ? (
            <CreatableSelect
              isMulti
              isClearable={false}
              value={selectedOptions}
              className="bg-danger"
              options={Hotspot}
              onChange={(e) => {
                handleChange(e);
              }}
              closeMenuOnScroll
              ref={selectValue}
            />
          ) : null}

          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
           /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default NotingerDialog;
