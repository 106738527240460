/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Typography } from "@mui/material";

function CheckoutSuccess(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom py={2} px={3}>
        Sucessfully created!
      </Typography>
      <Typography variant="subtitle1" px={3}>
        <b>{props.CompanyId}</b>
      </Typography>
    </>
  );
}

export default CheckoutSuccess;
