/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { ref, uploadString } from "firebase/storage";
import { Storage } from "FirebaseConfi";
import { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";

function ImageCropper(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const editorRef = useRef(null);

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setCroppedImage(null);
    setScale(1);
    setPosition({ x: 0.5, y: 0.5 });
  };

  const handleImageReady = () => {
    const canvas = editorRef.current.getImageScaledToCanvas();
    setCroppedImage(canvas.toDataURL());
  };

  const handlePreviewImage = () => {
    const canvas = editorRef.current.getImageScaledToCanvas();
    setCroppedImage(canvas.toDataURL());
  };

  const handleSaveImage = () => {
    const canvas = editorRef.current.getImageScaledToCanvas();

    // Add code to send the cropped image data to the server or display it on the web page.
    try {
      const storageRef = ref(Storage, `/company/${props.companyUID}/CompanyMainLogo`);

      uploadString(storageRef, canvas.toDataURL(), "data_url").then((snapshot) => {
        props.DialogCloseActionBtn();
        props.setImage(canvas.toDataURL());
        props.setImageLoadError(false);
        toast.success("Logo updated successfully!");
        console.log("Uploaded a base64 string!");
        console.log(snapshot);
      });
    } catch (error) {
      setErrorMessage("some thing is wrong");
      console.log(error);
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const handlePositionChange = (position) => {
    setPosition(position);
  };
  const grid = {
    size: 10,
    color: [0, 0, 0, 1],
    renderStart: () => (
      <div style={{ position: "absolute", top: 0, left: 0, color: "white", fontSize: "16px" }}>
        Crop the image
      </div>
    ),
  };

  return (
    <>
      <Box>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <Typography variant="span" color="error" component="h5">
          {errorMessage}
        </Typography>
        {selectedImage && (
          <>
            <Grid container justifyContent="flex-end" my={2}>
              <Grid item>
                <button onClick={handleZoomIn}>Zoom In</button>
              </Grid>
              <Grid item>
                <button onClick={handleZoomOut}>Zoom Out</button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <AvatarEditor
                image={selectedImage}
                width={350}
                height={150}
                border={30}
                color={[0, 0, 0, 0.6]} // RGBA
                scale={scale}
                position={position}
                rotate={0}
                onImageReady={handleImageReady}
                onPositionChange={handlePositionChange}
                ref={editorRef}
                grid={grid}
              />
            </Grid>
            <DialogActions>
              <Button onClick={props.DialogCloseActionBtn} color="primary">
                Cancel
              </Button>
              {croppedImage ? (
                <Button onClick={handleSaveImage}>Save Image</Button>
              ) : (
                <Button onClick={handlePreviewImage}>Preview</Button>
              )}
            </DialogActions>
          </>
        )}
      </Box>
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ImageCropper;

const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
