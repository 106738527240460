/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { Box, Button, TextField, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useState } from "react";
import renameIcon from "../../../assets/images/rename-icon.svg";

function ChatTitle({ InitialTitle, setSceneTitle, changeName }) {
  const [isEditing, setIsEditing] = useState(false);
  // Replace with actual title

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Perform save action, e.g., update the title in your data source
    changeName();
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <Box display="flex" alignItems="center">
          <TextField
            type="text"
            value={InitialTitle}
            onChange={(e) => setSceneTitle(e.target.value)}
          />

          <StyledBtn onClick={handleSaveClick}>
            <CheckIcon />
          </StyledBtn>
          <StyledBtn onClick={handleCancelClick} type="delete">
            <ClearIcon />
          </StyledBtn>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <ImageTitle>{InitialTitle}</ImageTitle>
          <StyledBtn onClick={handleEditClick}>
            <img src={renameIcon} alt="_rename" />
          </StyledBtn>
        </Box>
      )}
    </div>
  );
}

export default ChatTitle;
const StyledBtn = styled(Button)(({ type }) => ({
  borderRadius: "8px",
  background: type === "delete" ? "#FEF3F2" : "#F9F5FF",
  color: type === "delete" ? "#D92D20" : "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  display: "flex",
  padding: "8px 14px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
}));
const ImageTitle = styled("p")({
  color: "#101828",
  fontSize: "16px",
  fontWeight: "500",
});
