/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
export const disclaimer = [
  "Denna besiktning är utförd på uppdrag av säljaren. Observera att Du som köpare har en egen långtgående undersökningsplikt! För att uppfylla en del av Din undersökningsplikt och för att juridiskt överta detta besiktningsutlåtande krävs att Du kontaktar besiktningsföretaget för utförande av en köpargenomgång. I annat fall har besiktningsmannen inget juridiskt ansvar gentemot Dig. Vid en köpargenomgång gäller samma villkor och ansvar som vid en separat köparbesiktning. Observera att en köpargenomgång är endast möjlig att utföra inom 6 månader efter genomförd besiktning",
];
export const forstattDefaultText = [
  "Köpare kan begära fortsatt teknisk utredning för att klarlägga om risk för väsentligt fel som besiktningsmannen antecknat i riskanalysen finns eller inte. En fortsatt teknisk utredning ingår inte i överlåtelsebesiktningen. En sådan utredning kan beställas särskilt. En fortsatt teknisk utredning förutsätter fastighetsägarens uttryckliga medgivande.",
];

export const shortNoteAfterTable = [
  "Uppdraget utförs enligt ”villkor för överlåtelsebesiktning för säljare enligt SBR modellen”. En uppdragsbekräftelse med bifogad villkorsbilaga överlämnades till beställaren den Innan besiktningen påbörjades gjordes en genomgång av uppdragsbekräftelsen. Besiktningsmannen ansvarar inte för fel och är inte skyldig att betala för krav som reklamerats respektive framställts senare än två år efter att uppdraget avslutats. Uppdraget är avslutat i och med att besiktningsmannen översänt utlåtandet till uppdragsgivaren",
];
export const Säljarinformation = [
  "Under denna rubrik är samtliga uppgifter lämnade av fastighetsägare eller dess ombud.  Nuvarande ägare sedan 2017 ( i släkten sedan 1969) -Byggnaden är renoverad i omgångar sedan 70-talet och framåt. -Huset har tilläggsisolerats, ny panel samt flertalet fönster är utbytta. -Våtrum på övre plan renoverades - 2009 -Avloppsanläggningen är utbytt samt ny vattenbrunn borrades - 2015/2016 Ingen lukt eller övrigt avvikande har noterats av fastighetsägare.",
];
export const Särskilda = [
  "Byggnaden var vid besiktningstillfället möblerad. Besiktning har skett av de delar som varit normalt åtkomliga utan omflyttning av belamrade ytor eller möbler. Bakomliggande ytor ingår i köparens undersökningsplikt. För ytor, utrymmen och byggnadsdelar som noterats helt eller delvis ej besiktningsbara eller belamrade har besiktningsmannen inget ansvar. Notering ”----” innebär att utrymmet/ytan bedöms vara i normalt skick med hänsyn taget till byggnadens ålder och byggnadssätt. Vid en överlåtelsebesiktning ingår i regel ej fuktmätningar och ingrepp i konstruktioner. I de fall som en fuktmätning beställs som ett tilläggsuppdrag kommer resultatet att redovisas i ett separat utlåtande som bifogas till besiktningsutlåtandet.",
];
export const Muntliga = [
  "Avsikten har varit att i detta utlåtande redovisa samtliga förhållanden, som framkommit vid besiktningen och då även samtliga muntliga uppgifter av någon betydelse som lämnats av besiktningsmannen vid besiktningen. Skulle någon muntlig uppgift av betydelse ha utelämnats eller blivit felaktigt återgiven, enligt beställarens uppfattning, har beställaren att snarast och helst inom sju dagar meddela besiktningsmannen vad som enligt beställarens uppfattning skall ändras i utlåtandet. Om sådant meddelande inte lämnats inom ovan angiven tid kan inte besiktningsmannen göras ansvarig för eventuella brister i utlåtandet, som på så sätt kunnat rättas.",
];
export const Besiktningsman = ["Rebaz Amin"];

export const BILAGA_1_contentListSeller = [
  { Heading: "ÖVERLÅTELSEBESIKTNING FÖR SÄLJARE", text: [] },
  { Heading: "ENLIGT SBR-MODELLEN ", text: [] },
  {
    Heading: "Begreppsbestämningar",
    text: [
      "Med uppdragsgivare avses säljaren av fastigheten eller den som på säljarens uppdrag beställer överlåtelsebesiktningsuppdraget av besiktningsmannen och som undertecknat uppdragsbekräftelsen.",
      "Med besiktningsman avses i tillämpliga delar även det besiktningsföretag som mottagit uppdraget att utföra överlåtelsebesiktningen.",
      "Med fastighet avses den del av registerfastigheten som omfattas av besiktningen.",
      "Med fel i fastighet avses en avvikelse från det skick som en tänkt köpare med fog kan förutsätta att fastigheten skall ha vid tidpunkten för köpet om köpet genomfördes vid tidpunkten för överlåtelsebesiktningen.",
    ],
  },
  {
    Heading: "Ändamålet ",
    text: [
      "Ändamålet med en överlåtelsebesiktning för säljare är att i samband med en fastighetsöverlåtelse samla och redovisa information om fastighetens fysiska skick. Insamlingen sker genom en byggnadsteknisk undersökning som utförs av en särskilt anlitad sakkunnig besiktningsman. Resultatet redovisas i ett protokoll benämnt besiktningsutlåtande som är avsett att användas vid fastighetsförsäljningen som en byggnadsteknisk beskrivning av fastighetens skick. ",
      "Besiktningsutlåtandet kan även ha betydelse vid förhandling om de villkor som ska gälla för fastighetsköpet och det kan också utgöra underlag till en doldafelförsäkring. ",
    ],
  },
  {
    Heading: "GENOMFÖRANDET  ",
    text: [],
  },
  {
    Heading: "Uppdragsbekräftelse",
    text: [
      "Efter mottagandet av uppdraget översänder eller överlämnar besiktningsmannen en uppdragsbekräftelse till uppdragsgivaren jämte dessa villkor. Av uppdragsbekräftelsen och dessa villkor framgår överlåtelsebesiktningens omfattning. Besiktningsmannen går igenom uppdragsbekräftelsen och villkoren för uppdraget med uppdragsgivaren innan överlåtelsebesiktningen påbörjas.",
    ],
  },
  {
    Heading: "Överlåtelsebesiktningens huvudsakliga innehåll",
    text: [
      "Överlåtelsebesiktning enligt SBR-modellen består sammanfattningsvis av fyra delar;",
      "1) tillhandahållna handlingar samt information från säljaren,",
      "2) okulär besiktning,",
      "3) riskanalys om sådan är påkallad samt",
      "4) eventuell rekommendation om fortsatt teknisk utredning.",
      "Resultatet av överlåtelsebesiktningen sammanställs i ett besiktningsutlåtande",
    ],
  },
  {
    Heading: "1) Handlingar och upplysningar",
    text: [
      "Inför överlåtelsebesiktningen eller i samband med överlåtelsebesiktningens påbörjande tar besiktningsmannen del av de handlingar och övriga upplysningar som överlämnats. De handlingar och upplysningar som besiktningsmannen lägger till grund för överlåtelsebesiktningen antecknas i besiktningsutlåtandet",
    ],
  },
  {
    Heading: "2) Besiktning",
    text: [
      "Överlåtelsebesiktningen genomförs i form av en omsorgsfull okulär besiktning av fastigheten, dvs. vad som kan upptäckas med blotta ögat. Besiktningen sker således utan några hjälpmedel.Överlåtelsebesiktningen gäller förhållandena vid besiktningstillfället.",
      "Vid den okulära besiktningen undersöker besiktningsmannen synliga ytor i samtliga tillgängliga utrymmen samt fasader, tak och mark i den mån marken är av byggnadsteknisk betydelse. Med tillgängliga utrymmen menas alla sådana utrymmen som kan undersökas via öppningar, dörrar, inspektionsluckor och liknande samt alla utrymmen som i övrigt är krypbara.",
      "Om inte annat avtalats omfattar överlåtelsebesiktningen en byggnadsteknisk okulär besiktning av fastighetens huvudbyggnad, samt vidbyggd del av hus såsom garage, carport eller förråd samt den markyta i anslutning till byggnad som har teknisk betydelse för de besiktade byggnaderna.",
      "Överlåtelsebesiktningen omfattar således inte hela registerfastigheten. För det fall parterna kommer överens om att besiktningen skall ha annan omfattning än vad som nu sagts, skall detta skrivas in i uppdragsbekräftelsen.",
    ],
  },
  {
    Heading: "Besiktningen omfattar inte ",
    text: [
      "Överlåtelsebesiktningen omfattar inte installationer såsom el, värme, vatten, sanitet, maskinell utrustning, mekanisk ventilation, rökgångar eller eldstäder. Besiktningen omfattar inte energideklaration, miljöinventering, undersökningar som kräver ingrepp i byggnaden, provtryckning, radonmätning, fuktmätning eller annan mätning",
      "I överlåtelsebesiktningen ingår inte att lämna åtgärdsförslag eller kostnadskalkyler.",
      "Överlåtelsebesiktningens omfattning kan utökas eller inskränkas efter särskild överenskommelse mellan uppdragsgivaren och besiktningsmannen. Sådan överenskommelse ska i förekommande fall framgå av uppdragsbekräftelsen eller enligt särskilt avtal om tilläggsuppdrag.",
      "Överlåtelsebesiktningens omfattning kan utökas eller inskränkas efter särskild överenskommelse mellan uppdragsgivaren och besiktningsmannen. Sådan överenskommelse ska i förekommande fall framgå av uppdragsbekräftelsen eller enligt särskilt avtal om tilläggsuppdrag.",
    ],
  },
  {
    Heading: "3) Riskanalys",
    text: [
      "Om besiktningsmannen bedömer att det finns påtaglig risk för att fastigheten har andra väsentliga fel än de som framkommit vid den okulära besiktningen redovisar besiktningsmannen förhållandet i en riskanalys i besiktningsutlåtandet.",
      "Till grund för en sådan riskanalys lägger besiktningsmannen bland annat fastighetens konstruktion, ålder och skick, iakttagelser som gjorts vid den okulära besiktningen, den information som lämnats genom handlingar och upplysningar samt beskaffenheten hos jämförbara fastigheter och omständigheterna vid överlåtelsebesiktningen. I riskanalysen ges även en motivering till bedömningen",
    ],
  },
  {
    Heading: "4) Fortsatt teknisk utredning",
    text: [
      "Besiktningsmannen kan föreslå fortsatt teknisk utredning avseende ett förhållande som inte kunnat klarläggas vid den okulära besiktningen. Sådan utredning kan även föreslås för misstänkta fel i en del av fastigheten som i och för sig inte ingår i överlåtelsebesiktningen",
      "Om besiktningsmannen gjort en anteckning om en påtaglig risk för väsentligt fel i form av en riskanalys, så föreslår inte besiktningsmannen någon fortsatt teknisk utredning i den delen",
      "Uppdragsgivaren kan själv se till att den påtalade risken utreds.",
      "Fortsatt teknisk utredning ingår inte i överlåtelsebesiktningen. Uppdragsgivaren och besiktningsmannen kan dock komma överens om att besiktningsmannen även skall utföra den fortsatta tekniska utredningen, förutsatt att detta medges av fastighetens ägare. Se vidare om sådant tilläggsuppdrag under rubrik nedan",
    ],
  },
  {
    Heading: "Besiktningsutlåtande",
    text: [
      "Besiktningsmannen upprättar ett besiktnings-utlåtande över överlåtelsebesiktningen",
      "I besiktningsutlåtandet redovisas de fel som upptäckts vid den okulära besiktningen.",
      "Besiktningsutlåtandets innehåll är en följd av att överlåtelsebesiktningen utförts med sådan omsorg som är påkallad med hänsyn till fastighetens skick, den normala beskaffenheten hos jämförliga fastigheter och omständigheterna vid överlåtelsebesiktningen. Vid överlåtelsebesiktningen har således bland annat byggnadernas ålder och konstruktion betydelse. En äldre byggnad har normalt fler fel än en nyare byggnad och en äldre byggnad uppfyller inte alltid moderna krav.",
      "I besiktningsutlåtandet redovisas endast de fel som har någon betydelse för bedömningen av fastighetens skick. Mindre fel (skavanker) antecknas normalt inte.",
      "Besiktningsutlåtandet kan även innehålla riskanalys och rekommendation om fortsatt teknisk utredning.",
    ],
  },
  {
    Heading: "Tilläggsuppdrag",
    text: [
      "Uppdragsgivare kan genom särskild överenskommelse med besiktningsmannen träffa avtal om tilläggsuppdrag i anslutning till överlåtelsebesiktningen. Tilläggsuppdrag förutsätter ett godkännande av fastighetens ägare.",
      "Syftet med ett tilläggsuppdrag kan vara att utvidga undersökningen till att omfatta delar som inte ingår i överlåtelsebesiktningen eller att undersöka omständighet som inte kunnat klarläggas vid överlåtelsebesiktningen. Ett tilläggsuppdrag är inte en del av överlåtelsebesiktningen, men kan utföras i samband med denna.",
      "Omfattningen av överenskomna tilläggsuppdrag ska anges i uppdragsbekräftelsen till överlåtelsebesiktningen eller i en separat uppdragsbekräftelse och resultatet av ett sådant tilläggsuppdrag ska redovisas i ett särskilt utlåtande.",
      "Om uppdragsgivaren beställer tilläggsuppdrag som utförts i anslutning till överlåtelsebesiktningen gäller villkoren för överlåtelsebesiktningen även för tilläggsuppdraget.",
    ],
  },
  {
    Heading: "Besiktningsmannens ansvar",
    text: [
      "Besiktningsmannen är endast ansvarig för uppdraget gentemot sin uppdragsgivare. Annan än uppdragsgivare äger således inte rätt till skadestånd från besiktningsmannen.",
      "Besiktningsmannen är inte skyldig att kontrollera riktigheten av de handlingar och upplysningar om fastigheten som han mottar i samband med överlåtelsebesiktningen",
      "Besiktningsmannen är skyldig att ersätta den skada som besiktningsmannen orsakar uppdragsgivaren genom vårdslöshet eller försummelse vid utförandet av överlåtelsebesiktningen. Besiktningsmannens ansvar är dock begränsat enligt nedanstående villkor.",
      "Besiktningsmannens skadeståndsskyldighet är begränsad till ett belopp motsvarande skillnaden mellan fastighetens värde vid överlåtelsetillfället med respektive utan fel i besiktningsutlåtandet och överstiger inte i något fall 15 prisbasbelopp enligt lagen om allmän försäkring vid den tidpunkt då avtal om överlåtelsebesiktning träffades.",
      "Skada vars värde understiger ett belopp motsvarande 0,5 prisbasbelopp är besiktningsmannen inte skyldig att ersätta.",
      "Om besiktningsmannen utför tilläggsuppdrag i anslutning till överlåtelsebesiktningen skall begränsningen i besiktningsmannens skadeståndsskyldighet omfatta även skada på grund av fel i tilläggsuppdraget.",
      "Skada som beror på att det i besiktningsutlåtandet saknas någon uppgift eller påpekande som besiktningsmannen lämnat muntligen ersätts endast om uppdragsgivaren omgående efter erhållandet av besiktningsutlåtandet begärt komplettering av besiktningsutlåtandet",
      "Besiktningsmannen är skyldig att inneha en giltig konsultansvarsförsäkring med särskilda villkor om överlåtelsebesiktning.",
      "Besiktningsmannen skall arkivera en kopia av besiktningsutlåtandet under minst två år från det att uppdraget slutförts",
    ],
  },
  {
    Heading: "Reklamation och preskription",
    text: [
      "Fel i besiktningsmannens uppdrag eller krav på grund av sådant fel skall reklameras respektive framställas inom skälig tid från det att felet märkts eller borde ha märkts respektive från det att skada upptäckts. Besiktningsmannen ansvarar dock inte i något fall för fel och är inte skyldig att betala för krav som reklamerats respektive framställts senare än två år efter att uppdraget avslutats. Uppdraget är avslutat i och med att besiktningsmannen översänt besiktningsutlåtandet till uppdragsgivaren (säljaren).",
    ],
  },
  {
    Heading: "Uppdragsgivarens ansvar",
    text: [
      "Uppdragsgivaren skall verka för att få fram alla relevanta handlingar och se till att fastigheten är besiktningsbar, enligt vad som ovan sagts, för att besiktningen skall bli så rättvisande som möjligt.Besiktningsmannen ansvar inte för att de handlingar och övriga upplysningar om fastigheten som behövs för överlåtelsebesiktningen finns tillgängliga för besiktningsmannen vid överlåtelsebesiktningens påbörjande.",
      "Besiktningsmannen ansvarar inte för att fastighetens samtliga utrymmen och ytor är tillgängliga för besiktning. För att kunna utföra besiktningen på ett rättvisande sätt skall ytorna vara lättåtkomliga och fria från skrymmande bohag. Godkänd stege ska finnas tillgänglig.",
      "Uppdragsgivaren ska noggrant läsa besiktningsutlåtandet efter mottagandet och utan dröjsmål därefter meddela besiktningsmannen om besiktningsutlåtandet innehåller någon felaktighet eller saknar något.",
      "För genomförandet av uppdraget förutsätts att säkra uppstigningsanordningar till vindsutrymmen och yttertak finns på byggnaden.",
      "Besiktningsmannen är inte skyldig att genomföra besiktningsåtgärd som innebär att han utsätter sig för fara. Besiktningsmannen avgör i varje enskilt fall vad som är en säker uppstigningsanordning eller fara vid utförandet av uppdraget.",
    ],
  },
  {
    Heading: "Besiktningsutlåtandets juridiska betydelse",
    text: [
      "De fel som redovisas i besiktningsutlåtandet kan få betydelse mellan säljaren och köparen av fastigheten.",
      "Genom en överlåtelsebesiktning för säljare och överlämnandet av besiktningsutlåtandet till köparen klargörs ansvarsfördelningen mellan säljare och köpare för de fel som redovisas i besiktningsutlåtandet. De redovisade felen kan till exempel inte anses utgöra dolda fel i fastigheten. De fel som antecknats, liksom de risker som antecknats i riskanalysen och som senare infrias, kan en köpare normalt inte göra gällande såsom dolda fel mot säljaren efter fastighetsköpet. ",
      "Om inte annat avtalats i samband med överlåtelsen av fastigheten ersätter besiktningsutlåtandet inte köparens undersökningsplikt enligt jordabalken. Syftet med en överlåtelsebesiktning för säljare är således inte att utgöra en del av fullgörandet av köparens undersökningsplikt. ",
      "Överlämnandet av besiktningsutlåtandet till köparen innebär inte heller att säljaren utfäster eller garanterar att fastigheten har de egenskaper eller det skick som framgår av besiktningsutlåtandet. För att en utfästelse eller garanti skall föreligga krävs att säljaren ger särskilt uttryck för det. T ex genom att det anges i köpekontraktet. ",
      "Besiktningsutlåtandet redovisar förhållandena vid tidpunkten för överlåtelsebesiktningen. Uppdragsgivaren måste vara observant på att förhållandena kan ändras eller försämras under den tid som förflyter mellan besiktning och fastighetsköp. ",
    ],
  },
  {
    Heading: "Äganderätt och nyttjanderätt till besiktningsutlåtandet",
    text: [
      "Besiktningsmannen har upphovsrätten till besiktningsutlåtandet. Uppdragsgivaren får endast använda besiktningsutlåtandet i enlighet med det avtalade ändamålet.",
      "Uppdragsgivaren äger inte överlåta besiktningsutlåtandet eller nyttjanderätt till besiktningsutlåtandet utan besiktningsmannens uttryckliga medgivande.",
      "Ansvaret för denna besiktning är endast gällande mellan uppdragsgivare (säljare) och besiktningsföretaget. För att ansvar skall kunna göras gällande mellan köparen och besiktningsföretaget krävs särskilt avtal om uppdrag mellan besiktningsföretaget och köparen. Observera att ett sådant avtal endast är möjligt att utföra inom 6 månader från avslutat uppdrag. ",
      "Om överlåtelse av utlåtandet skall ske har besiktningsmannen rätt att vid sammanträffande eller på annat lämpligt sätt mot ersättning redovisa besiktningsutlåtandet för förvärvaren.",
      "Sker överlåtelse utan medgivande från besiktningsmannen kan innehållet i besiktningsutlåtandet inte göras gällande mot besiktningsmannen. Samma sak gäller om uppdragsgivaren utan medgivande använder besiktningsutlåtandet för annat än det avtalade ändamålet.",
      "I inget fall har förvärvare av besiktnings-utlåtandet bättre rätt än uppdragsgivaren. ",
    ],
  },
  {
    Heading: "Betalning och hävning ",
    text: [
      "Uppdragsgivaren skall erlägga betalning för överlåtelsebesiktningen i enlighet med villkoren i uppdragsbekräftelsen. Har ej annat angetts i uppdragsbekräftelsen skall betalning erläggas inom 10 dagar från fakturadatum.",
      "Om betalning inte erläggs i rätt tid och detta inte beror på besiktningsmannen eller något förhållande på dennes sida, får besiktningsmannen välja mellan att kräva att uppdragsgivaren betalar eller, om uppdragsgivarens dröjsmål med betalningen utgör ett väsentligt avtalsbrott, häva uppdragsavtalet.",
      "Har besiktningsmannen förelagt uppdragsgivaren en bestämd tilläggstid för betalningen om minst 10 dagar, får uppdragsavtalet även hävas om uppdragsgivaren inte betalar inom tilläggstiden. Medan tilläggstiden löper får besiktningsmannen häva uppdragsavtalet endast om uppdragsgivaren meddelar att denne inte kommer att betala inom denna tid.",
    ],
  },
];
export const BILAGA_1_contentListBuyer = [
  { Heading: "ÖVERLÅTELSEBESIKTNING FÖR KÖPARE", text: [] },
  { Heading: "ENLIGT SBR-MODELLEN ", text: [] },
  {
    Heading: "Begreppsbestämningar",
    text: [
      "Med uppdragsgivare avses köparen av fastigheten och som undertecknat uppdragsbekräftelsen. ",

      "Med besiktningsman avses i tillämpliga delar även det besiktningsföretag som mottagit uppdraget att utföra överlåtelsebesiktningen. ",

      "Med fastighet avses den del av registerfastigheten som omfattas av besiktningen.",

      "Med fel i fastighet avses en avvikelse från det skick som en tänkt köpare med fog kan förutsätta att fastigheten skall ha vid tidpunkten för köpet om köpet genomfördes vid tidpunkten för överlåtelsebesiktningen. ",
    ],
  },
  {
    Heading: "Ändamålet ",
    text: [
      "Ändamålet med en överlåtelsebesiktning för köpare är att i samband med en fastighetsöverlåtelse samla och redovisa information om fastighetens fysiska skick. Insamlingen sker genom en byggnadsteknisk undersökning som utförs av en särskilt anlitad sakkunnig besiktningsman. Resultatet redovisas i ett protokoll benämnt besiktningsutlåtande",
      "Med hjälp av besiktningsutlåtandet ges köparen ett underlag för att bedöma fastighetens skick och vilka förväntningar köparen rimligen kan ha på fastigheten. Besiktningsutlåtandet kan ha betydelse vid förhandling om villkoren för fastighetsköpet, t ex förhandling om priset. Överlåtelsebesiktningen ersätter inte den undersökningsplikt som en köpare är skyldig att genomföra enligt jordabalken, men utgör en del av denna undersökningsplikt. ",
    ],
  },
  {
    Heading: "GENOMFÖRANDET  ",
    text: [],
  },
  {
    Heading: "Uppdragsbekräftelse",
    text: [
      "Efter mottagandet av uppdraget översänder eller överlämnar besiktningsmannen en uppdragsbekräftelse till uppdragsgivaren jämte dessa villkor. Av uppdragsbekräftelsen och dessa villkor framgår överlåtelsebesiktningens omfattning. ",
      "Besiktningsmannen går igenom uppdragsbekräftelsen och villkoren för uppdraget med uppdragsgivaren innan överlåtelsebesiktningen påbörjas.",
    ],
  },
  {
    Heading: "Överlåtelsebesiktningens huvudsakliga innehåll",
    text: [
      "Överlåtelsebesiktning enligt SBR-modellen består sammanfattningsvis av fyra delar;",
      "1) tillhandahållna handlingar samt information från säljaren,",
      "2) okulär besiktning,",
      "3) riskanalys om sådan är påkallad samt",
      "4) eventuell rekommendation om fortsatt teknisk utredning.",
      "Resultatet av överlåtelsebesiktningen sammanställs i ett besiktningsutlåtande",
    ],
  },
  {
    Heading: "1) Handlingar och upplysningar",
    text: [
      "Inför överlåtelsebesiktningen eller i samband med överlåtelsebesiktningens påbörjande tar besiktningsmannen del av de handlingar och övriga upplysningar som överlämnats. De handlingar och upplysningar som besiktningsmannen lägger till grund för överlåtelsebesiktningen antecknas i besiktningsutlåtandet",
    ],
  },
  {
    Heading: "2) Besiktning",
    text: [
      "Överlåtelsebesiktningen genomförs i form av en omsorgsfull okulär besiktning av fastigheten, dvs. vad som kan upptäckas med blotta ögat. Besiktningen sker således utan några hjälpmedel",
      "Överlåtelsebesiktningen gäller förhållandena vid besiktningstillfället. ",
      "Vid den okulära besiktningen undersöker besiktningsmannen synliga ytor i samtliga tillgängliga utrymmen samt fasader, tak och mark i den mån marken är av byggnadsteknisk betydelse. Med tillgängliga utrymmen menas alla sådana utrymmen som kan undersökas via öppningar, dörrar, inspektionsluckor och liknande samt alla utrymmen som i övrigt är krypbara.",
      "Om inte annat avtalats omfattar överlåtelsebesiktningen en byggnadsteknisk okulär besiktning av fastighetens huvudbyggnad, samt vidbyggd del av hus såsom garage, carport eller förråd samt den markyta i anslutning till byggnad som har teknisk betydelse för de besiktade byggnaderna.",
      "Överlåtelsebesiktningen omfattar således inte hela registerfastigheten. För det fall parterna kommer överens om att besiktningen skall ha annan omfattning än vad som nu sagts, skall detta skrivas in i uppdragsbekräftelsen.",
    ],
  },
  {
    Heading: "Besiktningen omfattar inte ",
    text: [
      "Överlåtelsebesiktningen omfattar inte installationer såsom el, värme, vatten, sanitet, maskinell utrustning, mekanisk ventilation, rökgångar eller eldstäder. Besiktningen omfattar inte energideklaration, miljöinventering, undersökningar som kräver ingrepp i byggnaden, provtryckning, radonmätning, fuktmätning eller annan mätning",
      "I överlåtelsebesiktningen ingår inte att lämna åtgärdsförslag eller kostnadskalkyler.",
      "Överlåtelsebesiktningens omfattning kan utökas eller inskränkas efter särskild överenskommelse mellan uppdragsgivaren och besiktningsmannen. Sådan överenskommelse ska i förekommande fall framgå av uppdragsbekräftelsen eller enligt särskilt avtal om tilläggsuppdrag.",
      "Om besiktningsmannen av någon anledning inte har gjort en okulär besiktning av ett utrymme eller en yta som omfattas av överlåtelsebesiktningen skall detta antecknas i besiktningsutlåtandet. ",
    ],
  },
  {
    Heading: "3) Riskanalys",
    text: [
      "Om besiktningsmannen bedömer att det finns påtaglig risk för att fastigheten har andra väsentliga fel än de som framkommit vid den okulära besiktningen redovisar besiktningsmannen förhållandet i en riskanalys i besiktningsutlåtandet.",
      "Till grund för en sådan riskanalys lägger besiktningsmannen bland annat fastighetens konstruktion, ålder och skick, iakttagelser som gjorts vid den okulära besiktningen, den information som lämnats genom handlingar och upplysningar samt beskaffenheten hos jämförbara fastigheter och omständigheterna vid överlåtelsebesiktningen. I riskanalysen ges även en motivering till bedömningen",
    ],
  },
  {
    Heading: "4) Fortsatt teknisk utredning",
    text: [
      "Besiktningsmannen kan föreslå fortsatt teknisk utredning avseende ett förhållande som inte kunnat klarläggas vid den okulära besiktningen. Sådan utredning kan även föreslås för misstänkta fel i en del av fastigheten som i och för sig inte ingår i överlåtelsebesiktningen",
      "Om besiktningsmannen gjort en anteckning om en påtaglig risk för väsentligt fel i form av en riskanalys, så föreslår inte besiktningsmannen någon fortsatt teknisk utredning i den delen",
      "Uppdragsgivaren kan själv se till att den påtalade risken utreds.",
      "Fortsatt teknisk utredning ingår inte i överlåtelsebesiktningen. Uppdragsgivaren och besiktningsmannen kan dock komma överens om att besiktningsmannen även skall utföra den fortsatta tekniska utredningen, förutsatt att detta medges av fastighetens ägare. Se vidare om sådant tilläggsuppdrag under rubrik nedan",
    ],
  },
  {
    Heading: "Besiktningsutlåtande",
    text: [
      "Besiktningsmannen upprättar ett besiktnings-utlåtande över överlåtelsebesiktningen",
      "I besiktningsutlåtandet redovisas de fel som upptäckts vid den okulära besiktningen.",
      "Besiktningsutlåtandets innehåll är en följd av att överlåtelsebesiktningen utförts med sådan omsorg som är påkallad med hänsyn till fastighetens skick, den normala beskaffenheten hos jämförliga fastigheter och omständigheterna vid överlåtelsebesiktningen. Vid överlåtelsebesiktningen har således bland annat byggnadernas ålder och konstruktion betydelse. En äldre byggnad har normalt fler fel än en nyare byggnad och en äldre byggnad uppfyller inte alltid moderna krav.",
      "I besiktningsutlåtandet redovisas endast de fel som har någon betydelse för bedömningen av fastighetens skick. Mindre fel (skavanker) antecknas normalt inte.",
      "Besiktningsutlåtandet kan även innehålla riskanalys och rekommendation om fortsatt teknisk utredning.",
    ],
  },
  {
    Heading: "Tilläggsuppdrag",
    text: [
      "Uppdragsgivare kan genom särskild överenskommelse med besiktningsmannen träffa avtal om tilläggsuppdrag i anslutning till överlåtelsebesiktningen. Tilläggsuppdrag förutsätter ett godkännande av fastighetens ägare.",
      "Syftet med ett tilläggsuppdrag kan vara att utvidga undersökningen till att omfatta delar som inte ingår i överlåtelsebesiktningen eller att undersöka omständighet som inte kunnat klarläggas vid överlåtelsebesiktningen. Ett tilläggsuppdrag är inte en del av överlåtelsebesiktningen, men kan utföras i samband med denna.",
      "Omfattningen av överenskomna tilläggsuppdrag ska anges i uppdragsbekräftelsen till överlåtelsebesiktningen eller i en separat uppdragsbekräftelse och resultatet av ett sådant tilläggsuppdrag ska redovisas i ett särskilt utlåtande.",
      "Om uppdragsgivaren beställer tilläggsuppdrag som utförts i anslutning till överlåtelsebesiktningen gäller villkoren för överlåtelsebesiktningen även för tilläggsuppdraget.",
    ],
  },
  {
    Heading: "Besiktningsmannens ansvar",
    text: [
      "Besiktningsmannen är endast ansvarig för uppdraget gentemot sin uppdragsgivare. Annan än uppdragsgivare äger således inte rätt till skadestånd från besiktningsmannen.",
      "Besiktningsmannen är inte skyldig att kontrollera riktigheten av de handlingar och upplysningar om fastigheten som han mottar i samband med överlåtelsebesiktningen",
      "Besiktningsmannen är skyldig att ersätta den skada som besiktningsmannen orsakar uppdragsgivaren genom vårdslöshet eller försummelse vid utförandet av överlåtelsebesiktningen. Besiktningsmannens ansvar är dock begränsat enligt nedanstående villkor.",
      "Besiktningsmannens skadeståndsskyldighet är begränsad till ett belopp motsvarande skillnaden mellan fastighetens värde vid överlåtelsetillfället med respektive utan fel i besiktningsutlåtandet och överstiger inte i något fall 15 prisbasbelopp enligt lagen om allmän försäkring vid den tidpunkt då avtal om överlåtelsebesiktning träffades.",
      "Skada vars värde understiger ett belopp motsvarande 0,5 prisbasbelopp är besiktningsmannen inte skyldig att ersätta.",
      "Om besiktningsmannen utför tilläggsuppdrag i anslutning till överlåtelsebesiktningen skall begränsningen i besiktningsmannens skadeståndsskyldighet omfatta även skada på grund av fel i tilläggsuppdraget.",
      "Skada som beror på att det i besiktningsutlåtandet saknas någon uppgift eller påpekande som besiktningsmannen lämnat muntligen ersätts endast om uppdragsgivaren omgående efter erhållandet av besiktningsutlåtandet begärt komplettering av besiktningsutlåtandet",
      "Besiktningsmannen är skyldig att inneha en giltig konsultansvarsförsäkring med särskilda villkor om överlåtelsebesiktning.",
      "Besiktningsmannen skall arkivera en kopia av besiktningsutlåtandet under minst två år från det att uppdraget slutförts",
    ],
  },
  {
    Heading: "Reklamation och preskription",
    text: [
      "Fel i besiktningsmannens uppdrag eller krav på grund av sådant fel skall reklameras respektive framställas inom skälig tid från det att felet märkts eller borde ha märkts respektive från det att skada upptäckts. Besiktningsmannen ansvarar dock inte i något fall för fel och är inte skyldig att betala för krav som reklamerats respektive framställts senare än två år efter att uppdraget avslutats. Uppdraget är avslutat i och med att besiktningsmannen översänt besiktningsutlåtandet till uppdragsgivaren.",
    ],
  },
  {
    Heading: "Uppdragsgivarens ansvar",
    text: [
      "Uppdragsgivaren skall verka för att få fram alla relevanta handlingar och se till att fastigheten är besiktningsbar, enligt vad som ovan sagts, för att besiktningen skall bli så rättvisande som möjligt.Besiktningsmannen ansvar inte för att de handlingar och övriga upplysningar om fastigheten som behövs för överlåtelsebesiktningen finns tillgängliga för besiktningsmannen vid överlåtelsebesiktningens påbörjande.",
      "Besiktningsmannen ansvarar inte för att fastighetens samtliga utrymmen och ytor är tillgängliga för besiktning. För att kunna utföra besiktningen på ett rättvisande sätt skall ytorna vara lättåtkomliga och fria från skrymmande bohag. Godkänd stege ska finnas tillgänglig.",
      "Uppdragsgivaren ska noggrant läsa besiktningsutlåtandet efter mottagandet och utan dröjsmål därefter meddela besiktningsmannen om besiktningsutlåtandet innehåller någon felaktighet eller saknar något.",
      "För genomförandet av uppdraget förutsätts att säkra uppstigningsanordningar till vindsutrymmen och yttertak finns på byggnaden.",
      "Besiktningsmannen är inte skyldig att genomföra besiktningsåtgärd som innebär att han utsätter sig för fara. Besiktningsmannen avgör i varje enskilt fall vad som är en säker uppstigningsanordning eller fara vid utförandet av uppdraget.",
    ],
  },
  {
    Heading: "Besiktningsutlåtandets juridiska betydelse",
    text: [
      "De fel som redovisas i besiktningsutlåtandet kan få betydelse mellan säljaren och köparen av fastigheten.",
      "Antecknade fel, liksom de risker som antecknats i riskanalysen och som senare infrias, kan en köpare normalt inte göra gällande såsom dolda fel mot säljaren efter fastighetsköpet. Om inte annat avtalats i samband med överlåtelsen av fastigheten ersätter besiktningsutlåtandet inte köparens undersökningsplikt enligt jordabalken. . ",

      "Besiktningsutlåtandet redovisar förhållandena vid tidpunkten för överlåtelsebesiktningen. Uppdragsgivaren måste vara observant på att förhållandena kan ändras eller försämras under den tid som förflyter mellan besiktning och fastighetsköp. ",
      "Sedan uppdragsgivaren läst besiktningsutlåtandet kan uppdragsgivaren därefter välja ett eller flera av här angivna fem alternativ, beroende på vad parterna avtalat. ",
      "Antingen att köpa den besiktade fastigheten på de villkor som säljaren angivit, ",
      "1) eller att avstå från att köpa den besiktade fastigheten, ",
      "2) eller att med utgångspunkt från informationen i besiktningsutlåtandet inleda en diskussion med säljaren om pris och andra villkor för köp, ",
      "3) eller att be säljaren om en skriftlig garanti i köpekontraktet för att förhållande eller risk för fel som anges i besiktningsutlåtandet inte föreligger, ",
      "4) eller att be säljaren att få utföra en fördjupad undersökning genom en fortsatt teknisk utredning för att klarlägga omfattningen av antecknade fel eller för att förvissa sig om den påtagliga risk för väsentligt fel som anges i riskanalysen är infriad eller inte. ",
    ],
  },
  {
    Heading: "Äganderätt och nyttjanderätt till besiktningsutlåtandet",
    text: [
      "Besiktningsmannen har upphovsrätten till besiktningsutlåtandet. Uppdragsgivaren får endast använda besiktningsutlåtandet i enlighet med det avtalade ändamålet.",
      "Uppdragsgivaren äger inte överlåta besiktningsutlåtandet eller nyttjanderätt till besiktningsutlåtandet utan besiktningsmannens uttryckliga medgivande.",

      "Om överlåtelse av utlåtandet skall ske har besiktningsmannen rätt att vid sammanträffande eller på annat lämpligt sätt mot ersättning redovisa besiktningsutlåtandet för förvärvaren.",

      "Sker överlåtelse utan medgivande från besiktningsmannen kan innehållet i besiktningsutlåtandet inte göras gällande mot besiktningsmannen. Samma sak gäller om uppdragsgivaren utan medgivande använder besiktningsutlåtandet för annat än det avtalade ändamålet.",

      "I inget fall har förvärvare av besiktnings-utlåtandet bättre rätt än uppdragsgivaren. ",
    ],
  },
  {
    Heading: "Betalning och hävning ",
    text: [
      "Uppdragsgivaren skall erlägga betalning för överlåtelsebesiktningen i enlighet med villkoren i uppdragsbekräftelsen. Har ej annat angetts i uppdragsbekräftelsen skall betalning erläggas inom 10 dagar från fakturadatum.",

      "Om betalning inte erläggs i rätt tid och detta inte beror på besiktningsmannen eller något förhållande på dennes sida, får besiktningsmannen välja mellan att kräva att uppdragsgivaren betalar eller, om uppdragsgivarens dröjsmål med betalningen utgör ett väsentligt avtalsbrott, häva uppdragsavtalet.",

      "Har besiktningsmannen förelagt uppdragsgivaren en bestämd tilläggstid för betalningen om minst 10 dagar, får uppdragsavtalet även hävas om uppdragsgivaren inte betalar inom tilläggstiden. Medan tilläggstiden löper får besiktningsmannen häva uppdragsavtalet endast om uppdragsgivaren meddelar att denne inte kommer att betala inom denna tid.",
    ],
  },
];

export const BILAGA_2_contentList = [
  { Heading: "Asfaboard", text: ["Porös, asfaltimpregnerad skiva."] },
  {
    Heading: "Alkydoljefärg",
    text: [
      "En ”modernare” variant av oljefärg som består av linoljefärg och alkydhartser. Torkar snabbare än oljefärg men tränger inte lika djupt in i virket.",
    ],
  },
  {
    Heading: "Avloppsluftare",
    text: [
      "Rör som går upp genom yttertaket och som har till uppgift att ta in luft vid spolning I avlopp, så att vakuum inte uppstår I systemet",
    ],
  },
  {
    Heading: "Betong",
    text: ["Blandning av cement (bindmedel) och grus, sten (ballast)"],
  },
  {
    Heading: "Blåbetong",
    text: ["Lättbetongblock tillverkade av alunskiffer. Radonhaltigt."],
  },
  {
    Heading: "Brunröta",
    text: [
      "Angrepp av brunröta innebär att virkets hållfasthet nedsättes och att fibrerna spricker tvärs längdriktningen. Orsakas av svampangrepp.",
    ],
  },
  {
    Heading: "Byggfukt ",
    text: ["Fukt som tillförs byggnadsmaterial under byggtiden."],
  },
  { Heading: "Bärläkt ", text: ["Virke som bär upp takpannor."] },
  { Heading: "Båge ", text: ["Den del av ett fönster som är öppningsbar."] },
  { Heading: "Cement ", text: ["Bindmedel i betong och putsbruk."] },
  { Heading: "Cylinder ", text: ["Lås."] },
  {
    Heading: "Dagvattenledning ",
    text: ["Ledning i mark för att avleda vatten från stuprör och regnvattenbrunnar."],
  },
  {
    Heading: "Dränering ",
    text: ["System av dränerande (vattenavledande) massor och ledningar."],
  },
  { Heading: "Dörrblad ", text: ["Den öppningsbara delen av en dörr."] },
  {
    Heading: "Falsad plåt ",
    text: ["Slätplåt som skarvas ihop med övervikta ståndskarvar."],
  },
  {
    Heading: "Fotplåt ",
    text: ["Dropplåt placerad vid takfot och som leder vatten ner i hängrännan."],
  },
  {
    Heading: "Fuktskydd ",
    text: [
      "Skikt av vattentät massa, luftspaltbildande matta av HD-polyeten eller bitumenduk etc., som har till uppgift att förhindra fuktinträngning i konstruktion eller hindra avdunstning från mark i s.k. uteluftsventilerad krypgrund.",
    ],
  },
  {
    Heading: "Fuktkvot ",
    text: [
      "Förhållandet mellan vikten på fuktigt material och materialets torrvikt. Anges i % eller kg/kg.",
    ],
  },
  { Heading: "Foder ", text: ["Täckande listverk runt fönster eller dörr."] },
  {
    Heading: "Grundmur ",
    text: [
      "Murverk runt uteluftsventilerad krypgrund eller s.k. torpargrund varpå byggnaden vilar.",
    ],
  },
  {
    Heading: "Hammarband ",
    text: ["Träregel längst upp på en regelvägg varpå takstolen vilar."],
  },
  {
    Heading: "Hanbjälke ",
    text: ["Tvärgående bjälke i takstol (mot s.k. ”kattvind”)."],
  },
  { Heading: "Imma", text: ["Se mättnadsånghalt."] },
  {
    Heading: "Karm",
    text: ["Den del av en dörr eller ett fönster som sitter fast i väggen."],
  },
  { Heading: "Klinker ", text: ["Plattor av keramiskt material."] },
  {
    Heading: "Klämring",
    text: [
      "Ring m uppgift att hålla fast en plastmatta mot en golvbrunn, så att inget vatten kan tränga in mellan golvbrunnen och mattan.",
    ],
  },
  {
    Heading: "Limträ ",
    text: [
      "T ex balkar sammanlimmade av flertal trästavar. Är starkare än motsvarande dimension ”vanligt” virke.",
    ],
  },
  {
    Heading: "Mekanisk ventilation ",
    text: [
      "Ventilation som styrs av fläktar. Kan vara endast frånluft eller både frånluft och tilluft. Ibland förekommer även energiåtervinning ur frånluften.",
    ],
  },
  {
    Heading: "Mättnadsånghalt ",
    text: [
      "Den ånghalt som luft vid en viss temperatur maximalt kan innehålla. Kan även benämnas daggpunkt. Vattenångan övergår då till vatten (kondenserar).",
    ],
  },
  { Heading: "Okulär ", text: ["Vad man kan se med ögat."] },
  { Heading: "Plansteg ", text: ["De horisontella stegen i en trappa."] },
  {
    Heading: "Relativ fuktighet ",
    text: ["Ånghalten i luft i % av mättnadsånghalten."],
  },
  {
    Heading: "Radon ",
    text: [
      "Radongas avgår vid radioaktivt sönderfall av radium i mineralkornen i jorden eller berggrunden.",
    ],
  },
  {
    Heading: "Revetering",
    text: ["Puts på rörvassmatta, som beklädnad på hus med trästomme."],
  },
  {
    Heading: "Självdragsventilation ",
    text: [
      "Fungerar genom att varm luft, som är lättare än kall, stiger uppåt i rummet och ut genom frånluftskanaler.",
    ],
  },
  {
    Heading: "Sättsteg ",
    text: ["Den vertikala ytan mellan planstegen i en trappa."],
  },
  {
    Heading: "Stödben ",
    text: ["Den del av en takstol som utgör del av vägg längs takfot."],
  },
  { Heading: "Svall ", text: ["Underlagstak av spontade bräder."] },
  { Heading: "Trycke", text: ["Dörr eller fönsterhandtag."] },
  { Heading: "Takfot ", text: ["Där taket möter ytterväggen."] },
  { Heading: "Taknock ", text: ["Översta delen av ett yttertak."] },
  {
    Heading: "Underlagstak ",
    text: [
      "Tak som ligger under beläggning av t ex tegelpannor, plåt eller överläggsplattor. Utgöres ibland av papp på träsvall, av masonit eller av armerad plastfolie.",
    ],
  },
  {
    Heading: "Underram",
    text: ["Nedersta bjälken i en takstol. Utgör även del av bjälklag."],
  },
  {
    Heading: "Överluft",
    text: [
      "Ventilation mellan två utrymmen via ventil i vägg eller springa mellan dörrkarm och dörrblad.",
    ],
  },
  {
    Heading: "Överram ",
    text: ["Den del av en takstol som underlagstaket vilar på."],
  },
];

export const BILAGA_3_contentList = [
  {
    heading: "Takkonstruktioner",
    text: [
      { label: "Takpapp låglutande/platta konstruktioner", value: "20 år" },

      { label: "Gummiduk låglutande/platta konstruktioner", value: "30 år" },
      { label: "Takpapp under takpannor av betong, tegel", value: "30 år" },
      { label: "(Takpannor av betong/tegel)", value: "30 år" },
      { label: "Korrugerad takplåt med underliggande papp", value: "35 år" },
      {
        label: "Bandfalsad plåt, falsad plåt med underliggande papp",
        value: "35 år",
      },
      { label: "Asbestcementskivor/eternitskivor", value: "30 år" },
      {
        label: "Plåtdetaljer (runt skorstenar, ventilation etc.)",
        value: "35 år",
      },
      {
        label: "Skorsten (renovering/ommurning skorstenstopp, tätning rökkanaler), ",
        value: "40 år",
      },
      { label: "Nytt undertak - invändigt", value: "40 år" },
    ],
  },
  {
    heading: "Terrasser/balkonger/altaner/utomhustrappor ",
    text: [
      { label: "Tätskikt (t ex asfaltsbaserade tätskikt)", value: "35 år" },
      { label: "Plåt", value: "35 år" },
      {
        label: "Betongbalkonger (armering, betong exkl. tätskikt)",
        value: "50 år",
      },
    ],
  },
  {
    heading: "Fasader",
    text: [
      { label: "Träpanel (byte)", value: "40 år" },
      { label: "Träpanel (målning)", value: "10 år" },
      { label: "Tegel", value: "Ej byte" },
      { label: "Puts - tjockputs 2cm (renovering/omputsning)", value: "30 år" },
      { label: "Asbestcementplattor (eternitplattor)", value: "30 år" },
    ],
  },
  {
    heading: "Fönster/dörrar ",
    text: [
      { label: "Isolerglasfönster (blir otäta med tiden)", value: "25 år" },
      { label: "Byte fönster", value: "40 år" },
      { label: "Byte dörrar (inne/ute)", value: "35 år" },
      { label: "Målning fönster/dörrar", value: "10 år" },
    ],
  },
  {
    heading: "Dränering/utvändigt fuktskydd ",
    text: [
      {
        label:
          "Dräneringsledning och utvändig vertikal (inklusive dagvattenledning i anslutning till dräneringsledning.)",
        value: "25 år",
      },
      { label: "Dagvattenledning utanför byggnaden", value: "50 år" },
    ],
  },
  {
    heading: "Invändigt ",
    text: [
      { label: "Målning/tapetsering etc.", value: "10 år" },
      { label: "Parkettgolv/trägolv (byte)", value: "40 år" },
      { label: "Parkettgolv/trägolv (slipning)", value: "15 år" },
      { label: "Laminatgolv", value: "20 år" },
      { label: "Klinkergolv", value: "Ej byte" },
      { label: "Plastmatta på golv (ej våtrum)", value: "15 år" },
      { label: "Köksluckor, bänkskivor, köksinredning", value: "30 år" },
      {
        label: "Snickerier och inredning (t ex lister). Ej kök ",
        value: "40 år",
      },
    ],
  },
  {
    heading: "Värmegolv",
    text: [
      { label: "Elvärmeslingor i golv", value: "25 år" },
      { label: "Elvärmeslingor i våtutrymme", value: "30 år" },
      { label: "Vattenburna slingor i golv", value: "30 år" },
    ],
  },
  {
    heading: "Våtutrymmen",
    text: [
      { label: "Plastmatta i våtrum", value: "20 år" },
      { label: "Tätskikt under klinker/kakel i våtutrymme", value: "30 år" },
      {
        label:
          "Tätskikt i golv/vägg av typ tunn dispersion utfört under 1980-tal/i början av 1990-talet",
        value: "15 år",
      },
      {
        label: "Typgodkänd våtrumsmatta (plast) som tätskikt under klinker/kakel",
        value: "",
      },
      { label: "  * utfört före 1995", value: "20 år" },
      { label: "  * utfört efter 1995", value: "30 år" },
      { label: "Våtrumstapeter väggar", value: "15 år" },
    ],
  },
  {
    heading: "Installationer för vatten, avlopp samt värme",
    text: [
      { label: "Avloppsledningar", value: "50 år" },
      { label: "Värmeledningar kall-/varmvattenledningar", value: "50 år" },
      { label: "Avloppstank", value: "30 år" },
      { label: "Sanitetsgods (tvättställ, WC stol m.m.)", value: "30 år" },
      { label: "Badkar", value: "30 år" },
      { label: "Värmeväxlare", value: "20 år" },
    ],
  },
  {
    heading: "Elinstallationer ",
    text: [{ label: "Kablar, centraler etc.", value: "45 år" }],
  },
  {
    heading: "Vitvaror ",
    text: [
      { label: "Kyl, frys, diskmaskin, spis, spishäll etc.", value: "10 år" },
      { label: "Tvättmaskin, torktumlare, torkskåp ", value: "10 år" },
    ],
  },
  {
    heading: "Övriga installationer och annan maskinell utrustning än hushållsmaskiner",
    text: [
      {
        label: "T ex varmvattenberedare, elradiator, värmepanna (olja/el) inkl. expansionskärl",
        value: "20 år",
      },
      { label: "Luftvärmepump", value: "8 år" },
    ],
  },
  {
    heading: "Ventilation",
    text: [
      { label: "Injustering av ventilationssystem", value: "5 år" },
      { label: "Byte av fläktmotor", value: "15 år" },
      { label: "Styr- och reglerutrustning", value: "20 år" },
    ],
  },
];

export const guideToScanCodeStep1 = [
  "1. I PDF-formatet finns en textbaserad sammanfattning av besiktningens noteringar och observationer, vilket ger en översikt över fastighetens skick.  ",
];
export const guideToScanCodeStep2 = [
  "2. För de som föredrar en mer interaktiv upplevelse erbjuds 360-visning, där man kan gå runt i fastigheten och utforska den i 360-graders vy. Genom att panorera, zooma in och klicka på ikoner kan man se detaljerade noteringar och observationer direkt på plats. Denna virtuella besiktning ger intressenter möjlighet att noggrant granska fastigheten och få en realistisk uppfattning om dess skick och eventuella problemområden. Det ger också flexibilitet och bekvämlighet genom att man kan utföra besiktningen när som helst och var som helst med hjälp av en dator, smartphone eller surfplatta med internetanslutning. ",
];
