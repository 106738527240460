/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/button-has-type */
/* eslint-disable new-cap */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { doc, getDoc } from "firebase/firestore";
import { userTypeController } from "context/userInfo";
import { db, Storage } from "FirebaseConfi";
import Button from "@mui/material/Button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import { v4 } from "uuid";

import dummySignature from "../../../../assets/images/dummy-signature.gif";
import blueLogo from "../../../../assets/images/blueLogo.svg";
import VILogo from "../../../../assets/images/PdfDefaultImages/virtualinspectLogoLarge.png";
import SBRLogoImage from "../../../../assets/images/PdfDefaultImages/SBR_logo_blue.jpg";
import guideStep1 from "../../../../assets/images/PdfDefaultImages/guideStep1.png";
import guideStep2 from "../../../../assets/images/PdfDefaultImages/guideStep2.png";
import guideStep3 from "../../../../assets/images/PdfDefaultImages/guideStep3.png";
// import QRcode from "../../../../assets/images/QRcode.svg";
import scanningQRCode from "../../../../assets/images/PdfDefaultImages/ScanningImage.png";
import {
  disclaimer,
  shortNoteAfterTable,
  Besiktningsman,
  BILAGA_1_contentListSeller,
  BILAGA_1_contentListBuyer,
  BILAGA_2_contentList,
  BILAGA_3_contentList,
  guideToScanCodeStep1,
  guideToScanCodeStep2,
  forstattDefaultText,
} from "../StaticDataForPdf";
import BackDropLoading from "../../../../components/BackDropLoading";

function PreviewContent({ InspectionNoteData, pdfData, setOpenPreviewModal }) {
  const document = new jsPDF({ compress: true });
  const [transformedData, setTransformedData] = useState({
    inside: [],
    outside: [],
  });
  const [riskArray, setRiskArray] = useState([]);
  const [ftuArray, setFtuArray] = useState([]);
  const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);
  const [organisationValues, setOrganisationValues] = useState(null);
  const [open, setOpen] = useState(false);
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  let pageY = 0;
  let pageCount = 1;
  let pageNumbers = [];

  useEffect(() => {
    console.log("pdfData", pdfData);
    console.log("InspectionNoteData", InspectionNoteData);
  }, [pdfData, InspectionNoteData]);
  useEffect(() => {
    // Create a script element

    const qrcode = new window.QRCode(window.document.getElementById("qrcode"), {
      text: `https://tour.virtualinspect.se/${pdfData.PUid}`,
      width: 158,
      height: 128,
      colorDark: "#000",
      colorLight: "#fff",
      correctLevel: window.QRCode.CorrectLevel.H,
    });

    console.log(qrcode);
  }, []);
  useEffect(async () => {
    if (!companyUID) return;
    const docRef = doc(db, "companies", companyUID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrganisationValues(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }, [companyUID]);

  useEffect(() => {
    const updatedInside = [];
    const updatedOutside = [];
    const newRiskArray = [...riskArray]; // Create a copy of existing riskArray
    const newFtuArray = [...ftuArray]; // Create a copy of existing ftuArray

    InspectionNoteData.forEach((item) => {
      const { step1, step2, step3, textpoints } = item;
      const textpointsWithId = textpoints?.map((textpoint) => ({
        ...textpoint,
        step2Value: step2,
      }));

      const updatedItem = {
        step3Value: step3?.value,
        textpoints: textpointsWithId,
      };

      if (step1 === "inside") {
        const existingInsideObjectIndex = updatedInside.findIndex((obj) => obj.id === step2);

        if (existingInsideObjectIndex !== -1) {
          const existingInsideObject = updatedInside[existingInsideObjectIndex];
          const existingValueIndex = existingInsideObject.values.findIndex(
            (val) => val.step3Value === step3.value
          );

          if (existingValueIndex !== -1) {
            existingInsideObject.values[existingValueIndex].textpoints.push(
              ...updatedItem.textpoints
            );
          } else {
            existingInsideObject.values.push(updatedItem);
          }
        } else {
          updatedInside.push({ id: step2, values: [updatedItem] });
        }
      } else if (step1 === "outside") {
        const existingOutsideObject = updatedOutside.find((obj) => obj.step3Value === step3.value);

        if (existingOutsideObject) {
          existingOutsideObject.textpoints.push(...updatedItem.textpoints);
        } else {
          updatedOutside.push(updatedItem);
        }
      }

      textpoints?.forEach((textpoint, index) => {
        if (textpoint.hasRisk) {
          newRiskArray.push({
            index: `${3}.${newRiskArray.length}`,
            id: textpoint.ID,
            text: textpoint.riskText.text,
          });
        }
        if (textpoint.hasFtu) {
          newFtuArray.push({
            index: `${4}.${newFtuArray.length}`,
            id: textpoint.ID,
            text: textpoint.ftuText.text,
          });
        }
      });
    });

    setTransformedData({
      inside: updatedInside,
      outside: updatedOutside,
    });

    setRiskArray(newRiskArray); // Update the riskArray with new data
    setFtuArray(newFtuArray); // Update the ftuArray with new data
  }, [InspectionNoteData]);

  const VerticalSpace = (height) => {
    pageY = pageY + height;
  };

  const footer = () => {
    document.setFont("Helvetica", "normal");

    document.text(
      `Page ${pageCount}`,
      document.internal.pageSize.width - 20,
      document.internal.pageSize.height - 10
    );
    pageCount = pageCount + 1;
  };

  const normalSmallFont = (text, x, pageYlength) => {
    document.setFontSize(11);
    document.setFont("Helvetica", "normal");
    const splitText = document.splitTextToSize(text, document.internal.pageSize.width - 40);
    splitText.forEach(function (line) {
      document.text(line, x, pageYlength);
      pageYlength += 5;
      if (pageYlength > 285) {
        document.addPage();
        footer();
        pageYlength = 20;
      }
    });
    pageY = pageYlength;
  };

  const boldExtraLargeHeadingFont = (text, x, pageYlength, color = null) => {
    let splitText = document.splitTextToSize(text, 190);
    document.setFontSize(16);
    document.setTextColor(color ? color : 118, 171, 237); // blue color to text
    document.setFont("Helvetica", "bold");
    document.text(splitText, x, pageYlength);
    pageY = pageY + 5 * splitText.length + 4;
    document.setTextColor(0, 0, 0); // black color to text
  };

  const boldLargeHeadingFont = (text, x, pageYlength) => {
    let splitText = document.splitTextToSize(text, 190);
    document.setFontSize(13);
    document.setFont("Helvetica", "bold");
    document.text(splitText, x, pageYlength);

    pageY = pageY + 5 * splitText.length + 4;
  };
  const boldMediumHeadingFont = (text, x, pageYlength) => {
    let splitText = document.splitTextToSize(text, 190);
    document.setFontSize(12);
    document.setFont("Helvetica", "bold");
    pageY = pageY + 5 * splitText.length;
    document.text(splitText, x, pageYlength);
  };

  const table = (startY, tableID) => {
    let splitedTable = false;
    document.autoTable({
      startY,
      styles: {
        lineWidth: 0.01,
        lineColor: "black",
        halign: "left",
        fontSize: 10,
      },
      columnStyles: {
        0: { cellWidth: tableID === "risk-data" || tableID === "ftu-data" ? 20 : 60 },
      },
      didDrawPage: (d) => {
        pageY = d.cursor.y + 5;
        if (splitedTable) {
          footer();
        }
        splitedTable = !splitedTable;
      },
      html: `#${tableID}`,
    });
  };

  const boldSmallHeadingFont = (text, x, pageYlength) => {
    let splitText = document.splitTextToSize(text, 190);
    document.setFontSize(10);
    document.setFont("Helvetica", "bold");
    document.text(splitText, x, pageYlength);
    pageY = pageY + 5 * splitText.length;
  };

  const normalLargeFont = (text, x, pageYlength) => {
    const splitText = document.splitTextToSize(text, 250);
    document.setFontSize(12);
    document.setFont("Helvetica", "normal");
    document.text(splitText, x, pageYlength);

    pageY = pageY + 10 * splitText.length;
  };
  let FirstLogoPageY = 10;
  let CompanyLogoPageY = 10;
  let FrontImagePageY = 10;
  const addImageToPDF = (url, x, y, width, height, pageNumber, imagefor) => {
    document.setPage(pageNumber);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        console.log("file added", imagefor, url);
        document.addImage(img, "jpeg", x, y, width, height, imagefor);
        resolve();
      };
      img.onerror = (error) => {
        reject(error);
        setOpen(false);
      };
    });
  };
  async function getCompanyLogoURl() {
    try {
      // Create a reference to the file you want to get the URL for
      const fileRef = ref(Storage, `company/${companyUID}/CompanyMainLogo`); // Replace with your file's path

      // Get the download URL
      const url = await getDownloadURL(fileRef);
      return `${url}`;

      // You can use this URL to display the image or create a link for download
    } catch (error) {
      setOpen(false);

      return null;
    }
  }

  const generatePDF = async () => {
    try {
      const companyLogoUrl = await getCompanyLogoURl();
      const qrCodeDiv = window.document.getElementById("qrcode");
      const qrCodeImage = qrCodeDiv.querySelector("img");
      const qrCodeSrc = qrCodeImage.src;
      const qrCodeImageObj = new Image();
      qrCodeImageObj.src = qrCodeSrc;

      document.setProperties({
        title: "SBR-Inspection",
        subject: "--",
        author: "Virtual Inspect",
        keywords: "SBR inspection Pdf ",
      });
      pageY = 10;
      document.setFontSize(22);
      FirstLogoPageY = pageY;
      document.addImage(SBRLogoImage, "PNG", 10, pageY, 50, 15, "dummyImg");
      CompanyLogoPageY = pageY;
      //document.addImage(dummyImg, "PNG", 150, pageY, 50, 15, "dummyImg");
      VerticalSpace(20);

      normalSmallFont(`Uppdrag ${pdfData?.uppdragNumber}`, 20, pageY);
      const date = new Date(
        pdfData?.inspectionTime?.seconds * 1000 + pdfData?.inspectionTime?.nanoseconds / 1000000
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      normalSmallFont(date, 20, pageY);
      footer();
      document.setTextColor(118, 171, 237);
      VerticalSpace(15);
      document.setFontSize(20);
      document.text("Utlåtande över", 15, pageY);
      VerticalSpace(10);
      document.text("Överlåtelsebesiktning", 15, pageY);
      VerticalSpace(15);
      document.setTextColor(0, 0, 0);
      document.text(pdfData?.propertyDestination, 15, pageY);
      VerticalSpace(10);
      document.text(pdfData?.PAddress, 15, pageY);
      VerticalSpace(10);
      document.setFontSize(10);
      FrontImagePageY = pageY;
      //document.addImage(dummyImg, "jpeg", 30, pageY, 150, 100, "dummyImg");
      VerticalSpace(80);
      const textX =
        (document.internal.pageSize.getWidth() - document.getTextWidth(disclaimer[0])) / 2;
      normalSmallFont(disclaimer[0], textX, pageY);
      const pageWidth = document.internal.pageSize.width;
      // Set the margins
      const leftMargin = 20;
      // Split the text into an array of strings that fit within the page width
      const textLines = document.splitTextToSize(disclaimer[0], pageWidth - leftMargin * 2);
      // Loop through each line of text and add it to the PDF
      pdfData?.inspectFor?.toLowerCase() === "seller" &&
        textLines.forEach(function (line) {
          // Calculate the width of the line
          const lineWidth = document.getTextWidth(line);
          // Calculate the x-coordinate to center the line
          const x = (pageWidth - lineWidth) / 2;
          // Add the line to the PDF at the centered x-coordinate
          document.text(x, pageY, line);
          // Move to the next line
          pageY += 5;
        });
      VerticalSpace(20);
      normalSmallFont(`${organisationValues?.CName}`, 10, pageY);
      normalSmallFont(`${organisationValues?.website}`, 150, pageY - 5);
      normalSmallFont(`${organisationValues?.CAddress}`, 10, pageY);
      normalSmallFont(`${organisationValues?.orgNumber}`, 150, pageY - 5);
      normalSmallFont(`${organisationValues?.telNumber}`, 10, pageY);
      normalSmallFont(`${organisationValues?.Tax}`, 150, pageY - 5);

      document.addPage(); //page3
      pageCount++;
      pageNumbers.push(pageCount);
      footer();
      pageY = 20;
      boldExtraLargeHeadingFont("UPPDRAG", 10, pageY);
      boldLargeHeadingFont(
        `Överlåtelsebesiktning för ${
          pdfData?.inspectFor?.toLowerCase() === "seller" ? "säljare" : "köpare"
        } enligt SBR-modellen`,
        10,
        pageY
      );
      boldMediumHeadingFont("Objekt", 10, pageY);
      table(pageY, "table-one");
      table(pageY - 5, "table-two");
      table(pageY - 5, "table-three");
      table(pageY - 5, "table-four");
      normalSmallFont(shortNoteAfterTable, 15, pageY);

      document.addPage(); //page4
      pageNumbers.push(pageCount);

      footer();

      pageY = 20;
      boldExtraLargeHeadingFont("1. HANDLINGAR OCH UPPLYSNINGAR", 10, pageY);
      document.setLineWidth(0.3);
      document.line(10, pageY - 5, 190, pageY - 5);
      boldMediumHeadingFont("Tillhandahållna handlingar", 10, pageY);
      normalSmallFont(
        `${
          pdfData?.propertyInformation?.documentDetails
            ? pdfData?.propertyInformation?.documentDetails
            : "---"
        }`,
        10,
        pageY
      );
      VerticalSpace(30);
      boldSmallHeadingFont("Säljarinformation", 10, pageY);
      document.line(10, pageY, 190, pageY);
      VerticalSpace(5);
      document.setFont("Helvetica", "normal");
      document.setFontSize(12);
      const renovationParagraph =
        "Under denna rubrik är samtliga uppgifter lämnade av fastighetsägare eller dess ombud. Uppgifterna är inte kontrollerade av besiktningsmannen. Säljaren förvärvade fastigheten 2020. Följande renoveringar och underhåll är utförda";
      //  renovationParagraph = pdfData?.renovationDetails?.map(e => `${e.year} > ${e.renovationDetail}`).join(' - ');
      const renovatedDetails = pdfData?.renovationDetails
        ?.map((e) => `${e.year}      ${e.renovationDetail}`)
        .join("\n");
      const joinedParagraph = `${renovationParagraph}\n${renovatedDetails}\nEgendomsfel      ${pdfData?.propertyInformation?.propertyFauls}`;
      normalSmallFont(joinedParagraph, 10, pageY);
      document.setFont("Helvetica", "bold");
      VerticalSpace(5);

      document.addPage(); //part 5
      pageNumbers.push(pageCount);

      footer();
      pageY = 20;
      boldExtraLargeHeadingFont("2. OKULÄR BESIKTNING", 10, pageY);
      document.setLineWidth(0.3);
      document.line(10, 30, 190, pageY);
      boldSmallHeadingFont("Särskilda förutsättningar vid besiktningen", 10, pageY);
      VerticalSpace(5);
      normalSmallFont(
        pdfData?.ocularInspectionDetails?.specialCondition
          ? pdfData?.ocularInspectionDetails?.specialCondition
          : "---",
        10,
        pageY
      );
      boldSmallHeadingFont("Muntliga uppgifter", 10, pageY);
      normalSmallFont(
        pdfData?.ocularInspectionDetails?.oralTasks
          ? pdfData?.ocularInspectionDetails?.oralTasks
          : "---",
        10,
        pageY
      );
      boldLargeHeadingFont("Byggnadsinformation", 10, pageY);
      table(pageY, "Byggnadsinformation-table");

      table(pageY, "Värme-table");
      document.addPage(); //part 6;
      footer();
      pageY = 20;
      document.setLineWidth(0.3);
      document.line(10, pageY, 190, pageY);
      boldExtraLargeHeadingFont("NOTERINGAR", 10, pageY);

      boldMediumHeadingFont(
        "Överlåtelse av fast egendom enligt jordabalken För säljare",
        10,
        pageY
      );
      boldMediumHeadingFont("HUVUDBYGGNAD", 10, pageY);
      boldMediumHeadingFont("UTVÄNDIGT", 10, pageY);
      transformedData?.outside?.length
        ? table(pageY, "outside-data")
        : normalSmallFont("---", 10, pageY);
      boldMediumHeadingFont("INVÄNDIGT", 10, pageY);
      // table(pageY, "inside-data");
      transformedData?.inside?.length
        ? transformedData?.inside?.forEach((item) => {
            boldMediumHeadingFont(item?.id, 10, pageY);
            table(pageY, item?.id);
          })
        : normalSmallFont("---", 10, pageY);
      document.addPage(); //part 7;
      pageNumbers.push(pageCount);
      footer();
      pageY = 20;
      boldLargeHeadingFont("Riskanalyser", 10, pageY);
      riskArray?.length ? table(pageY, "risk-data") : normalSmallFont("---", 10, pageY);
      document.addPage(); //part 8;
      pageNumbers.push(pageCount);
      pageY = 20;
      boldLargeHeadingFont("Fortsatt teknisk utredning", 10, pageY);
      ftuArray?.length ? table(pageY, "ftu-data") : normalSmallFont("---", 10, pageY);
      VerticalSpace(20);

      normalSmallFont(forstattDefaultText, 10, pageY);
      VerticalSpace(20);

      boldSmallHeadingFont("Besiktningsbolaget", 10, pageY);
      VerticalSpace(1);
      document.addImage(dummySignature, "jpeg", 10, pageY, 50, 15, "dummyImg");
      VerticalSpace(25);
      normalSmallFont(Besiktningsman, 10, pageY);
      normalSmallFont(
        new Date(
          pdfData?.inspectionTime?.seconds * 1000 + pdfData?.inspectionTime?.nanoseconds / 1000000
        ).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
        10,
        pageY
      );
      footer();

      document.addPage();
      pageNumbers.push(pageCount);
      footer();
      // Set the initial position for the text
      let xPos = 20;
      let yPos = 20;
      document.setFontSize(16);
      document.setFont("Helvetica", "bold");
      document.setTextColor(118, 171, 237); // blue color to text
      document.text(
        `BILAGA 1: Villkor för överlåtelsebesiktning för ${
          pdfData?.inspectFor?.toLowerCase() === "seller" ? "säljare" : "köpare"
        }`,
        20,
        20
      );
      document.setTextColor(0, 0, 0); // black color to text
      document.setFont("Helvetica", "normal");
      document.setFontSize(10);
      // Set the column width and gap
      const columnWidth = (document.internal.pageSize.width - 2 * xPos) / 2;
      const columnGap = 20;

      let pageFull = yPos;
      let xleft = xPos;
      let y = yPos;
      let bilagaContent =
        pdfData?.inspectFor?.toLowerCase() === "seller"
          ? BILAGA_1_contentListSeller
          : BILAGA_1_contentListBuyer;
      bilagaContent.forEach((element) => {
        // Add the heading
        const text = document.splitTextToSize(element.Heading, columnWidth);
        y += 8;
        pageFull += 8;
        document.setFont("Helvetica", "bold");
        text.forEach((element) => {
          document.text(element, xleft, y);
          y += 5;
          pageFull += 5;
        });

        document.setFont("Helvetica", "normal");
        document.setFontSize(10);

        element.text.forEach((elements) => {
          const chunks = document.splitTextToSize(elements, columnWidth);
          for (let i = 0; i < chunks.length; i++) {
            // Check if the current column is full
            if (y + document.getLineHeight() > document.internal.pageSize.height - 15) {
              // If so, add a new page
              // Reset the position for the new page
              xleft = columnWidth + columnGap + 10;
              y = 35;
              if (pageFull > 500) {
                document.addPage();
                footer();
                y = 20;
                pageFull = 20;
                xleft = xPos;
              }
            }
            // Add the chunk to the current column
            document.text(chunks[i], xleft, y);
            y += 5;
            pageFull += 5;
          }
        });
      });
      // BILAGA 2
      document.addPage();
      pageNumbers.push(pageCount);
      footer();

      y = 25;
      pageFull = 35;
      xleft = xPos;
      document.setFontSize(16);
      document.setFont("Helvetica", "bold");
      document.setTextColor(118, 171, 237); // blue color to text
      document.text("BILAGA 2: Liten Byggordbok", 20, 20);
      document.setTextColor(0, 0, 0); // black color to text
      document.setFont("Helvetica", "normal");
      document.setFontSize(8);

      BILAGA_2_contentList.forEach((element) => {
        // Add the heading
        const text = document.splitTextToSize(element.Heading, columnWidth);
        y += 3;
        pageFull += 3;
        document.setFont("Helvetica", "bold");
        text.forEach((element) => {
          document.text(element, xleft, y);
          y += 3;
          pageFull += 3;
        });

        document.setFont("Helvetica", "normal");
        document.setFontSize(8);

        element.text.forEach((elements) => {
          const chunks = document.splitTextToSize(elements, columnWidth);
          for (let i = 0; i < chunks.length; i++) {
            // Check if the current column is full
            if (y + document.getLineHeight() > document.internal.pageSize.height - 15) {
              // If so, add a new page
              // Reset the position for the new page
              xleft = columnWidth + columnGap + 10;
              y = 28;
              if (pageFull > 500) {
                document.addPage();
                footer();

                y = 28;
                pageFull = 28;
                xleft = xPos;
              }
            }
            // Add the chunk to the current column
            document.text(chunks[i], xleft, y);
            y += 3;
            pageFull += 3;
          }
        });
      });
      //BILAGA 3
      document.addPage();
      pageNumbers.push(pageCount);
      footer();

      y = 25;
      pageFull = 35;
      xleft = xPos;
      document.setFontSize(16);
      document.setFont("Helvetica", "bold");

      document.setTextColor(118, 171, 237); // blue color to text
      const splitText = document.splitTextToSize(
        "BILAGA 3: Teknisk medellivslängd för byggnadsdelar och byggkonstruktioner",
        190
      );
      document.text(splitText, 20, 20);
      document.setTextColor(0, 0, 0); // black color to text
      document.setFont("Helvetica", "normal");
      document.setFontSize(8);

      document.setFontSize(8);
      // Set the column width and spacing
      const columnWidth2 = document.internal.pageSize.width / 2 - 10; // divide page width into two columns
      const columnSpacing = 10;

      // Set the starting position for the text
      xPos = 20;
      yPos = 35;
      pageFull = 35;

      // Loop through the data and add it to the PDF
      BILAGA_3_contentList.forEach(function (item) {
        // Add the heading to the PDF
        document.setFont("Helvetica", "bold");
        const splitText = document.splitTextToSize(item.heading, 60);
        splitText.forEach((element) => {
          yPos += 3;
          pageFull += 3;
          document.text(element, xPos, yPos);
        });
        document.setFont("Helvetica", "normal");

        // Loop through the text and add it to the PDF
        yPos += 6;
        pageFull += 6;
        item.text.forEach(function (textItem) {
          // Add the label and value to the PDF
          const splitText = document.splitTextToSize(textItem.label, 60);
          splitText.forEach((element, idx, array) => {
            if (yPos > 284) {
              xPos += columnWidth2 + columnSpacing;
              yPos = 35;
            }

            if (pageFull > 550) {
              document.addPage();
              footer();
              xPos = 10;
              yPos = 20;
              pageFull = 20;
            }
            document.text(element, xPos, yPos);
            if (idx === array.length - 1) {
              yPos -= 4;
              pageFull -= 4;
            }
            yPos += 4;
            pageFull += 4;
          });
          document.text(textItem.value, xPos + columnWidth2 - 30, yPos);
          // Increment the Y position for the next text item
          yPos += 5;
          pageFull += 5;
        });
      });
      document.insertPage(2); //table of content inserted
      pageY = 20;
      boldExtraLargeHeadingFont("INNEHÅLLSFÖRTECKNING", 15, pageY);
      normalLargeFont(
        `UPPDRAG.....................................................................................................................................${pageNumbers[0]}`,
        15,
        pageY
      );
      normalLargeFont(
        `1. HANDLINGAR OCH UPPLYANINGAR...............................................................................  ${pageNumbers[1]}`,
        20,
        pageY
      );
      normalLargeFont(
        `2. OKULÄR BESIKTNING........................................................................................................ ${pageNumbers[2]}`,
        20,
        pageY
      );
      normalLargeFont(
        `3. RISKANALYS.........................................................................................................................${pageNumbers[3]}`,
        20,
        pageY
      );
      normalLargeFont(
        `4. FORTSATT TEKNISK UTREDNING.....................................................................................${pageNumbers[4]}`,
        20,
        pageY
      );
      normalLargeFont(
        `BILAGA 1: Villkor för överlåtelsebesiktning för ${
          pdfData?.inspectFor?.toLowerCase() === "seller" ? "säljare" : "köpare"
        }..................................................................${pageNumbers[5]}`,
        15,
        pageY
      );
      normalLargeFont(
        `BILAGA 2: Liten Byggordbok.......................................................................................................${pageNumbers[6]}`,
        15,
        pageY
      );
      normalLargeFont(
        `BILAGA 3: Teknisk medellivslängd för byggnadsdelar och byggkonstruktioner..........................${pageNumbers[7]}`,
        15,
        pageY
      );
      document.text(
        "Page 2",
        document.internal.pageSize.width - 20,
        document.internal.pageSize.height - 10
      );
      if (pdfData?.selectedInspectionFormat === "panaroma") {
        document.insertPage(1);
        pageY = 20;
        boldExtraLargeHeadingFont("Virtuell Överlåtelsebesiktning", 65, 15, "#00072E");
        normalSmallFont(
          "En virtuell överlåtelsebesiktning erbjuder två alternativa format för att undersöka en fastighet:PDF och 360-visning.",
          20,
          29
        );
        normalSmallFont(guideToScanCodeStep1, 20, 48);
        normalSmallFont(guideToScanCodeStep2, 20, 68);
        boldExtraLargeHeadingFont("Steg 1", 42, 160, "#00072E");
        normalLargeFont("Öppna kameran på", 34, 172);
        normalLargeFont("din telefon.", 42, 177);
        boldExtraLargeHeadingFont("Steg 2", 92, 160, "#00072E");
        normalLargeFont("Skanna QR-koden.", 88, 172);
        boldExtraLargeHeadingFont("Steg 3", 142, 160, "#00072E");
        normalLargeFont("Få tillgång till", 138, 172);
        normalLargeFont("innehållet.", 142, 177);
        normalLargeFont("QR-koden", 45, 264);
        normalLargeFont("Virtuell överlåtelsebesiktning", 118, 264);

        document.addImage(guideStep1, "PNG", 35, 110, 30, 43, "GuideStep1", "MEDIUM");
        document.addImage(guideStep2, "PNG", 88, 110, 30, 43, "GuideStep2", "MEDIUM");
        document.addImage(guideStep3, "PNG", 138, 110, 30, 43, "GuideStep3", "MEDIUM");
        document.addImage(qrCodeImageObj, "PNG", 34, 213, 45, 40, "qrCode", "MEDIUM");
        document.addImage(scanningQRCode, "PNG", 115, 198, 60, 60, "scanning", "MEDIUM");
      }

      addImageToPDF(
        companyLogoUrl,
        150,
        CompanyLogoPageY,
        50,
        15,
        pdfData?.selectedInspectionFormat === "panaroma" ? 2 : 1,
        "companyLogo"
      )
        .then(() => {
          addImageToPDF(
            pdfData?.frontImage,
            30,
            FrontImagePageY,
            150,
            100,
            pdfData?.selectedInspectionFormat === "panaroma" ? 2 : 1,
            "frontImage"
          ).then(async () => {
            // Save the PDF

            const pdfBlob = await document.output("blob", {
              filename: "InspectionPdf.pdf",
            });

            let Uid = v4().slice(0, 5);
            const storageee = ref(
              Storage,
              `companies/${companyUID}/Projects/${pdfData.PUid}/DocsBucket/InspectionPDF/inspectionPdf${Uid}`
            );
            // Upload the blob to Firebase Storage

            uploadBytes(storageee, pdfBlob)
              .then(() => {
                document.save("SBRInspection.pdf");
                const docRef = ref(db);
                setOpen(false);
                setDownloadBtnDisable(false);
              })
              .catch((error) => {
                console.log("Unable to Create PDF", error);
                setOpen(false);
                setDownloadBtnDisable(false);
              });
          });
        })

        .catch((error) => {
          console.error("Error adding images to PDF:", error);
        });

      // return document.output('datauristring');
    } catch (error) {
      setOpen(false);
      setDownloadBtnDisable(false);
    }
  };

  return (
    <>
      <div style={{ display: "none" }} id="qrcode"></div>
      <BackDropLoading open={open} />
      {transformedData?.outside?.length > 0 && (
        <>
          <Heading>UTVÄNDIGT:</Heading>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableBody>
                {transformedData?.outside?.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{item?.step3Value}</StyledTableCell>
                    <StyledTableCell>
                      {item?.textpoints?.map((text) => (
                        <>
                          <span>{text?.text}</span> {console.log("this is risk array", riskArray)}
                          {riskArray.some((riskItem) => riskItem.id === text?.ID) && (
                            <IndexText>
                              (See Risk{" "}
                              {riskArray.find((riskItem) => riskItem.id === text?.ID)?.index})
                            </IndexText>
                          )}
                          {ftuArray.some((ftuItem) => ftuItem.id === text?.ID) && (
                            <IndexText>
                              (See FTU {ftuArray.find((ftuItem) => ftuItem.id === text?.ID)?.index})
                            </IndexText>
                          )}
                          <br />
                        </>
                      ))}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </>
      )}
      {transformedData?.inside?.length > 0 && (
        <>
          <Heading>INVÄNDIGT:</Heading>
          {transformedData?.inside?.map((item) => (
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>{item?.id}</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {item?.values?.map((e, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{e?.step3Value}</StyledTableCell>
                      <StyledTableCell>
                        {e?.textpoints?.map((text) => (
                          <>
                            <span>{text?.text}</span>{" "}
                            {riskArray.some((riskItem) => riskItem.id === text?.ID) && (
                              <IndexText>
                                (See Risk{" "}
                                {riskArray.find((riskItem) => riskItem.id === text?.ID)?.index})
                              </IndexText>
                            )}
                            {ftuArray.some((ftuItem) => ftuItem.id === text?.ID) && (
                              <IndexText>
                                (See FTU{" "}
                                {ftuArray.find((ftuItem) => ftuItem.id === text?.ID)?.index})
                              </IndexText>
                            )}
                            <br />
                          </>
                        ))}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          ))}
        </>
      )}
      {riskArray?.length > 0 && (
        <>
          <Heading>Risk(s):</Heading>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableBody>
                <StyledTableCell>
                  {riskArray?.map((item) => (
                    <>
                      <Indexes>{item?.index}:</Indexes>
                      <span>{item?.text}</span>
                      <br />
                    </>
                  ))}
                </StyledTableCell>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </>
      )}
      {ftuArray?.length > 0 && (
        <>
          <Heading>FTU(s):</Heading>
          <StyledTableContainer component={Paper}>
            <StyledTable>
              <TableBody>
                <StyledTableCell>
                  {ftuArray?.map((item) => (
                    <>
                      <Indexes>{item?.index}:</Indexes>
                      <span>{item?.text}</span>
                      <br />
                    </>
                  ))}
                </StyledTableCell>
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </>
      )}
      {/* Modal Footer */}
      <DialogBtnsDiv style={{ width: "50%", float: "right" }}>
        <RandomButton
          sx={{
            width: "100%",
            textAlgn: "center",
            color: "#344054 !important",
            border: "1px solid #D0D5DD",
          }}
          purple="false"
          variant="contained"
          color="primary"
          onClick={() => setOpenPreviewModal(false)}
        >
          Cancel
        </RandomButton>
        <RandomButton
          sx={{ width: "100%", textAlgn: "center", fontWeight: "600" }}
          purple="true"
          variant="contained"
          disabled={downloadBtnDisable}
          color="primary"
          onClick={() => {
            setDownloadBtnDisable(true);
            setOpen(true);
            generatePDF();
          }}
        >
          Download Pdf
        </RandomButton>
      </DialogBtnsDiv>
      {/* Table for PDF */}
      <table style={{ display: "none" }} id="table-one" class="tableview">
        <tr>
          <td>
            <label for="property-designation" id="l-property-designation">
              <b>Fastighetsbeteckning</b>
            </label>
          </td>
          <td id="property-designation-preview">{pdfData?.propertyDestination}</td>
        </tr>
        <tr>
          <td>
            <label for="adress-info" id="l-adress-info">
              Adress
            </label>
          </td>
          <td id="adress-info-preview">{pdfData?.PAddress}</td>
        </tr>
        <tr>
          <td>
            <label for="postal-code-and-city" id="l-postal-code-and-city">
              Postnummer och ort
            </label>
          </td>
          <td id="postal-code-and-city-preview">
            {pdfData?.pincode} {pdfData?.city}
          </td>
        </tr>
        <tr>
          <td>
            <label for="muncipality" id="l-muncipality">
              Kommun
            </label>
          </td>
          <td id="muncipality-preview">{pdfData?.municipality}</td>
        </tr>
        <tr>
          <td>
            <label for="property-owners" id="l-property-owners">
              Fastighetsägare
            </label>
          </td>
          <td id="property-owners-preview">{pdfData?.agare}</td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="table-two" class="tableview">
        <tr>
          <td>
            <label for="client" id="l-client">
              Uppdragsgivare
            </label>
          </td>
          <td id="client-preview">{pdfData?.customerInfo?.name}</td>
        </tr>
        <tr>
          <td>
            <label for="assignment-number" id="l-assignment-number">
              Uppdragsnummer
            </label>
          </td>
          <td id="assignment-number-preview">{pdfData?.uppdragNumber}</td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="table-three" class="tableview">
        <tr>
          <td>
            <label for="inspector" id="l-inspector">
              Besiktningsförrättare
            </label>
          </td>
          <td id="inspector-preview">
            {pdfData?.InspectorName}, (Inspector)
            <br />
            Phone: {organisationValues?.telNumber} <br />
            Email: {pdfData?.inspectorEpost}
          </td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="table-four" class="tableview">
        <tr>
          <td>
            <label for="inspection-day" id="l-inspection-day">
              Besiktningsdag
            </label>
          </td>
          <td id="inspection-day-preview">
            {new Date(
              pdfData?.inspectionTime?.seconds * 1000 +
                pdfData?.inspectionTime?.nanoseconds / 1000000
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </td>
        </tr>

        <tr>
          <td>
            <label for="present" id="l-present">
              Närvarande
            </label>
          </td>
          <td id="present-preview">{pdfData?.surrenderInspectionDetails?.present}</td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="Byggnadsinformation-table" class="tableview">
        <tr>
          <td>
            <label for="Byggår">Byggår</label>
          </td>
          <td>
            <p id="Byggår-preview">{pdfData?.ocularInspectionDetails?.constructionYear}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="weather" id="l-weather">
              Väderlek
            </label>
          </td>
          <td id="weather-preview">{pdfData?.ocularInspectionDetails?.weatherInfo}</td>
        </tr>
        <tr>
          <td>
            <label for="Byggnadstyp">Byggnadstyp</label>
          </td>
          <td>
            <p id="Byggnadstyp-preview">{pdfData?.surrenderInspectionDetails?.buildingType}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Takbeläggning">Takbeläggning</label>
          </td>
          <td>
            <p id="Takbeläggning-preview">{pdfData?.ocularInspectionDetails?.roofDetails}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Fasad">Fasad</label>
          </td>
          <td>
            <p id="Fasad-preview">{pdfData?.ocularInspectionDetails?.facade}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Stomme">Stomme</label>
          </td>
          <td>
            <p id="Stomme-preview">{pdfData?.ocularInspectionDetails?.frameFloor}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Fönster">Fönster</label>
          </td>
          <td>
            <p id="Fönster-preview">{pdfData?.ocularInspectionDetails?.window}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Grund">Grund</label>
          </td>
          <td>
            <p id="Grund-preview">{pdfData?.ocularInspectionDetails?.foundation}</p>
          </td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="Värme-table" class="tableview">
        <tr>
          <td>
            <label for="Ventilation">Ventilation</label>
          </td>
          <td>
            <p id="Ventilation-preview">{pdfData?.ocularInspectionDetails?.ventilation}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Värmesystem">Värmesystem</label>
          </td>
          <td>
            <p id="Värmesystem-preview">{pdfData?.ocularInspectionDetails?.heating}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Vatten">Vatten</label>
          </td>
          <td>
            <p id="Vatten-preview">{pdfData?.ocularInspectionDetails?.water}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Vatten">Avlopp</label>
          </td>
          <td>
            <p id="Vatten-preview">{pdfData?.ocularInspectionDetails?.Sewer}</p>
          </td>
        </tr>
        <tr>
          <td>
            <label for="Kompletterande">Kompletterande</label>
          </td>
          <td>
            <p id="Kompletterande-preview">{pdfData?.ocularInspectionDetails?.miscellaneousInfo}</p>
          </td>
        </tr>
      </table>
      <table style={{ display: "none" }} id="outside-data">
        <tbody>
          {transformedData?.outside?.map((item, index) => (
            <tr key={index}>
              <td>{item?.step3Value}</td>
              <td>
                {item?.textpoints?.map((text, textIndex) => (
                  <React.Fragment key={textIndex}>
                    <span>{text?.text}</span>{" "}
                    {riskArray.some((riskItem) => riskItem.id === text?.id) && (
                      <span>
                        (See Risk {riskArray.find((riskItem) => riskItem.id === text?.id)?.index})
                      </span>
                    )}
                    {ftuArray.some((ftuItem) => ftuItem.id === text?.id) && (
                      <span>
                        (See FTU {ftuArray.find((ftuItem) => ftuItem.id === text?.id)?.index})
                      </span>
                    )}
                    <br />
                  </React.Fragment>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {transformedData?.inside?.map((item, itemIndex) => (
          <table id={item?.id} style={{ display: "none" }}>
            <tbody>
              {item?.values?.map((e, index) => (
                <tr key={index}>
                  <td>{e?.step3Value}</td>
                  <td>
                    {e?.textpoints?.map((text, textIndex) => (
                      <React.Fragment key={textIndex}>
                        <span>{text?.text}</span>{" "}
                        {riskArray.some((riskItem) => riskItem.id === text?.id) && (
                          <span>
                            (See Risk{" "}
                            {riskArray.find((riskItem) => riskItem.id === text?.id)?.index})
                          </span>
                        )}
                        {ftuArray.some((ftuItem) => ftuItem.id === text?.id) && (
                          <span>
                            (See FTU {ftuArray.find((ftuItem) => ftuItem.id === text?.id)?.index})
                          </span>
                        )}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
      <table style={{ display: "none" }} id="risk-data">
        <tbody>
          {riskArray?.map((item, index) => (
            <tr>
              <React.Fragment key={index}>
                <td>
                  <span>{item?.index}</span>
                </td>
                <td>
                  <span>{item?.text}</span>
                </td>
              </React.Fragment>
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ display: "none" }} id="ftu-data">
        <tbody>
          {ftuArray?.map((item, index) => (
            <tr>
              <React.Fragment key={index}>
                <td>
                  <span>{item?.index}</span>
                </td>
                <td>
                  <span>{item?.text}</span>
                </td>
              </React.Fragment>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
export default PreviewContent;

const Heading = styled("h2")({
  color: "#5856d6",
  padding: "0px 50px",
});
const StyledTableContainer = styled(TableContainer)({
  boxShadow: "none !important",
  padding: "20px 50px",
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
  padding: theme.spacing(1),
  "&:first-child": {
    width: "20%",
    borderRight: "1px solid #000",
  },
  "&:last-child": {
    width: "80%",
  },
}));

const StyledTable = styled(Table)({
  border: "1px solid #000",
  borderCollapse: "collapse",
  width: "100%",
});

const StyledTableHead = styled(TableHead)({
  padding: "0",
  th: {
    border: "none",
    background: "white",
    borderRight: "none !important",
  },
});
const Indexes = styled("span")({
  fontWeight: "800",
  marginRight: "10px",
});
const IndexText = styled("span")({
  fontWeight: "bold",
  fontSize: "14px",
  color: "#5856d6",
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: dialogBtns === "yes" ? "10px 18px" : "14px 28px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: dialogBtns === "yes" ? "600" : "",
  fontSize: dialogBtns === "yes" ? "16px" : "",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  },
}));
