export default {
  formId: "checkoutForm",
  formField: {
    CompanyName: {
      name: "CompanyName",
      label: "Company Name*",
      requiredErrorMsg: "company name is required",
    },
    OrganizationNumber: {
      name: "OrganizationNumber",
      label: "Organization Number*",
      requiredErrorMsg: "Organization Number is required",
    },

    AdminName: {
      name: "AdminName",
      label: "Admin Name*",
      requiredErrorMsg: "Admin Name is required",
    },

    AdminEmail: {
      name: "AdminEmail",
      label: "Admin Email*",
      requiredErrorMsg: "Admin Email is required",
    },
    AdminConfimEmail: {
      name: "AdminConfimEmail",
      label: "ReEnter Admin Email*",
      requiredErrorMsg: " Email confirmation is required",
    },
    AdminPassword: {
      name: "AdminPassword",
      label: "AdminPassword*",
      requiredErrorMsg: "atleast 6 characters",
    },
  },
};
