/* eslint-disable no-useless-computed-key */
/* eslint-disable object-shorthand */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Storage, db } from "FirebaseConfi";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { userTypeController } from "context/userInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import closeModalIcon from "../../assets/images/closeModalIcon.svg";
import InspectionImageModal from "./InspectionImageModal";

export const uploadImagesToFirebaseStorage = async ({
  setIsUploading,
  selectedBuildingImgsFile,
  companyUID,
  updateUploadProgress,
  setBuildingImages,
  selectedFloorMapImgsFile,
  setFloorMaps,
  ProjectID,
}) => {
  try {
    setIsUploading(true);
    const buildingImageUploads = selectedBuildingImgsFile.map(async (imageObject) => {
      const { imagePath, id, title } = imageObject;
      const storageRef = ref(
        Storage,
        `companies/${companyUID}/Projects/${ProjectID}/InpectionImages/BuildingImages/${id}`
      );

      // Upload the image Blob to Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, imagePath);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            updateUploadProgress(id, progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
              default:
                reject(error);
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                console.log("File available at", downloadURL);
                setBuildingImages((prevImages) => ({
                  ...prevImages,
                  [id]: { panorama: downloadURL, title: title },
                }));
                resolve();
              })
              .catch(reject);
          }
        );
      });
    });

    const floorMapImageUploads = selectedFloorMapImgsFile.map(async (imageObject) => {
      const { imagePath, id, title } = imageObject;
      const storageRef = ref(
        Storage,
        `companies/${companyUID}/Projects/${ProjectID}/InpectionImages/FloorMapImages/${id}`
      );

      // Convert the data URL back to a Blob to upload to Firebase Storage
      const res = await fetch(imagePath);
      const blob = await res.blob();

      // Upload the image Blob to Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, blob);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            updateUploadProgress(id, progress);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
              default:
                reject(error);
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                console.log("File available at", downloadURL);
                setFloorMaps((prevImages) => ({
                  ...prevImages,
                  [id]: { floorMap: downloadURL, floorNumber: title },
                }));
                resolve();
              })
              .catch(reject);
          }
        );
      });
    });

    // Wait for all image uploads to complete
    await Promise.all([...buildingImageUploads, ...floorMapImageUploads]);
  } catch (error) {
    console.error("Error while uploading images:", error);
  }
  setIsUploading(false);
};

function InspectionImages() {
  const [open, setOpen] = useState(false);
  const [selectedBuildingImgsFile, setSelectedBuildingImgsFile] = useState([]);
  const [selectedFloorMapImgsFile, setSelectedFloorMapImgsFile] = useState([]);
  const [floorMaps, setFloorMaps] = useState({});
  const location = useLocation();
  const Data = location.state?.data;
  const navigate = useNavigate();
  const { id } = useParams();
  const [disableFloorNumbers, setDisableFloorNumbers] = useState([]);
  const [btnText, setBtnText] = useState("Upload");
  const [buildingImages, setBuildingImages] = useState({});
  const [activeTab, setActiveTab] = useState("buildingImg");
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [userInfo] = userTypeController();
  // const [pdfData, setPdfData] = useState();
  const { companyUID } = userInfo;
  //

  useEffect(() => {
    console.log("floorMaps", floorMaps);
    console.log("buildingImages", buildingImages);
  }, [buildingImages, floorMaps]);
  useEffect(async () => {
    const docRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      navigate(`${location.pathname}/editor`, {
        state: { buildingImages: docSnap.data()?.scenes, pdfData: Data },
      });
    }
  }, []);
  useEffect(() => {
    if (selectedBuildingImgsFile?.length || selectedFloorMapImgsFile?.length) {
      setIsUpdateButtonDisabled(
        selectedBuildingImgsFile.some((imageObject) => !imageObject.title) ||
          selectedFloorMapImgsFile.some((imageObject) => imageObject.title === null)
      );
    }
  }, [selectedBuildingImgsFile, selectedFloorMapImgsFile]);

  // useEffect(async () => {
  //   const docRef = doc(db, `companies/${companyUID}/projects`, id);
  //   const docSnap = await getDoc(docRef);

  //   if (Data && Data?.propertyInformation) {
  //     setPdfData(Data);
  //   } else if (docSnap.exists()) {
  //     setPdfData(docSnap.data());
  //   }
  // }, [companyUID]);

  const updateUploadProgress = (Uid, progress) => {
    setUploadProgress((prevProgress) => ({ ...prevProgress, [Uid]: progress }));
  };

  const handleSave = async () => {
    setIsUploading(true);
    await uploadImagesToFirebaseStorage({
      setIsUploading,
      selectedBuildingImgsFile,
      companyUID,
      updateUploadProgress,
      setBuildingImages,
      selectedFloorMapImgsFile,
      setFloorMaps,
      ProjectID: id,
    });
    setBtnText("Start");
    setSelectedBuildingImgsFile([]);
    setSelectedFloorMapImgsFile([]);
  };

  const handleStart = () => {
    const itemsArray = Object.entries(buildingImages).map(([key, value]) => ({ key, ...value }));

    const ScenesRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
    setDoc(ScenesRef, { scenes: itemsArray, CUid: companyUID, PUid: id }).then(() => {
      if (Object.keys(floorMaps).length > 0) {
        const FloorMapsRef = doc(
          db,
          `companies/${companyUID}/projects/${id}/FloorMaps`,
          "FloorMaps"
        );
        setDoc(FloorMapsRef, { PUid: id, floorMaps }).then(() => {
          navigate(`${location.pathname}/editor`, {
            state: {
              floorMaps: floorMaps,
              buildingImages: itemsArray,
              disableFloorNumbers: disableFloorNumbers,
            },
          });
        });
      } else {
        navigate(`${location.pathname}/editor`, {
          state: {
            buildingImages: itemsArray,
            disableFloorNumbers: disableFloorNumbers,
          },
        });
      }
    });
  };

  return (
    <DashboardLayout>
      <Header>
        <Headings>
          <Heading>Add Inspection Images</Heading>
          <SubHeading>Add floor and building panorama images to create a virtual tour. </SubHeading>
        </Headings>
        <ActionButtons>
          <CancelButton
          // onClick={() => setNextBtnClicked(false)}
          >
            Cancel
          </CancelButton>
          <NextButton
          //  onClick={handleNextClick}
          >
            Next
          </NextButton>
        </ActionButtons>
      </Header>
      <Container>
        <AddImages>
          <AddImagesText>
            Add floor maps, along with inspection images to provide a visual representation of the
            building layout.
          </AddImagesText>
          <UploadImageBtn onClick={() => setOpen(true)}>+ Upload Images</UploadImageBtn>
        </AddImages>
      </Container>
      <CustomDialog disableBackdropClick open={open}>
        <ModalContainer>
          <ModalHeader>
            <ModalHeading>Add inspection images</ModalHeading>
            <img
              style={{ cursor: !isUploading ? "pointer" : "not-allowed" }}
              onClick={() => {
                !isUploading &&
                  (setOpen(false),
                  setSelectedBuildingImgsFile([]),
                  setSelectedFloorMapImgsFile([]),
                  setDisableFloorNumbers([]));
              }}
              src={closeModalIcon}
              alt="_close"
            />
          </ModalHeader>
          <ModalContent>
            <InspectionImageModal
              setSelectedBuildingImgsFile={setSelectedBuildingImgsFile}
              selectedBuildingImgsFile={selectedBuildingImgsFile}
              setSelectedFloorMapImgsFile={setSelectedFloorMapImgsFile}
              selectedFloorMapImgsFile={selectedFloorMapImgsFile}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              uploadProgress={uploadProgress}
              uploading={isUploading}
              disableFloorNumbers={disableFloorNumbers}
              setDisableFloorNumbers={setDisableFloorNumbers}
            />
          </ModalContent>
          <ModalFooter>
            <ModalCancelBtn
              isUploading={isUploading}
              onClick={() => {
                !isUploading &&
                  (setOpen(false),
                  setSelectedBuildingImgsFile([]),
                  setSelectedFloorMapImgsFile([]),
                  setIsUpdateButtonDisabled(true));
              }}
            >
              Cancel
            </ModalCancelBtn>
            <ModalUploadBtn
              disabled={isUpdateButtonDisabled || isUploading}
              onClick={btnText === "Start" ? handleStart : handleSave}
            >
              {btnText}
            </ModalUploadBtn>
          </ModalFooter>
        </ModalContainer>
      </CustomDialog>
    </DashboardLayout>
  );
}
export default InspectionImages;

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "32px 40px 16px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  flexWrap: "wrap",
});
const Headings = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Heading = styled("p")({
  color: "#101828",
  fontSize: "20px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const ActionButtons = styled("div")({
  display: "flex",
  gap: "12px",
});
const CancelButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  fontFamily: "Inter",
  height: "max-content",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const NextButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Inter",
  height: "max-content",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const Container = styled("div")({
  background: "#FCFCFD",
  display: "flex",
  justifyContent: "center" /* Horizontally center the content */,
  alignItems: "center" /* Vertically center the content */,
  flexGrow: 1 /* Take up remaining width */,
  minHeight: "calc(100vh - 120px)",
});

const AddImages = styled("div")({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
});
const AddImagesText = styled("p")({
  color: "#667085",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: "400",
  width: "423px",
});
const UploadImageBtn = styled("button")({
  cursor: "pointer",
  padding: "10px 16px",
  borderRadius: "8px",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#FFF",
  fontSize: "14px",
  fontWeight: "600",
});

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "51%",
    height: "80%",
    maxWidth: "none", // Set this to prevent the modal from being restricted by the default maxWidth
  },
});

const ModalContainer = styled("div")({
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  height: "100%", // Set the modal container to occupy the full height
});

const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  flex: "0 0 auto", // Fixed height for the header
});

const ModalHeading = styled("p")({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
});

const ModalContent = styled("div")({
  flex: "1 1 auto", // Allow the content to grow and occupy the remaining space with a scrollable behavior
  overflowY: "auto", // Enable vertical scrolling if the content overflows
});

const ModalFooter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  flex: "0 0 auto", // Fixed height for the footer
});

const ModalCancelBtn = styled("button")(({ isUploading }) => ({
  cursor: !isUploading ? "pointer" : "not-allowed",
  padding: "10px 18px",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#344054",
  fontSize: "16px",
  fontWeight: "600",
}));

const ModalUploadBtn = styled("button")(({ disabled }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
  padding: "10px 18px",
  borderRadius: "8px",
  border: "1px solid #7F56D9",
  background: disabled ? "#cbb3ff" : "#7F56D9",
  color: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  fontSize: "16px",
  fontWeight: "600",
}));
