/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import { doc, setDoc } from "firebase/firestore";
import { userTypeController } from "context/userInfo";
import { db, auth } from "FirebaseConfi";
import { ToastContainer, toast } from "react-toastify";
import { getAuth, updateProfile, onAuthStateChanged, updateEmail } from "firebase/auth";
import ProfileComponent from "./ProfileComponent";
import MembersLayout from "../AdminLayouts/MembersLayout";
import Certificates from "../CertificatesLayout";
import Workspace from "./Workspace";

import { handleCancelToBackRouteButton } from "../../CommonFunctions/index";

import BackDropLoading from "../../components/BackDropLoading";

function Settings() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab ? location.state?.activeTab : "profile"
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const [organisationInformation, setOrganisationInformation] = useState({
    CAddress: "",
    CName: "",
    City: "",
    Tax: "",
    ePost: "",
    orgNumber: null,
    telNumber: null,
    uppdragNumber: null,
    website: "",
  });
  const [userProfileInfo, setUserProfileInfo] = useState({
    displayName: "",
    email: "",
    photoURL: "",
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const Auth = getAuth();
        const updatedUserInfo = {
          // ...userInfo,
          displayName: Auth.currentUser?.displayName,
          email: Auth.currentUser?.email,
          photoURL: Auth.currentUser?.photoURL,
        };
        console.log("Auth.currentUser", Auth.currentUser);
        console.log("updatedUserInfo >>>", updatedUserInfo);
        setUserProfileInfo(updatedUserInfo);
      } else {
        console.log("Their is no updated data.");
      }
    });
  }, []);
  useEffect(() => {
    console.log(organisationInformation);
  }, [organisationInformation]);

  const handleSubmitOrganisationInfo = () => {
    try {
      setSubmitting(!isSubmitting);
      const companyRef = doc(db, "companies", companyUID);
      setDoc(companyRef, organisationInformation, { merge: true }).then(() => {
        toast.success("Organisation Data Updated Successfully!");
        setSubmitting(false);
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error(JSON.stringify(e));
      setSubmitting(false);
    }
  };

  const handleSubmitUserProfileInfo = () => {
    const Auth = getAuth();
    updateProfile(Auth.currentUser, {
      displayName: userProfileInfo?.displayName,
    })
      .then(() => {
        updateEmail(auth.currentUser, userProfileInfo?.email)
          .then(() => {
            toast.success("Profile Information Updated Successfully!");
          })
          .catch((error) => {
            toast.error("Name Updated successfully but, Unable to update the Email.");
          });
      })
      .catch((error) => {
        toast.error("Unable to update the profile information.");
      });
  };
  return (
    <>
      <BackDropLoading open={isSubmitting} />
      <DashboardLayout>
        <div>
          <Header>
            <Head>
              <Headings>
                <Heading>Settings</Heading>
              </Headings>
              <ActionButtons>
                <Link to="/dashboard">
                  <CancelButton
                  //   onClick={() => setNextBtnClicked(false)}
                  >
                    Cancel
                  </CancelButton>
                </Link>
                {activeTab === "profile" && (
                  <SaveButton onClick={activeTab === "profile" ? handleSubmitUserProfileInfo : ""}>
                    Save
                  </SaveButton>
                )}
                {activeTab === "workspace" && (
                  <SaveButton
                    onClick={activeTab === "workspace" ? handleSubmitOrganisationInfo : ""}
                  >
                    Save
                  </SaveButton>
                )}
                {activeTab === "members" && (
                  <SaveButton onClick={activeTab === "members" ? handleSubmitOrganisationInfo : ""}>
                    Save
                  </SaveButton>
                )}
                {activeTab === "certificates" && (
                  <SaveButton
                    onClick={activeTab === "certificates" ? handleSubmitOrganisationInfo : ""}
                  >
                    Save
                  </SaveButton>
                )}
              </ActionButtons>
            </Head>
            <Tabs>
              <Option activeTab={activeTab === "profile"} onClick={() => setActiveTab("profile")}>
                Profile
              </Option>
              <Option
                activeTab={activeTab === "workspace"}
                onClick={() => setActiveTab("workspace")}
              >
                Work Space
              </Option>
              <Option activeTab={activeTab === "members"} onClick={() => setActiveTab("members")}>
                Members
              </Option>
              <Option
                activeTab={activeTab === "certificates"}
                onClick={() => setActiveTab("certificates")}
              >
                Certificates
              </Option>
            </Tabs>
          </Header>
        </div>
        <div style={{ padding: "32px", background: "#FCFCFD" }}>
          {activeTab === "profile" && (
            <ProfileComponent
              setUserProfileInfo={setUserProfileInfo}
              userProfileInfo={userProfileInfo}
            />
          )}
          {activeTab === "workspace" && (
            <Workspace
              organisationInformation={organisationInformation}
              setOrganisationInformation={setOrganisationInformation}
            />
          )}
          {activeTab === "members" && <MembersLayout />}
          {activeTab === "certificates" && <Certificates />}
        </div>
        {/* ToastContainer */}
        <ErrorToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </DashboardLayout>
    </>
  );
}
export default Settings;

const Header = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "32px 40px 16px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  flexWrap: "wrap",
  position: "sticky",
  top: 0,
  zIndex: 1,
  paddingBottom: "0",
});
const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const Headings = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Heading = styled("p")({
  color: "#101828",
  fontSize: "20px",
  fontWeight: "500",
});
const ActionButtons = styled("div")({
  display: "flex",
  gap: "12px",
});
const CancelButton = styled("button")({
  cursor: "pointer",
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  fontFamily: "Inter",
  height: "max-content",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor: "pointer",
});
const SaveButton = styled("button")({
  cursor: "pointer",
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Inter",
  height: "max-content",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor: "pointer",
});
const Tabs = styled("div")({
  display: "inline-flex",
  alignItems: "flex-start",
  gap: "16px",
  paddingTop: "10px",
  transition: "0.5s ease",
});
const Option = styled("div")(({ activeTab }) => ({
  color: activeTab ? "#6941C6" : "#667085",
  fontSize: "14px",
  fontWeight: "500",
  paddingBottom: "10px",
  borderBottom: `2px solid ${activeTab ? "#6248BF" : "transparent"}`,
  cursor: "pointer",
  transition: "0.5s ease",
}));
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
