/* eslint-disable react/prop-types */
import React from "react";
import { Paper } from "@mui/material";

// import { theme, useStyle } from "./styles";

export default function MaterialStepFormLayout({ children }) {
  return (
    <div>
      <Paper>{children}</Paper>
    </div>
  );
}
