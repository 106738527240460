import { Grid } from "@mui/material";
import ManageUsers from "../EditCompanyInfo/components/ManageUsers/ManageUsers";

function MembersLayout() {
  return (
    <Grid container xs={12}>
      <ManageUsers />
    </Grid>
  );
}

export default MembersLayout;
