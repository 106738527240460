/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { doc, updateDoc } from "firebase/firestore";
import { userTypeController } from "context/userInfo";
import { db } from "FirebaseConfi";
import Header from "./Components/Header";
import Textfield from "./Components/FormsUI/Textfield";
import Select from "./Components/FormsUI/Select";
import Button from "./Components/FormsUI/Button";
import BuildingType from "../data/BuildingType.json";
import Checkbox from "./Components/FormsUI/Checkbox";
import PanoramaProject from "../Panorama";

const INITIAL_FORM_STATE = {
  city: "",
  municipality: "",
  witness: "",
  property_owned_by_seller_year: "",
  state: "",
  BuildingType: "",
  message: "",
  providedDocuments: "",
  docChecked: false,
  RenovationList: [
    {
      year: "",
      text: "",
    },
  ],
  otherStatementForRenovations: "",
  defectMessage: "---",
  special_condition_message:
    "Byggnaden var vid besiktningstillfället fullt möblerad. Besiktning har skett av de delar som varit normalt åtkomliga utan omflyttning av möbler på belamrade ytor. Bakomliggande ytor ingår i köparens undersökningsplikt.\nFör ytor, utrymmen och byggnadsdelar som noterats helt eller delvis ej besiktningsbara eller belamrade har besiktningsmannen inget ansvar.\nNotering ”-----” innebär att utrymmet/ytan bedöms vara i normalt skick med hänsyn taget till byggnadens ålder och byggnadssätt",
  orl_task:
    "Avsikten har varit att i detta utlåtande redovisa samtliga förhållanden, som framkommit vid besiktningen och då även samtliga muntliga uppgifter av någon betydelse som lämnats av besiktningsmannen vid besiktningen.\nSkulle någon muntlig uppgift av betydelse ha utelämnats eller blivit felaktigt återgiven, enligt beställarens uppfattning, har beställaren att snarast och helst inom sju dagar meddela besiktningsmannen vad som enligt beställarens uppfattning skall ändras i utlåtandet. \nOm sådant meddelande inte lämnats inom ovan angiven tid kan inte besiktningsmannen göras ansvarig för eventuella brister i utlåtandet, som på så sätt kunnat rättas",
  temperature: "",
  water_and_sevage: "",
  ventilation: "",
  heating: "",
  roof: "",
  window: "",
  facade: "",
  frame_floor: "",
  foundation: "",
  building_Type: "",
  year_of_construction: "",
};

const FORM_VALIDATION = Yup.object().shape({
  municipality: Yup.string().required("Required"),
  witness: Yup.string().required("Required"),
  property_owned_by_seller_year: Yup.number().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  BuildingType: Yup.string().required("Required"),
  message: Yup.string(),
  docChecked: Yup.boolean().required("Please Select"),
  providedDocuments: Yup.string().when("docChecked", {
    is: true,
    then: Yup.string().required("Please enter documents Provided"),
    otherwise: Yup.string(),
  }),
  // termsOfService: Yup.boolean()
  //  .oneOf([true], "The terms and conditions must be accepted.")
  //  .required("The terms and conditions must be accepted."),
});

function CollectNewData() {
  const [projectID, setProjectId] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const handleDocumentCheck = (event) => {
    setIsChecked(event.target.checked);
  };
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  useEffect(() => {
    const currentprojectID = window.location.pathname.slice(
      window.location.pathname.lastIndexOf("/") + 1,
      window.location.pathname.length
    );
    setProjectId(currentprojectID);
  }, []);
  const handleSubmit = async (formData) => {
    console.log(formData);
    const docRef = doc(db, `companies/${companyUID}/projects`, projectID);
    await updateDoc(
      docRef,
      {
        formData,
      },
      { merge: true }
    )
      .then(() => {
        console.log("Sucessfully Updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Grid container mt={0}>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="md">
          <div>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={handleSubmit}
            >
              {({ values, dirty, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>UTLÅTANDE ÖVER ÖVERLÅTELSEBESIKTNING</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select name="BuildingType" label="Building Type" options={BuildingType} />
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield name="city" label="City" />
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield name="municipality" label="Kommun" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="witness" label="Närvarande:" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>BESIKTNING </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="property_owned_by_seller_year" label="Köpår" type="number" />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        name="docChecked"
                        legend="Tillhandahållna Handlingar"
                        label="Documents provided by seller(check if yes)"
                      />
                    </Grid>
                    {values.docChecked && (
                      <>
                        <Typography>Enter the documents Names Provided </Typography>
                        <Grid item xs={12}>
                          <Textfield
                            name="providedDocuments"
                            label="Enter The docs Name"
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <Textfield name="state" label="State" />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray name="RenovationList">
                        {({ insert, remove, push }) => (
                          <Grid item xs={12}>
                            <Typography>Enter the renovation details</Typography>
                            <Stack
                              spacing={{ xs: 1, sm: 2 }}
                              direction="row"
                              useFlexGap
                              flexWrap="wrap"
                              m={2}
                            >
                              {values.RenovationList.length > 0 &&
                                values.RenovationList.map((item, index) => (
                                  <Stack
                                    key={index}
                                    spacing={{ xs: 2, sm: 2 }}
                                    direction="row"
                                    useFlexGap
                                    flexWrap="wrap"
                                  >
                                    <Stack
                                      direction="row"
                                      justifyContent="space-evenly"
                                      spacing={1}
                                    >
                                      {/* <Field
                                    name={`RenovationList.${index}.year`}
                                    placeholder="Enter Year"
                                    type="number"
                                  /> */}
                                      <Textfield
                                        name={`RenovationList.${index}.year`}
                                        placeholder="Enter Year"
                                        type="number"
                                      />
                                      <Textfield
                                        name={`RenovationList.${index}.text`}
                                        placeholder="enter Text"
                                        type="text"
                                      />

                                      {/* <Field
                                    name={`RenovationList.${index}.text`}
                                    placeholder="enter Text"
                                    type="text"
                                  /> */}
                                    </Stack>
                                    <Stack justifyContent="center">
                                      <CancelIcon onClick={() => remove(index)} />
                                    </Stack>
                                  </Stack>
                                ))}
                            </Stack>
                            <Button
                              type="button"
                              className="secondary"
                              onClick={() => push({ year: "", text: "" })}
                            >
                              Add More
                            </Button>
                          </Grid>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield name="otherStatementForRenovations" label="Övrigt" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Upplysningar om fel i fastigheten</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield
                        name="defectMessage"
                        label="Enter defect note here"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>OKULÄR BESIKTNING </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Särskilda förutsättningar vid besiktningen
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield
                        name="special_condition_message"
                        label="Enter Note Here"
                        multiline
                        rows={6}
                        spellCheck={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Muntliga uppgifter</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield
                        name="orl_task"
                        label="Enter Note Here"
                        multiline
                        rows={6}
                        spellCheck={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield name="temperature" label="Väderlek" />
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield name="year_of_construction" label="Byggnadsår" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="building_Type" label="Byggnadstyp" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="foundation" label="Grundläggning" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="frame_floor" label="Stomme/bjälklag" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="facade" label="Fasad" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="window" label="Fönster" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="roof" label="Yttertak" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="heating" label="Uppvärmning" />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="ventilation" label="Ventilation " />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield name="water_and_sevage" label="Vatten och avlopp" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4">NOTERINGAR </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button disabled={!dirty || !isValid}>Submit Form</Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
          <PanoramaProject companyUID={companyUID} projectID={projectID} />
        </Container>
      </Grid>
    </Grid>
  );
}

export default CollectNewData;
