import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "FirebaseConfi";
import { useNavigate } from "react-router-dom";
import CustomTableComponent from "../OverviewTable/index";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
});
function Overview() {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (users) => {
      if (!users.emailVerified) navigate("/authentication/verify-email");
    });

    return () => unsubscribe();
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
    }
  };
  return (
    <DashboardLayout>
      <MainDiv>
        <ThemeProvider theme={theme}>
          <Header>
            <HeadingsDiv>
              <Heading>Overview</Heading>
              <SubHeading>Explore projects and get insights of each inspection.</SubHeading>
            </HeadingsDiv>
            <SearchDiv>
              <CustomTextField
                id="outlined-helperText"
                sx={{ marginRight: "20px", height: "40px" }}
                placeholder="Search by name or ID"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "gray" }} />,
                }}
                onKeyDown={handleKeyPress}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker />
              </LocalizationProvider>
            </SearchDiv>
          </Header>
          <OuterDiv>
            <CustomTableContainer>
              <CustomTableComponent />
            </CustomTableContainer>
          </OuterDiv>
        </ThemeProvider>
      </MainDiv>
    </DashboardLayout>
  );
}

export default Overview;

const MainDiv = styled("div")({
  padding: "32px 40px",
  background: "white",
  minHeight: "100vh",
});
const CustomTextField = styled(TextField)`
  && .MuiInputBase-root {
    height: 40px;
    border-radius: 8px;
  }
  width: 70%;
`;
const CustomDatePicker = styled(DatePicker)`
  && .MuiInputBase-root {
    height: 40px;
    border-radius: 8px;
  }
  width: 30% !important;
`;
const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});
const HeadingsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10",
});
const SearchDiv = styled("div")({
  display: "flex",
  marginTop: "15px",
  justifyContent: "end",
});
const Heading = styled("p")({
  color: "#000",
  fontsize: "24px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const OuterDiv = styled("div")({
  width: "100%",
  overflowX: "auto",
  marginTop: "32px",
  borderRadius: "8px",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Adjust the color as needed
  },
});

const CustomTableContainer = styled("div")({
  display: "inline-block",
  minWidth: "100%",
});
