/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function ActionDailog(props) {
  return (
    <Dialog open={props.open} onClose={props.closeFunction}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeFunction} color="primary">
          Cancel
        </Button>
        <Button onClick={props.actionFunction} color="primary">
          {props.ButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionDailog;
