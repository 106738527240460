/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from "react";
import { Typography, Grid } from "@mui/material";
// import useStyles from "./styles";

function AdminDetails(props) {
  const { formValues } = props;
  // const classes = useStyles();
  const { AdminName, AdminEmail } = formValues;
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      <Typography variant="h6" gutterBottom>
        Admin Details
      </Typography>
      <Grid container>
        <>
          <Grid item xs={6}>
            <Typography gutterBottom>Email</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom>{AdminEmail}</Typography>
          </Grid>
          <>
            <Grid item xs={6} fullWidth>
              <Typography gutterBottom>Display Name</Typography>
            </Grid>
            <Grid item xs={6} fullWidth>
              <Typography gutterBottom>{AdminName}</Typography>
            </Grid>
          </>
        </>
      </Grid>
    </Grid>
  );
}

export default AdminDetails;
