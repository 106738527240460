const hotsportDData = [
  {
    sections: {
      UTVÄNDIGT: {
        "Mark mm": [
          {
            value: "this is text for Fasad1",
            label: "this is text for Fasad1",
          },
          {
            value: "this is text for Fasad2",
            label: "this is text for Fasad2",
          },
        ],
        Grundmur: [
          {
            value: "this is text for Fasad1",
            label: "this is text for Fasad1",
          },
          {
            value: "this is text for Fasad2",
            label: "this is text for Fasad2",
          },
        ],
        Fasad: [
          {
            value: "this is text for Fasad1",
            label: "this is text for Fasad1",
          },
          {
            value: "this is text for Fasad2",
            label: "this is text for Fasad2",
          },
        ],
        Fönster: [
          {
            value: "this is text for Fönster1",
            label: "this is text for Fönster1",
          },
          {
            value: "this is text for Fönster2",
            label: "this is text for Fönster2",
          },
        ],
        Yttertak: [
          {
            value: "this is text for Yttertak1",
            label: "this is text for Yttertak1",
          },
          {
            value: "this is text for Yttertak2",
            label: "this is text for Yttertak2",
          },
        ],
        Övrigt: [
          {
            value: "this is text for Fasad1",
            label: "this is text for Fasad1",
          },
          {
            value: "this is text for Fasad2",
            label: "this is text for Fasad2",
          },
        ],
      },
      INVÄNDIGT: {
        SOUTERRÄNGVÅNING: {
          Trapp: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Hall: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          WC: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Passage: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Allrum: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Dusch: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Pannrum: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Förråd1: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Förråd2: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Gym: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Biorum: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
        },
        ÖVREPLAN: {
          Hall: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Sovrum3: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Sovrum4: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          WC: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          VIND: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
        },
        ENTRÉPLAN: {
          Entré: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Sovrum1: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Kök: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Matrum: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Vardagsrum: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Sovrum2: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Klädkammare: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
          Trapp: [
            {
              value: "this is text for Fasad1",
              label: "this is text for Fasad1",
            },
            {
              value: "this is text for Fasad2",
              label: "this is text for Fasad2",
            },
          ],
        },
      },
    },
  },
];
export default hotsportDData;
