/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import exclamationMark from "../../../../assets/images/exclamation-mark.svg";

function DeleteModal({ openDeletModal, setOpenDeletModal, onOk }) {
  return (
    <DeletePopup open={openDeletModal} onClose={() => setOpenDeletModal(false)}>
      <DoneContent>
        <img src={exclamationMark} width="48px" alt="done" />
        <DoneTypography sx={{ marginBottom: "8px !important" }}>
          Are you sure to delete Photo?
        </DoneTypography>
        <DialogBtnsDiv sx={{ width: "100%" }}>
          <RandomButton
            dialogBtns="yes"
            sx={{
              width: "100%",
              textAlgn: "center",
              color: "#344054 !important",
              border: "1px solid #D0D5DD",
            }}
            purple="false"
            variant="contained"
            color="primary"
            onClick={() => setOpenDeletModal(false)}
          >
            Cancel
          </RandomButton>
          <RandomButton
            dialogBtns="yes"
            sx={{
              width: "100%",
              textAlgn: "center",
              marginRight: "0",
              background: "#D92D20 !important",
              color: "white !important",
            }}
            purple="false"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenDeletModal(false);
              onOk();
            }}
          >
            Delete
          </RandomButton>
        </DialogBtnsDiv>
      </DoneContent>
    </DeletePopup>
  );
}
export default DeleteModal;

const DeletePopup = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "30%",
  },
});
const DoneContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  alignItems: "center",
});
const DoneTypography = styled(Typography)({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
  marginTop: "20px",
  marginBottom: "32px",
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#fff",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  // padding: dialogBtns === "yes" ? "10px 18px" : "14px 28px",
  padding: "10px 18px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: dialogBtns === "yes" ? "600" : "",
  fontSize: "16px",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#fff",
  },
}));
