/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { userTypeController } from "context/userInfo";
import { ToastContainer, toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import { getAuth, updateProfile } from "firebase/auth";
import Compressor from "compressorjs";
import { getDownloadURL, uploadBytes, ref, deleteObject } from "firebase/storage";
import { Storage } from "FirebaseConfi";
import MailIcon from "../../../assets/images/mailIcon.svg";
import ChangePasswordModal from "./ChangePassword/index";
import DeleteModal from "./DeleteModal/index";
// import HidePassIcon from "../../../assets/images/hidePassIcon.svg";
// import ViewPassIcon from "../../../assets/images/viewPassIcon.svg";

function ProfileComponent({ setUserProfileInfo, userProfileInfo }) {
  // const [hidePassword, setHidePassword] = useState(true);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeletModal, setOpenDeletModal] = useState(false);
  const [selectedSignatureImages, setSelectedSignatureImages] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const signaturefileInputRef = useRef(null);
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const Auth = getAuth();

  useEffect(() => {
    if (!companyUID) return;
    const storageRef = ref(
      Storage,
      `/companies/${companyUID}/Users/${Auth?.currentUser?.uid}/signatureImage`
    );
    getDownloadURL(storageRef)
      .then((url) => {
        setSelectedSignatureImages(url);
      })
      .catch((error) => {
        console.log("There is error in loding signature file!");
      });
  }, [companyUID]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can use FileReader to get the URL of the selected image
      const reader = new FileReader();
      const newPhotoURL = URL.createObjectURL(file);
      setSelectedFile(newPhotoURL);
      updateProfile(Auth.currentUser, {
        photoURL: newPhotoURL,
      })
        .then(() => {})
        .catch((error) => {
          // toast.error("Unable to update the profile information.");
        });
    }
  };

  const handleDeleteSignature = () => {
    const desertRef = ref(
      Storage,
      `/companies/${companyUID}/Users/${Auth?.currentUser?.uid}/signatureImage`
    );
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        setSelectedSignatureImages("");
        toast.success("Signature Deleted Successfully!");
      })
      .catch((error) => {
        toast.error("Uh-oh, an error occurred!!");
      });
  };
  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        strict: true,
        checkOrientation: true,
        maxWidth,
        maxHeight,
        minWidth: 0,
        minHeight: 0,
        width: 3698,
        height: undefined,
        resize: "none",
        quality: 0.8,
        mimeType: "",
        convertTypes: "image/png",
        convertSize: 5000000,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleUploadSignatureChange = (event) => {
    try {
      // eslint-disable-next-line prefer-destructuring
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        const storageRef = ref(
          Storage,
          `/companies/${companyUID}/Users/${Auth?.currentUser?.uid}/signatureImage`
        );
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            const compressedFile = await compressImage(file, 400, 200);
            await uploadBytes(storageRef, compressedFile).then(() => {
              getDownloadURL(storageRef).then((downloadURL) => {
                setSelectedSignatureImages(downloadURL);
                toast.success("Signature added successfully!");
              });
            });
          } catch (error) {
            toast.error("Unable To upload image, Try Again!");
          }
        };
      }

      // setSelectedImages(URL.createObjectURL(file));
    } catch (error) {
      toast.error("Unable To upload image, Try Again!");
    }
  };

  const handleUploadButtonClick = () => {
    // Trigger the hidden file input when the button is clicked
    fileInputRef.current.click();
  };
  const handleUploadSignatureButtonClick = () => {
    // Trigger the hidden file input when the button is clicked
    signaturefileInputRef.current.click();
  };

  const handleSettingUserInfo = (event) => {
    const { name, value } = event.target;
    setUserProfileInfo({
      ...userProfileInfo,
      [name]: value,
    });
  };
  return (
    <div>
      <FieldContainer>
        <Label>Name</Label>
        <FieldsGrid>
          <InputField
            name="displayName"
            value={userProfileInfo?.displayName}
            onChange={handleSettingUserInfo}
            placeholder="Full Name"
          />
          {/* <InputField name="present" placeholder="Last Name" /> */}
        </FieldsGrid>
      </FieldContainer>
      <FieldContainer>
        <Label>Profile Image</Label>
        <ImageFlex>
          <Avatar
            sx={{ width: "90px", height: "90px" }}
            alt="Cindy Baker"
            src={userProfileInfo?.photoURL}
          />
          <BtnsFlex>
            <div>
              <StyledButton onClick={handleUploadButtonClick}>Upload Image</StyledButton>
              {/* Hidden file input */}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
            </div>
            <StyledButton>Delete Image</StyledButton>
          </BtnsFlex>
        </ImageFlex>
      </FieldContainer>
      <FieldContainer>
        <Label>Email Address</Label>
        <FieldsGrid>
          <InputField
            name="email"
            value={userProfileInfo?.email}
            onChange={handleSettingUserInfo}
            type="email"
            placeholder="Enter Your Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={MailIcon} alt="Mail Icon" />
                </InputAdornment>
              ),
            }}
          />
        </FieldsGrid>
      </FieldContainer>
      {/* <FieldContainer>
        <Label>Password</Label>
        <FieldsGrid>
          <PasswordFlex>
            <Label style={{ width: "fit-content", paddingTop: "0px" }}>Current Password</Label>
            <InputField
              name="present"
              type={hidePassword ? "password" : "text"}
              placeholder="Your Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      onClick={() => setHidePassword(!hidePassword)}
                      src={hidePassword ? ViewPassIcon : HidePassIcon}
                      alt="Mail Icon"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </PasswordFlex>
          <div style={{ display: "flex", alignItems: "end" }}>
            <StyledButton onClick={() => setOpenChangePassword(true)}>Change Password</StyledButton>
          </div>
        </FieldsGrid>
      </FieldContainer> */}
      <FieldContainer>
        <Label>Signature</Label>
        <FieldsGrid>
          <Image>
            {selectedSignatureImages ? (
              <img
                style={{ width: "248px", height: "88px", borderRadius: "8px" }}
                src={selectedSignatureImages}
                alt="_signature"
              />
            ) : (
              <Text>No signature added</Text>
            )}
          </Image>
          <BtnsFlex>
            {/* <StyledButton>Upload Signature</StyledButton> */}
            <div>
              <StyledButton onClick={handleUploadSignatureButtonClick}>
                Upload Signature
              </StyledButton>
              {/* Hidden file input */}
              <input
                ref={signaturefileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUploadSignatureChange}
              />
            </div>
            <StyledButton onClick={() => setOpenDeletModal(true)}>Delete Signature</StyledButton>
          </BtnsFlex>
        </FieldsGrid>
      </FieldContainer>
      <FieldContainer>
        <Label>Logout</Label>
        <div style={{ padding: "24px 0" }}>
          <StyledButton>Logout of all devices</StyledButton>
        </div>
      </FieldContainer>
      <FieldContainer style={{ borderBottom: "none" }}>
        <Label>Account Deletion</Label>
        <GridContainer>
          <AccountDelteFlex>
            <Text2>
              Deleting your account results in permanent loss of all saved information, with no
              possibility of recovery.
            </Text2>
            <DeleteBtn>Delete my Account</DeleteBtn>
          </AccountDelteFlex>
        </GridContainer>
      </FieldContainer>
      <ChangePasswordModal
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
      />
      <DeleteModal
        openDeletModal={openDeletModal}
        setOpenDeletModal={setOpenDeletModal}
        onOk={handleDeleteSignature}
      />
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
    // Change Password Modal
  );
}
export default ProfileComponent;

const FieldContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
});
const Label = styled("label")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
  width: "20%",
  paddingTop: "24px",
});
const FieldsGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "16px",
  width: "80%",
  padding: "24px 0",
});
const InputField = styled(TextField)(({ nextBtnClicked, isEmpty }) => ({
  background: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired hover border color
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired focused border color
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired clicked border color
  },
}));
const ImageFlex = styled("div")({
  display: "flex",
  gap: "24px",
  padding: "24px 0",
});
const BtnsFlex = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "12px",
});
const StyledButton = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  background: "#F2F4F7",
  color: "#475467",
  fontSize: "14px",
  fontWeight: "600",
  border: "none",
  height: "fit-content",
  outline: "none",
  cursor: "pointer",
});
// const PasswordFlex = styled("div")({
//  display: "flex",
//  flexDirection: "column",
//  gap: "6px",
// });
const Image = styled("div")({
  width: "248px",
  height: "88px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid #EAECF0",
});
const Text = styled("p")({
  color: "#475467",
  fontSize: "12px",
  fontWeight: "500",
});
const GridContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
});
const AccountDelteFlex = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "24px 0 0 0",
});
const Text2 = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const DeleteBtn = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  background: "#F04438",
  color: "#FFF",
  fontSize: "14px",
  fontWeight: "600",
  width: "fit-content",
  border: "none",
  outline: "none",
  cursor: "pointer",
});
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
