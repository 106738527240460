/* eslint-disable react/prop-types */
import React from "react";
import { Typography, Grid } from "@mui/material";
// import useStyles from "./styles";

function CompanyDetails(props) {
  const { formValues } = props;
  // const classes = useStyles();
  const { CompanyName, OrganizationNumber } = formValues;
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom>
        Company Details
      </Typography>
      <Typography gutterBottom>{`${CompanyName}`}</Typography>
      <Typography gutterBottom>{`${OrganizationNumber}`}</Typography>
    </Grid>
  );
}

export default CompanyDetails;
