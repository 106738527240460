import React from "react";
import { Toolbar, AppBar, Typography } from "@mui/material";

function Header() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">Enter Information</Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
