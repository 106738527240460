/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, deleteObject } from "firebase/storage";
import { Storage, db } from "FirebaseConfi";
import { ToastContainer, toast } from "react-toastify";
import { userTypeController } from "context/userInfo";
import DeleteModal from "../ProfileComponent/DeleteModal/index";
import UploadLogoModal from "./UploadLogoModal";

function Workspace({ organisationInformation, setOrganisationInformation }) {
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [openDeletModal, setOpenDeletModal] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [image, setImage] = useState("");
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  useEffect(() => {
    if (!companyUID) return;
    console.log(companyUID);
    const storageRef = ref(Storage, `/company/${companyUID}/CompanyMainLogo`);
    getDownloadURL(storageRef)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        setImage(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // eslint-disable-next-line default-case
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            // setImageLoading(false);
            setImageLoadError(true);
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            toast.error("User doesn't have permission to access the object");
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            toast.error("Unknown error occurred, inspect the server response");
            break;
        }
      });
  }, [companyUID]);
  useEffect(async () => {
    if (!companyUID) return;
    const docRef = doc(db, "companies", companyUID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrganisationInformation({ ...docSnap.data() });
    } else {
      // doc.data() will be undefined in this case
      toast.error("No such document!");
    }
  }, [companyUID]);

  const handleAddUserDialogClose = () => {
    setAddUserDialogOpen(false);
  };
  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };
  const handleDeleteLogo = () => {
    const desertRef = ref(Storage, `/company/${companyUID}/CompanyMainLogo`);
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        setImage("");
        toast.success("Logo Deleted Successfully!");
      })
      .catch((error) => {
        toast.error("Uh-oh, an error occurred!!");
      });
  };

  const handleSettingOrganisationInfo = (event, type = "string") => {
    console.log(event.target.value);
    const { name, value } = event.target;
    let newValue = null;
    if (type === "number") {
      newValue = value === "" ? null : parseFloat(value);
    }
    console.log(name, value);
    setOrganisationInformation({
      ...organisationInformation,
      [name]: type === "number" ? newValue : value,
    });
  };

  return (
    <div>
      <FieldContainer>
        <Label>Organisation Name</Label>
        <FieldsGrid>
          <InputField
            name="CName"
            value={organisationInformation.CName}
            onChange={handleSettingOrganisationInfo}
            placeholder="Enter organisation name"
          />
        </FieldsGrid>
      </FieldContainer>
      <FieldContainer>
        <Label>Organisation Logo</Label>
        <ImageFlex>
          <Avatar sx={{ width: "90px", height: "90px" }} alt="Virtual Inspect" src={image} />
          <BtnsFlex>
            <StyledButton onClick={handleAddUserDialogOpen}>Upload Logo</StyledButton>
            <StyledButton onClick={() => setOpenDeletModal(true)}>Delete Logo</StyledButton>
          </BtnsFlex>
        </ImageFlex>
      </FieldContainer>
      <FieldContainer>
        <Label>Organisation Number</Label>
        <FieldsGrid>
          <InputField
            name="orgNumber"
            onChange={(e) => handleSettingOrganisationInfo(e)}
            value={organisationInformation.orgNumber}
            placeholder="Enter organisation number"
          />
        </FieldsGrid>
      </FieldContainer>
      <FieldContainer>
        <Label>Organisation Address</Label>
        <FieldsGrid>
          <AddressFlex>
            <Label style={{ width: "fit-content", paddingTop: "0px" }}>City</Label>
            <InputField
              name="City"
              value={organisationInformation.City}
              onChange={handleSettingOrganisationInfo}
              type="text"
              placeholder="City"
            />
          </AddressFlex>
          <AddressFlex>
            <Label style={{ width: "fit-content", paddingTop: "0px" }}>Postal Code</Label>
            <InputField
              name="Pincode"
              value={organisationInformation.Pincode}
              onChange={handleSettingOrganisationInfo}
              type="text"
              placeholder="City"
            />
          </AddressFlex>
          <AddressFlex>
            <Label style={{ width: "fit-content", paddingTop: "0px" }}>Address</Label>
            <InputField
              name="CAddress"
              value={organisationInformation.CAddress}
              onChange={handleSettingOrganisationInfo}
              type="text"
              placeholder="Address"
            />
          </AddressFlex>
        </FieldsGrid>
      </FieldContainer>
      <FieldContainer>
        <Label>Organisation Contacts</Label>
        <div style={{ width: "80%" }}>
          <FieldsGrid style={{ width: "100%" }}>
            <AddressFlex>
              <Label style={{ width: "fit-content", paddingTop: "0px" }}>E-post</Label>
              <InputField
                name="ePost"
                value={organisationInformation.ePost}
                onChange={handleSettingOrganisationInfo}
                type="email"
                placeholder="E-Post"
              />
            </AddressFlex>
            <AddressFlex>
              <Label style={{ width: "fit-content", paddingTop: "0px" }}>Tel Phone</Label>
              <InputField
                name="telNumber"
                value={organisationInformation.telNumber}
                onChange={(e) => handleSettingOrganisationInfo(e, "number")}
                type="number"
                placeholder="Tel Number"
              />
            </AddressFlex>
            <AddressFlex>
              <Label style={{ width: "fit-content", paddingTop: "0px" }}>Uppdrag Number</Label>
              <InputField
                name="uppdragNumber"
                value={organisationInformation.uppdragNumber}
                onChange={(e) => handleSettingOrganisationInfo(e, "number")}
                type="number"
                placeholder="Uppdrag Number"
              />
            </AddressFlex>
          </FieldsGrid>
          <FieldsGrid style={{ paddingTop: "0", width: "100%" }}>
            <AddressFlex>
              <Label style={{ width: "fit-content", paddingTop: "0px" }}>Website</Label>
              <InputField
                name="website"
                value={organisationInformation.website}
                onChange={handleSettingOrganisationInfo}
                type="text"
                placeholder="Website"
              />
            </AddressFlex>
            <AddressFlex>
              <Label style={{ width: "fit-content", paddingTop: "0px" }}>Tax</Label>
              <InputField
                name="Tax"
                value={organisationInformation.Tax}
                onChange={(e) => handleSettingOrganisationInfo(e)}
                type="text"
                placeholder="Tax"
              />
            </AddressFlex>
          </FieldsGrid>
        </div>
      </FieldContainer>
      <DeleteModal
        openDeletModal={openDeletModal}
        setOpenDeletModal={setOpenDeletModal}
        onOk={handleDeleteLogo}
      />
      <UploadLogoModal
        addUserDialogOpen={addUserDialogOpen}
        handleAddUserDialogClose={handleAddUserDialogClose}
        setImageLoadError={setImageLoadError}
        setImage={setImage}
      />
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
export default Workspace;

const FieldContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "32px",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
});
const Label = styled("label")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
  width: "20%",
  paddingTop: "24px",
});
const FieldsGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "16px",
  width: "80%",
  padding: "24px 0",
});
const InputField = styled(TextField)(({ nextBtnClicked, isEmpty }) => ({
  background: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired hover border color
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired focused border color
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired clicked border color
  },
}));
const ImageFlex = styled("div")({
  display: "flex",
  gap: "24px",
  padding: "24px 0",
});
const BtnsFlex = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "12px",
});
const StyledButton = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  background: "#F2F4F7",
  color: "#475467",
  fontSize: "14px",
  fontWeight: "600",
  border: "none",
  height: "fit-content",
  outline: "none",
  cursor: "pointer",
});
const AddressFlex = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
});
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
