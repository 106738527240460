import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Box, Card, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { auth } from "FirebaseConfi";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import logoImage from "../../../assets/images/logo-ct.png";
import BasicLayout from "../components/BasicLayout";
import "react-toastify/dist/ReactToastify.css";

function index() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (users) => {
      setUser(users);
      if (users.emailVerified) navigate("/");
    });

    return () => unsubscribe();
  }, []);
  const handleResendVerificationEmail = () => {
    if (user) {
      sendEmailVerification(user)
        .then(() => {
          toast.success("sent successfully");

          console.log("Verification email sent successfully");
        })
        .catch((error) => {
          toast.error("Unable to send");

          console.error("Error sending verification email", error);
        });
    }
  };

  return (
    <BasicLayout>
      <ToastContainer />
      <Card sx={{ borderRadius: "15px" }}>
        <Box pt={2} pb={3} px={3}>
          <Box display="flex" justifyContent="center" m={1}>
            <img src={logoImage} alt="Logo" height={60} />
          </Box>
          <Typography variant="h5" textAlign="center" my={3}>
            VERIFY YOUR EMAIL
          </Typography>
          <Typography variant="body2" textAlign="center" my={3}>
            We have sent you email verify link on your Email: &quot;{user?.email}&quot;. Refresh
            page after successfully verified
          </Typography>

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleResendVerificationEmail}
            >
              RESEND LINK
            </MDButton>
          </MDBox>
        </Box>
      </Card>
    </BasicLayout>
  );
}

export default index;
