/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable spaced-comment */
/* eslint-disable no-console */
import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 React example components
import Sidenav from "WebItems/Sidenav";

//import Configurator from "WebItems/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
//import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
//import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
//import rtlPlugin from "stylis-plugin-rtl";
//import { CacheProvider } from "@emotion/react";
//import createCache from "@emotion/cache";
import routes from "routes";
import SuperAdminRoutes from "SuperAdminRoutes";
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { userTypeController, setUserTypeState, setUserCompanyUIDState } from "context/userInfo";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "FirebaseConfi";
import SignIn from "layouts/authentication/sign-in";
import RegisterUser from "layouts/authentication/register";
import VerifyEmail from "layouts/authentication/verifyEmail";
import ProjectWorkSpace from "layouts/ProjectWorkSpace";
import InspectionDetails from "layouts/InspectionDetails";
import "./App.css";

export default function App() {
  const history = useNavigate();
  const location = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const [userInfo, dispatchUser] = userTypeController();
  const { userType } = userInfo;
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    localStorage.setItem("previousPath", location.pathname);
  }, [location]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    const previousPath = localStorage.getItem("previousPath");

    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.emailVerified) {
          // User is verified, proceed with app usage
          auth.currentUser.getIdTokenResult().then((idTokenResult) => {
            console.log("this is role info>>>>>>>>>>>>", idTokenResult.claims);
            setUserTypeState(dispatchUser, idTokenResult.claims.role);
            setUserCompanyUIDState(dispatchUser, idTokenResult.claims.company);
            // eslint-disable-next-line no-unused-expressions
            previousPath ? history(previousPath) : history("/");
          });
          console.log("User is verified!");
        } else {
          history("/authentication/verify-email");
          // User is not verified, display a message or prompt to verify
          console.log("User is not verified. Please check your email to verify.");
        }
      } else {
        history("/authentication/sign-in");

        setUserTypeState(dispatchUser, "Anymous");
      }
    });
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {layout === "dashboard" && (
        <>
          {userType === "SuperAdmin" ? (
            <Sidenav
              color="rgba(0, 67, 206, 0.36)"
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Virtual Inspect"
              routes={SuperAdminRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          ) : (
            <Sidenav
              color="rgba(0, 67, 206, 0.36)"
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Virtual Inspect"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
        </>
      )}
      <Routes>
        <Route path="/authentication/sign-in" element={<SignIn />} />
        <Route path="/authentication/register" element={<RegisterUser />} />
        <Route path="/authentication/verify-email" element={<VerifyEmail />} />
        {userType === "SuperAdmin" ? getRoutes(SuperAdminRoutes) : getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="/projectworkspace/:id" component={ProjectWorkSpace} />
        <Route path="/:id/inspectionDetails" component={InspectionDetails} />
        {/* <Route path="/inspectionDetails" component={InspectionDetails} /> */}
      </Routes>
    </ThemeProvider>
  );
}
