/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

function Certificates() {
  const [open, setOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [currentImage, setCurrentImage] = useState({ image: "", name: "" });
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions, eqeqeq
    currentImage.name == "" || currentImage.image == "" || (currentImage.image != "" && imageError)
      ? setBtnDisable(true)
      : setBtnDisable(false);
  }, [currentImage, imageError]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setImageError(false);
    setBtnDisable(true);
  };
  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageType = /^image\//;
      if (!imageType.test(file.type)) {
        setCurrentImage({ name: currentImage.name, image: "" });
        setImageError(true);
        return;
      }
      setCurrentImage((prevImage) => ({
        ...prevImage,
        image: e.target.result,
      }));
      setImageError(false);
    };

    reader.readAsDataURL(file);
  };
  const handleNameChange = (event) => {
    const name = event.target.value;
    setCurrentImage((prevImage) => ({
      ...prevImage,
      // eslint-disable-next-line object-shorthand
      name: name,
    }));
  };
  const handleSave = () => {
    if (currentImage.image && !imageError) {
      setImages((prevImages) => [
        ...prevImages,
        { image: currentImage.image, name: currentImage.name },
      ]);
      setCurrentImage({
        image: "",
        name: "",
      });
      setOpen(false);
    }
  };
  return (
    <MainDiv>
      {/* <HeadingTypography variant="subtitle2" sx={{ marginTop: "10px" }} gutterBottom>
        My certificates
      </HeadingTypography>
      <hr style={{ marginBottom: "20px" }} />

      <Typography variant="body1" gutterBottom>
        Members
      </Typography> */}
      <CustomTypography variant="body2" gutterBottom>
        Manage certificates here.
      </CustomTypography>
      <Button variant="contained" onClick={handleOpen}>
        Add Certificates
      </Button>
      <div>
        <FlexContainer>
          {images.length === 0 && (
            <FlexItem>
              <DummyItem onClick={() => setOpen(true)}>
                <PlusIcon />
              </DummyItem>
            </FlexItem>
          )}
          {images.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FlexItem key={index}>
              <CustomImg
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src={item.image}
                alt={`${index}`}
                style={{ width: "100%", height: "100%" }}
              />
              <p>{item.name}</p>
            </FlexItem>
          ))}

          {images.length > 0 && (
            <FlexItem>
              <DummyItem onClick={() => setOpen(true)}>
                <PlusIcon />
              </DummyItem>
            </FlexItem>
          )}
        </FlexContainer>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox>
          <AddTypography variant="body2" gutterBottom>
            Add Certificate
          </AddTypography>
          <FormContainer onSubmit={handleSave}>
            <TextField
              label="Institute Name"
              placeholder="Institute name e.g ABC college"
              onChange={handleNameChange}
              variant="outlined"
              required
              sx={{ marginTop: "30px" }}
            />
            <TextField
              type="file"
              accept="image/*"
              onChange={handleUpload}
              sx={{ marginBottom: "10px" }}
            />
            {imageError && <ImageError>Selected file is not an image.</ImageError>}
            <SaveButton type="submit" variant="contained" color="primary" disabled={btnDisable}>
              Save
            </SaveButton>
          </FormContainer>
        </CustomBox>
      </Modal>
    </MainDiv>
  );
}

export default Certificates;

const MainDiv = styled("div")({
  fontFamily: "Poppins !important",
});
// const HeadingTypography = styled(Typography)({
//   fontWeight: "500",
//   borderBottom: "#0F62FE solid 2px",
//   marginBottom: "0px",
//   padding: "0px 20px",
//   paddingBottom: "10px",
//   paddingLeft: "0px",
//   width: "fit-content",
// });

const CustomTypography = styled(Typography)({
  fontSize: "14px",
  color: "gray",
});
const FlexContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "50px 10px",
  marginTop: "20px",
});

// Styled component for each item in the flex container
const FlexItem = styled(Box)({
  width: "calc(33.33% - 8px)", // Adjust the width as per your requirement
  height: "200px",
  marginBottom: "16px", // Adjust the spacing between items as per your requirement
  "@media (max-width: 768px)": {
    width: "calc(50% - 8px)", // Adjust the width for smaller screens
  },
  "@media (max-width: 480px)": {
    width: "100%", // Adjust the width for even smaller screens
  },
  "@media (min-width: 0px) and (max-width: 1300px)": {
    height: "150px",
  },
});

const DummyItem = styled(Box)({
  border: "2px dashed gray",
  borderRadius: "4px",
  padding: "16px",
  position: "relative",
  height: "inherit",
});

const PlusIcon = styled(AddIcon)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50px",
});

const CustomBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  boxShadow: 24,
  padding: "20px 40px",
});
const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  maxWidth: "400px",
  margin: "0 auto",
});
const SaveButton = styled(Button)({
  marginTop: "16px",
});

const AddTypography = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "20px",
});
const ImageError = styled("p")({
  fontSize: "12px",
  color: "red",
  marginTop: "-20px",
  marginLeft: "10px",
});
const CustomImg = styled("img")({
  border: "1px solid gray",
  borderRadius: "4px",
});
