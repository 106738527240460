/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Storage } from "FirebaseConfi";
import { ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";

function MyView({ companyUID, projectID }) {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [selectedFloorImage, setSelectedFloorImage] = useState([]);
  const [FlooruploadProgress, setFloorUploadProgress] = useState({});
  const [FlooruploadStatus, setFloorUploadStatus] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]);
    setUploadProgress({});
    setUploadStatus({});
    setSelectedFloorImage([]);
    setFloorUploadProgress({});
    setFloorUploadStatus({});
  };

  const handleFileInputChange = (event) => {
    setSelectedFiles(event.target.files);
    setUploadProgress({});
    setUploadStatus({});
  };
  const handleFloorFileInputChange = (event) => {
    setSelectedFloorImage(event.target.files);
    setFloorUploadProgress({});
    setFloorUploadStatus({});
  };
  function uploadFLoorMap() {
    for (let i = 0; i < selectedFloorImage.length; i++) {
      if (selectedFloorImage[0]) {
        const storageRefs = ref(
          Storage,
          `companies/${companyUID}/Projects/${projectID}/FloorMapImage`
        );
        const Floorprogress = {};
        const Floorstatus = {};

        const files = selectedFloorImage[0];
        const fileRefs = ref(storageRefs, files.name);
        const uploadTasks = uploadBytesResumable(fileRefs, files);
        Floorprogress[files.name] = 0;
        Floorstatus[files.name] = "Uploading";
        uploadTasks.on(
          "state_changed",
          (snapshot) => {
            const fileProgress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            Floorprogress[files.name] = fileProgress;
            setFloorUploadProgress({ ...Floorprogress });
          },

          (error) => {
            console.error(error);
            Floorstatus[files.name] = "Error";
            setFloorUploadStatus({ ...Floorstatus });
          },
          () => {
            console.log(`Floor Image ${files.name} uploaded successfully!`);
            Floorstatus[files.name] = "Uploaded";
            setFloorUploadStatus({ ...Floorstatus });
          }
        );
      }
    }
  }

  const handleUpload = () => {
    // Upload the selected files to Firebase Storage
    const storageRef = ref(Storage, `companies/${companyUID}/Projects/${projectID}/360Images`);
    const promises = [];
    const progress = {};
    const status = {};
    let uploadTask;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileRef = ref(storageRef, file.name);
      uploadTask = uploadBytesResumable(fileRef, file);
      promises.push(uploadTask);
      progress[file.name] = 0;
      status[file.name] = "Uploading";
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const fileProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          progress[file.name] = fileProgress;
          setUploadProgress({ ...progress });
        },
        (error) => {
          console.error(error);
          status[file.name] = "Error";
          setUploadStatus({ ...status });
        },
        () => {
          console.log(`${file.name} uploaded successfully!`);
          status[file.name] = "Uploaded";
          setUploadStatus({ ...status });
        }
      );
    }
    Promise.all(promises)
      .then(() => {
        console.log("All files uploaded successfully!");
        uploadFLoorMap();
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        uploadTask.cancel();
      });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Images
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Images</DialogTitle>
        <DialogContent>
          <input type="file" accept="image/*" multiple onChange={handleFileInputChange} />
          {selectedFiles.length > 0 && (
            <ul>
              {Array.from(selectedFiles).map((file) => (
                <li key={file.name}>
                  {file.name}
                  <LinearProgress variant="determinate" value={uploadProgress[file.name] || 0} />
                  {uploadStatus[file.name] && uploadStatus[file.name] === "Uploaded" ? (
                    <Typography sx={{ color: "green" }} variant="string">
                      {uploadStatus[file.name]}
                    </Typography>
                  ) : (
                    <Typography>{uploadStatus[file.name]}</Typography>
                  )}
                </li>
              ))}
            </ul>
          )}
          <Typography>select Floor image</Typography>
          <input
            key="floorImage"
            type="file"
            accept="image/*"
            onChange={handleFloorFileInputChange}
          />
          {selectedFloorImage.length > 0 && (
            <ul>
              {Array.from(selectedFloorImage).map((file) => (
                <li key={file.name}>
                  {file.name}
                  <LinearProgress
                    variant="determinate"
                    value={FlooruploadProgress[file.name] || 0}
                  />
                  {FlooruploadStatus[file.name] && FlooruploadStatus[file.name] === "Uploaded" ? (
                    <Typography sx={{ color: "green" }}>{FlooruploadStatus[file.name]}</Typography>
                  ) : (
                    <Typography>{FlooruploadStatus[file.name]}</Typography>
                  )}
                </li>
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary" disabled={selectedFiles.length === 0}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MyView;
