/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField } from "../../FormFields";

export default function NewAdminForm(props) {
  const {
    formField: { AdminName, AdminEmail, AdminConfimEmail, AdminPassword },
  } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Admin Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item mx={3} xs={12}>
          <InputField name={AdminName.name} label={AdminName.label} fullWidth />
        </Grid>
        <Grid item mx={3} xs={12}>
          <InputField name={AdminEmail.name} label={AdminEmail.label} fullWidth />
        </Grid>
        <Grid item mx={3} xs={12}>
          <InputField name={AdminConfimEmail.name} label={AdminConfimEmail.label} fullWidth />
        </Grid>
        <Grid item mx={3} xs={12}>
          <InputField name={AdminPassword.name} label={AdminPassword.label} fullWidth />
        </Grid>
      </Grid>
    </>
  );
}
