/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */

import { userTypeController } from "context/userInfo";
import { Navigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function SuperAdminCheck({ children }) {
  const [UserInfo] = userTypeController();
  const { userType } = UserInfo;
  console.log(userType === "SuperAdmin");
  if (userType === "SuperAdmin") {
    return children;
  }
  return <Navigate to="*" replace />;
}

export default SuperAdminCheck;
