/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { collection, doc, getDocs, orderBy, query, updateDoc, deleteDoc } from "firebase/firestore";
import { userTypeController } from "context/userInfo";
import { db } from "FirebaseConfi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import tickIcon from "../../assets/images/green-tick-icon.svg";
import delImg from "../../assets/images/redDelIcon.svg";

// Table component
function CustomTableComponent() {
  const [Projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  const refreshedData = async (cUID) => {
    console.log("@@@@@@@@@@@@@@@@@", cUID);
    const q = query(collection(db, `companies/${cUID}/projects`), orderBy("CreatedAt", "desc"));

    const querySnapshot = await getDocs(q);
    const ProjectList = [];
    querySnapshot.forEach((document) => {
      // doc.data() is never undefined for query doc snapshots
      ProjectList.push({ id: document.id, ...document.data() });
    });
    setProjects(ProjectList);
  };
  useEffect(() => {
    if (companyUID) refreshedData(companyUID);
  }, [companyUID]);

  const handleDeleteProject = (id) => {
    const projectRef = doc(db, `companies/${companyUID}/projects`, id);

    // Delete the document
    deleteDoc(projectRef)
      .then(() => {
        toast.success("Project successfully deleted!");
      })
      .catch((error) => {
        toast.error("Failed to delete the project");
        console.error("Error deleting document:", error);
      });
    refreshedData();
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // Calculate pagination range
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedProjects = Projects.slice(startIndex, endIndex);

  const Inpection = (val) => {
    console.log("val", val);
    if (val !== "Inspect") {
      const docRef = doc(db, `companies/${companyUID}/projects`, val);
      updateDoc(docRef, { status: "confirmed" })
        .then(() => {
          console.log("A New Document Field has been added to an existing document");
        })
        .catch((error) => {
          console.log(error);
        });
      const updatedProjectList = [...Projects];

      // Update the status of the object at the specified index
      updatedProjectList[Projects.findIndex((item) => item.id === val)].status = "confirmed";

      // Update the state with the new objects list
      setProjects(updatedProjectList);
    } else {
      console.log(val);
    }
  };
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <CustomTable>
        <CustomTableHead>
          <TableRow>
            <CustomTableCell header>
              <HeaderText>Uppdrag</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Property Designation</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Creation date</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Inspection date</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Inspection time</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Inspector</HeaderText>
            </CustomTableCell>
            <CustomTableCell header>
              <HeaderText>Status</HeaderText>
            </CustomTableCell>
            <CustomTableCell isActionsCell header>
              <HeaderText>Actions</HeaderText>
            </CustomTableCell>
          </TableRow>
        </CustomTableHead>
        <TableBody>
          {paginatedProjects?.map((item) => {
            return (
              <TableRow>
                <CustomTableCell>
                  <RowText>{item?.uppdragNumber}</RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <RowText>{item?.propertyDestination}</RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <RowText>
                    {new Date(
                      item?.CreatedAt?.seconds * 1000 + item?.CreatedAt?.nanoseconds / 1000000
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <RowText>
                    {new Date(
                      item?.inspectionDate?.seconds * 1000 +
                        item?.inspectionDate?.nanoseconds / 1000000
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <RowText>
                    {new Date(item?.inspectionTime?.seconds * 1000).toLocaleTimeString()}
                  </RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <RowText>{item?.InspectorName}</RowText>
                </CustomTableCell>
                <CustomTableCell>
                  <StatusRowText status={item?.status}>{item?.status}</StatusRowText>
                </CustomTableCell>
                <CustomTableCell isActionsCell>
                  <RowText>
                    {item?.status === "confirmed" || item?.status === "Confirmed" ? (
                      <Link to={`/${item.id}/inspectionDetails`}>
                        <InspectionButton
                          onClick={() =>
                            Inpection(
                              item?.status === "confirmed" || item?.status === "Confirmed"
                                ? "Inspect"
                                : "Confirm"
                            )
                          }
                          text={
                            item?.status === "confirmed" || item?.status === "Confirmed"
                              ? "Inspect"
                              : "Confirm"
                          }
                        >
                          Inspect
                        </InspectionButton>
                      </Link>
                    ) : (
                      <ActionIcons>
                        <Icon
                          onClick={() =>
                            Inpection(
                              item?.status === "confirmed" || item?.status === "Confirmed"
                                ? "Inspect"
                                : item.id
                            )
                          }
                          type="confirm"
                          src={tickIcon}
                          alt="_confirm"
                        />
                        <Icon
                          onClick={() => handleDeleteProject(item?.id)}
                          type="del"
                          src={delImg}
                          alt="_confirm"
                        />
                      </ActionIcons>
                    )}
                  </RowText>
                </CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
      {/* Pagination */}
      <CustomPagination
        count={Math.ceil(Projects.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* ToastContainer */}
      <ErrorToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default CustomTableComponent;

// Define custom styles

const CustomTable = styled(Table)({
  borderRadius: "8px",
  border: "1px solid var(--gray-200, #EAECF0)",
  background: "var(--white, #FFF)",
  minWidth: "100%",
  width: "max-content",
});

const CustomTableHead = styled(TableHead)({
  background: "var(--gray-50, #F9FAFB)",
});

// const CustomTableCell = styled(TableCell)({
//   padding: "16px 24px",
// });
const CustomTableCell = styled(TableCell)(({ isActionsCell, header }) => ({
  padding: "16px 24px",
  position: "sticky",
  background: header ? "#F9FAFB" : "white",
  right: isActionsCell ? 0 : "unset", // Only apply `right: 0` to the "Actions" cell
  zIndex: 1, // Added to maintain the stacking order
}));

const HeaderText = styled("div")({
  color: "var(--gray-500, #667085)",
  fontSize: "12px",
  fontWeight: 500,
});

const RowText = styled("div")({
  color: "var(--gray-500, #667085)",
  fontSize: "14px",
  fontWeight: 400,
});

const StatusRowText = styled("div")(({ status }) => ({
  display: "flex",
  padding: "2px 10px",
  justifyContent: "center",
  width: "max-content !important",
  alignItems: "center",
  borderRadius: "16px",
  background: "#ECFDF3",
  color:
    // eslint-disable-next-line no-nested-ternary
    status === "Confirmed" || status === "confirmed"
      ? "#027A48"
      : // eslint-disable-next-line no-nested-ternary
      status === "Pending" || status === "pending"
      ? "#B54708"
      : status === "Draft" || status === "draft"
      ? "#026AA2"
      : "black",
  fontSize: "14px",
  fontWeight: "500",
}));
const InspectionButton = styled("button")(({ text }) => ({
  color: text === "Confirm" ? "#6941C6" : "white",
  textTransform: "none",
  borderRadius: "8px",
  background: text === "Confirm" ? "#F9F5FF" : "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "8px 14px",
  border: "none",
  outline: "none",
  fontFamily: "Poppins",
}));
const CustomPagination = styled(Pagination)({
  display: "flex",
  justifyContent: "space-between",
  listStyleType: "none",
  padding: 0,

  // Apply styles to list item elements
  "& li:first-child": {
    marginRight: "auto",
  },
  "& li:last-child": {
    marginLeft: "auto",
  },
  "& li:not(:first-child):not(:last-child)": {
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const ActionIcons = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Icon = styled("img")(({ type }) => ({
  cursor: "pointer",
  background: "white",
  padding: type === "confirm" ? "5px" : "7px",
  borderRadius: "25px",
  "&:hover": {
    background: type === "confirm" ? "#ecfdfc" : "#fce3e4",
  },
}));
const ErrorToastContainer = styled(ToastContainer)({
  fontFamily: "Inter !important",
  fontSize: "14px !important",
});
