/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import KeyIcon from "@mui/icons-material/Key";

import { firebasefunctions, auth } from "FirebaseConfi";
import { httpsCallable } from "firebase/functions";
import { sendPasswordResetEmail } from "firebase/auth";
import ActionDailog from "./ActionDailog";

function UsersTable() {
  const [users, setUsers] = useState([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [changeEmailDialogOpen, setchangeEmailDialogOpen] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [addUserEmail, setAddUserEmail] = useState("");
  const [UserNewEmail, setUserNewEmail] = useState("");
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [resetPasswordUid, setResetPasswordUid] = useState(null);
  const [disableUserDialogOpen, setDisableUserDialogOpen] = useState(false);
  const [disableUserUid, setDisableUserUid] = useState(null);
  const [enableUserDialogOpen, setenableUserDialogOpen] = useState(false);
  const [enableUserUid, setenableUserUid] = useState(null);
  const [userSelectedUid, setUserSelectedUid] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [role, setRole] = useState("Inspector");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  // useEffect(() => {
  //  setUsers([
  //    {
  //      id: 1,
  //      name: null,
  //      email: "example3@gmai.com",
  //      role: "Inspector",
  //      metadata: {
  //        lastSignInTime: null,
  //        creationTime: "Fri, 24 Mar 2023 10:46:46 GMT",
  //      },
  //      uid: "5SQl5hLd64hrwLZDKo9rH0yPlFn2",
  //      disabled: false,
  //    },
  //    {
  //      id: 2,
  //      name: null,
  //      email: "xde@gmail.com",
  //      role: "Editor",
  //      metadata: {
  //        lastSignInTime: null,
  //        creationTime: "Fri, 24 Mar 2023 08:47:10 GMT",
  //      },
  //      uid: "AbpmDi13Uye24RmOUM6n4ZYagzU2",
  //      disabled: false,
  //    },
  //    {
  //      id: 3,
  //      name: null,
  //      email: "ex4@gmail.com",
  //      role: "Inspector",
  //      metadata: {
  //        lastSignInTime: null,
  //        creationTime: "Fri, 24 Mar 2023 10:43:47 GMT",
  //      },
  //      uid: "QLHtA0GA64dn4cvI3eSk4vc13XA2",
  //      disabled: false,
  //    },
  //    {
  //      id: 4,
  //      name: null,
  //      email: "manish5@gmail.com",
  //      role: "Editor",
  //      metadata: {
  //        lastSignInTime: "Fri, 24 Mar 2023 07:49:05 GMT",
  //        creationTime: "Fri, 24 Mar 2023 07:48:25 GMT",
  //      },
  //      uid: "iV0Rv73ESYgNcPzqDZXWEEXAV9J2",
  //      disabled: false,
  //    },
  //    {
  //      id: 5,
  //      name: null,
  //      email: "company1@gmail.com",
  //      role: "Admin",
  //      metadata: {
  //        lastSignInTime: "Fri, 24 Mar 2023 07:35:02 GMT",
  //        creationTime: "Mon, 16 Jan 2023 10:07:06 GMT",
  //      },
  //      uid: "iwoVfUw5HUR2Dd4T6lvBR1slxcA3",
  //      disabled: false,
  //    },
  //    {
  //      id: 6,
  //      name: null,
  //      email: "maniddsh@gmail.com",
  //      role: "Inspector",
  //      metadata: {
  //        lastSignInTime: null,
  //        creationTime: "Fri, 24 Mar 2023 08:22:03 GMT",
  //      },
  //      uid: "kx1a4negE8UB21jlsM5NzoLcks92",
  //      disabled: false,
  //    },
  //    {
  //      id: 7,
  //      name: null,
  //      email: "ex22@gmail.com",
  //      role: "Admin",
  //      metadata: {
  //        lastSignInTime: "Fri, 24 Mar 2023 12:18:06 GMT",
  //        creationTime: "Fri, 24 Mar 2023 07:46:50 GMT",
  //      },
  //      uid: "uLrRuspQfLZ0WiJwujghtOVI6qV2",
  //      disabled: false,
  //    },
  //  ]);
  // }, []);
  useEffect(() => {
    const getUsersWithSameCompany = httpsCallable(firebasefunctions, "getUsersWithSameCompany");

    getUsersWithSameCompany()
      .then((result) => {
        setUsers(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };
  const handelChanageUserEmailDailogOpen = (params) => {
    setUserSelectedUid(params.row.id);
    setchangeEmailDialogOpen(true);
    setUserNewEmail("");
  };

  const handleAddUserDialogClose = () => {
    setAddUserEmail("");
    setPassword("");
    setAddUserDialogOpen(false);
    setErrorMessage("");
  };
  const handleChangeEmailDialogClose = () => {
    setchangeEmailDialogOpen(false);
    setUserSelectedUid(null);
    setErrorMessage("");
  };

  const handleAddUser = () => {
    // Get a reference to the 'addUser' function

    const addUser = httpsCallable(firebasefunctions, "addUser");
    // Call the 'addUser' function with the email, password, and role
    addUser({ FirstName, LastName, email: addUserEmail, password, role })
      .then((result) => {
        // Handle the result of the function call
        const newUser = {
          id: users.length + 1,
          email: addUserEmail,
          disabled: false,
          role,
          uid: result.data.message,
        };
        setUsers([...users, newUser]);
        handleAddUserDialogClose();
        console.log(result.data);
      })
      .catch((error) => {
        // Display the custom error message to the user
        console.log(error.message);
        setErrorMessage(error.message);
      });
  };
  const handleEmailChangeUser = () => {
    try {
      const updateUserEmail = httpsCallable(firebasefunctions, "updateUserEmail");
      const UserUuid = users.find((user) => user.id === userSelectedUid);
      console.log(UserUuid.uid);
      // Call the function with the user ID and new email.
      updateUserEmail({ uid: UserUuid.uid, newEmail: UserNewEmail })
        .then((result) => {
          const updatedUsers = users.map((user) =>
            user.id === userSelectedUid ? { ...user, email: UserNewEmail } : user
          );
          setUsers(updatedUsers);
          handleChangeEmailDialogClose();
        })
        .catch((error) => {
          setErrorMessage(error.message);
          console.error(error); // Log any errors that occur.
        });
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  const handleResetPasswordDialogOpen = (params) => {
    setResetPasswordUid(params.row.id);
    setResetPasswordDialogOpen(true);
  };

  const handleResetPasswordDialogClose = () => {
    setResetPasswordUid(null);
    setResetPasswordDialogOpen(false);
    setErrorMessage("");
  };

  const handleResetPassword = () => {
    // TODO: Implement password reset logic here
    const UserEmail = users.find((user) => user.id === resetPasswordUid);

    sendPasswordResetEmail(auth, UserEmail.email)
      .then(() => {
        console.log("updated");
        handleResetPasswordDialogClose();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.log(error);
      });
  };
  const handleEnableDisableUser = (uid) => {
    try {
      const toggleUserDisabledStatus = httpsCallable(firebasefunctions, "toggleUserDisabledStatus");

      // Call the function with a uid parameter.
      toggleUserDisabledStatus({ uid })
        .then((result) => {
          console.log(result.data);
          return true;
        })
        .catch((error) => {
          // Handle errors.
          console.error(error);
          return false;
        });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  const handleDisableUserDialogOpen = (params) => {
    setDisableUserUid(params.row.id);
    setDisableUserDialogOpen(true);
  };
  const handleEnableUserDialogOpen = (params) => {
    setenableUserUid(params.row.id);
    setenableUserDialogOpen(true);
  };

  const handleDisableUserDialogClose = () => {
    setDisableUserUid(null);
    setDisableUserDialogOpen(false);
    setErrorMessage("");
  };
  const handleEnableUserDialogClose = () => {
    setenableUserUid(null);
    setenableUserDialogOpen(false);
    setErrorMessage("");
  };
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setUserNewEmail(email);

    // Simple email validation using regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(regex.test(email));
  };

  const handleDisableUser = () => {
    const UserUuid = users.find((user) => user.id === disableUserUid);

    handleEnableDisableUser(UserUuid.uid);
    const updatedUsers = users.map((user) =>
      user.id === disableUserUid ? { ...user, disabled: !user.disabled } : user
    );
    setUsers(updatedUsers);
    handleDisableUserDialogClose();
  };

  const handleEnableUser = () => {
    const UserUuid = users.find((user) => user.id === enableUserUid);

    const status = handleEnableDisableUser(UserUuid.uid);
    console.log(status);
    const updatedUsers = users.map((user) =>
      user.id === enableUserUid ? { ...user, disabled: !user.disabled } : user
    );
    setUsers(updatedUsers);
    handleEnableUserDialogClose();
  };
  const columns = [
    { field: "id", headerName: "SNo", width: 70 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "role",
      headerName: "Role",
      width: 100,
    },
    {
      field: "uid",
      headerName: "Ref ID",
      width: 250,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "1px",
              display: "none",
            },
            "&::-webkit-scrollbar-thumb": {
              display: "none",
            },
          }}
        >
          {params.value}
        </Box>
      ),
    },

    {
      field: "disabled",
      headerName: "Disabled",
      width: 120,
      renderCell: (params) =>
        params.row.disabled ? (
          <Tooltip title="Disabled">
            <LockIcon onClick={() => handleEnableUserDialogOpen(params)} />
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <LockOpenIcon onClick={() => handleDisableUserDialogOpen(params)} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,

      renderCell: (params) => (
        <>
          <IconButton onClick={() => handelChanageUserEmailDailogOpen(params)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleResetPasswordDialogOpen(params)}>
            <KeyIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div style={{ height: 500, width: "100%" }}>
      <Button variant="contained" color="info" onClick={handleAddUserDialogOpen}>
        Add User
      </Button>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={10}
        disableRowSelectionOnClick
        disableSelectionOnClick
        sx={{
          boxShadow: 2,
          "& .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
      />

      <Dialog open={addUserDialogOpen} onClose={handleAddUserDialogClose}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <Typography variant="span" color="error" component="h5">
            {errorMessage}
          </Typography>

          <TextField
            autoFocus
            margin="dense"
            id="FirstName"
            label="First Name"
            fullWidth
            value={FirstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="LastName"
            label="Last Name"
            fullWidth
            value={LastName}
            onChange={(event) => setLastName(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={addUserEmail}
            onChange={(event) => setAddUserEmail(event.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            value={password}
            type="password"
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
          />
          <InputLabel id="userRoleLable">Role</InputLabel>
          <Select
            sx={{ height: "50px" }}
            labelId="userRoleLable"
            fullWidth
            id="userRole"
            value={role}
            onChange={(event) => {
              setRole(event.target.value);
            }}
            defaultValue="Inspector"
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Editor">Editor</MenuItem>
            <MenuItem value="Inspector" selected>
              Inspector
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddUserDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            disabled={!addUserEmail || !password || !role}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={changeEmailDialogOpen} onClose={handleChangeEmailDialogClose}>
        <DialogTitle>Change User Email {UserNewEmail}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter new email for the user you want to add</DialogContentText>
          <Typography variant="span" color="error" component="h5">
            {errorMessage}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeEmailDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEmailChangeUser} disabled={!isValidEmail} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <ActionDailog
        open={resetPasswordDialogOpen}
        closeFunction={handleResetPasswordDialogClose}
        title="Reset Password"
        text="Are you sure you want to reset the password for this user?"
        actionFunction={handleResetPassword}
        ButtonText="reset Password"
      />
      <ActionDailog
        open={enableUserDialogOpen}
        closeFunction={handleEnableUserDialogClose}
        title="Enable User"
        text="Are you sure you want to Enable this user?"
        actionFunction={handleEnableUser}
        ButtonText="Enable User"
      />
      <ActionDailog
        open={disableUserDialogOpen}
        closeFunction={handleDisableUserDialogClose}
        title="Disable User"
        text="Are you sure you want to disable this user?"
        actionFunction={handleDisableUser}
        ButtonText=" Disable User"
      />
    </div>
  );
}

export default UsersTable;
