/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "@mui/material";
// import { useFormikContext } from "formik";

function ButtonWrapper({ children, ...otherProps }) {
  // const { submitForm } = useFormikContext();

  // const handleSubmit = () => {
  //  submitForm();
  // };
  const configButton = {
    variant: "contained",
    color: "primary",
    fullWidth: true,
    type: "submit",
    ...otherProps,
  };

  return <Button {...configButton}>{children}</Button>;
}

export default ButtonWrapper;
