/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import closeModalIcon from "../../../../assets/images/closeModalIcon.svg";

function ChangePasswordModal({ openChangePassword, setOpenChangePassword }) {
  return (
    <ChangePassModal open={openChangePassword} onClose={() => setOpenChangePassword(false)}>
      <DialogHeader>
        <DialogTitle>Change Password</DialogTitle>
        <img
          style={{ marginRight: "20px", cursor: "pointer" }}
          onClick={() => setOpenChangePassword(false)}
          src={closeModalIcon}
          alt="_close"
        />
      </DialogHeader>
      <DialogContent style={{ padding: "24px", paddingTop: "0" }}>
        <ul style={{ padding: "0 24px" }}>
          <Li>Do not reuse any of your previous passwords as your new password.</Li>
          <Li>Password must be a minimum of 8 characters in length.</Li>
          <Li>Include at least 1 symbol and 1 capital letter in your password.</Li>
        </ul>
        <Fields>
          <FieldContainer>
            <Label>Old Password</Label>
            <InputField name="oldPassowrd" placeholder="Old Password" />
          </FieldContainer>
          <FieldContainer>
            <Label>New Password</Label>
            <InputField name="newPassword" placeholder="New Password" />
          </FieldContainer>
          <FieldContainer>
            <Label>Confirm New Password</Label>
            <InputField name="confiemNewPassword" placeholder="Confirm New Password" />
          </FieldContainer>
        </Fields>
      </DialogContent>
      <DialogBtnsDiv>
        <RandomButton
          sx={{
            width: "100%",
            textAlgn: "center",
            color: "#344054 !important",
            border: "1px solid #D0D5DD",
            marginLeft: "18px",
          }}
          purple="false"
          variant="contained"
          color="primary"
          onClick={() => setOpenChangePassword(false)}
        >
          Cancel
        </RandomButton>
        <RandomButton
          sx={{ width: "100%", textAlgn: "center" }}
          purple="true"
          variant="contained"
          color="primary"
          onClick={() => setOpenChangePassword(false)}
        >
          Save
        </RandomButton>
      </DialogBtnsDiv>
    </ChangePassModal>
  );
}
export default ChangePasswordModal;

const ChangePassModal = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "35%",
  },
});
const DialogHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "8px 18px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: "600",
  fontSize: dialogBtns === "yes" ? "16px" : "",
  height: "fit-content",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  },
}));
const Li = styled("li")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
  padding: "8px",
});
const FieldContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
});
const InputField = styled(TextField)(({ nextBtnClicked, isEmpty }) => ({
  background: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired hover border color
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired focused border color
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    border: isEmpty && nextBtnClicked ? "1px solid red" : "1px solid #D0D5DD", // Replace with your desired clicked border color
  },
}));
const Label = styled("p")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
});
const Fields = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  marginTop: "24px",
});
