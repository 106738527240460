/* eslint-disable import/no-unresolved */
import Dashboard from "layouts/dashboard";
import AddNewCompany from "layouts/AddNewCompany";
import AddNewSuperAdmin from "layouts/AddNewSuperAdmin";
import SuperAdminCheck from "components/AuthCheckPoints/SuperAdminCheck";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import ProjectWorkSpace from "layouts/ProjectWorkSpace";
import Notifications from "layouts/notifications";

// import logout from "layouts/authentication/sign-in";
import Logout from "layouts/authentication/log-out";

// @mui icons
import Icon from "@mui/material/Icon";

const SuperAdminRoutes = [
  {
    type: "collapse",
    name: "",
    key: "projectWorkspace",
    route: "/projectworkspace/:id",
    component: (
      <SuperAdminCheck>
        <ProjectWorkSpace />
      </SuperAdminCheck>
    ),
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Add New Company",
    key: "Company",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/AddNewCompany",
    component: (
      <SuperAdminCheck>
        <AddNewCompany />
      </SuperAdminCheck>
    ),
  },
  {
    type: "collapse",
    name: "Add Super Admin",
    key: "superAdmin",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/AddSuperAdmin",
    component: (
      <SuperAdminCheck>
        <AddNewSuperAdmin />
      </SuperAdminCheck>
    ),
  },

  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: (
      <SuperAdminCheck>
        <Tables />
      </SuperAdminCheck>
    ),
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: (
      <SuperAdminCheck>
        <Billing />
      </SuperAdminCheck>
    ),
  },

  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: (
      <SuperAdminCheck>
        <Notifications />
      </SuperAdminCheck>
    ),
  },

  {
    type: "collapse",
    name: "Log Out",
    key: "Log-out",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/Log-out",
    component: (
      <SuperAdminCheck>
        <Logout />
      </SuperAdminCheck>
    ),
  },
];

export default SuperAdminRoutes;
