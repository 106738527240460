/* eslint-disable import/no-unresolved */
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import DashboardNavbar from "WebItems/Navbars/DashboardNavbar";
import CheckoutPage from "layouts/MaterialStepFormLayout/components/CheckoutPage";
import MaterialStepFormLayout from "layouts/MaterialStepFormLayout/MaterialStepFormLayout";

import React from "react";

function index() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MaterialStepFormLayout>
        <CheckoutPage />
      </MaterialStepFormLayout>
    </DashboardLayout>
  );
}

export default index;
