/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PreviewContent from "../PreviewContent";

function PreviewModal({
  openPreviewModal,
  handleCancelPreviewButton,
  InspectionNoteData,
  pdfData,
  setOpenPreviewModal,
}) {
  return (
    <StyledPreviewModal open={openPreviewModal} onClose={handleCancelPreviewButton}>
      <DialogTitle>Preview Inspection Notes</DialogTitle>
      <DialogContent>
        <PreviewContent
          InspectionNoteData={InspectionNoteData}
          pdfData={pdfData}
          setOpenPreviewModal={setOpenPreviewModal}
        />
      </DialogContent>
    </StyledPreviewModal>
  );
}

export default PreviewModal;

const StyledPreviewModal = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "900px",
  },
});
