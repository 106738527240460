/* eslint-disable no-unused-vars */

/* eslint-disable no-shadow */
/* eslint-disable no-console */

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Images
import { useState } from "react";
import { auth, firebasefunctions } from "FirebaseConfi";
import { Box, Typography } from "@mui/material";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import handleError from "./handleError";

import logoImage from "../../../assets/images/logo-ct.png";

function RegisterUser() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [refCode, setRefCode] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  async function callCloudFunctionToAddCustomClaims(userId) {
    // You may need to make a request to a Cloud Function endpoint or use another method
    // to invoke your Cloud Function that adds custom claims.
    // The Cloud Function should be similar to the one I provided in the previous responses.

    // Example using fetch to call a Cloud Function endpoint (adjust as needed):
    const myFunction = httpsCallable(firebasefunctions, "addCustomClaims");

    try {
      const response = await myFunction();
      console.log("hellow");
    } catch (error) {
      console.error("Error calling Firebase function:", error);
    }
    // await httpsCallable(firebasefunctions, "addCustomClaims")
    //   .then(() => {
    //     console.log("function called ");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // fetch('YOUR_CLOUD_FUNCTION_ENDPOINT', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ userId }),
    // })
    // .then(response => response.json())
    // .then(data => {
    //   console.log('Custom claims added successfully:', data);
    // })
    // .catch(error => {
    //   console.error('Error adding custom claims:', error);
    // });
  }

  const handleSignUp = async (email, password, fullName, refCode) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      callCloudFunctionToAddCustomClaims();

      // Update user profile
      await updateProfile(userCredential.user, {
        displayName: fullName,
      });

      await sendEmailVerification(userCredential.user)
        .then(() => {
          navigate("/authentication/verify-email");
        })
        .catch((error) => {
          console.log(error);
          toast("Unable to send link");
        });
    } catch (error) {
      handleError(error);
      console.error(error);
      throw error;
    }
  };

  return (
    <BasicLayout>
      <ToastContainer />
      <Card sx={{ borderRadius: "15px" }}>
        <Box pt={2} pb={3} px={3}>
          <Box display="flex" justifyContent="center" m={1}>
            <img src={logoImage} alt="Logo" height={60} />
          </Box>
          <Typography variant="h5" textAlign="center" my={3}>
            Register
          </Typography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="string"
                label="FullName"
                fullWidth
                onChange={(event) => setFullName(event.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                required
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                required
                onChange={(event) => setPassword(event.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label="Referral Code"
                fullWidth
                required
                onChange={(event) => setPassword(event.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  handleSignUp(email, password, fullName, refCode);
                }}
              >
                Register
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Have Account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </Box>
      </Card>
    </BasicLayout>
  );
}

export default RegisterUser;
