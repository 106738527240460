/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { auth } from "FirebaseConfi";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";

function Cover() {
  const handelYes = () => {
    signOut(auth)
      .then(() => {
        console.log("logOut successfull");
      })
      .catch(() => {
        alert("Unable to log out");
      });
  };

  return (
    <CoverLayout coverHeight="100vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Log out
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Are you sure?
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDButton variant="gradient" onClick={handelYes} color="error" fullWidth>
            Yes
          </MDButton>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Link to="/Dashboard">
            <MDButton variant="gradient" color="info" fullWidth>
              No
            </MDButton>
          </Link>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
