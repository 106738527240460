/* eslint-disable prefer-destructuring */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
//import List from "@mui/material/List";
//import Divider from "@mui/material/Divider";
//import Link from "@mui/material/Link";
//import InputAdornment from "@mui/material/InputAdornment";
//import SearchIcon from "@mui/icons-material/Search";
//import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
//import AddCircleIcon from "@mui/icons-material/AddCircle";

//// Material Dashboard 2 React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";
////import MDButton from "components/MDButton";

//// Material Dashboard 2 React example components
//import SidenavCollapse from "WebItems/Sidenav/SidenavCollapse";

//// Custom styles for the Sidenav
//import SidenavRoot from "WebItems/Sidenav/SidenavRoot";
//import sidenavLogoLabel from "WebItems/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

//import Box from "@mui/material/Box";
//import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
//import PersonIcon from "@mui/icons-material/Person";
//import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
//import NewspaperIcon from "@mui/icons-material/Newspaper";
//import LanguageIcon from "@mui/icons-material/Language";
//import SettingsIcon from "@mui/icons-material/Settings";
//import PeopleIcon from "@mui/icons-material/People";
//import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
//import PaymentIcon from "@mui/icons-material/Payment";
//import GridViewIcon from "@mui/icons-material/GridView";
import { styled } from "@mui/material/styles";
//import TextField from "@mui/material/TextField";
//import Button from "@mui/material/Button";
//import ListItem from "@mui/material/ListItem";
//import ListItemButton from "@mui/material/ListItemButton";
//import ListItemIcon from "@mui/material/ListItemIcon";
//import ListItemText from "@mui/material/ListItemText";
//import NotificationsIcon from "@mui/icons-material/Notifications";
//import HelpIcon from "@mui/icons-material/Help";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { userTypeController, setUserTypeState, setUserCompanyUIDState } from "context/userInfo";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "FirebaseConfi";
//import UserProfile from "../../layouts/userProfile";
//import CompanySettings from "../../layouts/AdminLayouts/CompanySettings";
//import MembersLayout from "../../layouts/AdminLayouts/MembersLayout";
//import Notifications from "../../layouts/NotificationLayout";
//import Certificates from "../../layouts/CertificatesLayout";
import Logo from "../../assets/images/logo.svg";
//import logo from "../../assets/images/vi-logo.png";
//import logo2 from "../../assets/images/vi-logo-2.png";
//import via from "../../assets/images/VIA.png";
import expandIcon from "../../assets/images/expand-icon.png";
import collapseIcon from "../../assets/images/collapse-icon.png";

//import plusIcon from "../../assets/images/plus-icon.png";
//import userIcon from "../../assets/images/user-icon.png";
//import notification from "../../assets/images/notification.png";
//import help from "../../assets/images/help.png";
//import settingIcon from "../../assets/images/setting-icon.png";
import billingIcon from "../../assets/images/billing-icon.svg";

import sidebarSettingsIcon from "../../assets/images/sidebar-settings-icon.svg";
import logoutIcon from "../../assets/images/logout-icon.svg";
import CrossIcon from "../../assets/images/white-cross-icon.svg";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const history = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  //const collapseName = location.pathname.replace("/", "");
  //const [openModal, setOpenModal] = useState(false);
  //const [activeItem, setActiveItem] = useState("userprofile");
  //const [bottomActiveItem, setBottomActiveItem] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [mobileExpand, setMobileExpand] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [userInformation, setUserInformation] = useState({});
  const handleMiniSidenav2 = () => setMiniSidenav(dispatch, !miniSidenav);
  const [userInfo, dispatchUser] = userTypeController();
  //const { userType } = userInfo;

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/newproject") {
      setActiveTab("newproject");
    } else if (pathname === "/dashboard") {
      setActiveTab("dashboard");
    } else if (pathname === "/projectGallery") {
      setActiveTab("projectGallery");
    } else if (pathname.startsWith("/projectGallery/viewProject")) {
      setActiveTab("projectGallery");
    } else if (pathname === "/textlibraries") {
      setActiveTab("textlibraries");
    } else if (pathname === "/settings") {
      setActiveTab("settings");
    } else {
      setActiveTab("dashboard");
    }
  }, [location.pathname]);
  function check() {
    auth.currentUser.getIdTokenResult().then((tokenResult) => {
      if (tokenResult.claims) {
        console.log(tokenResult.claims);
      }
    });
  }

  useEffect(() => {
    const previousPath = localStorage.getItem("previousPath");
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserInformation(user);
        check();
        auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
          setUserTypeState(dispatchUser, idTokenResult.claims.role);
          console.log("@@@@@@@@@@@@@@@@@@@@", idTokenResult.claims.role);

          setUserCompanyUIDState(dispatchUser, idTokenResult.claims.company);
          // eslint-disable-next-line no-unused-expressions
          previousPath ? history(previousPath) : history("/");
        });
      } else {
        history("authentication/sign-in");
        setUserTypeState(dispatchUser, "Anymous");
      }
    });
  }, []);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    handleMiniSidenav2();
  };

  // let textColor = "white";

  // if (transparentSidenav || (whiteSidenav && !darkMode)) {
  //   textColor = "dark";
  // } else if (whiteSidenav && darkMode) {
  //   textColor = "inherit";
  // }

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(false);
      setMiniSidenav(dispatch, false);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   // A function that sets the mini state of the sidenav.
  //   function handleMiniSidenav() {
  //     // setMiniSidenav(dispatch, window.innerWidth < 1200);
  //     setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
  //     setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
  //   }

  //   /**
  //    The event listener that's calling the handleMiniSidenav function when resizing the window.
  //   */
  //   window.addEventListener("resize", handleMiniSidenav);

  //   // Call the handleMiniSidenav function to set the state with the initial value.
  //   handleMiniSidenav();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleMiniSidenav);
  // }, [dispatch, location]);
  function stringAvatar(name) {
    let initials = "";
    if (name) {
      const nameParts = name.split(" ");
      if (nameParts.length >= 1) {
        initials += nameParts[0][0]; // First name initial
      }
      if (nameParts.length >= 2) {
        initials += nameParts[1][0]; // Last name initial
      }
      return { children: initials };
    }
    //if (name) {
    //  const CapName = name.toUpperCase();
    //  return {
    //    sx: {
    //      bgcolor: deepPurple[500],
    //      fontSize: "15px",
    //    },
    //    children: `${CapName?.split(" ")[0][0] ? "ds" : "ds"}${
    //      CapName?.split(" ")[1][0] ? "dss" : "dss"
    //    }`,
    //  };
    //}
    return {
      sx: {
        bgcolor: deepPurple[500],
        fontSize: "15px",
      },
      children: `VI`,
    };
  }
  // Settings Modal Routes
  // const settingRoutes = [
  //   {
  //     key: "userprofile",
  //     route: "/userprofile",
  //     name: "User Profile",
  //     icon: <PersonIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "notifications",
  //     route: "/notifications",
  //     name: "Notifications",
  //     icon: <NotificationsActiveIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "certificates",
  //     route: "/certificates",
  //     name: "Certificates",
  //     icon: <NewspaperIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "language",
  //     route: "/language",
  //     name: "Language",
  //     icon: <LanguageIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  // ];
  // eslint-disable-next-line no-unused-vars
  // const adminSettingRoutes = [
  //   {
  //     key: "settings",
  //     route: "/settings",
  //     name: "Settings",
  //     icon: <SettingsIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "members",
  //     route: "/members",
  //     name: "Members",
  //     icon: <PeopleIcon style={{ marginBottom: "-4px" }} />,
  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "upgrade",
  //     route: "/upgrade",
  //     name: "Upgrade",
  //     icon: <ArrowUpwardIcon style={{ marginBottom: "-4px" }} />,

  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "billing",
  //     route: "/billing",
  //     name: "Billing",
  //     icon: <PaymentIcon style={{ marginBottom: "-4px" }} />,

  //     // component: <ProjectWorkSpace />,
  //   },
  //   {
  //     key: "connections",
  //     route: "/connections",
  //     name: "Connections",
  //     icon: <GridViewIcon style={{ marginBottom: "-4px" }} />,

  //     // component: <ProjectWorkSpace />,
  //   },
  // ];

  // const bottomListItems = [
  //   { icon: <PersonIcon />, name: "Profile" },
  //   { icon: <NotificationsIcon />, name: "Notifications" },
  //   { icon: <HelpIcon />, name: "Help" },
  // ];

  // const bottomItemClicked = (value) => {
  //   setBottomActiveItem(value);
  // };
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  // const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
  //   let returnValue;

  //   if (type === "collapse") {
  //     // eslint-disable-next-line no-nested-ternary
  //     returnValue = href ? (
  //       <Link
  //         href={href}
  //         key={key}
  //         target="_blank"
  //         rel="noreferrer"
  //         sx={{ textDecoration: "none" }}
  //       >
  //         <SidenavCollapse
  //           name={name}
  //           icon={icon}
  //           active={key === collapseName}
  //           noCollapse={noCollapse}
  //         />
  //       </Link>
  //     ) : (
  //       //  key === "settings" ? (
  //       //   <SidenavCollapse
  //       //     name={name}
  //       //     icon={icon}
  //       //     active={key === collapseName}
  //       //     onClick={() => {
  //       //       setOpenModal(true);
  //       //     }}
  //       //   />
  //       // ) :
  //       <NavLink key={key} to={route}>
  //         <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
  //       </NavLink>
  //     );
  //   } else if (type === "title") {
  //     returnValue = (
  //       <MDTypography
  //         key={key}
  //         color={textColor}
  //         display="block"
  //         variant="caption"
  //         fontWeight="bold"
  //         textTransform="uppercase"
  //         pl={3}
  //         mt={2}
  //         mb={1}
  //         ml={1}
  //       >
  //         {title}
  //       </MDTypography>
  //     );
  //   } else if (type === "divider") {
  //     returnValue = (
  //       <Divider
  //         key={key}
  //         light={
  //           (!darkMode && !whiteSidenav && !transparentSidenav) ||
  //           (darkMode && !transparentSidenav && whiteSidenav)
  //         }
  //       />
  //     );
  //   }

  //   return returnValue;
  // });

  return (
    <>
      <MobileTopNav
        mobileExpand={mobileExpand}
        style={{ marginTop: mobileExpand ? "-30px" : "0px" }}
      >
        <MenuIcon
          style={{ color: "white" }}
          onClick={() => {
            setMobileExpand(true);
          }}
        />
      </MobileTopNav>

      {/* <StyledNav
        collapsed={collapsed}
        mobileExpand={mobileExpand}
        // {...rest}
        // ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <StickyDiv>
          <CustomMobileNavCloseIcon
            onClick={() => {
              setMobileExpand(false);
            }}
          />
          <MDBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            sx={{ padding: collapsed ? "8px" : "8px 16px" }}
          >
            {collapsed ? (
              <img src={logo2} width="100%" alt="logo" style={{ padding: "0px" }} />
            ) : (
              <img src={logo} width="100%" alt="logo" style={{ padding: "0px" }} />
            )}
          </MDBox>
          <StyledHr />
        </StickyDiv>
        <div style={{ flexGrow: "1" }}>
          <br />

          <ListDiv>
            <CustomList collapsed={collapsed}>
              <NavLink key="createpanoramaproject" to="/newproject">
                {" "}
                {collapsed ? (
                  <SearchDiv>
                    <PlusIcon2 src={plusIcon} alt="add" />
                  </SearchDiv>
                ) : (
                  <ProjectButton variant="contained" size="large">
                    <PlusIcon src={plusIcon} alt="add" />
                    New Project
                  </ProjectButton>
                )}
              </NavLink>
              {renderRoutes}
            </CustomList>
          </ListDiv>
          <StyledHr />
          <div> */}
      {/* {collapsed ? (
              <BottomIconsDiv>
                <CustomBottomIcon
                  onClick={() => bottomItemClicked("Profile")}
                  bottomactiveitem={bottomActiveItem}
                  src={userIcon}
                  alt="profile"
                />
                <CustomBottomIcon
                  onClick={() => bottomItemClicked("Notifications")}
                  bottomactiveitem={bottomActiveItem}
                  src={notification}
                  alt="notification"
                />
                <CustomBottomIcon
                  src={help}
                  onClick={() => bottomItemClicked("Help")}
                  bottomactiveitem={bottomActiveItem}
                  alt="help"
                />
              </BottomIconsDiv>
            ) : (
              <List>
                {bottomListItems.map((item) => (
                  <StyledListItem
                    name={item.name}
                    bottomactiveitem={bottomActiveItem}
                    onClick={() => bottomItemClicked(item.name)}
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ marginLeft: "20px", color: "white !important" }}>
                        {item.icon}
                      </ListItemIcon>
                      <StyledListItemText primary={item.name} />
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </List>
            )} */}
      {/* <ViaSettings collapsed={collapsed}>
              <img src={via} alt="via" width="40px" style={{ cursor: "pointer" }} />
              {!collapsed && (
                <>
                  <span>VIA Customers</span>
                  <img
                    src={settingIcon}
                    alt="settings"
                    style={{ cursor: "pointer", width: "22px" }}
                  />
                </>
              )}
            </ViaSettings>
          </div>
        </div>
      </StyledNav> */}
      {/* <CustomCollapseIcon
        src={collapsed ? expandIcon : collapseIcon}
        alt="collapsed"
        collapsed={collapsed}
        onClick={handleCollapse}
      /> */}
      {/* <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            padding: "0px",
            overflowY: "scroll",
          }}
        >
          <div style={{ display: "flex" }}>
            <StyledUl>
              {settingRoutes.map((item) => (
                <StyledLi
                  onClick={() => setActiveItem(item.key)}
                  itemKey={item.key}
                  activeItem={activeItem}
                >
                  <span style={{ marginRight: "8px", marginBottom: "-4px" }}>{item.icon}</span>
                  <span style={{ fontSize: "smaller" }}>{item.name}</span>
                </StyledLi>
              ))}
              {userType === "Admin" && (
                <>
                  <Workspace>Workspace</Workspace>
                  <Workspace2>WS</Workspace2>
                  {adminSettingRoutes.map((item) => (
                    <StyledLi
                      onClick={() => setActiveItem(item.key)}
                      itemKey={item.key}
                      activeItem={activeItem}
                    >
                      <span style={{ marginRight: "8px", marginBottom: "-4px" }}>{item.icon}</span>
                      <span style={{ fontSize: "smaller" }}>{item.name}</span>
                    </StyledLi>
                  ))}
                </>
              )}
            </StyledUl>
            <div
              style={{
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "30px",
                paddingBottom: "0px",
                width: "-webkit-fill-available",
              }}
            >
              {activeItem === "userprofile" && <UserProfile />}
              {activeItem === "notifications" && <Notifications />}
              {activeItem === "certificates" && <Certificates />}
              {activeItem === "settings" && <CompanySettings />}
              {activeItem === "members" && <MembersLayout />}
            </div>
          </div>
        </Box>
      </Modal> */}
      <Sidebar collapsed={collapsed} mobileExpand={mobileExpand}>
        <LogoDiv>
          <LogoImg>
            <img width={`${collapsed ? "50px" : ""}`} src={Logo} alt="_logo" />
          </LogoImg>
          <div style={{ width: "10%" }}>
            <CustomMobileNavCloseIcon
              src={CrossIcon}
              alt="_close"
              onClick={() => {
                setMobileExpand(false);
              }}
            />
          </div>
        </LogoDiv>
        <RoutesDiv>
          <ListItems>
            {routes?.map((route) =>
              route?.type === "collapse" ? (
                <StyledNavLink
                  collapsed={collapsed}
                  onClick={() => setActiveTab(route.key)}
                  activeTab={route?.key === activeTab}
                  to={route?.route}
                >
                  {route?.icon}
                  {!collapsed && route?.name}
                </StyledNavLink>
              ) : null
            )}
          </ListItems>
          <div>
            <ListItems>
              {/* <StyledNavLink
                onClick={() => setActiveTab("billing")}
                activeTab={activeTab === "billing"}
                collapsed={collapsed}
                to="/settings"
              >
                <img width="20px" src={billingIcon} alt="_billing" />
                {!collapsed && <p>Billing</p>}
              </StyledNavLink> */}
              <StyledNavLink
                onClick={() => setActiveTab("settings")}
                activeTab={activeTab === "settings"}
                collapsed={collapsed}
                to="/settings"
              >
                <img width="20px" src={sidebarSettingsIcon} alt="_settings" />
                {!collapsed && <p>Settings</p>}
              </StyledNavLink>
            </ListItems>
            <Hr />
            <LogoutDiv collapsed={collapsed}>
              {!collapsed && (
                <UserInfo>
                  <Avatar
                    sx={{ bgcolor: deepPurple[500] }}
                    {...stringAvatar(userInformation?.displayName)}
                  />
                  {/*<Avatar alt={userInformation?.displayName} src={userInformation?.photoURL} />*/}
                  <Info>
                    <IntoText type="name">{userInformation?.displayName}</IntoText>
                    <IntoText type="email">{userInformation?.email}</IntoText>
                  </Info>
                </UserInfo>
              )}
              <NavLink to="/authentication/log-out">
                <img style={{ cursor: "pointer" }} width="20px" src={logoutIcon} alt="_logout" />
              </NavLink>
            </LogoutDiv>
          </div>
        </RoutesDiv>
      </Sidebar>
      <CustomCollapseIcon
        className="collapsed"
        src={collapsed ? expandIcon : collapseIcon}
        alt="collapsed"
        collapsed={collapsed}
        onClick={handleCollapse}
      />
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "rgba(0, 67, 206, 0.36)",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "rgba(0, 67, 206, 0.36)",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

const Sidebar = styled("div")(({ collapsed, mobileExpand }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  background: "#101828",
  position: "fixed",
  padding: collapsed ? "0px 6px 32px 6px" : "0px 16px 32px 16px",
  maxHeight: "100%",
  overflowY: "auto",
  width: collapsed ? "70px" : "264px",
  "@media (min-width: 0px) and (max-width: 767px)": {
    zIndex: "9",
    left: mobileExpand ? "0px" : "-264px",
    transition: "left ease 0.3s",
  },
  height: "-webkit-fill-available",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#53389E",
    borderRadius: "3px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
}));
const LogoDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  paddingTop: "24px",
  position: "sticky",
  top: "0",
  background: "#101828",
  paddingBottom: "10px",
});
const LogoImg = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (min-width: 0px) and (max-width: 767px)": {
    width: "90%",
    marginLeft: "20px",
  },
});
const RoutesDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "105px",
  paddingTop: "24px",
  width: "100%",
  height: "-webkit-fill-available",
  justifyContent: "space-between",
});
const ListItems = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const StyledNavLink = styled(NavLink)(({ collapsed, activeTab }) => ({
  display: "flex",
  padding: collapsed ? "12px" : "8px 12px",
  alignItems: "center",
  gap: "15px",
  alignSelf: "stretch",
  justifyContent: collapsed ? "center" : "",
  color: "#F2F4F7",
  fontSize: "14px",
  fontWeight: "400",
  letterSpacing: "0.5px",
  borderRadius: "8px",
  background: activeTab ? "#53389E" : "transparent",
  cursor: "pointer",
  "&:hover": {
    background: activeTab ? "#53389E" : "#344054",
  },
}));
const Hr = styled("hr")({
  width: "100%",
  borderColor: "#475467",
  marginTop: "20px",
  marginBottom: "20px",
});
const LogoutDiv = styled("div")(({ collapsed }) => ({
  display: "flex",
  width: "100%",
  justifyContent: collapsed ? "center" : "space-between",
  alignItems: "center",
}));
const UserInfo = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});
const Info = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const IntoText = styled("p")(({ type }) => ({
  color: "#FFF",
  fontSize: type === "name" ? "14px" : "12px",
  fontWeight: type === "name" ? "500" : "400",
  maxWidth: "150px",
  overflow: "hidden",
}));
// const StyledLi = styled("li")((props) => ({
//   background: props.activeItem === props.itemKey ? "rgba(0, 67, 206, 0.36)" : "",
//   padding: "2px 20px",
//   width: "auto",
//   borderRadius: "8px",
//   marginBottom: "10px",
//   cursor: "pointer",
//   "@media (min-width: 0px) and (max-width: 843px)": {
//     padding: "2px 10px",
//     "& span:nth-child(2)": {
//       display: "none",
//     },
//     "& span:first-child": {
//       width: "fit-content",
//       marginRight: "0px !important",
//       fontSize: "24px",
//     },
//   },
//   "@media (min-width: 844px) and (max-width: 1023px)": {
//     padding: "2px 10px",
//     "& span:nth-child(2)": {
//       fontSize: "14px !important",
//     },
//   },
// }));
// const StyledUl = styled("ul")({
//   background: "#344767",
//   color: "white",
//   padding: "50px 20px",
//   listStyle: "none",
//   width: "28%",
//   "@media (min-width: 0px) and (max-width: 843px)": {
//     width: "revert",
//   },
// });

// const StyledNav = styled("div")(({ collapsed, mobileExpand }) => ({
//   background: "#191B1F",
//   position: "fixed",
//   top: "0",
//   height: "-webkit-fill-available;",
//   display: "flex",
//   flexDirection: "column",
//   overflowY: "auto",
//   transition: "width 0.3s ease",
//   zIndex: "9",
//   width: collapsed ? "70px" : "265px",
//   overflowX: collapsed ? "hidden" : "",
//   "@media (min-width: 0px) and (max-width: 767px)": {
//     left: mobileExpand ? "0px" : "-265px",
//     transition: "left ease 0.3s",
//   },
//   "&::-webkit-scrollbar": {
//     width: 6,
//   },

//   /* Thin scrollbar thumb */
//   "&::-webkit-scrollbar-thumb": {
//     backgroundColor: "#888888",
//     borderRadius: 3,
//   },

//   /* Transparent scrollbar track on hover */
//   "&:hover::-webkit-scrollbar-track": {
//     backgroundColor: "transparent",
//   },
// }));

// const StyledHr = styled("hr")({
//   margin: "0px 16px",
//   border: "#333333 solid 1px",
// });
// const CustomTextField = styled(TextField)({
//   padding: "8px 16px",
//   borderRadius: "4px",
//   "& .MuiInputBase-input::placeholder": {
//     fontFamily: "Poppins",
//     fontSize: "14px",
//     color: "rgba(255, 255, 255, 0.48)",
//   },
//   "& .MuiOutlinedInput-root": {
//     backgroundColor: "#333333",
//     "& fieldset": {
//       borderColor: "transparent",
//     },
//     "&:hover fieldset": {
//       borderColor: "transparent",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "transparent",
//     },
//     border: "none",
//     "&:hover, &.Mui-focused": {
//       border: "none",
//     },
//   },
// });

// const ProjectButton = styled(Button)({
//   margin: "auto",
//   padding: "10px 40px",
//   marginBottom: "20px",
//   marginTop: "-35px",
//   display: "flex",
//   borderRadius: "25px",
//   background: "#0043CE",
//   fontSize: "14px",
//   fontFamily: "poppins",
//   fontWeight: "500",
// });

// const CustomAddCircleIcon = styled(AddCircleIcon)({
//   fontSize: 28,
// });

// const ListDiv = styled("div")({
//   background:
//     "linear-gradient(180deg, rgba(255, 255, 255, 0) 92.19%, rgba(255, 255, 255, 0.1) 100%)",
//   borderRadius: "4px",
//   margin: "16px",
//   marginTop: "36px",
// });
// const CustomList = styled(List)(({ collapsed }) => ({
//   "& .MuiBox-root css-1x3v3vy": {
//     margin: "0px",
//   },
//   margin: "auto",
//   marginLeft: collapsed && "-25px",
// }));

// const StyledListItem = styled(ListItem)(({ name, bottomactiveitem }) => ({
//   backgroundColor: name === bottomactiveitem ? "rgba(0, 67, 206, 0.36)" : "",
//   "&:hover": {
//     backgroundColor: "#606065",
//   },
//   "&.Mui-selected": {
//     backgroundColor: "rgba(0, 67, 206, 0.36)",
//   },
// }));
// const StyledListItemText = styled(ListItemText)({
//   color: "white !important",
//   fontFamily: "Poppins !important",
//   fontSize: "12px !important",
//   fontWeight: "100 !important",
// });
// const ViaSettings = styled("div")(({ collapsed }) => ({
//   marginTop: "16px",
//   marginBottom: collapsed ? "19px" : "34px",
//   padding: "16px",
//   color: "white",
//   fontFamily: "Poppins",
//   fontSize: "14px",
//   textAlign: "center",
//   display: "flex",
//   gap: "20px",
//   justifyContent: "center",
//   alignItems: "center",
//   background: "#333333",
// }));

// const StickyDiv = styled("div")((isScrolling) => ({
//   position: "sticky",
//   top: "0",
//   backgroundColor: "#191B1F",
//   boxShadow: isScrolling ? "2px 2px 35px 2px" : "",
//   zIndex: "1",
// }));

const CustomCollapseIcon = styled("img")(({ collapsed }) => ({
  cursor: "pointer",
  width: "30px",
  position: "fixed",
  marginLeft: collapsed ? "52px" : "248px",
  color: "white",
  marginTop: "80px",
  transition: "margin-left 0.3s ease",
  zIndex: "999",
  "@media (min-width: 0px) and (max-width: 767px)": {
    display: "none",
  },
}));

// const SearchDiv = styled("div")({
//   display: "flex",
//   justifyContent: "center",
//   cursor: "pointer",
// });

// const PlusIcon = styled("img")({
//   width: "30px",
//   marginRight: "10px",
// });
// const PlusIcon2 = styled("img")({
//   marginTop: "-50px",
//   marginBottom: "20px",
//   width: "50px",
//   background: "#0043CE",
//   borderRadius: "12px",
//   padding: "10px",
//   marginLeft: "25px",
// });
// const CustomBottomIcon = styled("img")({
//   width: "40px",
//   background: "#333333",
//   padding: "8px",
//   borderRadius: "4px",
//   cursor: "pointer",
//   "&:hover, &:active": {
//     background: "#132B5D",
//   },
// });
// const BottomIconsDiv = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   gap: "16px",
//   justifyContent: "center",
//   alignItems: "center",
//   marginTop: "16px",
// });
const MobileTopNav = styled("div")((mobileExpand) => ({
  display: "none",
  "@media (min-width: 0px) and (max-width: 767px)": {
    transition: "marginTop ease 0.3s",
    display: "flex",
    justifyContent: "flex-end",
    background: "#191B1F",
    padding: "5px",
    position: "fixed",
    width: "100vw",
    zIndex: "999",
  },
}));
const CustomMobileNavCloseIcon = styled("img")({
  display: "none",
  "@media (min-width: 0px) and (max-width: 767px)": {
    display: "block",
    float: "right",
    color: "white",
  },
});
// const Workspace = styled("p")({
//   color: "rgb(163, 168, 164)",
//   fontSize: "14px",
//   fontWeight: "600",
//   marginLeft: "25px",
//   padding: "20px 0px",
//   "@media (min-width: 0px) and (max-width: 843px)": {
//     display: "none",
//   },
// });
// const Workspace2 = styled("p")({
//   color: "rgb(163, 168, 164)",
//   fontSize: "14px",
//   fontWeight: "600",
//   marginLeft: "12px",
//   padding: "20px 0px",
//   display: "none",
//   "@media (min-width: 0px) and (max-width: 843px)": {
//     display: "block",
//   },
// });
