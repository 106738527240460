/* eslint-disable react/prop-types */
import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ImageCropper from "layouts/AdminLayouts/EditCompanyInfo/components/CompanyMainLogo/ImageCropper";
import { userTypeController } from "context/userInfo";

function UploadLogoModal({
  addUserDialogOpen,
  handleAddUserDialogClose,
  setImageLoadError,
  setImage,
}) {
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  return (
    <Dialog open={addUserDialogOpen} onClose={handleAddUserDialogClose}>
      <DialogTitle>Update Logo</DialogTitle>
      <DialogContent>
        <DialogContentText>Please the select logo image and fit in frame</DialogContentText>
        <ImageCropper
          companyUID={companyUID}
          setImage={setImage}
          DialogCloseActionBtn={handleAddUserDialogClose}
          setImageLoadError={setImageLoadError}
        />
      </DialogContent>
    </Dialog>
  );
}
export default UploadLogoModal;
