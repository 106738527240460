/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import { Box, ImageListItem, ImageListItemBar } from "@mui/material";
import React, { useRef } from "react";
import styled from "@emotion/styled";
import rightArrow from "../../../assets/images/right-arrow.svg";
import leftArrow from "../../../assets/images/left-arrow.svg";
// import floorMapsIcon from "../../../assets/images/floor-maps-icon.svg";

function ImagesNav({ scenesItem, changeScene, activeSceneId }) {
  const imagesContainerRef = useRef(null); // Ref for the container to scroll

  const scrollLeft = () => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollLeft -= 100; // You can adjust the scrolling amount
    }
  };

  const scrollRight = () => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollLeft += 100; // You can adjust the scrolling amount
    }
  };

  return (
    <ImagesContainer
      position="absolute"
      bottom={10}
      sx={{ marginBottom: "30px", marginLeft: "18%" }}
      zIndex={1}
      justifyContent="center"
      display="flex"
      gap={2}
    >
      {/* {Object.entries(scenesItem).map(([key, item]) => (
        <Box key={key}>
          <img src={item.panorama} position="relative" height="60%" alt="missing or removed" />
        </Box>
		
      ))} */}
      <img
        style={{ width: "35px", cursor: "pointer" }}
        onClick={scrollLeft}
        src={leftArrow}
        alt="_left"
      />
      <Box
        ref={imagesContainerRef}
        display="flex"
        gap={2}
        sx={{
          overflowX: "scroll", // Hide horizontal scrollbar
          overflowY: "hidden", // Hide horizontal scrollbar
          scrollSnapType: "x mandatory", // Enable horizontal scroll snapping
          WebkitOverflowScrolling: "touch", // Enable smooth scrolling on touch devices
          "&::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar completely
          },
          // Add scroll behavior for touch and dragging
          "&:not(.scrolling)": {
            scrollBehavior: "smooth", // Enable smooth scrolling on non-touch devices
          },
          "&.scrolling": {
            scrollBehavior: "auto", // Disable smooth scrolling while scrolling
          },
        }}
      >
        {Object.entries(scenesItem).map(([key, item]) => (
          <ImageListItem
            key={key}
            onClick={() => {
              changeScene(key);
            }}
            sx={{ border: activeSceneId === key ? "solid 2px white" : "solid 2px black" }}
            // border={activeSceneId ? "solid 2px black" : "solid 2px white"}
            height="20px"
          >
            <img
              style={{ borderRadius: "4px", width: "100px" }}
              src={`${item.panorama}`}
              srcSet={`${item.panorama}`}
              alt={key}
            />
            <StyledImageListItemBar
              title={item?.title}
              sx={{ height: 15, borderRadius: "0px 0px 4px 4px", color: "#101828" }}
            />
          </ImageListItem>
        ))}
      </Box>
      <img
        style={{ width: "35px", cursor: "pointer" }}
        src={rightArrow}
        onClick={scrollRight}
        alt="_right"
      />
    </ImagesContainer>
  );
}

export default ImagesNav;

const ImagesContainer = styled(Box)({
  width: "60%",
  height: "55px",
});
const StyledImageListItemBar = styled(ImageListItemBar)({
  color: "#101828",
  fontSize: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  background: "rgba(252, 252, 253, 0.80)",
  backdropFilter: "blur(4px)",
  "& > div": {
    color: "#101828 !important",
  },
  "& > div > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px !important",
    fontWeight: "600",
  },
});
