/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React main context

const UserInformation = createContext();
function _reducer(state, action) {
  console.log(state);
  switch (action.type) {
    case "UPDATEUSERTYPE": {
      return { ...state, userType: action.value };
    }
    case "UPDATEUSERCOMPANY": {
      return { ...state, companyUID: action.value };
    }
    default: {
      return null;
      // throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// User context

function UserProvider({ children }) {
  const initialState = {
    userType: "Anymous",
    companyUID: "",
  };
  const [UserInfo, dispatchUser] = useReducer(_reducer, initialState);

  const value = useMemo(() => [UserInfo, dispatchUser], [UserInfo, dispatchUser]);
  console.log(value);
  return <UserInformation.Provider value={value}>{children}</UserInformation.Provider>;
}
function userTypeController() {
  const context = useContext(UserInformation);
  if (!context) {
    throw new Error("UserContext should be used inside the MaterialUIControllerProvider.");
  }
  return context;
}
// module function

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setUserTypeState = (dispatchUser, value) => dispatchUser({ type: "UPDATEUSERTYPE", value });
const setUserCompanyUIDState = (dispatchUser, value) => {
  dispatchUser({ type: "UPDATEUSERCOMPANY", value });
};

export { userTypeController, UserProvider, setUserTypeState, setUserCompanyUIDState };
