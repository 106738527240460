/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { child, get, onValue, push, ref, remove, set, update } from "firebase/database";
import { rdb } from "FirebaseConfi";
import { userTypeController } from "context/userInfo";
import editIcon from "../../assets/images/edit-icon.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import tickIcon from "../../assets/images/tick-icon.svg";
import exclamationMark from "../../assets/images/exclamation-mark.svg";
import editWhiteIcon from "../../assets/images/edit-white-icon.svg";
import deleteWhiteIcon from "../../assets/images/delete-white-icon.svg";

function TextLibraries() {
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;
  const [data, setData] = useState([
    {
      outside: [
        {
          id: 1,
          text: "Default Text 1",
          notes: [],
          risks: [],
          ftu: [],
          defaultText: true,
        },
        {
          id: 2,
          text: "Default Text 2",
          notes: [],
          risks: [],
          ftu: [],
          defaultText: true,
        },
      ],
    },
    {
      inside: [
        {
          id: 1,
          text: "Default Text 1",
          notes: [],
          risks: [],
          ftu: [],
          defaultText: true,
        },
        {
          id: 2,
          text: "Default Text 2",
          notes: [],
          risks: [],
          ftu: [],
          defaultText: true,
        },
        {
          id: 3,
          text: "Default Text 3",
          notes: [],
          risks: [],
          ftu: [],
          defaultText: true,
        },
      ],
    },
  ]);
  const [stepOne, setStepOne] = useState({});
  const [step1Val, setstep1Val] = useState();
  const [step2Array, setStep2Array] = useState({});
  const [step2key, setStep2Key] = useState();
  const [notes, setNotes] = useState({});
  const [risks, setRisks] = useState({});
  const [ftu, setFtu] = useState({});
  const [deletingItemKey, setDeletingItemKey] = useState();
  const [editId, seteditBtnId] = useState("");
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [showStep4, setShowStep4] = useState(false);
  const [step2Index, setStep2Index] = useState();
  const [step3Index, setStep3Index] = useState();
  const [step3Val, setStep3Val] = useState();
  const [saveBtnDisable, setSaveBtnDisable] = useState(true);
  const [modalBtnDisable, setModalBtnDisable] = useState(true);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [firstDivWidth, setFirstDivWidth] = useState();
  const [editIndex, setEditIndex] = useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteText, setDeleteText] = useState("");
  const [doneText, setDoneText] = useState("add");
  const [open, setOpen] = useState(false);
  const [doneOpen, setDoneOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [deleteType, setDeleteType] = useState();
  const [indexToBeDelete, setIndexToBeDelete] = useState();
  const [indexToBeEdited, setIndexToBeEdited] = useState();
  const [textareaValue, setTextareaValue] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    setFirstDivWidth(data?.[stepOne.index]?.[stepOne.val]?.[step2Index]?.[step3Index]?.length > 0);
  }, [data?.[stepOne.index]?.[stepOne.val]?.[step2Index]?.[step3Index]?.length]);
  useEffect(() => {
    // Fetch data from Firebase Realtime Database
    const dataRef = ref(rdb, `companies/${companyUID}/textLibrary/${stepOne.val}/step2`);
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      console.log(fetchedData);
      if (fetchedData !== null) {
        setStep2Array(fetchedData);
      } else {
        setStep2Array([]);
      }

      // setData(fetchedData);
    });
  }, [stepOne]);
  useEffect(() => {
    console.log("this is data ", data);
  }, [data]);
  const step3Btns = ["Notes", "Risks", "FTU"];

  const handleAddButton = () => {
    setInputValue("");
    setDoneText("add");
    setOpen(true);
  };
  const handleEditButton = (value, index, key) => {
    seteditBtnId(key);
    setEditIndex(index);
    setInputValue(value);
    setDoneText("edit");
    setOpen(true);
    setModalBtnDisable(false);
  };
  const handleDeleteButton = (value, index, key) => {
    setDeletingItemKey(key);
    setDeleteType("textDelete");
    setDoneText("delete");
    setDeleteText(value);
    setDeleteIndex(index);
    setDeleteOpen(true);
  };
  const handleModalDeleteButton = () => {
    const dummyData = [...data];

    if (deleteType === "textDelete") {
      const dataRef = ref(
        rdb,
        `companies/${companyUID}/textLibrary/${stepOne.val}/${step3Val}/${step2key}/${deletingItemKey}`
      );
      remove(dataRef)
        .then(() => {
          console.log(`deleted successfully.`);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    } else if (deleteType === "btnDelete") {
      const dataRef = ref(
        rdb,
        `companies/${companyUID}/textLibrary/${stepOne.val}/step2/${editId}`
      );
      remove(dataRef)
        .then(() => {
          console.log(`deleted successfully.`);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
      dummyData[stepOne.index][stepOne.val].splice(indexToBeDelete, 1);
      if (step2Index > indexToBeDelete) {
        setStep2Index(step2Index - 1);
      } else if (step2Index === indexToBeDelete) {
        setShowStep3(false);
        setShowStep4(false);
        setSaveBtnDisable(true);
        setShowSideDrawer(false);
        setStep2Index();
      }
    }
    setData(dummyData);
    setDeleteOpen(false);
    setDoneOpen(true);
  };

  const handleSaveButton = () => {
    const dummyData = [...data];

    if (doneText === "add") {
      const Step2Ref = ref(rdb, `companies/${companyUID}/textLibrary/${stepOne.val}/step2`);
      const uniquekey = push(Step2Ref);

      set(uniquekey, { name: inputValue })
        .then(() => {
          console.log("New user added successfully!");
        })
        .catch((error) => {
          console.error("Error updating the array:", error);
        });
    } else if (doneText === "edit") {
      const Step2Refs = ref(
        rdb,
        `${companyUID}/textLibrary/${stepOne.val}/${step3Val}/${step2key}/${editId}`
      );
      update(Step2Refs, { name: inputValue })
        .then(() => {
          console.log("New user updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating the array:", error);
        });
    } else if (doneText === "btnEdit") {
      const Step2Refs = ref(
        rdb,
        `companies/${companyUID}/textLibrary/${stepOne.val}/step2/${editId}`
      );
      update(Step2Refs, { name: inputValue })
        .then(() => {
          console.log("New user updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating the array:", error);
        });
    }
    setData(dummyData);
    setOpen(false);
    setDoneOpen(true);
    setInputValue("");
  };
  const handleCancelButton = () => {
    setOpen(false);
    setDoneOpen(false);
    setInputValue("");
    setModalBtnDisable(true);
  };
  const handleDeleteCancelButton = () => {
    setDeleteOpen(false);
  };

  const handleInputChange = (e) => {
    if (e.target.value) {
      setInputValue(e.target.value);
      setModalBtnDisable(false);
    } else {
      setInputValue(e.target.value);
      setModalBtnDisable(true);
    }
  };

  const stepOneBtnClicked = (id, value) => {
    setStepOne({ index: id, val: value });
    setShowStep2(true);
    setShowStep3(false);
    setShowStep4(false);
    setStep2Index();
    setStep3Index();
    setSaveBtnDisable(true);
    setShowSideDrawer(false);
  };
  const stepTwoBtnClicked = (id, key) => {
    setStep2Key(key);
    setShowStep3(true);
    setStep2Index(id);
    setShowStep4(false);
    setStep3Index();
    setShowSideDrawer(false);
  };
  const stepThreeBtnClicked = (val) => {
    const dataRef = ref(
      rdb,
      `companies/${companyUID}/textLibrary/${stepOne.val}/${val.toLowerCase()}/${step2key}`
    );
    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData !== null) {
        if (val.toLowerCase() === "notes") {
          setNotes(fetchedData);
        } else if (val.toLowerCase() === "risks") {
          setRisks(fetchedData);
        } else if (val.toLowerCase() === "ftu") {
          setFtu(fetchedData);
        }
      } else if (val.toLowerCase() === "notes") {
        setNotes({});
      } else if (val.toLowerCase() === "risks") {
        setRisks({});
      } else if (val.toLowerCase() === "ftu") {
        setFtu({});
      }
    });
    setStep3Val(val.toLowerCase());
    setShowStep4(true);
    setStep3Index(val.toLowerCase());
    setShowSideDrawer(true);
  };
  useEffect(() => {
    console.log("these are notes", notes);
  }, [notes]);
  const onTextChange = (e) => {
    if (e.target.value) {
      setText(e.target.value);
      setTextareaValue(e.target.value);
      setSaveBtnDisable(false);
    } else {
      setSaveBtnDisable(true);
      setTextareaValue(e.target.value);
    }
  };
  const handleStep2Edit = (index, value, id) => {
    setInputValue(value);
    seteditBtnId(id);
    setOpen(true);
    setDoneText("btnEdit");
    setIndexToBeEdited(index);
    setModalBtnDisable(false);
  };
  const handleStep2Delete = (index, value, id) => {
    seteditBtnId(id);
    setDeleteOpen(true);
    setDoneText("delete");
    setDeleteType("btnDelete");
    setDeleteText(value);
    setIndexToBeDelete(index);
  };
  const saveBtnClicked = () => {
    console.log(stepOne);
    const Step2Ref = ref(
      rdb,
      `companies/${companyUID}/textLibrary/${stepOne.val}/${step3Val}/${step2key}`
    );
    const uniquekey = push(Step2Ref);

    set(uniquekey, { name: text })
      .then(() => {
        console.log("New user added successfully!");
      })
      .catch((error) => {
        console.error("Error updating the array:", error);
      });
    const dummyData = [...data];
    // dummyData[stepOne.index][stepOne.val][step2Index][step3Index].push(text);
    setData(dummyData);
    setTextareaValue("");
    setSaveBtnDisable(true);
  };

  return (
    <DashboardLayout style={{ background: "white" }}>
      <MainDiv>
        <ContentDiv showSideDrawer={showSideDrawer} firstDivWidth={firstDivWidth}>
          <HeadingTypography variant="body2" gutterBottom>
            Add Libraries
          </HeadingTypography>
          <Typography variant="body2" sx={{ color: "#000", fontSize: "16px" }} gutterBottom>
            In just four simple steps, you can add relevant notes, risks, or FTU or filter your
            previously adde text libraries.
          </Typography>
          <Container>
            <StepList>
              <StepListItem>
                <StepLine />
                <div>
                  <LabelDiv>
                    {" "}
                    <StepDot />
                    <CustomLabel>Step 1:</CustomLabel>
                  </LabelDiv>
                  <StepButton
                    id="outside"
                    clicked={stepOne.val}
                    disableRipple
                    variant="contained"
                    onClick={() => stepOneBtnClicked(0, "outside")}
                  >
                    Exterior
                  </StepButton>
                  <StepButton
                    id="inside"
                    clicked={stepOne.val}
                    disableRipple
                    variant="contained"
                    onClick={() => stepOneBtnClicked(1, "inside")}
                  >
                    Interior
                  </StepButton>
                </div>
              </StepListItem>
              {showStep2 && (
                <StepListItem>
                  <div>
                    <StepLine />
                  </div>
                  <div>
                    <LabelDiv>
                      {" "}
                      <StepDot />
                      <CustomLabel>Step 2:</CustomLabel>
                    </LabelDiv>
                    <Step2ButtonsDiv>
                      {Object.entries(step2Array).map(([key, value], index) => (
                        <Step2Btn id={index} clicked={step2Index}>
                          <StepButton
                            sx={{ margin: "0", padding: "10px", boxShadow: "none" }}
                            id={index}
                            clicked={step2Index}
                            disableRipple
                            variant="contained"
                            onClick={() => stepTwoBtnClicked(index, key)}
                          >
                            {value.name}
                          </StepButton>
                          {!value.defaultText && (
                            <div style={{ display: "flex" }}>
                              {index === step2Index ? (
                                <>
                                  <img
                                    onClick={() => handleStep2Edit(index, value?.name, key)}
                                    src={editWhiteIcon}
                                    alt="edit"
                                  />
                                  <img
                                    onClick={() => handleStep2Delete(index, value?.name, key)}
                                    src={deleteWhiteIcon}
                                    alt="delete"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    onClick={() => handleStep2Edit(index, value?.name, key)}
                                    src={editIcon}
                                    alt="edit"
                                  />
                                  <img
                                    onClick={() => handleStep2Delete(index, value?.name, key)}
                                    src={deleteIcon}
                                    alt="delete"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </Step2Btn>
                      ))}
                      {/* {data?.[stepOne?.index]?.[stepOne?.val]?.map((item, index) => (
                        <Step2Btn id={index} clicked={step2Index}>
                          <StepButton
                            sx={{ margin: "0", padding: "10px", boxShadow: "none" }}
                            id={index}
                            clicked={step2Index}
                            disableRipple
                            variant="contained"
                            onClick={() => stepTwoBtnClicked(index)}
                          >
                            {item?.text}
                          </StepButton>
                          {!item.defaultText && (
                            <div style={{ display: "flex" }}>
                              {index === step2Index ? (
                                <>
                                  <img
                                    onClick={() => handleStep2Edit(index, item?.text)}
                                    src={editWhiteIcon}
                                    alt="edit"
                                  />
                                  <img
                                    onClick={() => handleStep2Delete(index, item?.text)}
                                    src={deleteWhiteIcon}
                                    alt="delete"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    onClick={() => handleStep2Edit(index, item?.text)}
                                    src={editIcon}
                                    alt="edit"
                                  />
                                  <img
                                    onClick={() => handleStep2Delete(index, item?.text)}
                                    src={deleteIcon}
                                    alt="delete"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </Step2Btn>
                      ))} */}
                      <RandomButton
                        sx={{ marginBottom: "0px !important" }}
                        dialogBtns="no"
                        purple="false"
                        disableRipple
                        variant="contained"
                        onClick={handleAddButton}
                        startIcon={<AddIcon />}
                      >
                        Add
                      </RandomButton>
                    </Step2ButtonsDiv>
                  </div>
                </StepListItem>
              )}
              {showStep3 && (
                <StepListItem>
                  <div>
                    <StepLine />
                  </div>
                  <div>
                    <LabelDiv>
                      {" "}
                      <StepDot />
                      <CustomLabel>Step 3:</CustomLabel>
                    </LabelDiv>
                    {step3Btns?.map((item) => (
                      <StepButton
                        id={item.toLowerCase()}
                        clicked={step3Index}
                        disableRipple
                        variant="contained"
                        onClick={() => stepThreeBtnClicked(item)}
                      >
                        {item}
                      </StepButton>
                    ))}
                  </div>
                </StepListItem>
              )}
              {showStep4 && (
                <StepListItem>
                  <div>
                    <StepLine />
                  </div>
                  <div>
                    <LabelDiv>
                      {" "}
                      <StepDot />
                      <CustomLabel>Step 4:</CustomLabel>
                    </LabelDiv>
                    <TextFieldDiv>
                      <CustomTextField value={textareaValue} onChange={onTextChange} />
                      <ButtonsDiv>
                        <RandomButton
                          dialogBtns="no"
                          purple="false"
                          disableRipple
                          variant="contained"
                          // onClick={() => handleStepOneButtonClick("Inside")}
                        >
                          Cancel
                        </RandomButton>
                        <RandomButton
                          dialogBtns="no"
                          purple="true"
                          randome="false"
                          disableRipple
                          variant="contained"
                          disabled={saveBtnDisable}
                          onClick={saveBtnClicked}
                        >
                          Save
                        </RandomButton>
                      </ButtonsDiv>
                    </TextFieldDiv>
                  </div>
                </StepListItem>
              )}
            </StepList>
          </Container>
        </ContentDiv>

        {showStep4 && step3Val === "notes" && Object.keys(notes).length > 0 && (
          <SideDrawer>
            <HeadingTypography variant="body2" gutterBottom>
              Your text libraries
            </HeadingTypography>
            <CustomHr />
            {Object.entries(notes)?.map(([key, value], index) => (
              <DrawerTextDiv>
                <DrawerTypography>{value?.name}</DrawerTypography>
                <SideDrawerBtns>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => handleEditButton(value.name, index, key)}
                  />
                  <img
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => handleDeleteButton(value.name, index, key)}
                  />
                </SideDrawerBtns>
              </DrawerTextDiv>
            ))}
          </SideDrawer>
        )}
        {showStep4 && step3Val === "risks" && Object.keys(risks).length > 0 && (
          <SideDrawer>
            <HeadingTypography variant="body2" gutterBottom>
              Your text libraries
            </HeadingTypography>
            <CustomHr />
            {Object.entries(risks)?.map(([key, value], index) => (
              <DrawerTextDiv>
                <DrawerTypography>{value?.name}</DrawerTypography>
                <SideDrawerBtns>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => handleEditButton(value.name, index, key)}
                  />
                  <img
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => handleDeleteButton(value.name, index, key)}
                  />
                </SideDrawerBtns>
              </DrawerTextDiv>
            ))}
          </SideDrawer>
        )}
        {showStep4 && step3Val === "ftu" && Object.keys(ftu).length > 0 && (
          <SideDrawer>
            <HeadingTypography variant="body2" gutterBottom>
              Your text libraries
            </HeadingTypography>
            <CustomHr />
            {Object.entries(ftu)?.map(([key, value], index) => (
              <DrawerTextDiv>
                <DrawerTypography>{value?.name}</DrawerTypography>
                <SideDrawerBtns>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => handleEditButton(value.name, index, key)}
                  />
                  <img
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => handleDeleteButton(value.name, index, key)}
                  />
                </SideDrawerBtns>
              </DrawerTextDiv>
            ))}
          </SideDrawer>
        )}
      </MainDiv>

      {/* Add and Edit Modal */}

      <CustomDialog open={open} onClose={handleCancelButton}>
        <DialogTitle>
          {doneText === "add"
            ? "Add Text"
            : doneText === "edit" || doneText === "btnEdit"
            ? "Edit Text"
            : ""}
        </DialogTitle>
        <DialogContent>
          <DialogFieldDiv>
            <TextField
              sx={{ marginTop: "10px" }}
              label="Button Text"
              value={inputValue}
              onChange={handleInputChange}
            />
            <DialogBtnsDiv>
              <RandomButton
                dialogBtns="yes"
                sx={{
                  width: "100%",
                  textAlgn: "center",
                  color: "#344054 !important",
                  border: "1px solid #D0D5DD",
                }}
                purple="false"
                variant="contained"
                color="primary"
                onClick={handleCancelButton}
              >
                Cancel
              </RandomButton>
              <RandomButton
                dialogBtns="yes"
                sx={{ width: "100%", textAlgn: "center" }}
                purple="true"
                variant="contained"
                color="primary"
                disabled={modalBtnDisable}
                onClick={handleSaveButton}
              >
                {doneText === "add"
                  ? "Add"
                  : doneText === "edit" || doneText === "btnEdit"
                  ? "Done"
                  : ""}
              </RandomButton>
            </DialogBtnsDiv>
          </DialogFieldDiv>
        </DialogContent>
      </CustomDialog>

      {/* Done Modal */}

      <CustomDialog open={doneOpen} onClose={handleCancelButton}>
        <DoneContent>
          <img src={tickIcon} width="48px" alt="done" />
          <DoneTypography>
            Text{" "}
            {doneText === "add"
              ? "added"
              : doneText === "edit" || doneText === "btnEdit"
              ? "updated"
              : doneText === "delete"
              ? "deleted"
              : ""}{" "}
            successfully
          </DoneTypography>
          <RandomButton
            dialogBtns="yes"
            sx={{ width: "100%", textAlgn: "center", marginRight: "0" }}
            purple="true"
            variant="contained"
            color="primary"
            onClick={handleCancelButton}
          >
            Done
          </RandomButton>
        </DoneContent>
      </CustomDialog>

      {/* Delete Modal */}

      <CustomDialog open={deleteOpen} onClose={handleDeleteCancelButton}>
        <DoneContent>
          <img src={exclamationMark} width="48px" alt="done" />
          <DoneTypography sx={{ marginBottom: "8px !important" }}>
            Are you sure to delete {deleteText?.slice(0, 7)}
            {deleteText?.length > 7 ? "..." : ""}?
          </DoneTypography>
          <DoneSubTypography>
            You can not recover once the text library is deleted
          </DoneSubTypography>
          <DialogBtnsDiv sx={{ width: "100%" }}>
            <RandomButton
              dialogBtns="yes"
              sx={{
                width: "100%",
                textAlgn: "center",
                color: "#344054 !important",
                border: "1px solid #D0D5DD",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={handleDeleteCancelButton}
            >
              Cancel
            </RandomButton>
            <RandomButton
              dialogBtns="yes"
              sx={{
                width: "100%",
                textAlgn: "center",
                marginRight: "0",
                background: "#D92D20 !important",
                color: "white !important",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={handleModalDeleteButton}
            >
              Delete
            </RandomButton>
          </DialogBtnsDiv>
        </DoneContent>
      </CustomDialog>
    </DashboardLayout>
  );
}

export default TextLibraries;

const MainDiv = styled("div")({
  background: "white",
  minHeight: "100vh",
  display: " flex",
});
const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "30%",
  },
});
const ContentDiv = styled("div")(({ showSideDrawer, firstDivWidth }) => ({
  width: showSideDrawer && firstDivWidth ? "60%" : "100%",
  padding: "20px 25px",
}));
const DoneContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  alignItems: "center",
});
const SideDrawer = styled("div")({
  width: "40%",
  padding: "20px 25px",
  boxShadow: "-8px 0px 168px 0px rgb(120 120 120 / 10%)",
});
const HeadingTypography = styled(Typography)({
  color: "#000",
  fontSize: "24px",
  fontWeight: "500",
});
const DoneTypography = styled(Typography)({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
  marginTop: "20px",
  marginBottom: "32px",
});
const DoneSubTypography = styled(Typography)({
  color: "#667085",
  fontSize: "14px",
  marginBottom: "32px",
});
const DrawerTypography = styled(Typography)({
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  width: "80%",
  wordWrap: "break-word",
});
const SideDrawerBtns = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});
const LabelDiv = styled("div")({
  display: "flex",
});
const CustomHr = styled("hr")({
  marginTop: "16px",
  marginBottom: "40px",
  border: "#EAECF0 1px solid",
});
const DrawerTextDiv = styled("div")({
  display: "flex",
  padding: "10px 16px",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
  gap: "16px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});
const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  width: "fit-content",
});

const StepList = styled("ul")({
  padding: 0,
  marginBottom: "60px",
});

const StepListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  position: "relative",
});
const StepDot = styled("div")({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: "#7F56D9",
  marginRight: "16px",
  marginLeft: "-25px",
  marginBottom: "10px",
  marginTop: "10px",
});
const StepLine = styled("div")({
  position: "absolute",
  top: 0,
  marginTop: "40px",
  left: "-20px",
  height: "60%",
  width: "1px",
  backgroundColor: "#7F56D9",
});

const Step2Btn = styled(Button)(({ id, clicked }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  boxShadow: "0px 1px 2px 0px rgb(16 24 40 / 5%)",
  textTransform: "none",
  background: id === clicked ? "#7F56D9" : "#F9F5FF",
  color: id === clicked ? "#F9F5FF" : "#7F56D9",
  "&:hover": {
    boxShadow: "none",
    background: id === clicked ? "#7F56D9" : "#F9F5FF",
  },
}));
const StepButton = styled(Button)(({ id, clicked }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: id === clicked ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "14px 28px",
  fontWeight: "600",
  marginBottom: "20px",
  color: id === clicked ? "#F9F5FF" : "#7F56D9",
  "&:hover": {
    boxShadow: "none",
    background: id === clicked ? "#7F56D9" : "#F9F5FF",
  },
}));

const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: dialogBtns === "yes" ? "10px 18px" : "14px 28px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: dialogBtns === "yes" ? "600" : "",
  fontSize: dialogBtns === "yes" ? "16px" : "",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  },
}));

const CustomTextField = styled("textarea")({
  margin: "0px",
  padding: "10px",
  borderRadius: "8px",
  border: "1px solid #D6BBFB",
  background: "white",
  boxShadow: "0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px rgb(16 24 40 / 5%)",
  color: "#333",
  fontSize: "16px",
  minWidth: "450px",
  minHeight: "100px",
  fontFamily: "Inter",
  outline: "none",
  "@media (min-width: 0px) and (max-width: 530px)": {
    minWidth: "auto",
  },
});
const CustomLabel = styled("h3")({
  fontSize: "18px",
  fontWeight: "500",
});

const TextFieldDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const ButtonsDiv = styled("div")({
  marginTop: "50px",
  display: "flex",
  justifyContent: "right",
  marginRight: "-20px",
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const DialogFieldDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Step2ButtonsDiv = styled("div")({
  display: "flex",
  gap: "16px",
  flexWrap: "wrap",
});
