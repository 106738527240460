/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import Compressor from "compressorjs";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import { Chip } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Storage } from "FirebaseConfi";
import uploadImgIcon from "../../../assets/images/upload-img-icon.svg";
import delImg from "../../../assets/images/deleteBasket.svg";

function ManageImagesModalContent({
  setSelectedBuildingImgsFile,
  setNewSelectedBuildingImgsFile,
  selectedBuildingImgsFile,
  setSelectedFloorMapImgsFile,
  setNewSelectedFloorMapImgsFile,
  selectedFloorMapImgsFile,
  addNewScene,
  projectID,
  companyUID,
  activeTab,
  setActiveTab,
  uploadProgress,
  uploading,
  scenes,
  setUpdatedScenes,
  setScenes,
  FloorMapsData,
  setUpdatedFloorMapsData,
  setFloorMapsData,
  disableFloorNumbers,
  setDisableFloorNumbers,
  activeSceneId,
  removeScene,
}) {
  const [showUpload, setShowUpload] = useState(false);
  const [items, setItems] = useState([1, 2, 3, 4]);
  const [newScene, setNewScene] = useState({});
  useEffect(() => {
    setUpdatedFloorMapsData({ ...FloorMapsData });
    setUpdatedScenes({ ...scenes });
  }, []);
  const floorNumbers = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  useEffect(() => {
    console.log("selectedBuildingImgsFile", selectedBuildingImgsFile);
  }, [selectedBuildingImgsFile]);

  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth,
        maxHeight,
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  }

  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };
  const uploadToFirebase = (compressedFile) => {
    try {
      const id = generateUUID();
      const title = compressedFile.name.split(".")[0];
      console.log(id);
      const storageRef = ref(
        Storage,
        `companies/${companyUID}/Projects/${projectID}/InpectionImages/BuildingImages/${id}`
      );

      // Upload the image Blob to Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log("Some thing wrong");
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              console.log("unable to perform");
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setNewScene((prevImages) => ({
              ...prevImages,
              [id]: { panorama: downloadURL, title },
            }));
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (Object.keys(newScene).length > 0) {
      addNewScene(Object.keys(newScene)[0], newScene[Object.keys(newScene)[0]]);
      setScenes({ ...scenes, ...newScene });
      setNewScene({});
    }
  }, [newScene]);

  const handleBuildingImagesUpload = (e) => {
    const files = e.target.files;
    const uploadImage = (file) => {
      const reader = new FileReader();

      if (file) {
        const id = generateUUID();
        const imageName = file.name.replace(/\.[^/.]+$/, "");
        reader.readAsDataURL(file);
        let imageObject = {};
        reader.onload = () => {
          compressImage(file, 800, 600)
            .then((compressedFile) => {
              uploadToFirebase(compressedFile);
            })
            .catch((error) => {
              console.error(error);
            });
          const imagePath = reader.result;
          imageObject = {
            imagePath,
            uploading: 0,
            id,
            title: imageName,
          };
          setNewSelectedBuildingImgsFile((previousData) => [...previousData, imageObject]);
        };
      }
    };

    for (const file of files) {
      uploadImage(file);
    }
  };
  const handleFloorMapImagesUpload = (e) => {
    const files = e.target.files;
    const uploadImage = (file) => {
      const reader = new FileReader();

      if (file) {
        const id = generateUUID();
        // const imageName = file.name.replace(/\.[^/.]+$/, "");
        reader.readAsDataURL(file);
        let imageObject = {};
        reader.onload = () => {
          compressImage(file, 800, 600)
            .then((compressedFile) => {
              console.log("Uploaded a blob or file!");
              console.log("this is compressed", compressedFile.size);
            })
            .catch((error) => {
              console.error(error);
            });
          const imagePath = reader.result;
          imageObject = {
            imagePath,
            uploading: 0,
            id,
            title: null,
          };
          setNewSelectedFloorMapImgsFile((previousData) => [...previousData, imageObject]);
        };
      }
    };

    for (const file of files) {
      uploadImage(file);
    }
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const updatedKeys = swap(Object.keys(scenes), sourceIndex, targetIndex);

    // Create a copy of the scenes object with updated keys
    const updatedScenes = {};
    updatedKeys.forEach((key, index) => {
      updatedScenes[key] = scenes[key];
    });

    // Update the scenes state with the new order
    setScenes(updatedScenes);
    console.log("updatedScenes", updatedScenes);
  }

  return (
    <MainContainer>
      <Tab>
        <TabOption
          //   uploading={uploading}
          activeTab={activeTab === "buildingImg"}
          onClick={() => {
            // !uploading &&
            setActiveTab("buildingImg");
          }}
        >
          Building Images
        </TabOption>
        <TabOption
          //   uploading={uploading}
          activeTab={activeTab === "floorMap"}
          onClick={() => {
            // !uploading &&
            setActiveTab("floorMap");
          }}
        >
          Floor Maps (Optional)
        </TabOption>
      </Tab>
      <ScrollableContent>
        {showUpload ? (
          <UploadImageArea>
            <Uploader>
              <img src={uploadImgIcon} alt="_upload" />
            </Uploader>
            <div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={
                  activeTab === "buildingImg"
                    ? handleBuildingImagesUpload
                    : handleFloorMapImagesUpload
                }
                multiple
              />
              <label htmlFor="fileInput">
                <UploadButton variant="contained" component="span">
                  Click to upload
                </UploadButton>
              </label>
            </div>
            <UploadInst>SVG, PNG, JPG or GIF (max. 800x800px)</UploadInst>
          </UploadImageArea>
        ) : (
          <ImageContainer>
            <div style={{ width: "99%" }}>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={
                  !uploading &&
                  (activeTab === "buildingImg"
                    ? handleBuildingImagesUpload
                    : handleFloorMapImagesUpload)
                }
                multiple
              />
              <label htmlFor="fileInput">
                <AddUploadButton
                  // uploading={uploading}
                  variant="contained"
                  component="span"
                >
                  + Add Images
                </AddUploadButton>
              </label>
            </div>

            {activeTab === "buildingImg" ? (
              <GridContextProvider onChange={onChange}>
                <GridDropZone
                  id="items"
                  boxesPerRow={4}
                  rowHeight={300}
                  style={{ width: "100%", height: "400px", marginTop: "24px" }}
                >
                  {Object.keys(scenes).map((imageId, index) => {
                    const { panorama, title, assignedFloor } = scenes[imageId];
                    const progressPercentage = uploadProgress[imageId] || 0;
                    const isUploading = progressPercentage < 100;

                    return (
                      <GridItem style={{ height: "auto", width: "auto" }} key={imageId}>
                        <Img key={imageId}>
                          <InnerImag type="buildingImg" style={{ background: `url(${panorama})` }}>
                            {imageId !== activeSceneId ? (
                              <CrossIcon>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={delImg}
                                  alt="_remove"
                                  onClick={() => {
                                    if (imageId !== activeSceneId) {
                                      removeScene(imageId);

                                      const updatedScenes = { ...scenes };

                                      delete updatedScenes[imageId];

                                      setScenes(updatedScenes);
                                    }
                                  }}
                                />
                              </CrossIcon>
                            ) : (
                              <CrossIcon>
                                <Chip label="Active" color="success" />
                              </CrossIcon>
                            )}
                          </InnerImag>
                          <FieldDiv>
                            <TextField
                              sx={{ width: "-webkit-fill-available", background: "white" }}
                              id="outlined-basic"
                              placeholder="Image title"
                              variant="outlined"
                              value={title}
                              onChange={(event) => {
                                const updatedScenesData = { ...scenes }; // Create a copy of scenes
                                updatedScenesData[imageId].title = event.target.value; // Update title
                                setUpdatedScenes(updatedScenesData); // Update state with the updated scenes
                              }}
                            />
                            {/* <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{ width: "183px", background: "white" }}
                            value={assignedFloor}
                            onChange={(event) => {
                              const updatedScenesData = { ...scenes }; // Create a copy of scenes
                              updatedScenesData[imageId].assignedFloor = event.target.value; // Update title
                              setUpdatedScenes(updatedScenesData);
                              setDisableFloorNumbers((prevDisableFloorNumbers) => [
                                ...prevDisableFloorNumbers.filter((item) => item !== assignedFloor),
                                event.target.value,
                              ]);
                            }}
                          >
                            {floorNumbers?.map((e) => (
                              <MenuItem disabled={disableFloorNumbers.includes(e)} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select> */}
                          </FieldDiv>
                        </Img>
                      </GridItem>
                    );
                  })}
                </GridDropZone>
              </GridContextProvider>
            ) : (
              <Images>
                {Object.keys(FloorMapsData).map((imageId, index) => {
                  const { floorMap, floorNumber } = FloorMapsData[imageId];
                  const progressPercentage = uploadProgress[imageId] || 0;
                  const isUploading = progressPercentage < 100;

                  return (
                    <FloorMapImg key={imageId}>
                      <InnerImag type="floorMap" style={{ background: `url(${floorMap})` }}>
                        <CrossIcon>
                          <img
                            style={{ cursor: "pointer" }}
                            src={delImg}
                            alt="_remove"
                            onClick={() => {
                              const updatedFloorMaps = { ...FloorMapsData };
                              delete updatedFloorMaps[imageId];
                              setFloorMapsData(updatedFloorMaps);
                            }}
                          />
                        </CrossIcon>
                        {/* )} */}
                      </InnerImag>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ width: "200px", marginTop: "16px", backGround: "white" }}
                        value={floorNumber}
                        onChange={(event) => {
                          const updatedFloorMaps = { ...FloorMapsData };
                          updatedFloorMaps[imageId].floorNumber = event.target.value;
                          setUpdatedFloorMapsData(updatedFloorMaps);
                          setDisableFloorNumbers((prevDisableFloorNumbers) => [
                            ...prevDisableFloorNumbers.filter((item) => item !== floorNumber),
                            event.target.value,
                          ]);
                        }}
                      >
                        {floorNumbers?.map((e) => (
                          <MenuItem disabled={disableFloorNumbers?.includes(e)} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      </Select>
                    </FloorMapImg>
                  );
                })}
              </Images>
            )}
          </ImageContainer>
        )}
      </ScrollableContent>
    </MainContainer>
  );
}

export default ManageImagesModalContent;

const Tab = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
  marginTop: "24px",
});
const TabOption = styled("div")(({ activeTab, uploading }) => ({
  cursor: uploading ? "not-allowed" : "pointer",
  display: "flex",
  padding: "4px 0px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  borderBottom: `2px solid ${activeTab ? "#6248BF" : "transparent"}`,
  color: `${activeTab ? "#6248BF" : "#687083"}`,
  fontSize: "14px",
  fontWeight: "600",
  transition: "0.5s ease",
}));
const UploadImageArea = styled("div")({
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid #EAECF0",
  background: "#FFF",
  height: "350px",
  maxHeight: "350px",
  minHeight: "350px",
  overflow: "auto",
  marginTop: "24px",
  marginBottom: "24px",
});
const Uploader = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
  alignSelf: "stretch",
});
const UploadButton = styled("p")({
  cursor: "pointer",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
});
const AddUploadButton = styled("p")(({ uploading }) => ({
  padding: "8px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  textAlign: "center",
  cursor: !uploading ? "pointer" : "not-allowed",
}));
const UploadInst = styled("p")({
  color: "#667085",
  textAlign: "center",
  fontSize: "10px",
  fontWeight: "400",
});
const Images = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "50px 10px",
  marginTop: "20px",
});
const Img = styled("div")({
  height: "auto",
  marginBottom: "16px",
  display: "flex",
  width: "200px",
  padding: "8px 0px",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  borderRadius: "24px 24px 10px 10px",
  background: "#EAECF0",
});
const ImageContainer = styled("div")({
  width: "100%",
  display: "inline-flex",
  height: "439px",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: "0",
});
const InnerImag = styled("div")(({ type }) => ({
  position: "relative",
  height: "200px",
  width: type === "floorMap" ? "200px" : "184px",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
  borderRadius: "16px",
}));
// const Overlay = styled("div")({
//   position: "absolute",
//   top: "0",
//   left: "0",
//   width: "100%",
//   height: "100%",
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
//   borderRadius: "16px",
// });
// const UploadingTxt = styled("div")({
//   color: "white",
//   textAlign: "center",
//   height: "60%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
// });
const CrossIcon = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  padding: "8px",
});
const MainContainer = styled("div")({
  height: "100%", // Set the container to occupy the full height
  display: "flex",
  flexDirection: "column",
});

// Add a new styled component for the scrollable content area
const ScrollableContent = styled("div")({
  flex: "1", // Allow the content to grow and occupy the remaining space with a scrollable behavior
  overflowY: "auto", // Enable vertical scrolling if the content overflows
  marginTop: "24px", // Adjust the spacing as needed
  scrollbarWidth: "thin", // Set the scrollbar width to be thin (supported in webkit-based browsers)
  scrollbarColor: "#6248BF #EAECF0", // Set the scrollbar track and thumb colors
  "&::-webkit-scrollbar": {
    width: "6px", // Set the width of the scrollbar
  },
  "&::-webkit-scrollbar-track": {
    background: "#EAECF0", // Set the color of the scrollbar track
    borderRadius: "4px", // Set the border radius of the scrollbar track
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#6248BF", // Set the color of the scrollbar thumb
    borderRadius: "4px", // Set the border radius of the scrollbar thumb
  },
});
const FieldDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});
const FloorMapImg = styled("div")({
  height: "auto",
  marginBottom: "16px", // Adjust the spacing between items as per your requirement
  marginTop: "24px",
});
