/* eslint-disable react/no-array-index-key */
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { userTypeController } from "context/userInfo";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "FirebaseConfi";
import user from "../../assets/images/user.svg";
// import dummyIns2 from "../../assets/images/home-decor-3.jpg";
// import dummyIns3 from "../../assets/images/inspection-3.svg";
// import dummyIns4 from "../../assets/images/frontImage.png";
// import dummyIns5 from "../../assets/images/inspection-5.svg";
// import dummyIns6 from "../../assets/images/inspection-6.svg";
// import dummyIns7 from "../../assets/images/inspection-7.svg";
// import dummyIns8 from "../../assets/images/inspection-8.svg";
import noFrontImage from "../../assets/images/no-frontImage.png";

const options = [
  {
    value: "Inspector",
  },
  {
    value: "Manager",
  },
  {
    value: "Admin",
  },
];

// const data = [
//  {
//    name: "Residential Property",
//    price: 44556,
//    date: "March 15, 2023",
//    img: "",
//  },
//  {
//    name: "Renovation Readiness",
//    price: 13579,
//    date: "October 5, 2022",
//    img: dummyIns2,
//  },
//  {
//    name: "Home Safety Evaluation",
//    price: 24680,
//    date: "April 25, 2023",
//    img: dummyIns3,
//  },
//  {
//    name: "Energy Efficiency Audit",
//    price: 67890,
//    date: "September 10, 2022",
//    img: dummyIns4,
//  },
//  {
//    name: "Radon Testing Initiative",
//    price: 99998,
//    date: "June 3, 2023",
//    img: dummyIns5,
//  },
//  {
//    name: "Rental Property Inspection",
//    price: 77889,
//    date: "November 20, 2022",
//    img: dummyIns6,
//  },
//  {
//    name: "Pre-Purchase Review",
//    price: 44556,
//    date: "May 8, 2023",
//    img: dummyIns7,
//  },
//  {
//    name: "New Home Construction",
//    price: 11223,
//    date: "July 12, 2022",
//    img: dummyIns8,
//  },
//  {
//    name: "Residential Property",
//    price: 44556,
//    date: "March 15, 2023",
//    img: dummyIns2,
//  },
//  {
//    name: "Renovation Readiness",
//    price: 13579,
//    date: "October 5, 2022",
//    img: dummyIns2,
//  },
//  {
//    name: "Home Safety Evaluation",
//    price: 24680,
//    date: "April 25, 2023",
//    img: dummyIns3,
//  },
//  {
//    name: "Energy Efficiency Audit",
//    price: 67890,
//    date: "September 10, 2022",
//    img: dummyIns4,
//  },
// ];

function ProjectGallery() {
  const [Projects, setProjects] = useState([]);
  const navigate = useNavigate();

  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  useEffect(async () => {
    if (companyUID) {
      const q = query(
        collection(db, `companies/${companyUID}/projects`),
        orderBy("CreatedAt", "desc")
      );

      const querySnapshot = await getDocs(q);
      const ProjectList = [];
      querySnapshot.forEach((document) => {
        // doc.data() is never undefined for query doc snapshots
        ProjectList.push({ id: document.id, ...document.data() });
      });
      setProjects(ProjectList);
      console.log(ProjectList);
    }
  }, [companyUID]);

  const handleNavigateProjectView = (projectData) => {
    navigate(`/projectGallery/viewProject/${projectData?.id}`, {
      state: { data: projectData },
    });
  };

  return (
    <DashboardLayout>
      <MainDiv>
        <Header>
          <HeadingsDiv>
            <Heading>Project Gallery</Heading>
            <SubHeading>Explore projects and get insights of each inspection.</SubHeading>
          </HeadingsDiv>
          <SearchDiv>
            <CustomTextField
              id="outlined-helperText"
              sx={{ marginRight: "20px", height: "40px" }}
              placeholder="Search by name or ID"
              InputProps={{
                startAdornment: <SearchIcon style={{ color: "gray" }} />,
              }}
            />
            <CustomTextField
              id="outlined-select-currency"
              sx={{ width: "170px", height: "40px" }}
              select
              placeholder=""
              InputProps={{
                startAdornment: <img src={user} alt="user" />,
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </CustomTextField>
          </SearchDiv>
        </Header>
        <div>
          <FlexContainer>
            {Projects.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FlexItem key={index} onClick={() => handleNavigateProjectView(item)}>
                <CustomImg
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  src={item?.frontImage ? item?.frontImage : noFrontImage}
                  alt={`${index}`}
                  style={{ width: "100%" }}
                />
                <ItemName>{item.propertyDestination}</ItemName>
                <ItemPrice>No.{item.uppdragNumber}</ItemPrice>
                <ItemDate>{item.status}</ItemDate>
              </FlexItem>
            ))}
          </FlexContainer>
        </div>
      </MainDiv>
    </DashboardLayout>
  );
}

export default ProjectGallery;

const MainDiv = styled("div")({
  padding: "32px 40px",
  background: "white",
});
const CustomTextField = styled(TextField)`
  && .MuiInputBase-root {
    height: 40px;
    border-radius: 8px;
  }
`;
const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});
const HeadingsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10",
});
const SearchDiv = styled("div")({
  display: "flex",
  marginTop: "15px",
});
const Heading = styled("p")({
  color: "#000",
  fontsize: "24px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const FlexContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "50px 24px",
  marginTop: "40px",
  justifyContent: "center",
});
const FlexItem = styled(Box)({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  width: "calc(25% - 24px)", // Adjust the width as per your requirement (25% to display 4 items in a row)
  "@media (max-width: 1300px)": {
    width: "calc(33.33% - 24px)", // Adjust the width for smaller screens (33.33% to display 3 items in a row)
  },
  "@media (max-width: 768px)": {
    width: "calc(50% - 24px)", // Adjust the width for even smaller screens (50% to display 2 items in a row)
  },
  "@media (max-width: 480px)": {
    width: "100%", // Adjust the width for the smallest screens (100% to display 1 item in a row)
  },
  "@media (min-width: 0px) and (max-width: 1300px)": {
    // height: "150px",
  },
});
const CustomImg = styled("img")({
  borderRadius: "4px",
  height: "inherit",
  flex: 1,
  width: "inherit",
});
const ItemName = styled("p")({
  color: "#000",
  fontSize: "16px",
  fontWeight: "600",
});
const ItemPrice = styled("p")({
  color: "#000",
  fontSize: "14px",
  fontWeight: "500",
});
const ItemDate = styled("p")({
  color: "#000",
  fontSize: "12px",
  fontWeight: "500",
});
