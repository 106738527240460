/* eslint-disable import/named */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import DashboardLayout from "WebItems/LayoutContainers/DashboardLayout";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { rdb, db } from "FirebaseConfi";
import { onValue, ref } from "firebase/database";
import { userTypeController } from "context/userInfo";
import { useParams, useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import deleteBtnRed from "../../../assets/images/delete-btn-red.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import InspectionNoteModal from "./InspectionNoteModal";
import PreviewModal from "./PreviewModal/index";
import tickIcon from "../../../assets/images/tick-icon.svg";
import exclamationMark from "../../../assets/images/exclamation-mark.svg";
import { handleCancelToBackRouteButton } from "../../../CommonFunctions/index";

function InspectionNotes() {
  const { id } = useParams();
  const location = useLocation();
  const [inspectionNoteModalOpen, setInspectionNoteModalOpen] = useState(false);
  const [InspectionNoteData, setInspectionNoteData] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [update, setUpdate] = useState(false);
  const [doneOpen, setDoneOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [doneText, setDoneText] = useState("add");
  const [deleteText, setDeleteText] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [viewType, setViewType] = useState("");
  const [viewText, setViewText] = useState("");
  const [viewHeading, setViewHeading] = useState("");
  const [deleteIndex, setDeleteIndex] = useState();
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [textLibraryData, setTextLibraryData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [pdfData, setPdfData] = useState();
  const Data = location.state?.data;
  const [userInfo] = userTypeController();
  const { companyUID } = userInfo;

  useEffect(() => {
    console.log("InspectionNoteData", InspectionNoteData);
  }, [InspectionNoteData]);
  useEffect(() => {
    console.log("pdfData >>>>>>", pdfData);
  }, [pdfData]);
  useEffect(async () => {
    if (!companyUID) return;
    const docRef = doc(db, `companies/${companyUID}/projects`, id);
    const docSnap = await getDoc(docRef);

    if (Data && Data?.propertyInformation) {
      setPdfData(Data);
    } else if (docSnap.exists()) {
      setPdfData(docSnap.data());
    }
  }, [companyUID]);

  useEffect(() => {
    console.log(InspectionNoteData);
  }, [InspectionNoteData]);

  useEffect(() => {
    if (companyUID) {
      const dataRef = ref(rdb, `/companies/${companyUID}/textLibrary`);
      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData !== null) setTextLibraryData(fetchedData);
      });
    }
    console.log("state data", Data);
  }, [companyUID]);
  const SaveOnServer = async () => {
    const docRef = doc(db, `companies/${companyUID}/projects`, id);
    await updateDoc(docRef, {
      InspectionNoteData,
    })
      .then(() => {
        console.log("updated");
        setDoneOpen(true);
        setDataChanged(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log(dataChanged);
    if (dataChanged) SaveOnServer();
  }, [dataChanged]);
  useEffect(async () => {
    try {
      if (!companyUID) return;
      const docRef = doc(db, `companies/${companyUID}/projects`, id);

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (docSnap.data().InspectionNoteData)
          setInspectionNoteData(docSnap.data().InspectionNoteData);
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  }, [companyUID]);

  const handleCancelButton = () => {
    setInspectionNoteModalOpen(false);
    setUpdate(false);
    setUpdateData();
  };
  const handleCancelPreviewButton = () => {
    setOpenPreviewModal(false);
  };
  const handleSaveButton = () => {
    setDoneText("add");
    // Update the state with the new textpoints array
    const newData = [...InspectionNoteData, updateData]; // Create a copy of the original array
    setUpdateData();
    setInspectionNoteData(newData);
    setInspectionNoteModalOpen(false);
    setDataChanged(true);
  };
  const handleEditInspectionNote = (item) => {
    setUpdate(true);
    setInspectionNoteModalOpen(true);
    setUpdateData(item);
  };
  const handleUpdateButton = () => {
    setDoneText("edit");
    const indexToUpdate = InspectionNoteData.findIndex((item) => item.id === updateData.id);
    if (indexToUpdate !== -1) {
      // Update the state with the new textpoints array
      const newData = [...InspectionNoteData]; // Create a copy of the original array
      newData[indexToUpdate] = { ...updateData };
      setInspectionNoteData(newData);
      setUpdateData();
      setDataChanged(true);
    }

    setInspectionNoteModalOpen(false);
    setUpdate(false);
    setUpdateData();
  };

  const handleDelete = (index) => {
    setDoneText("delete");
    setDeleteText(`Note ${index + 1}`);
    setDeleteIndex(index);
    setDeleteOpen(true);
  };

  const handleModalDeleteButton = () => {
    const dummyData = InspectionNoteData;
    dummyData.splice(deleteIndex, 1);
    setInspectionNoteData(dummyData);
    setDeleteOpen(false);
    setDoneText("delete");
    setDataChanged(true);
  };
  const handleView = (data) => {
    if (!data?.disabled) {
      setViewOpen(true);
      setViewType(data?.type);
      setViewText(data?.text);
      setViewHeading(data?.heading);
    }
  };
  return (
    <DashboardLayout>
      <Header>
        <Headings>
          <Heading>Add Inspection Notes</Heading>
          <SubHeading>Add notes to your inspection details for any particular item.</SubHeading>
        </Headings>
        <ActionButtons>
          <CancelButton onClick={() => handleCancelToBackRouteButton()}>Cancel</CancelButton>
          <NextButton onClick={() => setOpenPreviewModal(true)}>Export</NextButton>
        </ActionButtons>
      </Header>
      <Container>
        <AddInspection>
          <AddBtn onClick={() => setInspectionNoteModalOpen(true)}>+ Add inspection note</AddBtn>
        </AddInspection>
        <NotesData>
          {InspectionNoteData?.map((item, index) => (
            <Note key={index}>
              <NoteHeading>
                <NoteName>
                  Note {index + 1}:
                  <NoteRoute>
                    {item.step1 ? `${item.step1} > ` : null}
                    {item.step2 ? `${item.step2} > ` : null}
                    {item.step3 ? ` ${item.step3.value}` : null}
                  </NoteRoute>
                  {/* <NoteRoute>
                    {Object.keys(datas[item?.step1])[0] ? Object.keys(datas[item?.step1])[0] : ""}
                    {datas[item?.step1][Object.keys(datas[item?.step1])][item.step2].text
                      ? ` > ${datas[item?.step1][Object.keys(datas[item?.step1])][item.step2].text}`
                      : ""}
                    {datas[item?.step1][Object.keys(datas[item?.step1])][item.step2]?.step3?.[
                      item?.step3
                    ]
                      ? ` > ${
                          datas[item?.step1][Object.keys(datas[item?.step1])][item.step2]?.step3?.[
                            item?.step3
                          ].text
                        } `
                      : ""}
                  </NoteRoute> */}
                </NoteName>
                <NoteActionButtons>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => {
                      handleEditInspectionNote(item, item.id);
                    }}
                  />
                  <img src={deleteBtnRed} onClick={() => handleDelete(index)} alt="delete" />
                </NoteActionButtons>
              </NoteHeading>
              <NoteTable>
                <TableHeader>
                  <Column1>Text libraries</Column1>
                  <Column2>Risk / FTU</Column2>
                </TableHeader>
                {item?.textpoints?.map((e) => (
                  <DataRow>
                    <Text>{e?.text}</Text>
                    <ViewBtn
                      disabled={e?.hasRisk || e?.hasFtu ? false : true}
                      onClick={() =>
                        handleView(
                          e?.riskText?.value !== undefined
                            ? {
                                text: e?.riskText?.text,
                                type: "Risk",
                                heading: e?.text,
                                disabled: e?.riskText?.text || e?.ftuText?.text ? false : true,
                              }
                            : {
                                text: e?.ftuText.text,
                                type: "FTU",
                                heading: e?.text,
                                disabled: e?.ftuText?.text || e?.ftuText.text ? false : true,
                              }
                        )
                      }
                    >
                      View
                    </ViewBtn>
                  </DataRow>
                ))}
              </NoteTable>
            </Note>
          ))}
        </NotesData>
      </Container>
      {/* Preview Modal */}
      <PreviewModal
        openPreviewModal={openPreviewModal}
        handleCancelPreviewButton={handleCancelPreviewButton}
        InspectionNoteData={InspectionNoteData}
        pdfData={pdfData}
        setOpenPreviewModal={setOpenPreviewModal}
      />
      {/* Inspection Note Modal */}
      <CustomMainDialog open={inspectionNoteModalOpen} onClose={handleCancelButton}>
        <DialogTitle>{update ? "Update" : "Add"} Inspection Note</DialogTitle>
        <DialogContent>
          <InspectionNoteModal
            textLibraryData={textLibraryData}
            setUpdateData={setUpdateData}
            update={update}
            updateData={updateData}
            setAddBtnDisable={setAddBtnDisable}
          />
          <DialogBtnsDiv>
            <RandomButton
              sx={{
                width: "100%",
                textAlgn: "center",
                color: "#344054 !important",
                border: "1px solid #D0D5DD",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={handleCancelButton}
            >
              Cancel
            </RandomButton>
            <RandomButton
              sx={{ width: "100%", textAlgn: "center" }}
              purple="true"
              variant="contained"
              color="primary"
              disabled={addBtnDisable}
              onClick={update ? handleUpdateButton : handleSaveButton}
            >
              {update ? "Update" : "Add"}
            </RandomButton>
          </DialogBtnsDiv>
        </DialogContent>
      </CustomMainDialog>
      {/* Done Modal */}

      <CustomDialog open={doneOpen} onClose={() => setDoneOpen(false)}>
        <DoneContent>
          <img src={tickIcon} width="48px" alt="done" />
          <DoneTypography>
            Text{" "}
            {doneText === "add"
              ? "added"
              : doneText === "edit"
              ? "updated"
              : doneText === "delete"
              ? "deleted"
              : ""}{" "}
            successfully
          </DoneTypography>
          <RandomButton
            sx={{ width: "100%", textAlgn: "center", marginRight: "0" }}
            purple="true"
            variant="contained"
            color="primary"
            onClick={() => setDoneOpen(false)}
          >
            Done
          </RandomButton>
        </DoneContent>
      </CustomDialog>
      {/* Delete Modal */}

      <CustomDialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DoneContent>
          <img src={exclamationMark} width="48px" alt="done" />
          <DoneTypography sx={{ marginBottom: "8px !important" }}>
            Are you sure to delete {deleteText?.slice(0, 7)}
            {deleteText?.length > 7 ? "..." : ""}?
          </DoneTypography>
          <DoneSubTypography>You can not recover once the Note is deleted</DoneSubTypography>
          <DialogBtnsDiv sx={{ width: "100%" }}>
            <RandomButton
              sx={{
                width: "100%",
                textAlgn: "center",
                color: "#344054 !important",
                border: "1px solid #D0D5DD",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={() => setDeleteOpen(false)}
            >
              Cancel
            </RandomButton>
            <RandomButton
              sx={{
                width: "100%",
                textAlgn: "center",
                marginRight: "0",
                background: "#D92D20 !important",
                color: "white !important",
              }}
              purple="false"
              variant="contained"
              color="primary"
              onClick={handleModalDeleteButton}
            >
              Delete
            </RandomButton>
          </DialogBtnsDiv>
        </DoneContent>
      </CustomDialog>

      {/* Risk & FTU View Modal */}

      <CustomDialog open={viewOpen} onClose={() => setViewOpen(false)}>
        <ViewContent>
          <ViewHeading>{viewHeading}</ViewHeading>
          <Field>
            <ViewLabel>{viewType}</ViewLabel>
            <ViewData>{viewText}</ViewData>
          </Field>
          <RandomButton
            sx={{ width: "100%", textAlgn: "center", marginRight: "0", marginTop: "8px" }}
            purple="true"
            variant="contained"
            color="primary"
            onClick={() => setViewOpen(false)}
          >
            Close
          </RandomButton>
        </ViewContent>
      </CustomDialog>
    </DashboardLayout>
  );
}

export default InspectionNotes;

const ViewContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  padding: "24px",
});
const ViewHeading = styled("p")({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
});
const Field = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
  alignSelf: "stretch",
});
const ViewLabel = styled("p")({
  color: "#344054",
  fontSize: "14px",
  fontWeight: "500",
});
const ViewData = styled("div")({
  display: "flex",
  padding: "10px 14px",
  gap: "8px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  color: "#475467",
  fontSize: "14px",
  fontWeight: "500",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  minHeight: "100px",
  alignItems: "baseline",
});

const DoneContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  alignItems: "center",
});
const DoneTypography = styled(Typography)({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
  marginTop: "20px",
  marginBottom: "32px",
});
const DoneSubTypography = styled(Typography)({
  color: "#667085",
  fontSize: "14px",
  marginBottom: "32px",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "32px 40px 16px",
  background: "white",
  borderBottom: "1px solid #EAECF0",
  flexWrap: "wrap",
});
const Headings = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Heading = styled("p")({
  color: "#101828",
  fontSize: "20px",
  fontWeight: "500",
});
const SubHeading = styled("p")({
  color: "#667085",
  fontSize: "14px",
  fontWeight: "400",
});
const ActionButtons = styled("div")({
  display: "flex",
  gap: "12px",
});
const CancelButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  fontFamily: "Inter",
  height: "max-content",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor: "pointer",
});
const NextButton = styled("button")({
  padding: "10px 16px",
  outline: "none",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Inter",
  height: "max-content",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor: "pointer",
});

const Container = styled("div")({
  padding: "0px 40px",
});
const AddInspection = styled("div")({
  display: "flex",
  padding: "24px 0px",
  justifyContent: "flex-end",
  alignItems: "center",
  alignSelf: "stretch",
});
const AddBtn = styled("button")({
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  border: "1px solid #7F56D9",
  background: "#7F56D9",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#FFF",
  fontSize: "14px",
  fontWeight: "600",
});
const NotesData = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  alignSelf: "stretch",
});

const Note = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
});
const NoteHeading = styled("div")({
  display: "flex",
  padding: "0px 8px 0px 4px",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
});

const NoteName = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  color: "#000",
  fontSize: "16px",
  fontWeight: "500",
});
const NoteRoute = styled("p")({
  color: "#667085",
  fontSize: "12px",
  fontWeight: "500",
});
const NoteActionButtons = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});
const NoteTable = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1.5px solid #EAECF0",
  background: "#FFF",
});
const TableHeader = styled("div")({
  display: "flex",
  height: "44px",
  padding: "12px 24px",
  alignItems: "center",
  gap: "12px",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
  background: "#F9FAFB",
});
const Column1 = styled("p")({
  color: "#667085",
  fontSize: "12px",
  fontWeight: "500",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
});
const Column2 = styled("p")({
  color: "#667085",
  fontSize: "12px",
  fontWeight: "500",
  display: "flex",
  width: "200px",
  flexDirection: "column",
  alignItems: "flex-start",
});
const DataRow = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
});
const Text = styled("p")({
  display: "flex",
  padding: "16px 24px",
  alignItems: "flex-start",
  flex: "1 0 0",
  color: "#667085",
  fontSize: "14px",
  fontWeight: "500",
});
const ViewBtn = styled("p")(({ disabled }) => ({
  display: "flex",
  width: "200px",
  padding: "16px",
  alignItems: "flex-start",
  color: disabled ? "#D0D5DD" : "#6941C6",
  cursor: disabled ? "not-allowed" : "pointer",
  fontSize: "14px",
  fontWeight: "600",
}));
const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "30%",
  },
});
const CustomMainDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "40%",
  },
});
const CustomPreviewDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "900px",
  },
});
const DialogBtnsDiv = styled("div")({
  display: "flex",
  gap: "8px",
  marginTop: "15px",
});
const RandomButton = styled(Button)(({ purple, dialogBtns }) => ({
  textTransform: "none",
  marginRight: "16px",
  borderRadius: "8px",
  background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: dialogBtns === "yes" ? "10px 18px" : "14px 28px",
  marginBottom: dialogBtns === "yes" ? "0" : "20px",
  color: purple === "false" ? "#7F56D9" : "#F9F5FF",
  fontWeight: dialogBtns === "yes" ? "600" : "",
  fontSize: dialogBtns === "yes" ? "16px" : "",
  height: dialogBtns === "yes" ? "fit-content" : "",
  "&:hover": {
    boxShadow: "none",
    background: purple === "true" ? "#7F56D9" : "#F9F5FF",
  },
}));
