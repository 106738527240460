/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-new */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ref, uploadBytesResumable } from "firebase/storage";
import { Storage } from "FirebaseConfi";
import uploadImgIcon from "../../../assets/images/upload-img-icon.svg";
import delImg from "../../../assets/images/del_img.svg";

function InspectionImageModal({
  setSelectedBuildingImgsFile,
  selectedBuildingImgsFile,
  setSelectedFloorMapImgsFile,
  selectedFloorMapImgsFile,
  activeTab,
  setActiveTab,
  uploadProgress,
  uploading,
  disableFloorNumbers,
  setDisableFloorNumbers,
}) {
  const [showUpload, setShowUpload] = useState(true);

  useEffect(() => {
    if (activeTab === "buildingImg") {
      !selectedBuildingImgsFile.length ? setShowUpload(true) : setShowUpload(false);
    }
    if (activeTab === "floorMap") {
      !selectedFloorMapImgsFile.length ? setShowUpload(true) : setShowUpload(false);
    }
  }, [selectedBuildingImgsFile, selectedFloorMapImgsFile, activeTab]);

  useEffect(() => {
    console.log("selectedBuildingImgsFile", selectedBuildingImgsFile);
    selectedBuildingImgsFile?.map((e) => {
      console.log("e", e);
    });
  }, [selectedBuildingImgsFile]);
  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };

  const floorNumbers = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  function compressImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        strict: true,
        checkOrientation: true,
        maxWidth: 3698,
        maxHeight: undefined,
        minWidth: 0,
        minHeight: 0,
        width: 3698,
        height: undefined,
        resize: "none",
        quality: 0.8,
        mimeType: "",
        convertTypes: "image/png",
        convertSize: 5000000,
        success(result) {
          console.log("this is Compressed", (result.size / (1024 * 1024)).toFixed(2));
          console.log("this is Originalfile", (file.size / (1024 * 1024)).toFixed(2));
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleBuildingImagesUpload = (e) => {
    const files = e.target.files;
    const uploadImage = (file) => {
      const reader = new FileReader();

      if (file) {
        let imagePath;

        const id = generateUUID();
        const imageName = file.name.replace(/\.[^/.]+$/, "");
        reader.readAsDataURL(file);
        let imageObject = {};
        reader.onload = () => {
          compressImage(file, 800, 600)
            .then((compressedFile) => {
              imagePath = compressedFile;

              imageObject = {
                imagePath,
                uploading: 0,
                id,
                title: imageName,
              };

              setSelectedBuildingImgsFile((previousData) => [...previousData, imageObject]);
            })
            .catch((error) => {
              console.error(error);
            });
        };
      }
    };

    for (const file of files) {
      uploadImage(file);
    }
  };

  const handleFloorMapImagesUpload = (e) => {
    const files = e.target.files;
    const uploadImage = (file) => {
      const reader = new FileReader();

      if (file) {
        const id = generateUUID();
        const imageName = file.name.replace(/\.[^/.]+$/, "");
        reader.readAsDataURL(file);
        let imageObject = {};
        reader.onload = () => {
          compressImage(file, 800, 600)
            .then((compressedFile) => {
              console.log("this is compressed", compressedFile.size);
            })
            .catch((error) => {
              console.error(error);
            });
          const imagePath = reader.result;
          imageObject = {
            imagePath,
            uploading: 0,
            id,
            title: null,
          };
          setSelectedFloorMapImgsFile((previousData) => [...previousData, imageObject]);
        };
      }
    };

    for (const file of files) {
      uploadImage(file);
    }
  };

  const RemoveImage = (indexToRemove) => {
    if (activeTab === "buildingImg") {
      setSelectedBuildingImgsFile((previousData) => {
        // Create a new array excluding the image at the specified index
        const newData = previousData.filter((image, index) => index !== indexToRemove);
        return newData;
      });
    }
    if (activeTab === "floorMap") {
      setSelectedFloorMapImgsFile((previousData) => {
        // Create a new array excluding the image at the specified index
        const newData = previousData.filter((image, index) => index !== indexToRemove);
        return newData;
      });
    }
  };

  return (
    <MainContainer>
      <Tab>
        <TabOption
          uploading={uploading}
          activeTab={activeTab === "buildingImg"}
          onClick={() => {
            !uploading && setActiveTab("buildingImg");
          }}
        >
          Building Images
        </TabOption>
        <TabOption
          uploading={uploading}
          activeTab={activeTab === "floorMap"}
          onClick={() => {
            !uploading && setActiveTab("floorMap");
          }}
        >
          Floor Maps (Optional)
        </TabOption>
      </Tab>
      <ScrollableContent>
        {showUpload ? (
          <UploadImageArea>
            <Uploader>
              <img src={uploadImgIcon} alt="_upload" />
            </Uploader>
            <div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={
                  activeTab === "buildingImg"
                    ? handleBuildingImagesUpload
                    : handleFloorMapImagesUpload
                }
                multiple
              />
              <label htmlFor="fileInput">
                <UploadButton variant="contained" component="span">
                  Click to upload
                </UploadButton>
              </label>
            </div>
            <UploadInst>SVG, PNG, JPG or GIF (max. 800x800px)</UploadInst>
          </UploadImageArea>
        ) : (
          <ImageContainer>
            <div style={{ width: "99%" }}>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={
                  !uploading &&
                  (activeTab === "buildingImg"
                    ? handleBuildingImagesUpload
                    : handleFloorMapImagesUpload)
                }
                multiple
              />
              <label htmlFor="fileInput">
                <AddUploadButton uploading={uploading} variant="contained" component="span">
                  + Add Images
                </AddUploadButton>
              </label>
            </div>
            <Images>
              {activeTab === "buildingImg"
                ? selectedBuildingImgsFile?.map((image, index) => {
                    const { imagePath, id, title } = image;
                    const blobImageUrl = URL.createObjectURL(imagePath);
                    const progressPercentage = uploadProgress[id] || 0;
                    const isUploading = progressPercentage < 100;

                    return (
                      <Img key={id}>
                        <InnerImag style={{ background: `url(${blobImageUrl})` }}>
                          {isUploading && (
                            <Overlay>
                              <CrossIcon>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={delImg}
                                  alt="_remove"
                                  onClick={() => {
                                    !uploading && RemoveImage(index);
                                  }}
                                />
                              </CrossIcon>
                              {progressPercentage === 0 ? (
                                <UploadingTxt>
                                  <p>Ready To upload</p>
                                  {/* <span>uploading...</span> */}
                                </UploadingTxt>
                              ) : (
                                <UploadingTxt>
                                  <p>{Math.floor(progressPercentage)}%</p>
                                  <span>uploading...</span>
                                </UploadingTxt>
                              )}
                            </Overlay>
                          )}
                          {!isUploading && (
                            <CrossIcon>
                              <img
                                style={{ cursor: "pointer" }}
                                src={delImg}
                                alt="_remove"
                                onClick={() => {
                                  !uploading && RemoveImage(index);
                                }}
                              />
                            </CrossIcon>
                          )}
                        </InnerImag>
                        <TextField
                          sx={{ width: "-webkit-fill-available", marginTop: "16px" }}
                          id="outlined-basic"
                          placeholder="Image title"
                          variant="outlined"
                          value={title}
                          onChange={(event) => {
                            const updatedImages = [...selectedBuildingImgsFile];
                            updatedImages[index].title = event.target.value;
                            setSelectedBuildingImgsFile(updatedImages);
                          }}
                        />
                      </Img>
                    );
                  })
                : selectedFloorMapImgsFile?.map((image, index) => {
                    const { imagePath, id, title } = image;
                    const progressPercentage = uploadProgress[id] || 0;
                    const isUploading = progressPercentage < 100;

                    return (
                      <Img key={id}>
                        <InnerImag style={{ background: `url(${imagePath})` }}>
                          {isUploading && (
                            <Overlay>
                              <CrossIcon>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={delImg}
                                  alt="_remove"
                                  onClick={() => {
                                    !uploading && RemoveImage(index);
                                  }}
                                />
                              </CrossIcon>
                              {progressPercentage === 0 ? (
                                <UploadingTxt>
                                  <p>Ready To upload</p>
                                </UploadingTxt>
                              ) : (
                                <UploadingTxt>
                                  <p>{Math.floor(progressPercentage)}%</p>
                                  <span>uploading...</span>
                                </UploadingTxt>
                              )}
                            </Overlay>
                          )}
                          {!isUploading && (
                            <CrossIcon>
                              <img
                                style={{ cursor: "pointer" }}
                                src={delImg}
                                alt="_remove"
                                onClick={() => {
                                  !uploading && RemoveImage(index);
                                }}
                              />
                            </CrossIcon>
                          )}
                        </InnerImag>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{ width: "183px", marginTop: "16px" }}
                          value={title}
                          // label="Age"
                          onChange={(event) => {
                            console.log("event.target.value", event.target.value);
                            console.log("event", event);
                            const updatedImages = [...selectedFloorMapImgsFile];
                            updatedImages[index].title = event.target.value;
                            setSelectedFloorMapImgsFile(updatedImages);
                            setDisableFloorNumbers((prevDisableFloorNumbers) => [
                              ...prevDisableFloorNumbers.filter((item) => item !== title),
                              event.target.value,
                            ]);
                          }}
                        >
                          {floorNumbers?.map((e) => (
                            <MenuItem disabled={disableFloorNumbers.includes(e)} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        </Select>
                      </Img>
                    );
                  })}
            </Images>
          </ImageContainer>
        )}
      </ScrollableContent>
    </MainContainer>
  );
}
export default InspectionImageModal;

const Tab = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  borderBottom: "1px solid #EAECF0",
  marginTop: "24px",
});
const TabOption = styled("div")(({ activeTab, uploading }) => ({
  cursor: uploading ? "not-allowed" : "pointer",
  display: "flex",
  padding: "4px 0px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  borderBottom: `2px solid ${activeTab ? "#6248BF" : "transparent"}`,
  color: `${activeTab ? "#6248BF" : "#687083"}`,
  fontSize: "14px",
  fontWeight: "600",
  transition: "0.5s ease",
}));
const UploadImageArea = styled("div")({
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid #EAECF0",
  background: "#FFF",
  height: "350px",
  maxHeight: "350px",
  minHeight: "350px",
  overflow: "auto",
  marginTop: "24px",
  marginBottom: "24px",
});
const Uploader = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "12px",
  alignSelf: "stretch",
});
const UploadButton = styled("p")({
  cursor: "pointer",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
});
const AddUploadButton = styled("p")(({ uploading }) => ({
  padding: "8px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  textAlign: "center",
  cursor: !uploading ? "pointer" : "not-allowed",
}));
const UploadInst = styled("p")({
  color: "#667085",
  textAlign: "center",
  fontSize: "10px",
  fontWeight: "400",
});
const Images = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "50px 10px",
  marginTop: "20px",
});
const Img = styled("div")({
  height: "200px",
  marginBottom: "16px", // Adjust the spacing between items as per your requirement
  marginTop: "24px",
  "@media (min-width: 0px) and (max-width: 1300px)": {
    height: "150px",
  },
});
const AddImgBtn = styled("button")({
  padding: "8px 14px",
  borderRadius: "8px",
  border: "1px solid #F9F5FF",
  background: "#F9F5FF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#6941C6",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
});

const ImageContainer = styled("div")({
  width: "100%",
  display: "inline-flex",
  height: "439px",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: "0",
});
const Loader = styled("div")({
  color: "#FFF",
  fontSize: "18px",
  fontWeight: "600",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "-21%",
  flexDirection: "column",
  background: "rgba(0, 0, 0, 0.50)",
  borderRadius: "16px",
});
const InnerImag = styled("div")({
  position: "relative",
  height: "inherit",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
  borderRadius: "16px",
});
const Overlay = styled("div")({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "16px",
});
const UploadingTxt = styled("div")({
  color: "white",
  textAlign: "center",
  height: "60%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
const CrossIcon = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  padding: "8px",
});
const MainContainer = styled("div")({
  height: "100%", // Set the container to occupy the full height
  display: "flex",
  flexDirection: "column",
});

// Add a new styled component for the scrollable content area
const ScrollableContent = styled("div")({
  flex: "1", // Allow the content to grow and occupy the remaining space with a scrollable behavior
  overflowY: "auto", // Enable vertical scrolling if the content overflows
  marginTop: "24px", // Adjust the spacing as needed
  scrollbarWidth: "thin", // Set the scrollbar width to be thin (supported in webkit-based browsers)
  scrollbarColor: "#6248BF #EAECF0", // Set the scrollbar track and thumb colors
  "&::-webkit-scrollbar": {
    width: "6px", // Set the width of the scrollbar
  },
  "&::-webkit-scrollbar-track": {
    background: "#EAECF0", // Set the color of the scrollbar track
    borderRadius: "4px", // Set the border radius of the scrollbar track
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#6248BF", // Set the color of the scrollbar thumb
    borderRadius: "4px", // Set the border radius of the scrollbar thumb
  },
});
