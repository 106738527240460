/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable default-case */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */

import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { useLocation, useParams } from "react-router-dom";
import { db } from "FirebaseConfi";
import { userTypeController } from "context/userInfo";
import { doc, getDoc } from "firebase/firestore";
import FloorMapPopoverContent from "./FloorMapPopoverContent";
import ImagesNav from "./ImagesNav";
import floorMapsIcon from "../../../assets/images/floor-maps-icon.svg";
import activeFloorMapsIcon from "../../../assets/images/active-floorMaps-icon.svg";
import {
  CanvasContainer,
  FloorMapList,
  Li,
  ImagesAndOptions,
  FloorMapsIcon,
} from "./PanoramaCanvas";

let viewer;

function ProjectViewPanoramaCanvas() {
  const [activeFloorMapImgId, setActiveFloorMapImgId] = useState("");
  const [activeFloorMapImg, setActiveFloorMapImg] = useState("");
  const [activeFloorMapHotspots, setActiveFloorMapHotspots] = useState([]);
  const [isFloorMapActive, setIsFloorMapActive] = useState(false);
  const location = useLocation();
  const [FloorMapsData, setFloorMapsData] = useState(location.state?.floorMaps || {});
  const [BuildingImagesData, setBuildingImagesData] = useState({});
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [activeSceneId, setActiveSceneId] = useState("");
  const [userInfo] = userTypeController();
  const [InspectionNoteData, setInspectionNoteData] = useState([]);
  const [scenes, setScenes] = useState({});
  const { companyUID } = userInfo;

  useEffect(async () => {
    if (!companyUID) return;
    const data = location.state?.buildingImages;

    const reconstructedObject = {};
    const reconstructedFloorMapsObject = {};

    if (data) {
      data.forEach((item) => {
        const { key, ...value } = item;
        reconstructedObject[key] = value;
      });

      setBuildingImagesData({ ...reconstructedObject });
    } else if (companyUID) {
      const docRef = doc(db, `companies/${companyUID}/projects/${id}/Scenes`, "scenes");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docSnap.data()?.scenes?.forEach((item) => {
          const { key, ...value } = item;
          reconstructedObject[key] = value;
        });

        setBuildingImagesData({ ...reconstructedObject });
      }
      const floorMapDocRef = doc(
        db,
        `companies/${companyUID}/projects/${id}/FloorMaps`,
        "FloorMaps"
      );
      const floorMapsDocSnap = await getDoc(floorMapDocRef);
      if (floorMapsDocSnap.exists()) {
        setFloorMapsData({ ...floorMapsDocSnap.data()?.floorMaps });

        floorMapsDocSnap.data()?.floorMaps?.forEach((item) => {
          const { key, ...value } = item;
          reconstructedFloorMapsObject[key] = value;
        });
      }
    }
  }, [companyUID]);

  useEffect(() => {
    // Initial screen size check
    setIsSmallScreen(window.matchMedia("(max-width: 840px)").matches);
    setIsMediumScreen(window.matchMedia("(min-width: 841px) and (max-width: 1040px)").matches);

    // Add event listener for screen resize
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 840px)").matches);
      setIsMediumScreen(window.matchMedia("(min-width: 841px) and (max-width: 1040px)").matches);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFloorClick = (event, projectID, activeFloorMapImage, hotspots) => {
    setAnchorEl(event.currentTarget);
    setActiveFloorMapImgId(projectID);
    setActiveFloorMapImg(activeFloorMapImage);
    hotspots && setActiveFloorMapHotspots(hotspots);
  };

  const popoverOpen = Boolean(anchorEl);

  const handleClosePopover = () => {
    setAnchorEl(null);
    setActiveFloorMapImgId("");
    setActiveFloorMapImg("");
    setActiveFloorMapHotspots([]);
  };

  useEffect(() => {
    if (Object.keys(BuildingImagesData).length > 0) {
      const scenesObject = {};
      const allHotspots = [];
      Object.entries(BuildingImagesData).forEach(([sceneId, scene]) => {
        scenesObject[sceneId] = {
          ...scene,
          hotSpots: [],
        };

        if (scene.hotSpots) {
          scene.hotSpots.forEach((hotspot) => {
            allHotspots.push(hotspot);
          });
        }
      });

      if (Object.keys(scenesObject).length > 0) {
        viewer = window.pannellum.viewer("panorama", {
          type: "equirectangular",
          scenes: scenesObject,
          autoLoad: true,
          showZoomCtrl: false,
          default: scenesObject[Object.keys(scenesObject)[0]],
          sceneFadeDuration: 3000,
          compass: false,
        });

        viewer.on("scenechange", (sceneId) => {
          // This function will be called whenever the scene changes
          setActiveSceneId(sceneId);
        });
        let updatedHotspotAray = [...InspectionNoteData];
        allHotspots?.map((item) => {
          if (item?.clickHandlerArgs) {
            updatedHotspotAray = [...updatedHotspotAray, item.clickHandlerArgs];
          }
        });
        setInspectionNoteData(updatedHotspotAray);
        console.log("All hotspots>>>>>", allHotspots);
        setScenes(viewer.getConfig().scenes);
        setActiveSceneId(Object.keys(BuildingImagesData)[0]);
      }

      allHotspots.forEach((item) => {
        console.log(item);
        viewer.addHotSpot(
          {
            id: item.id,
            pitch: item.pitch,
            yaw: item.yaw,
            type: "info",
            SceneID: item.SceneID,
            text: item.text,
            textImage: item?.textImage,
            clickHandlerArgs: { ...item.clickHandlerArgs },
          },
          item.SceneID
        );
      });
    }
  }, [BuildingImagesData]);

  useEffect(() => {
    if (viewer) {
      viewer.getConfig().scenes = { ...scenes };
    }
  }, [scenes]);

  const changeScene = (sceneID) => {
    viewer.loadScene(sceneID);
  };

  return (
    <div style={{ background: "white" }}>
      <CanvasContainer className="canvas-containerrr">
        <div>
          <ImagesNav
            className="images-navvv"
            scenesItem={scenes}
            activeSceneId={activeSceneId}
            changeScene={changeScene}
          />
        </div>
        {isFloorMapActive && (
          <FloorMapList>
            {FloorMapsData &&
              Object?.keys(FloorMapsData).map((projectID) => {
                const project = FloorMapsData[projectID];
                return (
                  <Li
                    key={projectID}
                    aria-describedby="floorMap"
                    onClick={(event) =>
                      handleFloorClick(event, projectID, project?.floorMap, project?.hotspot)
                    }
                    activeList={activeFloorMapImgId === projectID}
                  >
                    {project?.floorNumber}
                    {activeFloorMapImgId === projectID && (
                      <Box
                        sx={{
                          position: "relative",
                          mt: "10px",
                          "&::before": {
                            backgroundColor: "white !important",
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: "-10px",
                            marginLeft: "18px",
                            transform: "rotate(45deg)",
                            left: "calc(50% - 6px)",
                          },
                        }}
                      />
                    )}
                  </Li>
                );
              })}
          </FloorMapList>
        )}
        <Popover
          id="floorMap"
          open={popoverOpen}
          anchorEl={anchorEl}
          sx={{ marginLeft: "38px" }}
          onClose={handleClosePopover}
          arrow={true}
          PaperProps={{
            style: {
              backgroundColor: "white",
              padding: "16px",
              scrollbarWidth: "thin",
              scrollbarColor: "#6248BF #EAECF0",
              width: isSmallScreen ? "350px" : isMediumScreen ? "400px" : "auto",
              height: isSmallScreen ? "250px" : isMediumScreen ? "300px" : "auto",
              overflow: "auto",
            },
          }}
        >
          <FloorMapPopoverContent
            activeFloorMapHotspots={activeFloorMapHotspots}
            activeFloorMapImg={activeFloorMapImg}
            scenes={scenes}
            handleClosePopover={handleClosePopover}
            changeScene={changeScene}
            type="projectView"
          />
        </Popover>
        <ImagesAndOptions>
          <div style={{ height: "60px", padding: "20px", paddingBottom: "55px" }}>
            <FloorMapsIcon
              src={isFloorMapActive ? activeFloorMapsIcon : floorMapsIcon}
              alt="_floorMaps"
              onClick={() => setIsFloorMapActive(!isFloorMapActive)}
            />
          </div>
        </ImagesAndOptions>
        <div id="panorama" />
      </CanvasContainer>
    </div>
  );
}
export default ProjectViewPanoramaCanvas;
