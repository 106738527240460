/* eslint-disable no-unused-expressions */
/* eslint-disable object-shorthand */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import closeModalIcon from "../../../assets/images/close-modal-icon.svg";
import deleteBtnRed from "../../../assets/images/delete-btn-red.svg";

function FloorMapPopoverContent({
  activeFloorMapImg,
  scenes,
  handleClosePopover,
  setFloorMapHotspots,
  activeFloorMapHotspots,
  changeScene,
  type,
}) {
  const [circles, setCircles] = useState([]);
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(false);
  const [edit, setEdit] = useState(false);
  const [updateHotspotImgID, setUpdateHotspotImgID] = useState("");
  const [updateHotspotID, setUpdateHotspotID] = useState(null);

  useEffect(() => {
    setCircles(activeFloorMapHotspots);
  }, [activeFloorMapHotspots]);
  useEffect(() => {
    // Create hotspots from circles
    const newHotspots = circles.map((circle) => {
      const { key, props } = circle;
      const { cx, cy, imageId, imageTitle } = props;

      return {
        key,
        props: {
          cx,
          cy,
          imageId,
          imageTitle,
        },
      };
    });

    // Update hotspots state
    type === "3dTour" && setFloorMapHotspots(newHotspots);
  }, [circles]);

  const getClickCoords = (event) => {
    const e = event.target;
    const dim = e.getBoundingClientRect();
    const x = event.clientX - dim.left;
    const y = event.clientY - dim.top;
    return [x, y];
  };
  const addCircle = (e) => {
    if (type === "3dTour") {
      e.preventDefault();
      setOpen(true);
      setEvent(e);
    }
  };
  const generateUUID = () => {
    const uuid = uuidv4();
    return uuid;
  };
  const handleSelectedImg = (imageID, imageTitle) => {
    if (!edit) {
      // get click coordinates
      const id = generateUUID();
      const [x, y] = getClickCoords(event);
      const newCircle = (
        <circle
          key={id}
          cx={x}
          cy={y}
          r="10"
          stroke="black"
          strokeWidth="1"
          fill="red"
          imageId={imageID}
          imageTitle={imageTitle}
        />
      );
      const allCircles = [...circles, newCircle];

      // update 'circles'
      setCircles(allCircles);
    } else {
      const dummyCircleData = [...circles];

      // Find the index of the circle object to update
      const circleIndex = dummyCircleData.findIndex((circle) => circle.key === updateHotspotID);

      if (circleIndex !== -1) {
        // If the circle with the specified imageId is found
        // Update the imageId property of the circle object
        dummyCircleData[circleIndex] = {
          ...dummyCircleData[circleIndex],
          props: {
            ...dummyCircleData[circleIndex].props,
            imageId: imageID,
            imageTitle: imageTitle,
          },
        };
        setCircles(dummyCircleData);
      }
    }
    setEdit(false);
    setUpdateHotspotID(null);
    setUpdateHotspotImgID("");
    setOpen(false);
  };

  const handleDeleteHotspot = () => {
    const updatedCircles = circles.filter((circle) => circle.key !== updateHotspotID);
    setCircles(updatedCircles);
    setOpen(false);
    setUpdateHotspotID(null);
    setUpdateHotspotImgID("");
  };

  return (
    <>
      <div style={{ background: "white" }}>
        <Header>
          <img onClick={handleClosePopover} src={closeModalIcon} alt="_close" />
        </Header>
        <FloorImg onDoubleClick={addCircle}>
          <Image src={activeFloorMapImg} alt="_floorMap" />
          <Svg>
            {circles.map((circle) => (
              <Tooltip title={circle?.props?.imageTitle}>
                <circle
                  key={circle?.key}
                  cx={circle?.props?.cx}
                  cy={circle?.props?.cy}
                  r="10"
                  stroke="white"
                  strokeWidth="2"
                  fill="#7F56D9"
                  imageId={circle?.props?.imageId}
                  imageTitle={circle?.props?.imageTitle}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (type === "projectView") {
                      changeScene(circle?.props?.imageId);
                    } else {
                      setOpen(true);
                      setEdit(true);
                      setUpdateHotspotImgID(circle?.props?.imageId);
                      setUpdateHotspotID(circle?.key);
                    }
                  }}
                />
              </Tooltip>
            ))}
          </Svg>
        </FloorImg>
      </div>
      <CustomDialog
        // disableBackdropClick
        open={open}
      >
        <ModalContainer>
          <ModalHeader>
            <ModalHeading>Choose screen</ModalHeading>
            <HeaderDelBtnDiv>
              <DeleteHotspotBtn onClick={handleDeleteHotspot} src={deleteBtnRed} alt="_delete" />
              <img
                onClick={() => {
                  setOpen(false);
                  setEdit(false);
                  setUpdateHotspotImgID("");
                }}
                style={{ cursor: "pointer" }}
                src={closeModalIcon}
                alt="_close"
              />
            </HeaderDelBtnDiv>
          </ModalHeader>
          <ModalContent>
            <Images>
              {Object.keys(scenes).map((key) => {
                const scene = scenes[key];
                const { panorama, title } = scene;

                return (
                  <Img key={key} onClick={() => handleSelectedImg(key, title)}>
                    <InnerImag
                      style={{
                        background: `url(${panorama})`,
                        border: edit && updateHotspotImgID === key ? "5px solid black" : "",
                      }}
                    />
                    <TextField
                      sx={{
                        width: "-webkit-fill-available",
                        marginTop: "16px",
                        border: edit && updateHotspotImgID === key ? "5px solid black" : "",
                      }}
                      id="outlined-basic"
                      placeholder="Image title"
                      disabled
                      variant="outlined"
                      value={title}
                    />
                  </Img>
                );
              })}
            </Images>
          </ModalContent>
        </ModalContainer>
      </CustomDialog>
    </>
  );
}

export default FloorMapPopoverContent;

const Header = styled("div")({
  display: "flex",
  justifyContent: "end",
  gap: "10px",
  marginBottom: "12.5px",
  width: "fit-content",
});
const FloorImg = styled("div")({
  width: "500px",
  height: "345.907px",
  overflowX: "auto",
  overflowY: "hidden",
  position: "relative",
});
const Image = styled("img")({
  width: "500px",
  height: "345.907px",
  borderRadius: "8px",
  border: "1px solid #C5C6C8",
});
const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "45%",
    height: "80%",
    maxWidth: "none", // Set this to prevent the modal from being restricted by the default maxWidth
  },
});
const ModalContainer = styled("div")({
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  height: "100%", // Set the modal container to occupy the full height
});
const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  flex: "0 0 auto", // Fixed height for the header
});
const ModalHeading = styled("p")({
  color: "#101828",
  fontSize: "18px",
  fontWeight: "600",
});
const ModalContent = styled("div")({
  flex: "1 1 auto", // Allow the content to grow and occupy the remaining space with a scrollable behavior
  overflowY: "auto", // Enable vertical scrolling if the content overflows
  scrollbarWidth: "thin", // Set the scrollbar width to be thin (supported in webkit-based browsers)
  scrollbarColor: "#6248BF #EAECF0", // Set the scrollbar track and thumb colors
  "&::-webkit-scrollbar": {
    width: "6px", // Set the width of the scrollbar
  },
  "&::-webkit-scrollbar-track": {
    background: "#EAECF0", // Set the color of the scrollbar track
    borderRadius: "4px", // Set the border radius of the scrollbar track
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#6248BF", // Set the color of the scrollbar thumb
    borderRadius: "4px", // Set the border radius of the scrollbar thumb
  },
});
const Images = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "50px 20px",
  marginTop: "20px",
  padding: "0px 10px",
});
const Img = styled("div")({
  height: "200px",
  marginBottom: "16px", // Adjust the spacing between items as per your requirement
  marginTop: "24px",
  "@media (min-width: 0px) and (max-width: 1300px)": {
    height: "150px",
  },
});
const InnerImag = styled("div")({
  position: "relative",
  height: "inherit",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
  borderRadius: "16px",
});
const HeaderDelBtnDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "15px",
});
const DeleteHotspotBtn = styled("img")({
  width: "32px",
  padding: "5px",
  borderRadius: "25px",
  background: "#fee4e2",
  cursor: "pointer",
});
const Svg = styled("svg")({
  position: "absolute",
  left: "0px",
  width: "500px",
  height: "345.907px",
});
