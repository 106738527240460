// Material Dashboard 2 React layouts
// import Dashboard from "layouts/dashboard";
// import Dashboard from "layouts/DashboardLayout";
import Dashboard from "layouts/Overview";
// import Dashboard from "layouts/DashboardLayout";
import ProjectGallery from "layouts/ProjectGallery/index";
import ViewProject from "layouts/ProjectGallery/ViewProject/index";
import Settings from "layouts/Settings/index";
// import EditPdfDText from "layouts/AdminLayouts/EditPdfDefaultText";
// import AddNewUser from "layouts/AdminLayouts/AddInspector";
// import ManageUsers from "layouts/AdminLayouts/ManageUsers";
// import Notifications from "layouts/notifications";
// import PdfProject from "layouts/pdfProject";
import PanoramaProject from "layouts/PanoramaProject";

import ProjectWorkSpace from "layouts/ProjectWorkSpace";
// import AdminInvoice from "layouts/AdminLayouts/AdminInvoice";
import CreateProject from "layouts/AdminLayouts/CreateProject";
// import ViewProjects from "layouts/AdminLayouts/ViewProjects";
import InspectionNotes from "layouts/AdminLayouts/InspectionNotes";
import InspectionDetails from "layouts/InspectionDetails";
// import Feedbacks from "layouts/AdminLayouts/Feedbacks";
// import Profile from "layouts/profile";

// import SignIn from "layouts/authentication/sign-in";
import Logout from "layouts/authentication/log-out";

// @mui icons
import Icon from "@mui/material/Icon";
// import EditCompanyInfo from "layouts/AdminLayouts/EditCompanyInfo";
import InspectionImages from "layouts/InspectionImages";
import TextLibraries from "layouts/TextLibrariesLayout/TextLibraries";
// import projectGalleryIcon from "./assets/images/project-gallery-icon.svg";
// import textLibIcon from "./assets/images/text-library-icon.png";
import addProjIcon from "./assets/images/add-proj-icon.svg";
import dashboardIcon from "./assets/images/dashboard-icon.svg";
import projGalleryIcon from "./assets/images/proj-gallery-icon.svg";
import txtLibIcon from "./assets/images/text-lib-icon.svg";
// import billingIcon from "./assets/images/billing-icon.svg";
// import settingsIcon from "./assets/images/settings-icon.svg";

const routes = [
  {
    type: "collapse",
    name: "Create Project",
    key: "newproject",
    icon: <img src={addProjIcon} width="20px" alt="add_project" />,
    route: "/newproject",
    component: <CreateProject />,
  },
  {
    type: "collapse",
    name: "Overview",
    key: "dashboard",
    icon: <img src={dashboardIcon} width="20px" alt="dashboard" />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Project Gallery",
    key: "projectGallery",
    icon: <img src={projGalleryIcon} width="20px" alt="project_gallery" />,
    route: "/projectGallery",
    component: <ProjectGallery />,
  },
  {
    type: "",
    name: "Project Gallery",
    key: "projectGallery",
    icon: <img src={projGalleryIcon} width="20px" alt="project_gallery" />,
    route: "/projectGallery/viewProject/:id",
    component: <ViewProject />,
  },
  {
    type: "collapse",
    name: "Text Libraries",
    key: "textlibraries",
    icon: <img src={txtLibIcon} width="20px" alt="text_library" />,
    route: "/textlibraries",
    component: <TextLibraries />,
  },
  {
    key: "router",
    route: "/projectworkspace/:id",
    component: <ProjectWorkSpace />,
  },
  {
    type: "",
    name: "inspectionDetails",
    key: "inspectionDetails",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/:id/inspectionDetails",
    component: <InspectionDetails />,
  },
  {
    type: "",
    name: "InspectionNotes",
    key: "inspectionNotes",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/:id/inspectionDetails/InspectionNotes",
    component: <InspectionNotes />,
  },
  {
    type: "",
    name: "InspectionImages",
    key: "inspectionImages",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/:id/inspectionDetails/InspectionImages",
    component: <InspectionImages />,
  },
  // {
  //   type: "collapse",
  //   name: "Edit Company Info",
  //   key: "editcompanyinfo",
  //   icon: <Icon fontSize="small">apartment</Icon>,
  //   route: "/editcompanyinfo",
  //   component: <EditCompanyInfo />,
  // },
  // {
  //   type: "collapse",
  //   name: "Edit PDF Default Text",
  //   key: "editpdftext",
  //   icon: <Icon fontSize="small">text_fields</Icon>,
  //   route: "/editpdftext",
  //   component: <EditPdfDText />,
  // },

  // {
  //   type: "collapse",
  //   name: "View Projects",
  //   key: "projects",
  //   icon: <Icon fontSize="small">folder_zip</Icon>,
  //   route: "/projects",
  //   component: <ViewProjects />,
  // },
  // {
  //   type: "collapse",
  //   name: "Project Feedbacks",
  //   key: "feedbacks",
  //   icon: <Icon fontSize="small">reviews</Icon>,
  //   route: "/feedbacks",
  //   component: <Feedbacks />,
  // },
  // {
  //   type: "collapse",
  //   name: "Invoice",
  //   key: "invoice",
  //   icon: <Icon fontSize="small">receipt</Icon>,
  //   route: "/invoice",
  //   component: <AdminInvoice />,
  // },
  {
    type: "",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/log-out",
    component: <Logout />,
  },
  {
    type: "",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,

    route: "/:id/inspectionDetails/InspectionImages/editor",
    component: <PanoramaProject />,
  },
];
export default routes;
